import type {CountryCode} from "./offerings";
import type {
    AdvancedBetSelection,
    BetSelection,
    BetSelectionWithoutWildcard,
} from "./selectionTypes";

export type MatchNumber = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9";

export type Team = {
    name: string;
    shortName: string;
    abbreviation: string;
};

export enum MatchStatus {
    PUBLISHED = "PUBLISHED",
    UPCOMING = "UPCOMING",
    ONGOING = "ONGOING",
    DECIDED = "DECIDED",
    CANCELED = "CANCELED",
    UNDER_REVIEW = "UNDER_REVIEW",
}

export type MatchScore = {
    home: number;
    away: number;
} | null;

export type MatchResult = {
    winner: Exclude<BetSelection, "under" | "over" | "wildcard">;
    goals: Exclude<BetSelection, "home" | "tie" | "away" | "wildcard">;
};

export enum MatchClockStatus {
    Ongoing = "ongoing",
    Stopped = "stopped",
}

export type MatchClock = {
    minute: number;
    status: MatchClockStatus;
    period: number;
};

export enum MatchPushEventType {
    MatchStarted = "matchStarted",
    MatchEnded = "matchEnded",
    MatchCanceled = "matchCanceled",
    StartCanceled = "startCanceled",
    ScoreChange = "scoreChange",
    Overtime = "overtime",
}

export enum PeriodType {
    PeriodStarted = "PERIOD_STARTED",
    PeriodEnded = "PERIOD_ENDED",
    OverTime = "OVERTIME",
}

export type LiveData = {
    // This comes with the offering when the offering is live.
    period?: {
        timestamp: string;
        clockMinutes: number;
        type: PeriodType;
    };
    overTime?: {
        timestamp: string;
        type: string;
    };
};

export type Sportradar = {
    eventId: string | null;
    matchId?: string | null;
    seasonId: string | null;
};

export type Kambi = {
    eventId: number | null;
};

export type Championship = {
    name: string;
    sport: string;
    flag: CountryCode;
};

export type Odds = {[BS in BetSelectionWithoutWildcard]: number | null};

export type BetDistribution = {
    [W in MatchResult["winner"]]: {[G in MatchResult["goals"]]: number};
} & {
    wildcard: number;
};

export type SimpleBetDistribution = {
    [BS in BetSelection]: number;
};

export type AdvancedBetDistribution = {
    [ABS in AdvancedBetSelection]: number;
};

export type MatchComment = {[key: string]: string};

export type MatchComments = {[key: string]: MatchComment};

export type MatchRef = Pick<Match, "id" | "number" | "startTime" | "status">;

export type Match = {
    id: string;
    number: MatchNumber;
    status: MatchStatus;
    startTime: string; // UTCDateTime, date format ISO 8601, Date.prototype.toISOString()
    homeTeam: Team;
    awayTeam: Team;
    championship: Championship;
    odds?: Odds & {source?: string};
    betDistribution?: BetDistribution;
    result: MatchResult;
    score?: MatchScore;
    sportradar: Sportradar;
    kambi?: Kambi;
    liveData?: LiveData;
    lastUpdated?: string;
    canceledAt?: string;
};
