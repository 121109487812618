import type {SagaIterator} from "redux-saga";
import type {ApolloError} from "@apollo/client";
import {call, put, select, take, takeLatest} from "redux-saga/effects";
import {AuthActions} from "@atg-shared/auth";
import {TeamActions, TeamSelectors} from "@atg-tillsammans/team-data-access/redux/team";
import {TeamInviteStatus, type RoundErrorCode} from "@atg-tillsammans/types";
import {TeamService} from "@atg-tillsammans/team-data-access/services";
import {ToastContainerId} from "@atg-tillsammans/toasts-types";
import {ApplicationUtils} from "@atg-tillsammans/utils";
import * as ToastActions from "atg-ui-toast/domain/toastActions";
import {RoundService} from "../services";
import {
    PURCHASE_CONFIRM,
    PURCHASE_STARTED,
    RoundPurchaseActions,
    type RoundPurchaseConfirmAction,
} from "./round.actions";
import {RoundPurchaseModalType} from "./round.types";

/**
 * @param action: RoundPurchaseAction
 * @returns
 */
export function* initPurchase(): SagaIterator {
    yield put(AuthActions.checkAuth(true));

    const authResult = yield take(AuthActions.AUTH_CHECK_RESPONSE);

    if (authResult.error) {
        return;
    }

    if (authResult.payload.memberId) {
        yield put(RoundPurchaseActions.setPurchaseModal(RoundPurchaseModalType.CONFIRM));
    }
}

/**
 * @param action: RoundPurchaseConfirmAction
 * @returns
 */
export function* confirmPurchase(action: RoundPurchaseConfirmAction): SagaIterator {
    yield put(AuthActions.checkAuth(true));

    const authResult = yield take(AuthActions.AUTH_CHECK_RESPONSE);

    if (authResult.error) {
        return;
    }

    const isTeamMember = yield select(
        TeamSelectors.isTeamMember,
        action.payload.input.teamId,
    );

    if (!isTeamMember) {
        try {
            const [status, errors] = yield call(TeamService.joinTeam, {
                teamId: action.payload.input.teamId,
                greeting: null,
            });

            const success =
                status === TeamInviteStatus.JOINED ||
                status === TeamInviteStatus.ALREADY_MEMBER;

            if (success) {
                yield put(TeamActions.fetchMyTeams(true));
                yield put(
                    ToastActions.showToast({
                        containerId: ToastContainerId.TEAM_PAGE,
                        type: "success",
                        message: ApplicationUtils.getCreateSuccessMessage(status),
                        timeout: 10000,
                        reason: status,
                    }),
                );
            }

            if (!success || errors) {
                yield put(RoundPurchaseActions.purchaseFinished());
                return;
            }
        } catch (error: unknown) {
            log.error(error);
            yield put(RoundPurchaseActions.purchaseFinished());
            return;
        }
    }

    try {
        const result = yield call(RoundService.createParticipation, {
            input: action.payload.input,
        });

        if (result?.participation) {
            yield put(
                RoundPurchaseActions.purchaseSuccess({
                    participant: result.participation,
                    roundId: action.payload.roundId,
                }),
            );
            yield put(
                RoundPurchaseActions.setPurchaseModal(RoundPurchaseModalType.SUCCESS),
            );
        }

        if (result?.error) {
            yield put(
                RoundPurchaseActions.purchaseError({
                    roundId: action.payload.roundId,
                    errorCode: result.error,
                }),
            );
        }
    } catch (error: unknown) {
        yield put(
            RoundPurchaseActions.purchaseError({
                roundId: action.payload.roundId,
                errorCode: (error as ApolloError)?.graphQLErrors[0].extensions
                    ?.errorCode as RoundErrorCode,
            }),
        );
        log.error(error);
    }

    yield put(RoundPurchaseActions.purchaseFinished());
}

export default function* roundSaga() {
    yield takeLatest(PURCHASE_STARTED, initPurchase);
    yield takeLatest(PURCHASE_CONFIRM, confirmPurchase);
}
