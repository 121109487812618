import system from "@atg-shared/system";

/** @deprecated in favor of `atg-breakpoints` */
export const XXS = 374;

/** @deprecated in favor of `atg-breakpoints` */
export const XS = 480;

/** @deprecated in favor of `atg-breakpoints` */
export const SM = 768;

/** @deprecated in favor of `atg-breakpoints` */
export const MD = 992;

/** @deprecated in favor of `atg-breakpoints` */
export const LG = 1200;

/** @deprecated in favor of `atg-breakpoints` */
export const XL = system.isTillsammans() ? 1336 : 1500;

/** @deprecated in favor of `atg-breakpoints` */
export const XS_ONLY = XS - 1;
