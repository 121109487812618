import type {Reducer} from "redux";
import * as _FetchReducer from "@atg-shared/fetch-redux";
import type {PersonalizationAction} from "./personalizationActions";
import * as PersonalizationActions from "./personalizationActions";
import type {PersonalizationState} from "./personalization";

const FetchReducer = _FetchReducer as any;

const DEFAULT_STATE: PersonalizationState = {notificationSettings: [], guideFlags: []};

const personalization: Reducer<PersonalizationState> = FetchReducer.createFetchReducer(
    PersonalizationActions.UPDATE_PUSH_NOTIFICATION_SETTINGS_REQUEST,
    PersonalizationActions.UPDATE_PUSH_NOTIFICATION_SETTINGS_RESPONSE,
    "",
    (state: PersonalizationState, action: PersonalizationAction) => {
        switch (action.type) {
            case PersonalizationActions.RECEIVED_PERSONALIZATION_SETTINGS_PUSH:
                return {...state, ...action.payload};
            case PersonalizationActions.UPDATE_PUSH_NOTIFICATION_SETTINGS_RESPONSE:
                if (action.error) return state;
                return {...state, notificationSettings: action.payload};
            case PersonalizationActions.UPDATE_GUIDE_FLAG_RESPONSE:
                if (action.error) return state;
                return {...state, guideFlags: action.payload};
            default:
                return state;
        }
    },
    DEFAULT_STATE,
);

export default personalization;
