/* eslint-disable lingui/no-unlocalized-strings */

export const breakpoints = {
    xs: 0,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1536,
};

export const mediaQueries = {
    minWidth: {
        /** Legacy value for xs was 480 this field in mainly for support of old styles */
        legacy_xxs: `(min-width: 375px)`,
        /** Legacy value for xs was 480 this field in mainly for support of old styles */
        legacy_xs: `(min-width: 480px)`,
        sm: `(min-width: ${breakpoints.sm}px)`,
        md: `(min-width: ${breakpoints.md}px)`,
        lg: `(min-width: ${breakpoints.lg}px)`,
        xl: `(min-width: ${breakpoints.xl}px)`,
    },
    maxWidth: {
        xs: `(max-width: ${breakpoints.sm - 1}px)`,
    },
    only: {
        sm: `(min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.md - 1}px)`,
    },
};
