import {type ImageAspectRatio} from "../domain/aspectRatio";
import type {ImageSize, ImageProps, ImageUrl, ImageRef} from "../domain/image";
import {tools as ImageTools} from "../domain/image";

const resolvAspectRatio = (width: number, aspectRatio: ImageAspectRatio): ImageSize => ({
    width,
    height: Math.ceil(width * aspectRatio.heightMultiplicand),
});

const getResolutions = (params: ImageProps): string | null => {
    const {imageUrl, width = 0, height = 0} = params;
    if (!ImageTools.isValidUrl(imageUrl) || (width === 0 && height === 0)) return null;
    const x1 = ImageTools.size({
        imageUrl,
        width,
        height,
    });
    const x2 = ImageTools.size({
        imageUrl,
        width: width * 2,
        height: height * 2,
    });
    return [`${x1} 1x`, `${x2} 2x`].join(",");
};

const forAspectRatio = (aspectRatio: ImageAspectRatio) => ({
    getSrcSet: ({imageUrl, width = 0}: ImageProps) =>
        getResolutions({imageUrl, ...resolvAspectRatio(width, aspectRatio)}),
    getSrc: ({imageUrl, width = 0}: ImageProps) =>
        ImageTools.size({imageUrl, ...resolvAspectRatio(width, aspectRatio)}),
});

const forFixedHeight = (height: number) => ({
    getSrcSet: ({imageUrl, width = 0}: ImageProps) =>
        getResolutions({imageUrl, width, height}),
    getSrc: ({imageUrl, width = 0}: ImageProps) =>
        ImageTools.size({imageUrl, width, height}),
});

const getImageRef = (imageUrl: ImageUrl | string): ImageRef =>
    imageUrl.replace(/^.*[\\/]/, "");

const ImageUtils = {
    resolvAspectRatio,
    getResolutions,
    forAspectRatio,
    forFixedHeight,
    getImageRef,
};

export default ImageUtils;
