import {combineReducers} from "redux";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import {InvitationSetting, MembershipPolicy, TeamType} from "@atg-tillsammans/types";
import {
    CREATE_TEAM,
    CREATE_TEAM_ERROR,
    RECEIVE_REVOKE_APPLICATION,
    RECEIVE_TEAM,
    RECEIVE_TEAM_MEMBERS,
    RECEIVE_TEAMS,
    REQUEST_REVOKE_APPLICATION,
    REQUEST_TEAM_MEMBERS,
    RESET_CREATE_TEAM,
    RESET_REVOKE_APPLICATION,
    RESET_TEAM,
    RESET_TEAM_MEMBERS,
    RESET_TEAMS,
    SET_UNREAD_COMMENTS,
    TEAM_CREATED,
    TEAM_IMAGE_CHANGED,
    UPLOAD_TEAM_IMAGE,
    UPLOAD_TEAM_IMAGE_ERROR,
    UPLOAD_TEAM_IMAGE_SUCCESS,
} from "./team.actions";
import type {TeamAction} from "./team.actions";
import {DEFAULT_CREATE_TEAM_STATE, type MemberTeamState} from "./teamTypes";

const list = (state: MemberTeamState = {items: null}, action: TeamAction) => {
    switch (action.type) {
        case RECEIVE_TEAMS:
            return {
                ...state,
                items: action.payload.teams,
            };
        case RESET_TEAMS:
            return {
                ...state,
                items: null,
            };
        case SET_UNREAD_COMMENTS:
            if (!state.items) return state;
            return {
                ...state,
                items: state.items.map((item) =>
                    item.id === action.payload.teamId
                        ? {
                              ...item,
                              unreadComments: action.payload.nrOfUnreadComments,
                          }
                        : item,
                ),
            };
        default:
            return state;
    }
};

export const INITIAL_TEAM_STATE = {
    id: 0,
    name: "",
    leader: {
        id: 0,
        name: "",
    },
    type: TeamType.STANDARD,
    teamImageUrl: null,
    gameTypes: [],
    ranking: 0,
    description: "",
    location: {
        postalTown: "",
        postalCode: "",
    },
    membershipPolicy: MembershipPolicy.PRIVATE,
    invitation: InvitationSetting.TEAM_CAPTAIN,
    multipleReleasesAllowed: false,
    filePlayAllowed: false,
    visible: true,
    locationVisible: false,
    createdDate: "",
    isAdmin: false,
    isCaptain: false,
};

const team = (state = {teamId: null, teamImageUrl: null}, action: TeamAction) => {
    switch (action.type) {
        case RECEIVE_TEAM:
            return {
                ...state,
                teamId: action.payload.teamId,
            };
        case RESET_TEAM:
            return {teamId: null, teamImageUrl: null};
        case TEAM_IMAGE_CHANGED:
            return {
                ...state,
                teamImageUrl: action.payload.imageUrl,
            };
        default:
            return state;
    }
};

const teamMembers = createFetchReducer(
    REQUEST_TEAM_MEMBERS,
    RECEIVE_TEAM_MEMBERS,
    RESET_TEAM_MEMBERS,
    (state, action) => {
        switch (action.type) {
            case RECEIVE_TEAM_MEMBERS:
                if (action.error) return state;

                return {
                    data: action.payload,
                };
            default:
                return state;
        }
    },
    {
        data: [],
    },
);

const imageUploadFlow = (state = {loading: false, error: null}, action: TeamAction) => {
    switch (action.type) {
        case UPLOAD_TEAM_IMAGE:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case UPLOAD_TEAM_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case UPLOAD_TEAM_IMAGE_ERROR:
            return {
                ...state,
                loading: false,
                error: {
                    message: action.payload.message,
                },
            };
        default:
            return state;
    }
};

const createTeamFlow = (state = DEFAULT_CREATE_TEAM_STATE, action: TeamAction) => {
    switch (action.type) {
        case CREATE_TEAM:
            return {
                ...state,
                loading: true,
            };
        case TEAM_CREATED:
            return {
                ...state,
                teamId: action.payload.teamId,
                loading: false,
            };
        case CREATE_TEAM_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error.message,
            };
        case RESET_CREATE_TEAM:
            return {
                ...DEFAULT_CREATE_TEAM_STATE,
            };
        default:
            return state;
    }
};

const revokeApplication = createFetchReducer(
    REQUEST_REVOKE_APPLICATION,
    RECEIVE_REVOKE_APPLICATION,
    RESET_REVOKE_APPLICATION,
    (state) => state,
    {},
);

const teamsReducer = combineReducers({
    list,
    team,
    teamMembers,
    imageUploadFlow,
    revokeApplication,
    createTeamFlow,
});

export default teamsReducer;
