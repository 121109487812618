import type {Store, Action} from "redux";
import type {SagaMiddleware} from "redux-saga";
import {initLazyReducerStore} from "./lazyReducer";
import type {
    LazyReducerStore,
    ReducerMap as ReducerMapType,
    CreateReducerFunc,
} from "./lazyReducer";
import {initLazySagaStore} from "./lazySaga";
import type {LazySagaStore} from "./lazySaga";

export type ReducerMap<S, A extends Action> = ReducerMapType<S, A>;

/**
 * A lazy store consists of two extensions, making it possible to inject reducers and sagas later,
 * after the initial store setup
 */
export type LazyStore<S, A extends Action> = LazySagaStore<
    S,
    A,
    LazyReducerStore<S, A, Store<S, A>>
>;

export const initLazyStore = <S, A extends Action>(
    store: Store<S, A>,
    createReducer: CreateReducerFunc<S, A>,
    sagaMiddleware: SagaMiddleware,
): LazyStore<S, A> =>
    initLazySagaStore(initLazyReducerStore(store, createReducer), sagaMiddleware);
