import {type PersistPartial} from "redux-persist/lib/persistReducer";
import {type MemberFlags} from "../types/app";

export type FetchMemberCustomizationResponse = {
    id: string;
    context: string;
    data: string;
};

export interface CustomizationState {
    customizationId?: string;
}

export type FlagsPersistState = MemberFlags & PersistPartial;

export interface MemberCustomizationState {
    data: CustomizationState;
    flags: FlagsPersistState;
}

export interface GlobalState {
    memberCustomization: MemberCustomizationState;
}

/* Default data conbstants */
export const DEFAULT_MEMBER_FLAGS: MemberFlags = {
    showStartPageHero: true,
    showStartPageOnboarding: true,
};
