const CALL_AUTH = "call-auth/CALL_AUTH";

export const CallAuthActionType = {
    CALL_AUTH,
};

export type CallAuthInput = {
    onSuccess: () => void;
    onCancel?: () => void;
};
