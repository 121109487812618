import type {Store} from "redux";
import {call, take, takeLatest} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import {t} from "@lingui/macro";
import {applyPatch} from "fast-json-patch";
import log from "@atg-tillsammans/log";
import {subscribe} from "@atg-frame-shared/push";
import * as GameUtils from "../../../common/gameUtils";
import * as GameSelectors from "../../../common/redux/gameSelectors";
import * as GameActions from "../../../common/redux/gameActions";
import * as HorseGameActions from "../horseGameActions";
import type {HorseGame} from "../../horseGameTypes";
import type {HorseGamePatchData} from "./horseGamePushTypes";

const PUSH_TOPIC_ROOT = "racinginfo/game";

export const messageCallbackFn =
    (gameId: string, {dispatch, getState}: Store) =>
    async (message?: HorseGamePatchData) => {
        if (!message) return;

        const {version, previousVersion} = message;

        const game = GameSelectors.getGameById(getState(), gameId) as HorseGame | null;
        if (!game) return;

        // Do nothing here since the push message is the same or is an older version of the local version.
        if (game.version >= version) {
            return;
        }

        // The local version is not in sync with push, refetch the data from server
        if (!previousVersion || game.version < previousVersion) {
            dispatch(GameActions.fetchGame(gameId));
            return;
        }
        const updatedGame = applyPatch<HorseGame>({...game}, message.patch).newDocument;

        // Apply the patch and update reducer state
        dispatch(HorseGameActions.updateGame(gameId, updatedGame));
    };

export function* pushListener(
    store: Store,
    action: GameActions.GameStartPushListenerAction,
): SagaIterator {
    const {gameId} = action.payload;

    // Not supporting sport game push atm, that's handled separately.
    if (GameUtils.isSportGame(gameId)) return;

    log.debug(t`gameSaga [horse]: Subscribe action received. Starting push.`);

    const messageCallback = yield call(messageCallbackFn, gameId, store);

    const unsubscribe = yield call(
        subscribe,
        `${PUSH_TOPIC_ROOT}/${gameId}`,
        messageCallback,
        true,
        false,
    );

    log.debug(t`gameSaga [horse]: Push started. Receiving messages.`);

    yield take(GameActions.GAME_STOP_PUSH_LISTENER);
    yield call(unsubscribe);

    log.debug(t`gameSaga [horse]: Unsubscribe action recieved. Stopping push.`);
}

export default function* horsePushListener(store: Store): SagaIterator {
    yield takeLatest(GameActions.GAME_START_PUSH_LISTENER, pushListener, store);
}
