import {
    LOADING_STATUS,
    type LoadingStatus,
    type FetchState,
} from "@atg-shared/fetch-types";

const INITIAL_STATE = {loading: false, loaded: false, error: false};

export const getLoadingState = <State extends FetchState<unknown>>(
    state: State,
    // eslint-disable-next-line no-underscore-dangle
) => state.__loadingState || INITIAL_STATE;

export const isLoading = <State extends FetchState<unknown>>(state: State): boolean =>
    getLoadingState(state).loading;

export const isLoaded = <State extends FetchState<unknown>>(state: State): boolean =>
    getLoadingState(state).loaded;

export const hasError = <State extends FetchState<unknown>>(state: State): boolean =>
    getLoadingState(state).error;

// we don't want to inline these inside `getLoadingStatus` since they will cause unnecessary React
// rerenders (creating a new object each time the selector is called means the `connect`ed component
// is forced to rerender, even if the status hasn't changed)
const LOADING = {status: LOADING_STATUS.LOADING} as const;
const OK_STATUS = {status: LOADING_STATUS.OK} as const;
const INITIAL_STATUS = {status: LOADING_STATUS.INITIAL} as const;

export const getLoadingStatus = <State extends FetchState<unknown>>(
    state: State,
): LoadingStatus => {
    const loadingState = getLoadingState(state);
    const {loaded, loading, error} = loadingState;

    if (loading) return LOADING;
    if (loaded && !error) return OK_STATUS;
    if (error) return {status: LOADING_STATUS.ERROR, exception: loadingState.exception};
    return INITIAL_STATUS;
};

type FetchSelectors<State> = {
    isLoading: (state: State) => boolean;
    isLoaded: (state: State) => boolean;
    hasError: (state: State) => boolean;
};

export const createSelectors = <State>(
    sliceState: (state: State) => FetchState<unknown>,
): FetchSelectors<State> => ({
    isLoading: (state: State) => isLoading(sliceState(state)),
    isLoaded: (state: State) => isLoaded(sliceState(state)),
    hasError: (state: State) => hasError(sliceState(state)),
});

export default createSelectors;
