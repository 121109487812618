import type {Match, MatchPushEventType} from "@atg-sport-shared/big9-types/matchTypes";
import type {
    SportGame,
    SportMatchComments,
    SportMatchStatistics,
} from "../sportGameTypes";
import {EMPTY_MATCH_COMMENTS, EMPTY_MATCH_STATISTICS} from "../sportGameTypes";

export const SPORT_GAME_MATCH_CLOCK_START = "big9/START_MATCH_CLOCK";
export const SPORT_GAME_MATCH_CLOCK_STOP = "big9/STOP_MATCH_CLOCK";
export const SPORT_GAME_MATCH_CLOCK_UPDATE = "big9/UPDATE_MATCH_CLOCK";
export const SPORT_GAME_MATCH_CLOCK_ADD = "big9/ADD_MATCH_CLOCK";
export const SPORT_GAME_MATCH_CLOCKS_START_BY_OFFERING = "big9/START_CLOCKS_BY_OFFERING";

export const SPORT_GAME_FETCH_REQUEST = "game/SPORT_GAME_FETCH_REQUEST";
export const SPORT_GAME_FETCH_RESPONSE = "game/SPORT_GAME_FETCH_RESPONSE";

export const SPORT_GAME_UPDATE = "game/SPORT_GAME_UPDATE";

export const SPORT_GAME_MATCH_UPDATE = "game/SPORT_GAME_MATCH_UPDATE";

export const SPORT_MATCH_STATISTICS_FETCH_REQUEST =
    "game/SPORT_MATCH_STATISTICS_FETCH_REQUEST";
export const SPORT_MATCH_STATISTICS_FETCH_RESPONSE =
    "game/SPORT_MATCH_STATISTICS_FETCH_RESPONSE";

export const SPORT_MATCH_COMMENTS_FETCH_REQUEST =
    "game/SPORT_MATCH_COMMENTS_FETCH_REQUEST";
export const SPORT_MATCH_COMMENTS_FETCH_RESPONSE =
    "game/SPORT_MATCH_COMMENTS_FETCH_RESPONSE";

export type SportGameFetchRequestAction = {
    type: typeof SPORT_GAME_FETCH_REQUEST;
    payload: {gameId: string; forceFetch: boolean};
};

export type SportGameFetchResponseAction = {
    type: typeof SPORT_GAME_FETCH_RESPONSE;
    payload: {
        game: SportGame | null;
    };
    error: boolean;
};

export type SportGameUpdateAction = {
    type: typeof SPORT_GAME_UPDATE;
    payload: {gameId: string; game: SportGame};
};

export type SportGameMatchUpdateAction = {
    type: typeof SPORT_GAME_MATCH_UPDATE;
    payload: {match: Match; eventType?: MatchPushEventType};
};

export type SportMatchStatisticsFetchRequestAction = {
    type: typeof SPORT_MATCH_STATISTICS_FETCH_REQUEST;
    payload: {gameId: string};
};

export type SportMatchStatisticsFetchResponseAction = {
    type: typeof SPORT_MATCH_STATISTICS_FETCH_RESPONSE;
    payload: SportMatchStatistics;
    error: boolean;
};

export type SportMatchCommentsFetchRequestAction = {
    type: typeof SPORT_MATCH_COMMENTS_FETCH_REQUEST;
};

export type SportMatchCommentsFetchResponseAction = {
    type: typeof SPORT_MATCH_COMMENTS_FETCH_RESPONSE;
    payload: SportMatchComments;
    error: boolean;
};

// Match clock action types
type SportGameMatchClockStartPayload = {
    matchId: Match["id"];
    minute?: number;
    timestamp?: string;
};

export type SportGameMatchClockStartAction = {
    type: typeof SPORT_GAME_MATCH_CLOCK_START;
    payload: SportGameMatchClockStartPayload;
};

type SportGameMatchClockStopPayload = {matchId: Match["id"]};

export type SportGameMatchClockStopAction = {
    type: typeof SPORT_GAME_MATCH_CLOCK_STOP;
    payload: SportGameMatchClockStopPayload;
};

type SportGameMatchClockUpdatePayload = {matchId: Match["id"]; minute: number};

export type SportGameMatchClockUpdateAction = {
    type: typeof SPORT_GAME_MATCH_CLOCK_UPDATE;
    payload: SportGameMatchClockUpdatePayload;
};

type SportGameMatchClockAddPayload = {matchId: Match["id"]; minute: number};

export type SportGameMatchClockAddAction = {
    type: typeof SPORT_GAME_MATCH_CLOCK_ADD;
    payload: SportGameMatchClockAddPayload;
};

type SportGameMatchClocksStartByOfferingPayload = {
    offeringId: string;
};

export type SportGameMatchClocksStartByOfferingAction = {
    type: typeof SPORT_GAME_MATCH_CLOCKS_START_BY_OFFERING;
    payload: SportGameMatchClocksStartByOfferingPayload;
};

export type Action =
    | SportGameFetchRequestAction
    | SportGameFetchResponseAction
    | SportGameUpdateAction
    | SportGameMatchUpdateAction
    | SportMatchStatisticsFetchRequestAction
    | SportMatchStatisticsFetchResponseAction
    | SportMatchCommentsFetchRequestAction
    | SportMatchCommentsFetchResponseAction
    | SportGameMatchClockAddAction
    | SportGameMatchClockStartAction
    | SportGameMatchClockStopAction
    | SportGameMatchClockUpdateAction;

export const fetchGame = (
    gameId: string,
    forceFetch = false,
): SportGameFetchRequestAction => ({
    type: SPORT_GAME_FETCH_REQUEST,
    payload: {gameId, forceFetch},
});

export const fetchGameSuccess = (game: SportGame): SportGameFetchResponseAction => ({
    type: SPORT_GAME_FETCH_RESPONSE,
    payload: {game},
    error: false,
});

export const fetchGameError = (): SportGameFetchResponseAction => ({
    type: SPORT_GAME_FETCH_RESPONSE,
    payload: {game: null},
    error: true,
});

export const updateGame = (gameId: string, game: SportGame): SportGameUpdateAction => ({
    type: SPORT_GAME_UPDATE,
    payload: {gameId, game},
});

export const updateMatch = (match: Match): SportGameMatchUpdateAction => ({
    type: SPORT_GAME_MATCH_UPDATE,
    payload: {match},
});

export const fetchMatchStatistics = (
    gameId: string,
): SportMatchStatisticsFetchRequestAction => ({
    type: SPORT_MATCH_STATISTICS_FETCH_REQUEST,
    payload: {gameId},
});

export const fetchMatchStatisticsSuccess = (
    statistics: SportMatchStatistics,
): SportMatchStatisticsFetchResponseAction => ({
    type: SPORT_MATCH_STATISTICS_FETCH_RESPONSE,
    payload: statistics,
    error: false,
});

export const fetchMatchStatisticsError = (): SportMatchStatisticsFetchResponseAction => ({
    type: SPORT_MATCH_STATISTICS_FETCH_RESPONSE,
    payload: EMPTY_MATCH_STATISTICS,
    error: true,
});

export const fetchMatchComments = (): SportMatchCommentsFetchRequestAction => ({
    type: SPORT_MATCH_COMMENTS_FETCH_REQUEST,
});

export const fetchMatchCommentsSuccess = (
    comments: SportMatchComments,
): SportMatchCommentsFetchResponseAction => ({
    type: SPORT_MATCH_COMMENTS_FETCH_RESPONSE,
    payload: comments,
    error: false,
});

export const fetchMatchCommenetsError = (): SportMatchCommentsFetchResponseAction => ({
    type: SPORT_MATCH_COMMENTS_FETCH_RESPONSE,
    payload: EMPTY_MATCH_COMMENTS,
    error: true,
});

// Match clock actions
export const startMatchClock = ({
    matchId,
    minute,
    timestamp,
}: SportGameMatchClockStartPayload): SportGameMatchClockStartAction => ({
    type: SPORT_GAME_MATCH_CLOCK_START,
    payload: {matchId, minute, timestamp},
});

export const addMatchClock = ({
    matchId,
    minute,
}: SportGameMatchClockAddPayload): SportGameMatchClockAddAction => ({
    type: SPORT_GAME_MATCH_CLOCK_ADD,
    payload: {matchId, minute},
});

export const stopMatchClock = ({
    matchId,
}: SportGameMatchClockStopPayload): SportGameMatchClockStopAction => ({
    type: SPORT_GAME_MATCH_CLOCK_STOP,
    payload: {matchId},
});

export const updateMatchClock = ({
    matchId,
    minute,
}: SportGameMatchClockUpdatePayload): SportGameMatchClockUpdateAction => ({
    type: SPORT_GAME_MATCH_CLOCK_UPDATE,
    payload: {matchId, minute},
});

export const startClocksByOffering = (
    offeringId: string,
): SportGameMatchClocksStartByOfferingAction => ({
    type: SPORT_GAME_MATCH_CLOCKS_START_BY_OFFERING,
    payload: {offeringId},
});
