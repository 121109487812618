export const ACCESS_TOKEN_SUCCESS = "authentication/ACCESS_TOKEN_SUCCESS";
export const ACCESS_TOKEN_ERROR = "authentication/ACCESS_TOKEN_ERROR";
export const AUTHENTICATION_SUCCESS = "authentication/AUTHENTICATION_SUCCESS";
export const REQUEST_NEW_ACCESS_TOKEN = "authentication/REQUEST_NEW_ACCESS_TOKEN";
export const RECEIVE_NEW_ACCESS_TOKEN = "authentication/RECEIVE_NEW_ACCESS_TOKEN";
export const RESET_ACCESS_TOKEN = "authentication/RESET_ACCESS_TOKEN";
export const FINISHED_AUTHENTICATION = "authentication/FINISHED_AUTHENTICATION";
export const ACCESS_TOKEN_STORAGE_KEY = "ACCESS_TOKEN";

export const AUTHENTICATION_ERROR = "authentication/AUTHENTICATION_ERROR";

export const ACR_USERNAME_PASSWORD = "urn:se:atg:authentication:html";

export const ACR_BANKID = "urn:se:atg:authentication:bankid";
export const ACR_BANKID_QR = "urn:se:atg:authentication:bankidqr";

export const ACR_FREJA_EID = "urn:se:atg:authentication:freja";
export const ACR_FREJA_EID_QR = "urn:se:atg:authentication:frejaqr";

export const AZP_ATGSE = "atg";
export const FULL_SCOPE_AUTH = "betting_user";
export const REDUCED_SCOPE_AUTH = "general_user";
export const RECEIVE_REDUCED_ACCESS_TOKEN = "authentication/RECEIVE_REDUCED_ACCESS_TOKEN";
export const SET_ACCESS_TOKEN_TIMESTAMP = "authentication/SET_ACCESS_TOKEN_TIMESTAMP";
