import type {AtgErrorResponse} from "@atg-shared/fetch-types";

const UNAUTHORIZED = 401;

/**
 * Checks if the given response is an error response with a 401 Unauthorized
 * status code.
 */
export const requestHadExpiredAccessToken = (res: AtgErrorResponse) =>
    res.meta.code === UNAUTHORIZED;
