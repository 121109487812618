import styled from "@emotion/styled";
import {css} from "@emotion/react";
import {
    atgWhite,
    sm as smMedia,
    md as mdMedia,
    lg as lgMedia,
} from "atg-ui/css/variables.styles";
import type {BorderProps} from "../domain/image";

export const DEFAULT_BORDER: BorderProps = {
    color: atgWhite,
    xs: 2,
    sm: 4,
};

export const fixedBorder = (width: number): BorderProps => ({
    xs: width,
});

const image = css`
    width: 100%;
    position: relative;
`;

export const imageBorder = ({
    color = atgWhite,
    xs = 2,
    sm,
    md,
    lg,
    borderRadius,
}: BorderProps) => css`
    border: ${xs}px solid ${color};

    ${borderRadius &&
    css`
        border-radius: ${borderRadius};
    `}

    ${sm &&
    css`
        @media screen and ${smMedia} {
            border: ${sm}px solid ${color};
        }
    `}

    ${md &&
    css`
        @media screen and ${mdMedia} {
            border: ${md}px solid ${color};
        }
    `}

    ${lg &&
    css`
        @media screen and ${lgMedia} {
            border: ${lg}px solid ${color};
        }
    `}
`;

const fixedHeight = css`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    object-fit: cover;
`;

export const Img = styled.img<{
    borderOptions?: BorderProps;
    rounded?: boolean;
    wrapped?: boolean;
}>`
    ${({borderOptions}) => borderOptions && imageBorder(borderOptions)};
    ${({rounded}) => rounded && "border-radius: 50%"};
    ${image};
    ${({wrapped}) => wrapped && fixedHeight};
`;

export default Img;
