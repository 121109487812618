import type {SagaIterator} from "redux-saga";
import {call, put, take, takeLatest} from "redux-saga/effects";
import type {PlaceBetError} from "@atg-tillsammans-shared/types/sport";
import {PlaceBetErrorCode, PlaceBetFlow} from "@atg-tillsammans-shared/types/sport";
import {PlaceBetUtils} from "@atg-tillsammans-shared/utils/sport";
import * as SagaFlowActions from "@atg-tillsammans-shared/saga-flow/redux/sagaFlowActions";
import type {SportPlaceBetFlowAction} from "./sportBetActions";
import * as SportBetActions from "./sportBetActions";

function* clearPlaceBetFlowMessages(): SagaIterator {
    yield put(
        SagaFlowActions.removeMessage({
            flowId: PlaceBetFlow.PLACE_SPORT_BET,
        }),
    );
}

function* handlePlaceBetFlowError(
    flowId: PlaceBetFlow,
    couponId: string,
    {errorCode}: PlaceBetError,
): SagaIterator {
    yield call(clearPlaceBetFlowMessages);
    switch (errorCode) {
        case PlaceBetErrorCode.INSUFFICIENT_FUNDS:
        case PlaceBetErrorCode.FAILED_TO_GENERATE_BET:
        case PlaceBetErrorCode.MUST_PARTICIPATE: {
            yield put(SagaFlowActions.error(flowId));
            yield put(
                SagaFlowActions.warningMessage({
                    flowId,
                    itemId: couponId,
                    message: PlaceBetUtils.placeBetErrorMessage(errorCode),
                }),
            );
            break;
        }
        case PlaceBetErrorCode.ASYNC_RETRYABLE_TIMEOUT: {
            yield put(SagaFlowActions.warning(flowId));
            yield put(
                SagaFlowActions.warningMessage({
                    flowId,
                    itemId: couponId,
                    message: PlaceBetUtils.placeBetErrorMessage(errorCode),
                }),
            );
            break;
        }
        case PlaceBetErrorCode.ROUND_NOT_ACTIVATED:
        case PlaceBetErrorCode.MAX_NR_OF_BETS:
        case PlaceBetErrorCode.COMPETITION_UNDER_REVIEW:
        case PlaceBetErrorCode.SELL_NOT_OPEN:
        default: {
            yield put(SagaFlowActions.error(flowId));
            yield put(
                SagaFlowActions.errorMessage({
                    flowId,
                    itemId: couponId,
                    message: PlaceBetUtils.placeBetErrorMessage(errorCode),
                }),
            );
            break;
        }
    }
}

export function* postBet(couponId: string, receiveAction: string): SagaIterator {
    const {error, payload} = yield take(receiveAction);

    if (error) {
        yield call(
            handlePlaceBetFlowError,
            PlaceBetFlow.PLACE_SPORT_BET,
            couponId,
            payload.response.data,
        );
        if (
            payload.response.data.errorCode !== PlaceBetErrorCode.ASYNC_RETRYABLE_TIMEOUT
        ) {
            return;
        }
        const receiveRetryAction = yield take(SportBetActions.SPORT_PLACE_BET_RECEIVE);
        if (!receiveRetryAction.payload.response?.data) {
            yield call(handlePlaceBetFlowError, PlaceBetFlow.PLACE_SPORT_BET, couponId, {
                errorCode: PlaceBetErrorCode.TECHNICAL_ERROR,
            } as PlaceBetError);
            return;
        }
    }

    // success
    yield put(SagaFlowActions.success(PlaceBetFlow.PLACE_SPORT_BET));
}

export function* placeSportBet(action: SportPlaceBetFlowAction): SagaIterator {
    yield call(clearPlaceBetFlowMessages);
    yield put(SagaFlowActions.init(PlaceBetFlow.PLACE_SPORT_BET));

    const {teamId, gameId, coupon} = action.payload;

    if (coupon.isValid()) {
        yield put(
            SportBetActions.placeBet(teamId, gameId, {
                ...coupon.getCouponWithBackendFormat(),
                // @ts-ignore
                couponId: coupon.id,
            }),
        );
        yield call(postBet, coupon.id, SportBetActions.SPORT_PLACE_BET_RECEIVE);
    }
}

export function* placeHarrySportBet(action: SportPlaceBetFlowAction): SagaIterator {
    yield call(clearPlaceBetFlowMessages);

    const {teamId, gameId, coupon} = action.payload;

    // make this PLACE_HARRY_SPORT_BET ?
    yield put(SagaFlowActions.init(PlaceBetFlow.PLACE_SPORT_BET));

    if (coupon.isValid()) {
        yield put(
            SportBetActions.placeHarrySportBet(teamId, gameId, {
                ...coupon.getCouponWithBackendFormat(),
                // @ts-ignore
                couponId: coupon.id,
            }),
        );
        yield call(postBet, coupon.id, SportBetActions.SPORT_PLACE_HARRY_BET_RECEIVE);
    }
}

export function* placeBetFlow(action: SportPlaceBetFlowAction): SagaIterator {
    const {coupon} = action.payload;

    if (coupon.harrySelected) {
        yield call(placeHarrySportBet, action);
    } else {
        yield call(placeSportBet, action);
    }
}

export function* placeBetResetFlow(): SagaIterator {
    yield put(SagaFlowActions.reset(PlaceBetFlow.PLACE_SPORT_BET));
    yield call(clearPlaceBetFlowMessages);
}

export default function* placeBetSaga(): SagaIterator {
    yield takeLatest(SportBetActions.SPORT_PLACE_BET_FLOW, placeBetFlow);
    yield takeLatest(SportBetActions.SPORT_PLACE_BET_RESET, placeBetResetFlow);
}
