import {gql} from "@apollo/client";

export const CORE_MEMBER_FIELDS = gql`
    fragment CoreMemberFields on Member {
        id
        memberId
        firstName
        lastName
        imageUrl
    }
`;

export const CORE_MEMBERSHIP_FIELDS = gql`
    ${CORE_MEMBER_FIELDS}
    fragment CoreMembershiopFields on TeamMembership {
        id
        teamId
        roles
        member {
            ...CoreMemberFields
        }
        memberSince
    }
`;
