import {gql} from "@apollo/client";
import {CORE_BET_META_FIELDS} from "../fragment/betFragments.graphql";

export const MUTATION_PLACE_HORSE_BET = gql`
    mutation PlaceHorseBet($input: PlaceHorseBetInput!) {
        placeHorseBet(input: $input)
    }
`;

export const MUTATION_PLACE_SPORT_BET = gql`
    mutation PlaceSportBet($input: PlaceSportBetInput!) {
        placeSportBet(input: $input)
    }
`;

export const MUTATION_SAVE_BET_DESCRIPTION = gql`
    ${CORE_BET_META_FIELDS}
    mutation SaveBetDescription($input: SaveBetDescriptionInput!) {
        saveBetDescription(input: $input) {
            ...CoreBetMetaFields
        }
    }
`;

export const MUTATION_UPLOAD_BET_IMAGE = gql`
    ${CORE_BET_META_FIELDS}
    mutation UploadBetImage($input: UploadBetImageInput!, $image: Upload) {
        uploadBetImage(input: $input, image: $image) {
            ...CoreBetMetaFields
        }
    }
`;

export const MUTATION_RESET_BET_IMAGE_AND_DESCRIPTION = gql`
    ${CORE_BET_META_FIELDS}
    mutation ResetBetImageAndDescription($input: ResetImageAndDescriptionInput!) {
        resetImageAndDescription(input: $input) {
            ...CoreBetMetaFields
        }
    }
`;
