import type {SagaIterator} from "redux-saga";
import {put, takeLatest, takeLeading} from "redux-saga/effects";
import {closeEditPushNotificationSettingsModal} from "atg-modals/modalActions";
import {UPDATE_PUSH_NOTIFICATION_SETTINGS_RESPONSE} from "./personalizationActions";

export function* updatedPushNotificationSettings(): SagaIterator {
    yield put(closeEditPushNotificationSettingsModal());
}

export default function* personalizationSaga(): SagaIterator {
    yield takeLatest(
        UPDATE_PUSH_NOTIFICATION_SETTINGS_RESPONSE,
        updatedPushNotificationSettings,
    );
}
