import type {HorseGame, HorseRaceExtended} from "../horseGameTypes";

export const HORSE_GAME_FETCH_REQUEST = "game/HORSE_GAME_FETCH_REQUEST";
export const HORSE_GAME_FETCH_RESPONSE = "game/HORSE_GAME_FETCH_RESPONSE";

export const HORSE_GAME_UPDATE = "game/HORSE_GAME_UPDATE";

export const HORSE_RACE_EXTENDED_FETCH_REQUEST = "game/HORSE_RACE_EXTENDED_FETCH_REQUEST";
export const HORSE_RACE_EXTENDED_FETCH_RESPONSE =
    "game/HORSE_RACE_EXTENDED_FETCH_RESPONSE";

export type HorseGameFetchRequestAction = {
    type: typeof HORSE_GAME_FETCH_REQUEST;
    payload: {gameId: string; forceFetch: boolean};
};

export type HorseGameFetchResponseAction = {
    type: typeof HORSE_GAME_FETCH_RESPONSE;
    payload: {
        game: HorseGame | null;
    };
    error: boolean;
};

export type HorseRaceExtendedRequestAction = {
    type: typeof HORSE_RACE_EXTENDED_FETCH_REQUEST;
    payload: {raceId: string; forceFetch: boolean};
};

export type HorseRaceExtendedResponseAction = {
    type: typeof HORSE_RACE_EXTENDED_FETCH_RESPONSE;
    payload: {
        race: HorseRaceExtended | null;
    };
    error: boolean;
};

export type HorseGameUpdateAction = {
    type: typeof HORSE_GAME_UPDATE;
    payload: {gameId: string; game: HorseGame};
};

export type Action =
    | HorseGameFetchRequestAction
    | HorseGameFetchResponseAction
    | HorseRaceExtendedRequestAction
    | HorseRaceExtendedResponseAction
    | HorseGameUpdateAction;

export const fetchGame = (
    gameId: string,
    forceFetch = false,
): HorseGameFetchRequestAction => ({
    type: HORSE_GAME_FETCH_REQUEST,
    payload: {gameId, forceFetch},
});

export const fetchGameSuccess = (game: HorseGame): HorseGameFetchResponseAction => ({
    type: HORSE_GAME_FETCH_RESPONSE,
    payload: {game},
    error: false,
});

export const fetchGameError = (): HorseGameFetchResponseAction => ({
    type: HORSE_GAME_FETCH_RESPONSE,
    payload: {game: null},
    error: true,
});

export const fetchRaceExtended = (
    raceId: string,
    forceFetch = false,
): HorseRaceExtendedRequestAction => ({
    type: HORSE_RACE_EXTENDED_FETCH_REQUEST,
    payload: {raceId, forceFetch},
});

export const fetchRaceExtendedSuccess = (
    race: HorseRaceExtended,
): HorseRaceExtendedResponseAction => ({
    type: HORSE_RACE_EXTENDED_FETCH_RESPONSE,
    payload: {race},
    error: false,
});

export const fetchRaceExtendedError = (): HorseRaceExtendedResponseAction => ({
    type: HORSE_RACE_EXTENDED_FETCH_RESPONSE,
    payload: {race: null},
    error: true,
});

export const updateGame = (gameId: string, game: HorseGame): HorseGameUpdateAction => ({
    type: HORSE_GAME_UPDATE,
    payload: {gameId, game},
});
