import {
    CLEAR_MEMBER_CUSTOMIZATION,
    GET_MEMBER_CUSTOMIZATION_REQUEST,
    GET_MEMBER_CUSTOMIZATION_RESPONSE,
    SET_CUSTOMIZATION_ID,
    ADD_MEMBER_CUSTOMIZATION_RESPONSE,
    SET_STARTPAGE_HERO_FLAG,
} from "./memberCustomizationConstants";
import type {FetchMemberCustomizationResponse} from "./memberCustomizationTypes";

export type GetMemberCustomizationRequestAction = {
    type: typeof GET_MEMBER_CUSTOMIZATION_REQUEST;
};

export type GetMemberCustomizationResponseAction = {
    type: typeof GET_MEMBER_CUSTOMIZATION_RESPONSE;
    payload: {
        data: FetchMemberCustomizationResponse[] | null;
    };
    error: boolean;
};

export type AddMemberCustomizationResponseAction = {
    type: typeof ADD_MEMBER_CUSTOMIZATION_RESPONSE;
    payload: {
        data: FetchMemberCustomizationResponse | null;
    };
    error: boolean;
};

export type ClearMemberCustomizationAction = {
    type: typeof CLEAR_MEMBER_CUSTOMIZATION;
};

export type SetCustomizationIdAction = {
    type: typeof SET_CUSTOMIZATION_ID;
    payload: {
        customizationId: string;
    };
};

export type SetStartPageHeroFlagAction = {
    type: typeof SET_STARTPAGE_HERO_FLAG;
    payload: {
        visible: boolean;
    };
};

export type MemberCustomizationAction =
    | GetMemberCustomizationRequestAction
    | GetMemberCustomizationResponseAction
    | AddMemberCustomizationResponseAction
    | ClearMemberCustomizationAction
    | SetCustomizationIdAction
    | SetStartPageHeroFlagAction;

const fetchMemberCustomization = (): GetMemberCustomizationRequestAction => ({
    type: GET_MEMBER_CUSTOMIZATION_REQUEST,
});

const fetchMemberCustomizationSuccess = (
    data: FetchMemberCustomizationResponse[],
): GetMemberCustomizationResponseAction => ({
    type: GET_MEMBER_CUSTOMIZATION_RESPONSE,
    payload: {data},
    error: false,
});

const fetchMemberCustomizationError = (): GetMemberCustomizationResponseAction => ({
    type: GET_MEMBER_CUSTOMIZATION_RESPONSE,
    payload: {data: null},
    error: true,
});

const addMemberCustomizationSuccess = (
    data: FetchMemberCustomizationResponse,
): AddMemberCustomizationResponseAction => ({
    type: ADD_MEMBER_CUSTOMIZATION_RESPONSE,
    payload: {data},
    error: false,
});

const addMemberCustomizationError = (): AddMemberCustomizationResponseAction => ({
    type: ADD_MEMBER_CUSTOMIZATION_RESPONSE,
    payload: {data: null},
    error: true,
});

const clearMemberCustomization = (): ClearMemberCustomizationAction => ({
    type: CLEAR_MEMBER_CUSTOMIZATION,
});

const setCustomizationId = (customizationId: string): SetCustomizationIdAction => ({
    type: SET_CUSTOMIZATION_ID,
    payload: {customizationId},
});

const setStartPageHeroFlag = (visible: boolean): SetStartPageHeroFlagAction => ({
    type: SET_STARTPAGE_HERO_FLAG,
    payload: {visible},
});

export const MemberCustomizationActions = {
    fetchMemberCustomization,
    fetchMemberCustomizationSuccess,
    fetchMemberCustomizationError,
    addMemberCustomizationSuccess,
    addMemberCustomizationError,
    clearMemberCustomization,
    setCustomizationId,
    setStartPageHeroFlag,
};
