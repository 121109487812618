import * as React from "react";
import {isEmpty, isEqual, find} from "lodash";
import {usePrevious} from "@react-hookz/web";
import {makeVar, useReactiveVar} from "@apollo/client";
import type {BetWithDetails, BetMetaIds, BetMeta} from "@atg-tillsammans/types/generated";
import type HorseReceipt from "../domain/HorseReceipt.class";
import HorseBatchReceipt from "../domain/HorseBatchReceipt.class";

type HookParams = {
    receipts: Array<HorseReceipt | HorseBatchReceipt>;
    handleAddReceipt: (couponId: string, receiptData: BetWithDetails) => void;
};
export enum HorseReceiptPushEvent {
    ADD = "ADD",
    UPDATE = "UPDATE",
}
export const receiptPushDataVar = makeVar<{
    [receiptId: string]: {
        event: HorseReceiptPushEvent;
        couponId?: string;
        receipt?: BetWithDetails;
        betMeta?: BetMeta;
        betMetaIds?: BetMetaIds;
    };
}>({});
export const setHorseReceiptPushData = ({
    event,
    receiptId,
    couponId,
    receipt,
    betMeta,
    betMetaIds,
}: {
    event: HorseReceiptPushEvent;
    receiptId: string;
    couponId?: string;
    receipt?: BetWithDetails;
    betMeta?: BetMeta;
    betMetaIds?: BetMetaIds;
}) =>
    receiptPushDataVar({
        ...receiptPushDataVar(),
        [receiptId]: {
            event,
            receipt,
            couponId,
            betMeta,
            betMetaIds,
        },
    });
const findReceiptById = (
    receipts: Array<HorseReceipt | HorseBatchReceipt>,
    receiptId: string,
) => find(receipts, (receipt) => receipt.id === receiptId);

export default function useHorseReceiptPush({receipts, handleAddReceipt}: HookParams) {
    const receiptPushData = useReactiveVar(receiptPushDataVar);

    const prevReceiptPushData = usePrevious(receiptPushData);

    React.useEffect(() => {
        if (
            !prevReceiptPushData ||
            isEmpty(receiptPushData) ||
            isEqual(prevReceiptPushData, receiptPushData)
        ) {
            return;
        }
        Object.keys(receiptPushData).forEach((pushedReceiptId) => {
            const pushData = receiptPushData[pushedReceiptId];
            if (!pushData) return;
            const receipt = findReceiptById(receipts, pushedReceiptId);
            // Handle update
            if (
                receipt &&
                pushData.event === HorseReceiptPushEvent.UPDATE &&
                receipt instanceof HorseBatchReceipt &&
                pushData.betMeta &&
                pushData.betMetaIds
            ) {
                receipt.setBetMeta(pushData.betMeta);
            }
            // Handle add
            if (
                !receipt &&
                pushData.couponId &&
                pushData.event === HorseReceiptPushEvent.ADD &&
                pushData.receipt
            ) {
                handleAddReceipt(pushData.couponId, pushData.receipt);
            }
        });
    }, [receipts, prevReceiptPushData, receiptPushData, handleAddReceipt]);
}
