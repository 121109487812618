import * as React from "react";
import * as Redux from "react-redux";

type OwnProps = {
    action: () => any;
    isLoaded: Redux.Selector<any, boolean>;
    isOk: Redux.Selector<any, boolean>;
    successRoute: React.ReactNode;
    errorRoute: React.ReactNode | null | undefined;
};

type Props = OwnProps & {
    isDataLoaded: boolean;
    isDataOk: any;
    dispatchAction: () => void;
};

type State = {
    routeResolution: "success" | "error" | null;
};

const getRouteResolution = (props: Props): State | null => {
    const {isDataLoaded, isDataOk} = props;

    // avoid isDataOk undefined state(initial) causing render error/notFound page
    if (!isDataLoaded || isDataOk === undefined) return null;

    if (!isDataOk) {
        return {
            routeResolution: "error",
        };
    }

    return {
        routeResolution: "success",
    };
};

class LoadRoute extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            routeResolution: null,
        };
    }

    componentDidMount() {
        const {dispatchAction} = this.props;

        dispatchAction();
        const newState = getRouteResolution(this.props);
        this.setState(newState);
    }

    static getDerivedStateFromProps(nextProps: Props) {
        return getRouteResolution(nextProps);
    }

    render() {
        const {successRoute, errorRoute} = this.props;
        const {routeResolution} = this.state;

        if (!routeResolution) return null;

        if (routeResolution === "error") return <>{errorRoute || null}</>;
        return successRoute;
    }
}

const mapStateToProps = (
    state: {
        [key: string]: any;
    },
    props: OwnProps,
) => ({
    isDataLoaded: props.isLoaded(state),
    isDataOk: props.isOk(state),
});

const mapDispatchToProps = (dispatch: any, props: OwnProps) => ({
    dispatchAction: () => dispatch(props.action()),
});

export default Redux.connect(mapStateToProps, mapDispatchToProps)(LoadRoute);
