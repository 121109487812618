import {TeamImageDocument} from "@atg-tillsammans/graphql/query/__generated__/teamQueries.graphql.generated";
import {getApolloClient} from "./apolloClient";

export function updateImage(teamId: string) {
    const client = getApolloClient();

    if (!client) return;

    client.query({
        query: TeamImageDocument,
        variables: {
            teamId,
        },
        fetchPolicy: "network-only",
    });
}
