import type {Bet} from "@atg-sport-shared/big9-types/betTypes";
import type {WebsocketIds} from "@atg-frame-shared/push-saga/helpers";
import type {PersonalizationSettings} from "@atg-tillsammans-shared/personalization-redux";
import type {
    Round,
    BetWithDetails,
    GameType,
    BetMetaIds,
    BetMeta,
    HorseCoupon,
    ScheduledRelease,
    Participant,
    Member,
} from "@atg-tillsammans/types/generated";
import type {TeamID} from "@atg-tillsammans/types";

export enum TillsammansPushActionType {
    COMMAND_RELOAD_MY_TEAMS = "COMMAND_RELOAD_MY_TEAMS",
    COMMAND_LOGOUT = "COMMAND_LOGOUT",
    EVENT_TEAM_COUPON = "EVENT_TEAM_COUPON",
    EVENT_TEAM_UPDATED = "EVENT_TEAM_UPDATED",
    EVENT_TEAM_DELETED = "EVENT_TEAM_DELETED",
    EVENT_MEMBER_ONLINE = "EVENT_MEMBER_ONLINE",
    EVENT_TEAM_BET_PLACED = "EVENT_TEAM_BET_PLACED",
    EVENT_TEAM_BET_FAILED = "EVENT_TEAM_BET_FAILED",
    EVENT_MEMBER_ENTERED_TILLSAMMANS = "EVENT_MEMBER_ENTERED_TILLSAMMANS",
    EVENT_TEAM_BET_META_UPDATED = "EVENT_TEAM_BET_META_UPDATED",
    EVENT_TEAM_ROUND_BALANCE = "EVENT_TEAM_ROUND_BALANCE",
    EVENT_TEAM_ROUND_ACTIVATED = "EVENT_TEAM_ROUND_ACTIVATED",
    EVENT_TEAM_ROUND_UPDATED = "EVENT_TEAM_ROUND_UPDATED",
    EVENT_TEAM_MEMBER_JOINED = "EVENT_TEAM_MEMBER_JOINED",
    EVENT_TEAM_MEMBER_LEFT = "EVENT_TEAM_MEMBER_LEFT",
}

export const TEAM_MEMBER_LOGGED_IN = "tillsammans/TEAM_MEMBER_LOGGED_IN";

export const SSO_LOGOUT = "tillsammans/SSO_LOGOUT";
export const ROUND_ACTIVATED = "tillsammans/ROUND_ACTIVATED";

export type TillsammansPushAction = {
    type: TillsammansPushActionType;
    memberId?: number;
    member?: Member;
    bet?: Bet;
    personalizationSettings?: PersonalizationSettings;
    round?: Round;
    roundBalance?: number;
    subscriptions?: Record<number, number>;
    scheduledReleases?: Array<ScheduledRelease>;
    coupon?: HorseCoupon;
    receipt?: BetWithDetails;
    couponId?: string;
    gameType?: GameType;
    gameId?: string;
    teamId?: TeamID;
    errorCode?: string;
    betMetaIds?: BetMetaIds;
    betMeta?: BetMeta;
    websocketIds?: WebsocketIds;
    participation?: Participant;
};

export type RoundActivatedPayload = {
    round: Round;
    subscriptions?: Record<number, number>;
    scheduledReleases?: Array<ScheduledRelease>;
};

export type TeamMemberLoggedInPayload = {
    member: Member;
};

export type TeamMemberLoggedInAction = {
    type: typeof TEAM_MEMBER_LOGGED_IN;
    payload: TeamMemberLoggedInPayload;
};

export type SSOLogoutAction = {
    type: typeof SSO_LOGOUT;
};

export type RoundActivatedAction = {
    type: typeof ROUND_ACTIVATED;
    payload: RoundActivatedPayload;
};

export const roundActivated = (
    round: Round,
    subscriptions?: Record<number, number>,
    scheduledReleases?: Array<ScheduledRelease>,
): RoundActivatedAction => ({
    type: ROUND_ACTIVATED,
    payload: {round, subscriptions, scheduledReleases},
});

export const teamMemberLoggedIn = (member: Member): TeamMemberLoggedInAction => ({
    type: TEAM_MEMBER_LOGGED_IN,
    payload: {member},
});

export const ssoLogout = (): SSOLogoutAction => ({
    type: SSO_LOGOUT,
});
