const commonProperties = ["name", "message", "stack", "code"];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const destroyCircular = (from: Record<string, any>, seen: Array<any>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const to: Record<string, any> = Array.isArray(from) ? [] : {};

    seen.push(from);

    Object.entries(from).forEach(([key, value]) => {
        if (typeof value === "function") {
            return;
        }

        if (!value || typeof value !== "object") {
            to[key] = value;
            return;
        }

        if (!seen.includes(from[key])) {
            to[key] = destroyCircular(from[key], seen.slice());
            return;
        }

        to[key] = "[Circular]";
    });

    commonProperties.forEach((property) => {
        if (typeof from[property] === "string") {
            to[property] = from[property];
        }
    });

    return to;
};

/**
 * Helper function which pulls non-enumerable properties from an Error instance and converts them to
 * normal readable string values that will survive a `JSON.stringify`.
 *
 * This is a fork of https://github.com/sindresorhus/serialize-error, but with support for IE11.
 * Specifically the usage of "const" in that library (easy to fix with babel-loader) and the
 * combination of `"use strict";` and "module.exports = {...}" (overwrite module.exports) cause
 * issues in IE11.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const serializeError = (value: any) => {
    if (typeof value === "object" && value !== null) {
        return destroyCircular(value, []);
    }

    // People sometimes throw things besides Error objects…
    if (typeof value === "function") {
        // `JSON.stringify()` discards functions. We do too, unless a function is thrown directly.
        return `[Function: ${value.name || "anonymous"}]`;
    }

    return value;
};

export default serializeError;
