import {pureFetch} from "@atg-shared/fetch";
import {GAME_SERVICE_URL, RACE_SERVICE_URL} from "@atg-shared/service-url";
import {getAtgseUrl} from "@atg-tillsammans-shared/utils";
import type {HorseGame, HorseRaceExtended} from "../horseGameTypes";

export const getGameEndpoint = (): string =>
    process.env.NODE_ENV === "development"
        ? GAME_SERVICE_URL
        : `${getAtgseUrl()}${GAME_SERVICE_URL}`;

export const getRaceEndpoint = (): string =>
    process.env.NODE_ENV === "development"
        ? RACE_SERVICE_URL
        : `${getAtgseUrl()}${RACE_SERVICE_URL}`;

export const fetchHorseGame = (gameId: string) =>
    pureFetch<HorseGame>(`${getGameEndpoint()}/${gameId}`);

export const fetchExtendedRace = (raceId: string) =>
    pureFetch<HorseRaceExtended>(`${getRaceEndpoint()}/${raceId}/extended`);
