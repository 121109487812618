import type {
    AtgRequestError,
    RequestAction,
    ReceiveAction,
    ReceiveErrorAction,
} from "@atg-shared/fetch-types";

export const FETCH = "FETCH";
export const FETCH_PROMISE = "FETCH_PROMISE";

type Func = (...args: any[]) => any;

export const call = <T extends Func>(func: T, ...params: Parameters<T>) => ({
    params,
    func,
});

export const request = <RequestActionType, Context>(
    type: RequestActionType,
    context?: Context,
): RequestAction<RequestActionType, Context> => ({
    type,
    __category: "fetch",
    context,
});

export const receive = <ReceiveActionType, Response, Context>(
    type: ReceiveActionType,
    response: Response,
    context?: Context,
): ReceiveAction<ReceiveActionType, Response, Context> => ({
    type,
    error: false,
    __category: "fetch",
    payload: response,
    context,
});

export const receiveError = <ReceiveActionType, Context>(
    type: ReceiveActionType,
    errorResponse: AtgRequestError,
    context?: Context,
): ReceiveErrorAction<ReceiveActionType, Context> => ({
    type,
    error: true,
    __category: "fetch",
    payload: errorResponse,
    context,
});

type FetchActions = {
    REQUEST: string;
    RECEIVE: string;
    RESET: string;
};

export const getFetchActions = (fetchAction: string): FetchActions => ({
    REQUEST: `${fetchAction}/REQUEST`,
    RECEIVE: `${fetchAction}/RECEIVE`,
    RESET: `${fetchAction}/RESET`,
});
