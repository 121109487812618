export const RECEIVE_USER = "RECEIVE_USER";
export const RECEIVE_USER_FAILED = "RECEIVE_USER_FAILED";
export const LOGIN_FINISHED = "LOGIN_FINISHED";
export const RECEIVE_LOCAL_USER = "RECEIEVE_LOCAL_USER";

/**
 * This action starts a Users logout, it dispatches as a Frame action and will only be available
 * to take in Redux Resolvers and Sagas in Global and Frame.
 */
export const LOGOUT_USER = "LOGOUT_USER";
/**
 * User logout has initiated. An action of this type will be dispatched as a Broadcast action
 * when a User logout has started. This can be taken by any verticals Redux Reducers or Sagas.
 */
export const LOGOUT_INITIATED = "user/LOGOUT_INITIATED";
/** User logout from auth server (Curity) is done (remove httpOnly cookies, etc.) */
export const LOGOUT_FINALIZE = "LOGOUT_FINALIZE";
/**
 * User logout is finished. An action of this type will be dispatched as a Broadcast action
 * when a User logout has finished. This can be taken by any verticals Redux Reducers or Sagas.
 */
export const LOGOUT_FINISHED = "LOGOUT_FINISHED";

export const SET_REMEMBER_USERNAME = "SET_REMEMBER_USERNAME";
export const SET_REMEMBERED_USERNAME = "SET_REMEMBERED_USERNAME";
export const SET_SHOW_BALANCE = "SET_SHOW_BALANCE";
export const UPDATE_SHOW_BALANCE = "user/UPDATE_SHOW_BALANCE";
export const SET_FINGERPRINT_VERIFICATION = "SET_FINGERPRINT_VERIFICATION";
export const FETCH_USER = "FETCH_USER";
export const FETCH_BALANCE = "FETCH_BALANCE";
export const RECEIVE_BALANCE = "RECEIVE_BALANCE";
export const RECEIVE_BALANCE_ERROR = "RECEIVE_BALANCE_ERROR";
export const FETCH_USER_IF_AUTHORIZED = "FETCH_USER_IF_AUTHORIZED";
export const SET_NEXT_POSSIBLE_LOGIN = "SET_NEXT_POSSIBLE_LOGIN";
export const REDUCE_SCOPE = "REDUCE_SCOPE";

export const USER_AVATAR_UPDATED = "user/USER_AVATAR_UPDATED";
