import {css} from "@emotion/react";
import {atgWhite} from "@atg-tillsammans-shared/css/overridableVariables";

export const aside = css`
    margin-right: 16px;
`;

export const wrapper = {
    margin: "16px",
    borderRadius: "16px",
};

export const main = css`
    display: flex;
    flex-direction: inherit;
    align-items: center;
    word-wrap: break-word;
    word-break: break-word;
`;

export const container = css`
    display: flex;
    flex-direction: row;
    color: ${atgWhite};
`;

export const title = css`
    font-size: 16px;
`;
