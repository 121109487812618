import type {TeamType} from "../generated";
import type {deprecated_MemberTeam} from "./team";

export type Inviter = {
    firstName: string;
    lastName: string;
    imageUrl: string | null;
};

export type Invite = {
    teamName: string;
    teamType: TeamType;
    inviteText: string;
    inviter: Inviter;
    expired: boolean;
    gameId?: string;
};

/**
 *  Invite models
 */

export type InviteCode = {
    id: number;
    key: string;
    type: string;
    encryptedUserName?: string | null;
};

export type InviteError = {
    message: string;
};

export const TeamInviteStatus = {
    JOINED: "JOINED",
    QUEUED: "QUEUED",
    ALREADY_QUEUED: "ALREADY_QUEUED",
    TO_MANY_TEAMS: "TO_MANY_TEAMS",
    INVALID_INVITE: "INVALID_INVITE",
    ALREADY_MEMBER: "ALREADY_MEMBER",
    TECHNICAL_ERROR: "TECHNICAL_ERROR",
};

export type TeamInviteResult = {
    team?: deprecated_MemberTeam;
    status: keyof typeof TeamInviteStatus;
};

export type InviteResponse = Invite | InviteError;
