// BE: GameType (https://github.com/atgse/atg-service-team/blob/master/atg-service-team-app/src/main/java/se/atg/service/team/model/common/GameType.java)
export enum GameType {
    BIG9 = "BIG9",
    GS75 = "GS75",
    V64 = "V64",
    V75 = "V75",
    V86 = "V86",
    V65 = "V65",
    V4 = "V4",
    TOP7 = "TOP7",
}

/** Needed for backward compability remove when .flow file is not needed anymore */
export const GameTypes: {
    V75: GameType;
    GS75: GameType;
    V86: GameType;
    V64: GameType;
    BIG9: GameType;
    V65: GameType;
    V4: GameType;
    TOP7: GameType;
} = {
    V75: GameType.V75,
    GS75: GameType.GS75,
    V86: GameType.V86,
    V64: GameType.V64,
    BIG9: GameType.BIG9,
    V65: GameType.V65,
    V4: GameType.V4,
    TOP7: GameType.TOP7,
};

// BE: Track (https://github.com/atgse/atg-service-team/blob/master/atg-service-team-app/src/main/java/se/atg/service/team/model/game/Track.java)
export type Track = {
    id: number;
    name: string;
};

// BE: GameStatus (https://github.com/atgse/atg-service-team/blob/master/atg-service-team-app/src/main/java/se/atg/service/team/model/game/GameStatus.java)
export enum GameStatus {
    SCHEDULED = "SCHEDULED",
    UPCOMING = "UPCOMING",
    BETTABLE = "BETTABLE",
    STARTING = "STARTING",
    ONGOING = "ONGOING",
    RESULTS = "RESULTS",
    CANCELLED = "CANCELLED",
    INVALID = "INVALID",
}

/** Needed for backward compability remove when .flow file is not needed anymore */
export type GameStatusEnum = GameStatus;

export type GameID = string;

// BE: GameId (https://github.com/atgse/atg-service-team/blob/master/atg-service-team-app/src/main/java/se/atg/service/team/model/game/GameId.java)
export type GameId = {
    gameType: GameType;
    gameDate: string;
    gameAreaCode: number;
    gameRaceNumber: number;
};

export interface Game {
    id: GameID;
    gameId: GameId;
    gameStatus: GameStatus;
    tracks: Array<Track>;
    scheduledStartTime: string;
    endTime: string;
}
