import type {AtgErrorResponse} from "@atg-shared/fetch-types";

const FORBIDDEN = 403;

/**
 * Checks if the given response is an error response with a 403 Forbidden status
 * code.
 */
export const requestHadWrongAuthorizationScope = (res: AtgErrorResponse) =>
    res.meta.code === FORBIDDEN;
