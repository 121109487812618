import enquire from "enquire.js";

import * as size from "./domain/matchMediaConstants";

import {changeActiveBreakPoint} from "./domain/matchMediaActions";

/**
 * @deprecated in favor of `atg-breakpoints`
 */
export function matchMediaDriver(dispatch) {
    enquire
        .register(`screen and (max-width: ${size.XXS}px)`, () => {
            dispatch(changeActiveBreakPoint(size.XXS));
        })
        .register(
            `screen and (min-width: ${size.XXS}px) and (max-width: ${size.XS - 1}px)`,
            () => {
                dispatch(changeActiveBreakPoint(size.XS_ONLY));
            },
        )
        .register(
            `screen and (min-width: ${size.XS}px) and (max-width: ${size.SM - 1}px)`,
            () => {
                dispatch(changeActiveBreakPoint(size.XS));
            },
        )
        .register(
            `screen and (min-width: ${size.SM}px) and (max-width: ${size.MD - 1}px)`,
            () => {
                dispatch(changeActiveBreakPoint(size.SM));
            },
        )
        .register(
            `screen and (min-width: ${size.MD}px) and (max-width: ${size.LG - 1}px)`,
            () => {
                dispatch(changeActiveBreakPoint(size.MD));
            },
        )
        .register(
            `screen and (min-width: ${size.LG}px) and (max-width: ${size.XL - 1}px)`,
            () => {
                dispatch(changeActiveBreakPoint(size.LG));
            },
        )
        .register(`screen and (min-width: ${size.XL}px)`, () => {
            dispatch(changeActiveBreakPoint(size.XL));
        });
}
export default matchMediaDriver;
