import type {ApolloError} from "@apollo/client";
import {t} from "@lingui/macro";
import {
    InvitationSetting,
    MembershipPolicy,
    TeamSettingsUpdateMethod,
    PreBookedErrorCode,
    TeamSettingsErrorCode,
    TeamSettingsSuccessCode,
} from "@atg-tillsammans/types";
import {Paths} from "@atg-tillsammans-shared/navigation";
import type {Team, TeamID} from "@atg-tillsammans/types";

export const getTeamNavbarInfo = (teamId: TeamID) => ({
    activeBettersPath: Paths.getTeamSubpagePath(teamId, "spellaggare"),
    subscriptionPath: Paths.getTeamSubpagePath(teamId, "foranmalningar"),
    membersPath: Paths.getTeamSubpagePath(teamId, "medlemmar"),
    emailPath: Paths.getTeamSubpagePath(teamId, "epost"),
});

export const isChatEnabled = (path: string) => {
    const pages = [
        "bjud-in",
        "spellaggare",
        "foranmalningar",
        "laginstallningar",
        "laginformation",
        "medlemmar",
        "epost",
    ];

    const regexp = new RegExp(
        `^(\\/utloggad|\\/${
            Paths.TILLSAMMANS_NAMESPACE
        })?\\/lagsida\\/\\d+\\/(${pages.join("|")})$`,
        "g",
    );
    return regexp.test(path);
};

export const getPageTitle = (pathname: string) => {
    const path = pathname.substring(pathname.lastIndexOf("/") + 1);

    if (Paths.isTeamRoundPage(pathname)) {
        let gameType = path.split("_")[0];
        if (gameType === "BIG9") gameType = "Big 9";
        return t({
            id: `team.utils.teamRoundPage.pageTitle`,
            message: `Spela ${gameType}`,
        });
    }

    switch (path) {
        case "medlemmar":
            return t({id: "team.utils.members.pageTitle", message: "Medlemmar"});
        case "foranmalningar":
            return t({id: "team.utils.preBooking.pageTitle", message: "Föranmälningar"});
        case "laginstallningar":
            return t({
                id: "team.utils.teamSettings.pageTitle",
                message: "Laginställningar",
            });
        case "epost":
            return t({id: "team.utils.email.pageTitle", message: "Skicka e-post"});
        case "spellaggare":
            return t({id: "team.utils.gameInitiator.pageTitle", message: "Spelläggare"});
        case "bjud-in":
            return t({id: "team.utils.invite.pageTitle", message: "Bjud in"});
        case "laginformation":
            return t({id: "team.utils.teamInfo.pageTitle", message: "Laginformation"});
        default: {
            return t({id: "team.utils.teamPage.pageTitle", message: "Lagsida"});
        }
    }
};

type TeamPageInfo = {
    chatEnabled: boolean;
};

export const TEAM_PAGE_INFO = {chatEnabled: true};
export const TEAM_ROUND_PAGE_INFO = {chatEnabled: true};
export const TEAM_SUBPAGE_INFO = {chatEnabled: false};

export const getTeamPageInfo = (path: string): TeamPageInfo => ({
    chatEnabled:
        Paths.isTeamBasePage(path) || Paths.isTeamRoundPage(path) || isChatEnabled(path),
});

export const formatInviteType = (inviteType: string) => {
    switch (inviteType) {
        case InvitationSetting.ALL: {
            return {
                label: t({
                    id: "team.utils.InvitationSetting.ALL.label",
                    message: "Alla medlemmar",
                }),
                text: t({
                    id: "team.utils.InvitationSetting.ALL.text",
                    message: "Alla medlemmar kan bjuda in nya personer till laget.",
                }),
            };
        }
        default:
            return {
                label: t({
                    id: "team.utils.InvitationSetting.default.label",
                    message: "Bara lagkapten",
                }),
                text: t({
                    id: "team.utils.InvitationSetting.default.text",
                    message: "Endast lagkaptenen kan bjuda in nya personer till laget.",
                }),
            };
    }
};

export const formatMembershipSelection = (policy: string) => {
    switch (policy) {
        case MembershipPolicy.OPEN: {
            return {
                label: t({
                    id: "team.utils.MembershipPolicy.OPEN.label",
                    message: "Öppet",
                }),
                text: t({
                    id: "team.utils.MembershipPolicy.OPEN.text",
                    message:
                        "Vem som helst kan gå med i laget antingen genom att söka upp och gå med i laget via tillsammans.atg.se/hittalag eller genom att gå med via en inbjudningslänk.",
                }),
            };
        }
        case MembershipPolicy.CLOSED: {
            return {
                label: t({
                    id: "team.utils.MembershipPolicy.CLOSED.label",
                    message: "Slutet",
                }),
                text: t({
                    id: "team.utils.MembershipPolicy.CLOSED.text",
                    message:
                        "Medlemmar kan gå med i laget via en inbjudningslänk eller genom att söka upp och ansöka till laget via tillsammans.atg.se/hittalag. Det är bara lagkaptenen som kan godkänna en lagansökan.",
                }),
            };
        }
        case MembershipPolicy.PRIVATE: {
            return {
                label: t({
                    id: "team.utils.MembershipPolicy.PRIVATE.label",
                    message: "Privat",
                }),
                text: t({
                    id: "team.utils.MembershipPolicy.PRIVATE.text",
                    message:
                        "Medlemmar kan endast gå med i ett privat lag via en inbjudningslänk. Ett privat lag listas inte på tillsammans.atg.se/hittalag och det är heller inte sökbart.",
                }),
            };
        }
        default:
            return {};
    }
};

/**
 * This should probably be in teamUtils but since it is a js-file
 * we keep it here until we have refactored teamUtils.js to typescript
 */
export const getTeamSettingsErrorMessage = (
    apolloError?: ApolloError,
    updateMethod?: string,
): string => {
    const extensions = apolloError?.graphQLErrors[0]?.extensions;

    const errorCode = extensions
        ? (extensions.errorCode as TeamSettingsErrorCode)
        : TeamSettingsErrorCode.ACCESS_DENIED;

    switch (errorCode) {
        case TeamSettingsErrorCode.ACTIVE_ROUND_EXISTS:
            return t({
                id: "team.utils.TeamSettingsErrorCode.ACTIVE_ROUND_EXISTS",
                message: "Du kan inte ta bort ett lag som har en aktiv omgång.",
            });
        case TeamSettingsErrorCode.ACTIVE_ROUND_OF_GAME_TYPE_EXISTS:
            return t({
                id: "team.utils.TeamSettingsErrorCode.ACTIVE_ROUND_OF_GAME_TYPE_EXISTS",
                message:
                    "Du kan inte välja bort en spelform där ni har en aktiv omgång. När omgången är avslutad kan du ändra.",
            });
        case TeamSettingsErrorCode.ACCESS_DENIED:
            if (updateMethod === TeamSettingsUpdateMethod.LEAVE_TEAM) {
                return t({
                    id: "team.utils.TeamSettingsErrorCode.ACCESS_DENIED.TeamSettingsUpdateMethod.LEAVE_TEAM",
                    message: "För att kunna gå ur laget måste du logga in först.",
                });
            }
            if (updateMethod === TeamSettingsUpdateMethod.REMOVE_TEAM) {
                return t({
                    id: "team.utils.TeamSettingsErrorCode.ACCESS_DENIED.TeamSettingsUpdateMethod.REMOVE_TEAM",
                    message: "För att kunna ta bort laget måste du logga in först.",
                });
            }
            return t({
                id: "team.utils.TeamSettingsErrorCode.ACCESS_DENIED.TeamSettingsUpdateMethod.default",
                message: "För att kunna göra ändringar måste du logga in först.",
            });
        case TeamSettingsErrorCode.NOT_MEMBER_OF_TEAM:
            if (updateMethod === TeamSettingsUpdateMethod.REMOVE_TEAM) {
                return t({
                    id: "team.utils.TeamSettingsErrorCode.NOT_MEMBER_OF_TEAM.TeamSettingsUpdateMethod.REMOVE_TEAM",
                    message: "Du kan inte ta bort laget då du inte längre är medlem.",
                });
            }
            return t({
                id: "team.utils.TeamSettingsErrorCode.NOT_MEMBER_OF_TEAM.TeamSettingsUpdateMethod.default",
                message:
                    "Du kan inte ändra detta eftersom du inte inte längre är medlem i det här laget.",
            });
        case TeamSettingsErrorCode.LACKS_ROLE:
            if (updateMethod === TeamSettingsUpdateMethod.REMOVE_TEAM) {
                return t({
                    id: "team.utils.TeamSettingsErrorCode.LACKS_ROLE.TeamSettingsUpdateMethod.REMOVE_TEAM",
                    message: "Du kan inte ta bort laget eftersom du inte är lagkapten.",
                });
            }
            return t({
                id: "team.utils.TeamSettingsErrorCode.LACKS_ROLE.TeamSettingsUpdateMethod.default",
                message: "Du kan inte ändra detta eftersom du inte är lagkapten.",
            });
        case TeamSettingsErrorCode.TEAM_NAME_ALREADY_EXISTS:
            return t({
                id: "team.utils.TeamSettingsErrorCode.TEAM_NAME_ALREADY_EXISTS",
                message: "Det här namnet är tyvärr upptaget. Pröva ett nytt.",
            });
        case TeamSettingsErrorCode.TEAM_ALREADY_EXISTS:
            return t({
                id: "team.utils.TeamSettingsErrorCode.TEAM_ALREADY_EXISTS",
                message: "Laget existerar redan.",
            });
        case TeamSettingsErrorCode.MAX_NR_OF_TEAMS:
            return t({
                id: "team.utils.TeamSettingsErrorCode.MAX_NR_OF_TEAMS",
                message:
                    "Vad roligt att du är så engagerad i Tillsammans, tyvärr har du nått gränsen för hur många lag du kan vara medlem i. För att skapa ett till lag måste du först gå ur ett annat.",
            });
        case TeamSettingsErrorCode.GAME_TYPE_LIST_IS_EMPTY:
            return t({
                id: "team.utils.TeamSettingsErrorCode.GAME_TYPE_LIST_IS_EMPTY",
                message: "Du måste välja minst en spelform.",
            });
        case TeamSettingsErrorCode.MEMBERS_EXISTS:
            return t({
                id: "team.utils.TeamSettingsErrorCode.MEMBERS_EXISTS",
                message:
                    "För att kunna ta bort laget måste du först ta bort alla medlemmar från laget.",
            });
        case TeamSettingsErrorCode.IS_CAPTAIN:
            return t({
                id: "team.utils.TeamSettingsErrorCode.IS_CAPTAIN",
                message:
                    "Du måste lämna över din roll som lagkapten till en annan medlem innan du kan gå ur laget.",
            });
        case TeamSettingsErrorCode.IS_GAME_INITIATOR:
            return t({
                id: "team.utils.TeamSettingsErrorCode.IS_GAME_INITIATOR",
                message:
                    "Du kan inte gå ur ett lag där du är spelläggare för en aktiv omgång.",
            });
        case TeamSettingsErrorCode.IS_PARTICIPATING:
            return t({
                id: "team.utils.TeamSettingsErrorCode.IS_PARTICIPATING",
                message:
                    "Eftersom du köpt en andel måste du vänta tills omgången är avgjord innan du kan gå ur laget.",
            });
        case TeamSettingsErrorCode.UPDATE_TEAM_DB_ERROR:
        default:
            return t({
                id: "team.utils.TeamSettingsErrorCode.UPDATE_TEAM_DB_ERROR",
                message: "Tyvärr gick något fel hos oss. Försök igen om en stund!",
            });
    }
};

export const getTeamSettingsSuccessMessage = (
    type: TeamSettingsSuccessCode,
    team?: Team,
): string => {
    const teamName = team ? team.name : "laget";
    switch (type) {
        case TeamSettingsSuccessCode.CLOSE_TEAM:
            return t({
                id: `team.utils.TeamSettingsSuccessCode.CLOSE_TEAM`,
                message: `${teamName} är avslutat`,
            });
        case TeamSettingsSuccessCode.LEAVE_TEAM:
            return t({
                id: `team.utils.TeamSettingsSuccessCode.LEAVE_TEAM`,
                message: `Du är nu inte längre med i ${teamName}`,
            });
        case TeamSettingsSuccessCode.DEFAULT:
        default:
            return t({
                id: "team.utils.TeamSettingsSuccessCode.default",
                message: "Din ändring är sparad.",
            });
    }
};

export const getPreBookedErrorMessage = (apolloError?: ApolloError): string => {
    const extensions = apolloError?.graphQLErrors[0]?.extensions;

    const errorCode = extensions
        ? (extensions.errorCode as PreBookedErrorCode)
        : PreBookedErrorCode.ACCESS_DENIED;

    switch (errorCode) {
        case PreBookedErrorCode.ACCESS_DENIED:
            return t({
                id: "team.utils.PreBookedErrorCode.ACCESS_DENIED",
                message: "Du behöver vara inloggad för att kunna sätta en föranmälan.",
            });
        case PreBookedErrorCode.NOT_MEMBER_OF_TEAM:
            return t({
                id: "team.utils.PreBookedErrorCode.NOT_MEMBER_OF_TEAM",
                message: "Du kan inte skapa en föranmälan då du inte är medlem i laget.",
            });
        case PreBookedErrorCode.RGS_SELF_EXCLUSION:
        case PreBookedErrorCode.RGS_TEMPORARY_SELF_EXCLUSION:
            return t({
                id: "team.utils.PreBookedErrorCode.RGS_SELF_EXCLUSION",
                message: "Föranmälan kan inte aktiveras. Ditt konto är spärrat för spel.",
            });
        case PreBookedErrorCode.RGS_NAR_EXCLUSION:
            return t({
                id: "team.utils.PreBookedErrorCode.RGS_NAR_EXCLUSION",
                message:
                    "Din föranmälan kunde tyvärr inte genomföras. Enligt uppgifter från spelpaus.se har du stängt av dig för spel.",
            });
        case PreBookedErrorCode.RGS_NAR_UNDETERMINED:
            return t({
                id: "team.utils.PreBookedErrorCode.RGS_NAR_UNDETERMINED",
                message:
                    "Tjänsten spelpaus.se svarar inte just nu. Om du inte har stängt av dig för spel hos spelpaus.se vänligen logga ut och in på nytt.",
            });
        case PreBookedErrorCode.RGS_NETLOSS_LIMIT_EXCEEDED:
        case PreBookedErrorCode.RGS_NETLOSS_SERVER_ERROR:
            return t({
                id: "team.utils.PreBookedErrorCode.RGS_NETLOSS_LIMIT_EXCEEDED",
                message:
                    "Du har nått din förlustgräns. Kostnaden för spelet överskrider din satta förlustgräns.",
            });
        case PreBookedErrorCode.SUBSCRIPTION_NOT_FOUND:
        case PreBookedErrorCode.RGS_CASINO_HIGH_LOSS_LIMIT:
        case PreBookedErrorCode.RGS_CASINO_LOSS_LIMIT_EXCEEDED:
        case PreBookedErrorCode.RGS_CASINO_LOSS_LIMIT_NOT_SET:
        case PreBookedErrorCode.GENERAL_ERROR:
        default:
            return t({
                id: "team.utils.PreBookedErrorCode.GENERAL_ERROR",
                message: "Tyvärr gick något fel hos oss. Försök igen om en stund!",
            });
    }
};

export const getRemovePreBookedErrorMessage = (apolloError?: ApolloError): string => {
    const extensions = apolloError?.graphQLErrors[0]?.extensions;

    const errorCode = extensions
        ? (extensions.errorCode as PreBookedErrorCode)
        : PreBookedErrorCode.ACCESS_DENIED;

    switch (errorCode) {
        case PreBookedErrorCode.ACCESS_DENIED:
            return t({
                id: "team.utils.remove.PreBookedErrorCode.ACCESS_DENIED",
                message: "Du behöver vara inloggad för att kunna avsluta en föranmälan.",
            });
        case PreBookedErrorCode.NOT_MEMBER_OF_TEAM:
            return t({
                id: "team.utils.remove.PreBookedErrorCode.NOT_MEMBER_OF_TEAM",
                message:
                    "Du kan inte avsluta en föranmälan då du inte är medlem i laget.",
            });
        case PreBookedErrorCode.SUBSCRIPTION_NOT_FOUND:
            return t({
                id: "team.utils.remove.PreBookedErrorCode.SUBSCRIPTION_NOT_FOUND",
                message: "Du har ingen aktiv föranmälan.",
            });
        case PreBookedErrorCode.GENERAL_ERROR:
        default:
            return t({
                id: "team.utils.remove.PreBookedErrorCode.GENERAL_ERROR",
                message: "Tyvärr gick något fel hos oss. Försök igen om en stund!",
            });
    }
};

export const getPreBookedTestValue = (errorCode: string) => {
    switch (errorCode) {
        case PreBookedErrorCode.RGS_KYC_QUESTIONNAIRE_NOT_SUBMITTED:
            return "kyc-questionnaire-not-submitted";
        case PreBookedErrorCode.RGS_NETLOSS_LIMIT_EXCEEDED:
            return "net-loss-limit-exceeded";
        case PreBookedErrorCode.RGS_DEPOSIT_LIMIT_NOT_SET:
            return "deposit-limit-not-set";
        case PreBookedErrorCode.RGS_NAR_EXCLUSION:
        case PreBookedErrorCode.RGS_NAR_UNDETERMINED:
            return "nar-exclusion";
        case PreBookedErrorCode.RGS_SELF_EXCLUSION:
        case PreBookedErrorCode.RGS_TEMPORARY_SELF_EXCLUSION:
            return "self-exclusion";
        default:
            return "";
    }
};
