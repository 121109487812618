import * as Storage from "@atg-shared/storage";

export function storeTimestamp() {
    Storage.setItem("auth_timestamp", String(Date.now()), "session");
}

export function removeTimestamp() {
    Storage.removeItem("auth_timestamp", "session");
}

export function getTimestamp() {
    return Storage.getItem("auth_timestamp", "session");
}
