import * as React from "react";
import type {SerializedStyles} from "@emotion/react";
import {atgGreyEee} from "atg-ui/css/variables.styles";
import {
    type ImageUrl,
    type ResponsiveImageProps,
    type BorderProps,
} from "../domain/image";
import {ASPECT_RATIO_1_1} from "../domain/aspectRatio";
import EMPTY_IMAGE from "../assets/images/empty.svg";
import Image from "./Image";
import ResponsiveImage from "./ResponsiveImage";
import styles from "./AvatarImage.styles";

type Props = {
    style?: SerializedStyles;
    imageUrl: ImageUrl | null;
    size?: number;
    defaultImageSrc?: string;
    border?: boolean;
    borderOptions?: BorderProps;
    rounded?: boolean;
    shadow?: boolean;
    background?: string;
    alt?: string;
    showTooltip?: boolean;
} & ResponsiveImageProps;

function AvatarImage({
    style,
    imageUrl,
    size = 90,
    xs,
    sm,
    md,
    lg,
    defaultImageSrc = EMPTY_IMAGE,
    border = false,
    borderOptions,
    rounded = false,
    shadow = false,
    background = atgGreyEee,
    alt = "Avatar",
    showTooltip = false,
}: Props) {
    return (
        <div css={[styles.container({size, background, rounded, shadow}), style]}>
            <ResponsiveImage
                imageUrl={imageUrl}
                width={size}
                heightRule={ASPECT_RATIO_1_1}
                alt={alt}
                showTooltip={showTooltip}
                type="avatar"
                xs={xs}
                sm={sm}
                md={md}
                lg={lg}
                border={border}
                borderOptions={borderOptions}
                rounded={rounded}
                renderDefaultImage={() => (
                    <Image
                        src={defaultImageSrc}
                        alt={alt}
                        showTooltip={showTooltip}
                        type="avatar"
                        border={border}
                        borderOptions={borderOptions}
                        rounded={rounded}
                        wrapped
                    />
                )}
            />
        </div>
    );
}

export default AvatarImage;
