export const TEAM_CARD_CLICK = "teamCardClick";
export const TEAM_CHAT = "teamChat";
export const START_PAGE_CLICK = "clickTillsammansStartpage";
export const TEAM_PAGE_CLICK = "clickTillsammansTeamPage";
export const TEAM_SEARCH = "teamSearch";
export const MENU_CLICK = "menuClick";
export const LOGIN_STARTED = "loginLoginStarted";
export const LOGIN_COMPLETE = "loginLoginComplete";
export const TERMINATE_TEAM_CLICK = "terminateTeam";
export const PURCHASE_SHARE = "purchase";

export type TeamCardClick = {
    event: typeof TEAM_CARD_CLICK;
    clickedSection: string | number;
};

export type TeamChat = {
    event: typeof TEAM_CHAT;
    clickedAction: string;
    teamName: string;
};

export type StartPageEvent = {
    event: typeof START_PAGE_CLICK;
    startPageObject: string;
    resultOfClick: string;
    target?: string | undefined;
    area?: string | undefined;
};

export type TeamPageEvent = {
    event: typeof TEAM_PAGE_CLICK;
    teamPageObject: string;
    resultOfClick?: string | undefined;
    area?: string | undefined;
};

export type TeamSearchEvent = {
    event: typeof TEAM_SEARCH;
    eventAction: string;
    eventType?: string | string[] | undefined;
    value?: string | undefined;
};

export type SideMenuClick = {
    event: typeof MENU_CLICK;
    menuType: string;
    menuLabel: string;
};

export type LoginStartedEvent = {
    event: typeof LOGIN_STARTED;
};

export type LoginCompleteEvent = {
    event: typeof LOGIN_COMPLETE;
};

export type TerminateTeamEvent = {
    event: typeof TERMINATE_TEAM_CLICK;
    teamName: string;
};

export type PurchaseShareEvent = {
    event: typeof PURCHASE_SHARE;
    area: string | undefined;
    category: string;
    name: string;
    variant: string;
    cost: number | undefined;
    id: string;
};

export type TillsammansTrackingEvent =
    | TeamCardClick
    | TeamChat
    | StartPageEvent
    | TeamPageEvent
    | SideMenuClick
    | LoginStartedEvent
    | LoginCompleteEvent
    | TeamSearchEvent
    | TerminateTeamEvent
    | PurchaseShareEvent;
