import type {Store} from "redux";
import {put, call, spawn, select, takeLatest} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import * as GameUtils from "../../common/gameUtils";
import * as GameActions from "../../common/redux/gameActions";
import * as GameSelectors from "../../common/redux/gameSelectors";
import horseGamePushListener from "./push/horseGamePushListener";
import * as HorseGameActions from "./horseGameActions";
import * as HorseGameSelectors from "./horseGameSelectors";
import * as HorseGameAPI from "./horseGameApi";

export function* gameFetchFlow({
    payload: {gameId, forceFetch},
}:
    | HorseGameActions.HorseGameFetchRequestAction
    | GameActions.GameFetchAction): SagaIterator {
    if (GameUtils.isSportGame(gameId)) return;

    if (!forceFetch) {
        const existingGame = yield select(GameSelectors.getGameById, gameId);
        if (existingGame) return;
    }

    try {
        const {data} = yield call(HorseGameAPI.fetchHorseGame, gameId);

        // Note: In case of a horse game, add the missing game type (not provided by racing info)
        yield put(
            HorseGameActions.fetchGameSuccess({
                ...data,
                type: GameUtils.getGameType(gameId),
            }),
        );
    } catch (e: unknown) {
        yield put(HorseGameActions.fetchGameError());
    }
}

export function* raceFetchFlow({
    payload: {raceId, forceFetch},
}: HorseGameActions.HorseRaceExtendedRequestAction): SagaIterator {
    if (!forceFetch) {
        const existingRace = yield select(HorseGameSelectors.getExtendedRaceById, raceId);
        if (existingRace) return;
    }

    try {
        const {data} = yield call(HorseGameAPI.fetchExtendedRace, raceId);

        // Note: In case of a horse game, add the missing game type (not provided by racing info)
        yield put(HorseGameActions.fetchRaceExtendedSuccess(data));
    } catch (e: unknown) {
        yield put(HorseGameActions.fetchRaceExtendedError());
    }
}

export default function* gameSaga(store: Store): SagaIterator {
    yield spawn(horseGamePushListener, store);

    yield takeLatest(
        [GameActions.GAME_FETCH, HorseGameActions.HORSE_GAME_FETCH_REQUEST],
        gameFetchFlow,
    );
    yield takeLatest(HorseGameActions.HORSE_RACE_EXTENDED_FETCH_REQUEST, raceFetchFlow);
}
