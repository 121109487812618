import {put, take, select} from "redux-saga/effects";
import * as Storage from "@atg-shared/storage";
import log from "@atg-shared/log";
import * as AuthSelectors from "./authSelectors";
import {ACCESS_TOKEN_STORAGE_KEY} from "./accessTokenConstants";
import type {MemberFlowOptions} from "./authActions";
import {
    CANCELLED_LOGIN_FLOW,
    MEMBER_REGISTER_FINISHED,
    LOGIN_FINISHED,
    startAuthenticationFlow,
    DID_NOT_LOGIN,
} from "./authActions";

type AuthSuccess = {
    status: "authSuccess";
    loggedIn: boolean;
};

type AuthCancelled = {
    status: "authCancelled";
};

export type AuthStatus = AuthSuccess | AuthCancelled;

export const AUTH_SUCCESS_NO_LOGIN = {
    status: "authSuccess",
    loggedIn: false,
};

export const AUTH_SUCCESS_LOGIN = {
    status: "authSuccess",
    loggedIn: true,
};

export const AUTH_CANCELLED = {
    status: "authCancelled",
};

function* authenticate(
    memberFlowOptionsFromArgs?: MemberFlowOptions,
    forceAuth?: boolean,
) {
    const isLoggedIn: boolean = forceAuth
        ? false
        : Boolean(Storage.getItem(ACCESS_TOKEN_STORAGE_KEY));

    if (isLoggedIn) {
        return AUTH_SUCCESS_NO_LOGIN;
    }

    const memberFlowOptionsFromState: MemberFlowOptions | null | undefined = yield select(
        AuthSelectors.getMemberFlowOptions,
    );

    const memberFlowOptions =
        memberFlowOptionsFromArgs ?? memberFlowOptionsFromState ?? undefined;

    // TODO: mobile app does its own checks and disregards forceAuth option even if it is true. Should be revised or settled. Adding DID_NOT_LOGIN option for this.
    yield put(startAuthenticationFlow(memberFlowOptions, forceAuth));
    const flowAction: {
        type:
            | typeof LOGIN_FINISHED
            | typeof MEMBER_REGISTER_FINISHED
            | typeof CANCELLED_LOGIN_FLOW
            | typeof DID_NOT_LOGIN;
    } = yield take([
        LOGIN_FINISHED,
        MEMBER_REGISTER_FINISHED,
        CANCELLED_LOGIN_FLOW,
        DID_NOT_LOGIN,
    ]);

    if (flowAction.type === CANCELLED_LOGIN_FLOW) return AUTH_CANCELLED;

    if (flowAction.type === DID_NOT_LOGIN) {
        // happens in the app when we do not login in auth flow
        return AUTH_SUCCESS_NO_LOGIN;
    }

    return AUTH_SUCCESS_LOGIN;
}

export default authenticate;
