import {camelCase, omit} from "lodash";
import type {ModalsState, ModalDataState} from "./domain";
import {
    MODAL_SHOW,
    MODAL_CLOSE,
    CLOSE_ALL_MODALS,
    OPEN_START_DETAILS,
    CLOSE_START_DETAILS,
    FINISH_PURCHASE_FLOW,
    RESTORE_PURCHASE_FLOW,
} from "./modalActionTypes";
import type {Action} from "./modalActions";

const id = (payload: string | {modalId: string}): string =>
    typeof payload === "string" ? payload : payload.modalId;

const key = (payload: string | {modalId: string}): string =>
    camelCase(`show ${id(payload)}`);

const dataKey = (payload: string | {modalId: string}): string =>
    camelCase(`${id(payload)}Data`);

export const modalData = (state: ModalDataState = {}, action: Action) => {
    const {type, payload} = action;
    switch (type) {
        case MODAL_SHOW: {
            if (typeof payload === "string") return state;
            const extraPayload = omit(payload, "modalId");
            if (Object.keys(extraPayload).length > 1) {
                return {
                    ...state,
                    [dataKey(payload)]: extraPayload,
                };
            }
            return state;
        }
        case MODAL_CLOSE:
            return omit(state, dataKey(payload));
        case CLOSE_ALL_MODALS:
        case RESTORE_PURCHASE_FLOW:
        case FINISH_PURCHASE_FLOW:
            // disable all modals when restoring purchase flow
            return {};

        default:
            return state;
    }
};

export const modals = (state: ModalsState = {}, action: Action) => {
    const {type, payload} = action;
    switch (type) {
        case MODAL_SHOW:
            return {
                ...state,
                [key(payload)]: true,
            };
        case MODAL_CLOSE:
            return {
                ...state,
                [key(payload)]: false,
            };
        case CLOSE_ALL_MODALS:
        case RESTORE_PURCHASE_FLOW:
        case FINISH_PURCHASE_FLOW:
            // disable all modals when restoring purchase flow
            return {};
        case OPEN_START_DETAILS:
            return {
                ...state,
                showStartInfo: true,
            };
        case CLOSE_START_DETAILS:
            return {
                ...state,
                showStartInfo: false,
            };
        default:
            return state;
    }
};
