import type {GameType} from "@atg-tillsammans-shared/types";
import type {
    MembershipPolicy,
    TeamType,
    RoundBetMethod,
    RoundState,
    Team as TeamGQL,
    Participant,
    Member,
} from "../generated";

export type TeamRound = {
    betMethod: RoundBetMethod | null;
    description: string | null;
    game: unknown | null;
    gameId: string;
    gameInitiator: Member | null;
    id: string;
    isGameInitiator: boolean;
    sales: {
        buyLimit: number | null;
        buyLimitPerPerson: number | null;
        deadline: string | null;
        id: string;
        price: number | null;
        refundAmount: number | null;
        scheduledReleases: Array<{
            id: string;
            numberOfShares: number;
            time: string;
        }> | null;
        totalSalesAmount: number | null;
    };
    salesDefaults: {
        buyLimitPerPerson: number;
        price: number;
    } | null;
    state: RoundState;
    teamId: string;
};

export enum TeamRoundInfoStatus {
    ACTIVE = "ACTIVE",
    ACTIVE_WITH_PARTICIPATION = "ACTIVE_WITH_PARTICIPATION",
    INACTIVE = "INACTIVE",
}

export enum TeamSettingsUpdateMethod {
    REMOVE_TEAM = "REMOVE_TEAM",
    LEAVE_TEAM = "LEAVE_TEAM",
}

export enum TeamSettingsErrorCode {
    ACCESS_DENIED = "ACCESS_DENIED",
    NOT_MEMBER_OF_TEAM = "NOT_MEMBER_OF_TEAM",
    LACKS_ROLE = "LACKS_ROLE",
    UPDATE_TEAM_DB_ERROR = "UPDATE_TEAM_DB_ERROR",
    TEAM_NAME_ALREADY_EXISTS = "TEAM_NAME_ALREADY_EXISTS",
    TEAM_ALREADY_EXISTS = "TEAM_ALREADY_EXISTS",
    MAX_NR_OF_TEAMS = "MAX_NR_OF_TEAMS",
    GAME_TYPE_LIST_IS_EMPTY = "GAME_TYPE_LIST_IS_EMPTY",
    ACTIVE_ROUND_OF_GAME_TYPE_EXISTS = "ACTIVE_ROUND_OF_GAME_TYPE_EXISTS",
    ACTIVE_ROUND_EXISTS = "ACTIVE_ROUND_EXISTS",
    MEMBERS_EXISTS = "MEMBERS_EXISTS",
    IS_CAPTAIN = "IS_CAPTAIN",
    IS_GAME_INITIATOR = "IS_GAME_INITIATOR",
    IS_PARTICIPATING = "IS_PARTICIPATING",
}

export enum TeamSettingsSuccessCode {
    DEFAULT = "DEFAULT",
    CLOSE_TEAM = "CLOSE_TEAM",
    LEAVE_TEAM = "LEAVE_TEAM",
}

export enum PreBookedErrorCode {
    RGS_NETLOSS_LIMIT_EXCEEDED = "RGS_NETLOSS_LIMIT_EXCEEDED",
    RGS_NETLOSS_SERVER_ERROR = "RGS_NETLOSS_SERVER_ERROR",
    RGS_CASINO_LOSS_LIMIT_NOT_SET = "RGS_CASINO_LOSS_LIMIT_NOT_SET",
    RGS_CASINO_HIGH_LOSS_LIMIT = "RGS_CASINO_HIGH_LOSS_LIMIT",
    RGS_CASINO_LOSS_LIMIT_EXCEEDED = "RGS_CASINO_LOSS_LIMIT_EXCEEDED",
    SUBSCRIPTION_NOT_FOUND = "SUBSCRIPTION_NOT_FOUND",
    ACCESS_DENIED = "ACCESS_DENIED",
    NOT_MEMBER_OF_TEAM = "NOT_MEMBER_OF_TEAM",
    RGS_SELF_EXCLUSION = "RGS_SELF_EXCLUSION",
    RGS_TEMPORARY_SELF_EXCLUSION = "RGS_TEMPORARY_SELF_EXCLUSION",
    RGS_COVID_SELF_EXCLUSION = "RGS_COVID_SELF_EXCLUSION",
    RGS_NAR_EXCLUSION = "RGS_NAR_EXCLUSION",
    RGS_NAR_UNDETERMINED = "RGS_NAR_UNDETERMINED",
    RGS_DEPOSIT_LIMIT_NOT_SET = "RGS_DEPOSIT_LIMIT_NOT_SET",
    RGS_HIGH_DEPOSIT_LIMIT = "RGS_HIGH_DEPOSIT_LIMIT",
    RGS_KYC_QUESTIONNAIRE_NOT_SUBMITTED = "RGS_KYC_QUESTIONNAIRE_NOT_SUBMITTED",
    RGS_CASINO_PLAY_TIME_LIMIT_EXCEEDED = "RGS_CASINO_PLAY_TIME_LIMIT_EXCEEDED",
    RGS_CASINO_PLAY_TIME_LIMIT_NOT_SET = "RGS_CASINO_PLAY_TIME_LIMIT_NOT_SET",
    RGS_CASINO_FULL_PLAY_LIMIT_NOT_SET = "RGS_CASINO_FULL_PLAY_LIMIT_NOT_SET",
    RGS_CALL_FAILED = "RGS_CALL_FAILED",
    GENERAL_ERROR = "GENERAL_ERROR",
}

export enum TeamMembersErrorCode {
    TEAM_IS_FULL = "TEAM_IS_FULL",
    TOO_MANY_TEAMS = "TOO_MANY_TEAMS",
    GENERAL_ERROR = "GENERAL_ERROR",
}

export type TeamID = string;

/**
 * Team extends tha Team type in the generated schema and will inherit the following fields:
 *
 *      createdDate: Date as string
 *      vacantMemberships: number | null
 *
 *      // Team basics
 *      id: string
 *      name: string
 *      captain: Member (this is named 'leader' in REST Api)
 *      type: TeamType
 *      gameTypes: GameType[]
 *      teamImageUrl: string | null
 *
 *      // Team info
 *      ranking: number | 0
 *      description: string | null
 *      location: Location | null
 *
 *      // Team settings
 *      membershipPolicy: MembershipPolicy
 *      invitationSetting: InvitationSetting (this is named 'invitation' in REST Api)
 *      batchBetAllowed: boolean (this is named 'filePlayAllowed' in REST Api)
 *      multipleReleasesAllowed: boolean
 *      systemsLimit: number | null
 *
 *      // Team roles
 *      isTeamCaptain: boolean
 *
 *      // Team restrictions
 *      visible: boolean
 *      locationVisible: boolean
 */
export interface Team extends TeamGQL {
    // These are not available on the backend model
    rounds?: TeamRound[];
    participations?: Participant[];
}

/**
 * This interface is based on the depricated MemberTeam type thet was provided by Wicket.
 * */
export interface deprecated_MemberTeam {
    id: TeamID;
    name: string;
    leader?: Member;
    gameTypes: GameType[];
    teamImageUrl: string;
    type: TeamType;
    teamCaptain: boolean;
    subscriptions?: GameType[];
    memberSince: string;
    pendingRequests?: number;
    unreadComments?: number;
    campaignText: string;
    membershipPolicy: MembershipPolicy;
    memberAmount: number;
    ranking: number;
}
