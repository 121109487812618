import {get, last} from "lodash";
import type {Solace} from "@atg-frame-shared/push";
import {getClient} from "@atg-frame-shared/push";

export const getClientName = (c: Solace | null): string => {
    if (!c || !c.session) return "";

    return get(c.session.getSessionProperties(), "clientName", "");
};
export const getRouteName = (c: Solace | null): string => {
    if (!c || !c.session) return "";

    return (
        last(get(c.session.getSessionProperties(), "virtualRouterName", "").split(":")) ??
        ""
    );
};

export type WebsocketIds = {
    clientName: string;
    route: string;
};

export function getWebsocketIds(): WebsocketIds | null {
    const client = getClient();
    if (client) {
        const clientName = getClientName(client);
        const route = getRouteName(client);
        if (clientName && route) {
            return {clientName, route};
        }
    }
    return null;
}
