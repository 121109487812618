import {now} from "lodash";
import {TEAM_PERSONALIZATION_SERVICE_URL} from "@atg-shared/service-url";
import {fetchAuthorized} from "@atg-shared/auth";
import type {GuideFlag, NotificationSetting} from "./personalization";

export const updatePushNotificationSettings = ({
    notificationSettings,
}: {
    notificationSettings: NotificationSetting[];
}) =>
    fetchAuthorized<NotificationSetting[]>(
        `${TEAM_PERSONALIZATION_SERVICE_URL}/settings/push-notifications`,
        {
            method: "PUT",
            body: JSON.stringify(notificationSettings),
            headers: {
                token: `${now()}`,
            },
        },
        {
            memberFlowEnabled: false,
        },
    );

export const updateGuideFlag = (guideFlag: GuideFlag) =>
    fetchAuthorized<GuideFlag[]>(
        `${TEAM_PERSONALIZATION_SERVICE_URL}/settings/guide-flags`,
        {
            method: "PUT",
            body: JSON.stringify(guideFlag),
            headers: {
                token: `${now()}`,
            },
        },
    );
