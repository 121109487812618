import {take, put, takeLatest, call} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import type {Dispatch} from "redux";
import type solaceNamespace from "solclientjs";
import type {Solace} from "@atg-frame-shared/push";
import {
    getClient,
    initSolace,
    onSessionEvent,
    configureConnectCallback,
} from "@atg-frame-shared/push";
import {getClientName, getRouteName} from "../helpers";
import * as PushActions from "./pushActions";

export const onConnect = (dispatch: Dispatch) => (client: Solace) =>
    dispatch(PushActions.connected(client));

export function* initSaga(dispatch: Dispatch): SagaIterator<void> {
    const connectCallback = yield call(onConnect, dispatch);

    yield call(configureConnectCallback, connectCallback);
    let clientInfo = yield call(initSolace);

    // continuously checking for connect event to see if we are reconnected
    while (true) {
        const payload = yield take(PushActions.CONNECTED);
        if (payload) {
            const {client} = payload;
            if (
                getClientName(clientInfo) !== getClientName(client) ||
                getRouteName(clientInfo) !== getRouteName(client)
            ) {
                yield put(PushActions.reconnected(client));
                clientInfo = client;
            }
        }
    }
}

export function* onTestSolaceEvent(
    action: PushActions.TestSolaceEventAction,
): SagaIterator<void> {
    const client: ReturnType<typeof getClient> = yield call(getClient);
    if (!client?.session) return;
    const event = {sessionEventCode: action.eventCode} as solaceNamespace.SessionEvent;
    yield call(onSessionEvent, client.session, event);
}

export function* getSolaceClient(): SagaIterator<Solace> {
    let client = yield call(getClient);

    if (!client || getClientName(client) === "" || getRouteName(client) === "") {
        const initFinishedAction = yield take(PushActions.CONNECTED);

        if (initFinishedAction.client) {
            client = initFinishedAction.client;
        }
    }

    return client;
}

export default function* pushSaga(dispatch: Dispatch): SagaIterator<void> {
    yield takeLatest(PushActions.INIT, initSaga, dispatch);
    yield takeLatest(PushActions.TEST_SOLACE_EVENT, onTestSolaceEvent);
}
