export const brandSuccessText = "#1e6800";
export const brandDangerText = "#d9534f";
export const brandText = "#595953";
export const brandPrimaryColor = "#1b3481";
export const brandTextDark = "#1a3775";
export const brandPrimary = "#1b3481";
export const brandGrayBg = "#f5f5f2";
export const brandGrayBorder = "#d7dcdf";
export const brandGrayBorderDark = "#cacbcb";
export const brandDefaultPrimary = "#65acdc";
export const brandDefaultGradientStart = "#80bde6";
export const brandDefaultGradientEnd = "#298dcd";
export const brandDefaultHoverGradientStart = "#70b7e7";
export const brandDefaultHoverGradientEnd = "#358cc7";
export const brandV86Primary = "#711970";
export const brandV86GradientStart = "#91448f";
export const brandV86GradientEnd = "#711970";
export const brandV86HoverGradientStart = "#844387";
export const brandV86HoverGradientEnd = "#651d67";
export const brandV75Primary = "#014f9f";
export const brandV75GradientStart = "#0d57a1";
export const brandV75GradientEnd = "#1e3480";
export const brandV75HoverGradientStart = "#005e9d";
export const brandV75HoverGradientEnd = "#004286";
export const brandHorseColor = brandV75Primary;
export const brandCasinoColor = "#641F48";
export const brandSportColor = "#014f9f";
export const brandHarryPrimary = brandV75Primary;
export const brandHarryGradientStart = brandV75GradientStart;
export const brandHarryGradientEnd = brandV75GradientEnd;
export const brandHarryHoverGradientStart = brandV75HoverGradientStart;
export const brandHarryHoverGradientEnd = brandV75HoverGradientEnd;
export const brandGs75Primary = "#2C6045";
export const brandGs75GradientStart = "#2A724E";
export const brandGs75GradientEnd = "#2A513F";
export const brandGs75HoverGradientStart = "#2e363e";
export const brandGs75HoverGradientEnd = "#000";
export const brandV65Primary = "#c00a26";
export const brandV65GradientStart = "#cc2332";
export const brandV65GradientEnd = "#a60d1f";
export const brandV65HoverGradientStart = "#e21a2d";
export const brandV65HoverGradientEnd = "#a80921";
export const brandV64Primary = "#f39332";
export const brandV64GradientStart = "#ef7c22";
export const brandV64GradientEnd = "#e95a0c";
export const brandV64HoverGradientStart = "#f2861a";
export const brandV64HoverGradientEnd = "#d56602";
export const brandV5Primary = brandDefaultPrimary;
export const brandV5GradientStart = brandDefaultGradientStart;
export const brandV5GradientEnd = brandDefaultGradientEnd;
export const brandV5HoverGradientStart = brandDefaultHoverGradientStart;
export const brandV5HoverGradientEnd = brandDefaultHoverGradientEnd;
export const brandV4Primary = brandDefaultPrimary;
export const brandV4GradientStart = brandDefaultGradientStart;
export const brandV4GradientEnd = brandDefaultGradientEnd;
export const brandV4HoverGradientStart = brandDefaultHoverGradientStart;
export const brandV4HoverGradientEnd = brandDefaultHoverGradientEnd;
export const brandV3Primary = brandDefaultPrimary;
export const brandV3GradientStart = brandDefaultGradientStart;
export const brandV3GradientEnd = brandDefaultGradientEnd;
export const brandV3HoverGradientStart = brandDefaultHoverGradientStart;
export const brandV3HoverGradientEnd = brandDefaultHoverGradientEnd;
export const brandDdPrimary = brandDefaultPrimary;
export const brandDdGradientStart = brandDefaultGradientStart;
export const brandDdGradientEnd = brandDefaultGradientEnd;
export const brandDdHoverGradientStart = brandDefaultHoverGradientStart;
export const brandDdHoverGradientEnd = brandDefaultHoverGradientEnd;
export const brandLdPrimary = brandDefaultPrimary;
export const brandLdGradientStart = brandDefaultGradientStart;
export const brandLdGradientEnd = brandDefaultGradientEnd;
export const brandLdHoverGradientStart = brandDefaultHoverGradientStart;
export const brandLdHoverGradientEnd = brandDefaultHoverGradientEnd;
export const brandTrioPrimary = brandDefaultPrimary;
export const brandTrioGradientStart = brandDefaultGradientStart;
export const brandTrioGradientEnd = brandDefaultGradientEnd;
export const brandTrioHoverGradientStart = brandDefaultHoverGradientStart;
export const brandTrioHoverGradientEnd = brandDefaultHoverGradientEnd;
export const brandTvillingPrimary = brandDefaultPrimary;
export const brandTvillingGradientStart = brandDefaultGradientStart;
export const brandTvillingGradientEnd = brandDefaultGradientEnd;
export const brandTvillingHoverGradientStart = brandDefaultHoverGradientStart;
export const brandTvillingHoverGradientEnd = brandDefaultHoverGradientEnd;
export const brandKombPrimary = brandDefaultPrimary;
export const brandKombGradientStart = brandDefaultGradientStart;
export const brandKombGradientEnd = brandDefaultGradientEnd;
export const brandKombHoverGradientStart = brandDefaultHoverGradientStart;
export const brandKombHoverGradientEnd = brandDefaultHoverGradientEnd;
export const brandVinnarePrimary = brandDefaultPrimary;
export const brandVinnareGradientStart = brandDefaultGradientStart;
export const brandVinnareGradientEnd = brandDefaultGradientEnd;
export const brandVinnareHoverGradientStart = brandDefaultHoverGradientStart;
export const brandVinnareHoverGradientEnd = brandDefaultHoverGradientEnd;
export const brandPlatsPrimary = brandDefaultPrimary;
export const brandPlatsGradientStart = brandDefaultGradientStart;
export const brandPlatsGradientEnd = brandDefaultGradientEnd;
export const brandPlatsHoverGradientStart = brandDefaultHoverGradientStart;
export const brandPlatsHoverGradientEnd = brandDefaultHoverGradientEnd;
export const brandVpPrimary = brandDefaultPrimary;
export const brandVpGradientStart = brandDefaultGradientStart;
export const brandVpGradientEnd = brandDefaultGradientEnd;
export const brandVpHoverGradientStart = brandDefaultHoverGradientStart;
export const brandVpHoverGradientEnd = brandDefaultHoverGradientEnd;
export const brandRaketPrimary = brandDefaultPrimary;
export const brandRaketGradientStart = brandDefaultGradientStart;
export const brandRaketGradientEnd = brandDefaultGradientEnd;
export const brandRaketHoverGradientStart = brandDefaultHoverGradientStart;
export const brandRaketHoverGradientEnd = brandDefaultHoverGradientEnd;
export const brandTop7Primary = brandDefaultPrimary;
export const brandTop7GradientStart = brandDefaultGradientStart;
export const brandTop7GradientEnd = brandDefaultGradientEnd;
export const brandTop7HoverGradientStart = brandDefaultHoverGradientStart;
export const brandTop7HoverGradientEnd = brandDefaultHoverGradientEnd;
export const brandTop7TextColor = brandTop7GradientEnd;
export const top7QuickAmount24Start = "#ffdc00";
export const top7QuickAmount24End = "#fab900";
export const top7QuickAmount24Border = "#bb8b11";
export const top7QuickAmount48Start = "#ff9600";
export const top7QuickAmount48End = "#ff6400";
export const top7QuickAmount48Border = "#af4f18";
export const top7QuickAmount96Start = "#e60f3c";
export const top7QuickAmount96End = "#c80000";
export const top7QuickAmount96Border = "#741841";
export const top7QuickAmount480Start = "#e65096";
export const top7QuickAmount480End = "#e6007d";
