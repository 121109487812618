import * as React from "react";
import type {Dispatch} from "react";
import {isEqual, isNil, omit} from "lodash";
import type {SagaIterator} from "redux-saga";
import {call, select, take, takeEvery, takeLatest, takeLeading} from "redux-saga/effects";
import * as AuthSelectors from "@atg-shared/auth/domain/authSelectors";
import {fetchAuthorized} from "@atg-shared/auth";
import {TEAM_SUBSCRIBE_SERVICE_URL} from "@atg-shared/service-url";
import {addLogEntry} from "@atg-tillsammans/log";
import {TeamActions, TeamSelectors} from "@atg-tillsammans/team-data-access/redux/team";
import * as SportBetActions from "@atg-tillsammans/bet-common/sport/redux/sportBetActions";
import * as chatNotificationStyles from "@atg-tillsammans/chat/components/ChatNotification/ChatNotification.styles";
import ChatNotification from "@atg-tillsammans/chat/components/ChatNotification/ChatNotification";
import * as ChatActions from "@atg-tillsammans/chat/domain/teamChatActions";
import * as ChatSelectors from "@atg-tillsammans/chat/domain/teamChatSelectors";
import type {
    BetMeta,
    BetMetaIds,
    BetWithDetails,
    HorseCoupon,
    Round,
} from "@atg-tillsammans/types/generated";
import {RoundState} from "@atg-tillsammans/types/generated";
import * as BetActions from "@atg-tillsammans/bet-common/common/redux/betActions";
import {
    PersonalizationActions,
    PersonalizationSelectors,
} from "@atg-tillsammans-shared/personalization-redux";
import {notification} from "@atg-global-shared/notification";
import {TeamType} from "@atg-tillsammans/types";
import type {TeamID} from "@atg-tillsammans/types";

import {
    HorseCouponPushEvent,
    setHorseCouponPushData,
} from "@atg-tillsammans/coupon-common/horse/hooks/useHorseCouponPush";
import {
    HorseReceiptPushEvent,
    setHorseReceiptPushData,
} from "@atg-tillsammans/receipt-common/horse/hooks/useHorseReceiptPush";
import {Paths} from "@atg-tillsammans-shared/navigation";
import * as UserActions from "@atg-global-shared/user/userActionTypes";
import {subscribe} from "@atg-frame-shared/push";
import {getSolaceClient} from "@atg-frame-shared/push-saga/domain/pushSaga";
import type {WebsocketIds} from "@atg-frame-shared/push-saga/helpers";
import {
    getClientName,
    getRouteName,
    getWebsocketIds,
} from "@atg-frame-shared/push-saga/helpers";
import {RECONNECTED} from "@atg-frame-shared/push-saga/domain/pushActions";
import {TeamService} from "@atg-tillsammans/team-data-access/services";
import {TillsammansAnalytics} from "@atg-tillsammans/analytics";
import {getGameType, isSportGame} from "@atg-tillsammans/game/common/gameUtils";
import {USER_ENTERED_TILLSAMMANS} from "@atg-tillsammans/app/redux";
import {isSmallScreen} from "atg-match-media/domain/matchMediaSelectors";
import history from "atg-history";
import RoundActivatedNotification from "../components/RoundActivatedNotification";
import * as loginNotificationStyles from "../components/LoginNotification.styles";
import * as roundActivatedNotificationStyles from "../components/RoundActivatedNotification.styles";
import LoginNotification from "../components/LoginNotification";
import * as TillsammansPushUtils from "./tillsammansPushUtil";
import {
    removeByUpdateTeamMembersQuery,
    updateTeamMembersQuery,
    writeRoundBalanceQuery,
    writeRoundFragment,
    writeRoundParticipationsQuery,
    writeRoundStateFragment,
    writeSalesFragment,
} from "./tillsammansPushUtil";

import type {
    RoundActivatedAction,
    TeamMemberLoggedInAction,
    TillsammansPushAction,
} from "./tillsammansPushActions";
import {
    ROUND_ACTIVATED,
    roundActivated,
    SSO_LOGOUT,
    ssoLogout,
    TEAM_MEMBER_LOGGED_IN,
    teamMemberLoggedIn,
    TillsammansPushActionType,
} from "./tillsammansPushActions";
import {t} from "@lingui/macro";

export const chatNotificationOptions = {
    icon: false,
    position: notification.POSITION.BOTTOM_RIGHT,
    autoClose: 4000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    closeButton: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
};

export function* messages(
    action: ChatActions.ChangeMessageTextAction | ChatActions.AddMessageAction,
): SagaIterator {
    const memberId: number = yield select(AuthSelectors.getMemberId);

    const {user, channelId} = action.message;

    if (!memberId || memberId === user.id) return;

    const currentTeamId: string = yield select(TeamSelectors.getTeamId);

    if (channelId === currentTeamId) return;

    TeamService.incrementUnreadMessages(channelId);
}

export function* messageReceived(
    action: ChatActions.ChangeMessageTextAction | ChatActions.AddMessageAction,
): SagaIterator {
    const chatNotificationActivated = yield select(
        PersonalizationSelectors.isChatNotificationsActivated,
    );
    if (!chatNotificationActivated) {
        return;
    }
    const channelId = Number(action.message.channelId);
    const currentTeamId: string = yield select(TeamSelectors.getTeamId);
    const dialogOpen = yield select(ChatSelectors.isDialogOpen);
    const smallScreen = yield select(isSmallScreen);
    if (
        TillsammansPushUtils.chatNotificationAllowed(
            +currentTeamId,
            channelId,
            dialogOpen,
            smallScreen,
        )
    ) {
        const team = yield select(TeamSelectors.getMemberTeam, `${channelId}`);
        if (team && team.type === TeamType.STANDARD) {
            const chatNotification = (
                <ChatNotification
                    text={action.message.text}
                    user={action.message.user}
                    title={t({
                        id: "push.action.type.chat.message",
                        message: `${TillsammansPushUtils.capitalized(
                            action.message.user.firstName,
                        )} har skrivit i ${team.name}`,
                    })}
                    link={Paths.getTeamPagePath(`${channelId}`)}
                />
            );
            if (action.type === ChatActions.ADD_MESSAGE) {
                yield call(notification.dark, chatNotification, {
                    ...chatNotificationOptions,
                    toastId: action.message.id,
                    style: chatNotificationStyles.wrapper,
                });
            }
            if (action.type === ChatActions.CHANGE_MESSAGE_TEXT) {
                yield call(notification.update, action.message.id, {
                    render: chatNotification,
                    ...chatNotificationOptions,
                    style: chatNotificationStyles.wrapper,
                });
            }
        }
    }
}
export function* messageRemoved(action: ChatActions.DeleteMessageAction): SagaIterator {
    yield call(notification.dismiss, action.messageId);
}
export function* dismissAll(): SagaIterator {
    yield call(notification.dismiss);
}

export function handleHorseCouponPushEvent(
    couponId: string,
    teamId: TeamID,
    gameId: string,
    coupon?: HorseCoupon,
    websocketIds?: WebsocketIds,
) {
    if (isEqual(websocketIds, getWebsocketIds())) return;

    if (coupon) {
        setHorseCouponPushData(
            couponId,
            HorseCouponPushEvent.UPDATE,
            teamId,
            gameId,
            coupon,
        );
    } else {
        setHorseCouponPushData(couponId, HorseCouponPushEvent.REMOVE, teamId, gameId);
    }
}

export function handleBetMetaPush(
    betMetaIds?: BetMetaIds,
    betMeta?: BetMeta,
    websocketIds?: WebsocketIds,
) {
    if (isEqual(websocketIds, getWebsocketIds())) return;

    if (betMetaIds && betMeta && betMetaIds.tsn) {
        setHorseReceiptPushData({
            event: HorseReceiptPushEvent.UPDATE,
            receiptId: betMetaIds.tsn,
            betMeta,
            betMetaIds,
        });
    }
}

export function handleReceiptPush(
    couponId: string,
    receipt: BetWithDetails,
    websocketIds: WebsocketIds,
) {
    if (isEqual(websocketIds, getWebsocketIds())) return;

    setHorseReceiptPushData({
        event: HorseReceiptPushEvent.ADD,
        receiptId: receipt.id,
        couponId,
        receipt,
    });
}

export function activateRound(
    round: Round,
    roundBalance: number,
    subscriptions?: Record<number, number>,
) {
    // Update round
    writeRoundFragment(round);

    // Update participation
    if (!subscriptions) return;

    writeRoundParticipationsQuery(round, subscriptions);
    writeRoundBalanceQuery(`${round.teamId}`, round.gameId, roundBalance);

    // Update my teams
    TeamService.incrementActivatedRoundsAmount(round.teamId);

    // Tracking for purchase share event on members who subscribed to the round
    // Note that the game initiator is not included in the subscriptions since tracking
    // for game initiator is done in the RoundForm.tsx
    const {gameInitiator} = round;
    const memberId = gameInitiator?.memberId;

    const updatedSubscriptions = memberId ? omit(subscriptions, memberId) : null;

    if (updatedSubscriptions) {
        Object.values(updatedSubscriptions).forEach((subscription) => {
            const gameType = isSportGame(round.gameId) ? "sport" : "trav";
            const price = (round.sales?.price || 0) as number;
            TillsammansAnalytics.trackPurchaseShareEvent({
                category: gameType,
                name: getGameType(round.gameId),
                variant: "kupong",
                cost: (subscription as number) * price,
                id: round.id,
            });
        });
    }
}

export const getMemberCallback =
    (dispatch: Dispatch<any>, memberId: number) =>
    (action: TillsammansPushAction): void => {
        switch (action.type) {
            case TillsammansPushActionType.COMMAND_RELOAD_MY_TEAMS: {
                dispatch(TeamActions.fetchMyTeams(true));
                break;
            }
            case TillsammansPushActionType.EVENT_MEMBER_ONLINE:
                if (action.member) {
                    dispatch(teamMemberLoggedIn(action.member));
                }
                break;
            case TillsammansPushActionType.EVENT_MEMBER_ENTERED_TILLSAMMANS:
                if (action.memberId && action.personalizationSettings) {
                    dispatch(
                        PersonalizationActions.receivedPersonalizationSettingsPush(
                            action.personalizationSettings,
                        ),
                    );
                }
                break;
            case TillsammansPushActionType.EVENT_TEAM_BET_PLACED:
                try {
                    addLogEntry(
                        2,
                        t({
                            id: "push.action.type.EVENT_TEAM_BET_PLACED",
                            message: "Received receipt push",
                        }),
                        {
                            tsn: action.receipt?.bet.tsn,
                        },
                    );
                } catch (e: unknown) {
                    // Silent error
                }
                if (action.couponId && action.receipt) {
                    if (action.websocketIds) {
                        handleReceiptPush(
                            action.couponId,
                            action.receipt,
                            action.websocketIds,
                        );
                    }

                    dispatch(BetActions.betSuccess(action.couponId, action.receipt));

                    if (!isNil(action.roundBalance) && action.teamId && action.gameId) {
                        writeRoundBalanceQuery(
                            `${action.teamId}`,
                            action.gameId,
                            action.roundBalance,
                        );
                    }

                    writeRoundStateFragment(
                        `${action.teamId}_${action.gameId}`,
                        RoundState.PLACED,
                    );
                    break;
                }

                // Only for sport, bet is only present if sport
                if (action.bet) {
                    dispatch({
                        type: SportBetActions.SPORT_PLACE_BET_RECEIVE,
                        payload: {response: {data: action.bet}},
                    });
                }

                break;
            case TillsammansPushActionType.EVENT_TEAM_BET_FAILED:
                if (action.couponId && action.errorCode) {
                    dispatch(BetActions.betError(action.couponId, action.errorCode));
                }
                break;
            case TillsammansPushActionType.EVENT_TEAM_BET_META_UPDATED:
                handleBetMetaPush(action.betMetaIds, action.betMeta);
                break;
            case TillsammansPushActionType.EVENT_TEAM_COUPON:
                if (action.couponId && action.teamId && action.gameId) {
                    handleHorseCouponPushEvent(
                        action.couponId,
                        action.teamId,
                        action.gameId,
                        action.coupon as HorseCoupon,
                        action.websocketIds,
                    );
                }
                break;
            case TillsammansPushActionType.EVENT_TEAM_ROUND_BALANCE:
                if (!isNil(action.roundBalance) && action.teamId && action.gameId) {
                    const {round, participation, roundBalance, gameId, teamId} = action;
                    writeRoundBalanceQuery(`${teamId}`, gameId, roundBalance);
                    const wsid = getWebsocketIds();
                    if (round && round.sales && !isEqual(action.websocketIds, wsid)) {
                        writeSalesFragment(round.sales);
                        if (participation) {
                            TillsammansPushUtils.updateRoundParticipationsQuery(
                                participation,
                            );
                        }
                    }
                    if (participation && participation?.member?.memberId === memberId) {
                        TillsammansPushUtils.updateMyStartPageParticipationsQuery(
                            participation,
                        );
                    }
                }
                break;
            case TillsammansPushActionType.EVENT_TEAM_ROUND_ACTIVATED:
                if (action.round && !isNil(action.roundBalance)) {
                    // Note: For the new activate round flow.
                    activateRound(
                        action.round,
                        action.roundBalance,
                        action.subscriptions,
                    );

                    // Note: Keeping this for legacy support
                    dispatch(
                        roundActivated(
                            action.round,
                            action.subscriptions,
                            action.scheduledReleases,
                        ),
                    );
                }
                break;
            case TillsammansPushActionType.EVENT_TEAM_ROUND_UPDATED:
                if (action.round) {
                    writeRoundFragment(action.round);
                }
                break;
            case TillsammansPushActionType.COMMAND_LOGOUT:
                dispatch(ssoLogout());
                break;
            case TillsammansPushActionType.EVENT_TEAM_MEMBER_JOINED:
                if (action.member && action.teamId) {
                    updateTeamMembersQuery(action.member, action.teamId);
                }
                break;
            case TillsammansPushActionType.EVENT_TEAM_MEMBER_LEFT:
                if (action.memberId && action.teamId) {
                    removeByUpdateTeamMembersQuery(action.memberId, action.teamId);
                }
                break;
            case TillsammansPushActionType.EVENT_TEAM_UPDATED:
            case TillsammansPushActionType.EVENT_TEAM_DELETED:
            default:
                break;
        }
    };
export function* logoutIfLoggedIn(): SagaIterator {
    const isLoggedIn = yield select(AuthSelectors.isLoggedIn);
    if (isLoggedIn) {
        yield call(history.push, "/utloggad");
    }
}
export function* roundNotification(action: RoundActivatedAction): SagaIterator {
    const memberId: number = yield select(AuthSelectors.getMemberId);
    let boughtShares = 0;
    if (memberId && action.payload.subscriptions) {
        // @ts-ignore
        boughtShares = action.payload.subscriptions[memberId] || 0;
    }
    const {round, scheduledReleases} = action.payload;
    const roundActivatedNotificationsActivated = yield select(
        PersonalizationSelectors.isRoundActivatedNotificationsActivated,
    );
    const isGameInitiator =
        round.gameInitiator && memberId === round.gameInitiator.memberId;
    const showRoundNotification =
        roundActivatedNotificationsActivated && !scheduledReleases;
    if (showRoundNotification && !isGameInitiator) {
        const team = yield select(TeamSelectors.getMemberTeam, round.teamId);
        if (team)
            yield call(
                notification.dark,
                <RoundActivatedNotification
                    round={round}
                    teamName={team.name}
                    numberOfShares={boughtShares}
                />,
                {
                    ...chatNotificationOptions,
                    toastId: round.id,
                    style: roundActivatedNotificationStyles.wrapper,
                },
            );
    }
}

export function* subscribeOnBehalfOf(): SagaIterator {
    try {
        const client = yield call(getSolaceClient);
        const clientName = yield call(getClientName, client);
        const routeName = yield call(getRouteName, client);
        if (clientName && routeName) {
            yield call(fetchAuthorized, TEAM_SUBSCRIBE_SERVICE_URL, {
                body: JSON.stringify({
                    clientName,
                    route: routeName,
                }),
                method: "POST",
            });
        }
    } catch (e: unknown) {
        // Silent error
    }
}

export function* subscribeUserToPush(dispatch: Dispatch<any>): SagaIterator {
    const memberId: number = yield select(AuthSelectors.getMemberId);
    const memberCallback = yield call(getMemberCallback, dispatch, memberId);
    const userUnsubscribe = yield call(subscribe, "user/>", memberCallback, false, true);
    const teamUnsubscribe = yield call(subscribe, "team/>", memberCallback, false, true);
    const reconnectAction = yield take([UserActions.LOGOUT_INITIATED, RECONNECTED]);

    yield call(userUnsubscribe);
    yield call(teamUnsubscribe);

    if (reconnectAction.type === RECONNECTED) {
        yield call(subscribeOnBehalfOf);
        yield call(subscribeUserToPush, dispatch);
    }
}
export function* teamMemberOnline(action: TeamMemberLoggedInAction): SagaIterator {
    const loginNotificationActivated = yield select(
        PersonalizationSelectors.isLoginNotificationsActivated,
    );
    if (!loginNotificationActivated) {
        return;
    }
    const memberId = yield select(AuthSelectors.getMemberId);
    if (memberId && memberId !== action.payload.member.memberId) {
        const loginNotification = <LoginNotification member={action.payload.member} />;
        yield call(notification.dark, loginNotification, {
            ...chatNotificationOptions,
            toastId: action.payload.member.memberId,
            style: loginNotificationStyles.wrapper,
        });
    }
}
export default function* tillsammansPushSaga(dispatch: Dispatch<any>): SagaIterator {
    yield takeEvery(
        [ChatActions.ADD_MESSAGE, ChatActions.CHANGE_MESSAGE_TEXT],
        messageReceived,
    );

    yield takeEvery([ChatActions.ADD_MESSAGE, ChatActions.CHANGE_MESSAGE_TEXT], messages);
    yield takeEvery(ChatActions.DELETE_MESSAGE, messageRemoved);
    yield takeEvery(ROUND_ACTIVATED, roundNotification);
    yield takeLatest(UserActions.LOGOUT_INITIATED, dismissAll);
    yield takeEvery(TEAM_MEMBER_LOGGED_IN, teamMemberOnline);
    yield takeEvery(SSO_LOGOUT, logoutIfLoggedIn);
    /**
     * TODO: Listen to action that triggers when entering tillsammans, NEW action needed suggested name
     * EVENT_MEMBER_ENTERED_TILLSAMMANS as its named on the backend.
     */
    yield takeLeading(
        [UserActions.LOGIN_FINISHED, UserActions.RECEIVE_USER, USER_ENTERED_TILLSAMMANS],
        subscribeUserToPush,
        dispatch,
    );
    yield takeLatest(TeamActions.RECEIVE_TEAMS, subscribeOnBehalfOf);
}
