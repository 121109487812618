import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import calendar from "dayjs/plugin/calendar";
import {getMarketConfig} from "@atg-shared/market-config";
import isYesterday from "dayjs/plugin/isYesterday";

dayjs.extend(calendar);
dayjs.extend(LocalizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isYesterday);


/**
 *
 * @param date - The date string or timestamp to parse. It can be a Unix timestamp (As number), a UTC datetime string, or a datetime string in another timezone.
 * @returns A Day.js object representing the parsed date in the market timezone.
 *
 * The function handles three cases:
 * 1. If the date is a Unix timestamp, it converts it to a Day.js object in the market timezone.
 * 2. If the date is a UTC datetime string (containing "Z" or "GMT"), it parses it directly and converts it to the market timezone.
 * 3. If the date is in another timezone, it assumes the date is +2 hours ahead of UTC and converts it to the market timezone.
 */
export const parseDateTimestamp = (date: string | number) => {
    const marketConfig = getMarketConfig();

    // If date is unix timestamp
    // We dont want to parse UNIX as strings because this can break some date like 20241104
    if (typeof date === "number") {
        return dayjs.unix(Number(date)).tz(marketConfig.timezone);
    }

    // If the incoming datetime is in UTC we can parse it directly
    // and return it with the market timezone
    if (date.includes("Z") || date.includes("GMT")) {
        return dayjs.utc(date).tz(marketConfig.timezone);
    }

    // If the incoming datetime is not in UTC we assume it is +2 hours ahead of UTC
    // tz should take the market timezone
    return dayjs.utc(dayjs(date).tz("Europe/Stockholm")).tz(marketConfig.timezone);
};