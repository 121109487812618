import * as React from "react";
import type {ImageUrl, BorderProps} from "../domain/image";
import {DEFAULT_BORDER, Img} from "./Image.styles";

export type Props = {
    src: ImageUrl | string;
    srcSet?: string;
    alt?: string;
    type?: string;
    border?: boolean;
    borderOptions?: BorderProps;
    rounded?: boolean;
    wrapped?: boolean;
    showTooltip?: boolean;
    onError?: () => void;
};

function Image({
    src,
    srcSet,
    alt,
    type,
    border,
    borderOptions,
    rounded,
    wrapped,
    showTooltip = false,
    onError,
}: Props) {
    let borderProps;
    if (border || borderOptions) {
        borderProps = borderOptions || DEFAULT_BORDER;
    }
    const title = alt || type || "image";
    return (
        <Img
            srcSet={srcSet}
            src={src}
            alt={title}
            borderOptions={borderProps}
            rounded={rounded}
            wrapped={wrapped}
            data-test-id={type ? `${type}-image` : "image"}
            data-hj-masked
            data-di-mask
            title={showTooltip ? title : undefined}
            onError={onError}
        />
    );
}

export default Image;
