import type {Reducer, AnyAction} from "redux";
import {combineReducers} from "redux";
import type {SagaFlowMessage, SagaFlowStatus} from "./sagaFlow";
import * as SagaFlowActions from "./sagaFlowActions";

type SagaFlowStatusState = {
    [flowId: string]: SagaFlowStatus;
};

type SagaFlowMessageState = {
    messages: SagaFlowMessage[];
};

const flowStatus: Reducer<SagaFlowStatusState> = (
    state: SagaFlowStatusState = {},
    action: AnyAction,
) => {
    switch (action.type) {
        case SagaFlowActions.SET_SAGA_FLOW_STATUS:
            return {
                ...state,
                [action.payload.flowId]: action.payload.flowStatus,
            };
        default:
            return state;
    }
};

const flowMessage: Reducer<SagaFlowMessageState> = (
    state: SagaFlowMessageState = {messages: []},
    action: AnyAction,
) => {
    switch (action.type) {
        case SagaFlowActions.ADD_SAGA_FLOW_MESSAGE:
            return {
                messages: state.messages.concat([action.payload]),
            };
        case SagaFlowActions.REMOVE_SAGA_FLOW_MESSAGE: {
            const {flowId, itemId} = action.payload;
            if (flowId && itemId)
                return {
                    messages: state.messages.filter(
                        (msg) => !(msg.flowId === flowId && msg.itemId === itemId),
                    ),
                };
            if (flowId)
                return {
                    messages: state.messages.filter((msg) => msg.flowId !== flowId),
                };
            if (itemId)
                return {
                    messages: state.messages.filter((msg) => msg.itemId !== itemId),
                };
            return state;
        }
        default:
            return state;
    }
};

export type SagaFlowReducerState = {
    flowStatus: SagaFlowStatusState;
    flowMessage: SagaFlowMessageState;
};

export default combineReducers<SagaFlowReducerState>({
    flowStatus,
    flowMessage,
});
