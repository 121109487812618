import * as Types from '@atg-tillsammans/types/generated';

import { gql } from '@apollo/client';
import { CoreMemberFieldsFragmentDoc } from '../../fragment/__generated__/memberFragments.graphql.generated';
import { TeamCoreFieldsFragmentDoc } from '../../fragment/__generated__/teamFragments.graphql.generated';
import * as Apollo from '@apollo/client';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
const defaultOptions = {} as const;
export type SetGameInitiatorsMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID']['input'];
  gameInitiators: Array<Types.InputMaybe<Types.GameIdGameInitiator>> | Types.InputMaybe<Types.GameIdGameInitiator>;
}>;


export type SetGameInitiatorsMutation = { __typename?: 'Mutation', setGameInitiators: { __typename?: 'SetGameInitiatorsResponse', rounds: Array<{ __typename?: 'Round', gameId: string, gameInitiator: { __typename?: 'Member', id: string, memberId: number, firstName: string, lastName: string, imageUrl: string | null } | null, game: { __typename?: 'Game', scheduledStartTime: any | null, tracks: Array<{ __typename?: 'Track', id: number | null, name: string | null }> | null } | null } | null> | null, roundsNotUpdated: Array<{ __typename?: 'GameInitiatorNotUpdated', gameId: string | null, reason: Types.GameInitiatorReason | null } | null> | null } | null };

export type SetRotatingGameInitiatorsMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID']['input'];
  gameType: Types.GameType;
}>;


export type SetRotatingGameInitiatorsMutation = { __typename?: 'Mutation', setRotatingGameInitiators: { __typename?: 'GameInitiatorSchedule', id: string, teamId: string, gameType: Types.GameType, scheduleType: Types.GameInitiatorScheduleType } };

export type SetCustomRotatingGameInitiatorsMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID']['input'];
  gameType: Types.GameType;
  selectedMemberIds: Array<Types.Scalars['Int']['input']> | Types.Scalars['Int']['input'];
}>;


export type SetCustomRotatingGameInitiatorsMutation = { __typename?: 'Mutation', setCustomRotatingGameInitiators: { __typename?: 'GameInitiatorSchedule', id: string, teamId: string, gameType: Types.GameType, scheduleType: Types.GameInitiatorScheduleType, selectedMembers: Array<{ __typename?: 'Member', id: string, memberId: number, firstName: string, lastName: string, imageUrl: string | null }> | null } };

export type UpdateCaptainMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID']['input'];
  memberId: Types.Scalars['ID']['input'];
}>;


export type UpdateCaptainMutation = { __typename?: 'Mutation', updateCaptain: { __typename?: 'Team', ranking: number, membershipPolicy: Types.MembershipPolicy, description: string | null, createdDate: any, invitationSetting: Types.InvitationSetting, visible: boolean, locationVisible: boolean, id: string, name: string, type: Types.TeamType, gameTypes: Array<Types.GameType>, teamImageUrl: string | null, multipleReleasesAllowed: boolean, systemsLimit: number | null, location: { __typename?: 'Location', postalCode: string | null, postalTown: string | null } | null, captain: { __typename?: 'Member', id: string, memberId: number, firstName: string, lastName: string, imageUrl: string | null } } | null };

export type UpdateInvitationSettingsMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID']['input'];
  invitationSetting: Types.InvitationSetting;
}>;


export type UpdateInvitationSettingsMutation = { __typename?: 'Mutation', updateInvitationSettings: { __typename?: 'Team', ranking: number, membershipPolicy: Types.MembershipPolicy, description: string | null, createdDate: any, invitationSetting: Types.InvitationSetting, visible: boolean, locationVisible: boolean, id: string, name: string, type: Types.TeamType, gameTypes: Array<Types.GameType>, teamImageUrl: string | null, multipleReleasesAllowed: boolean, systemsLimit: number | null, location: { __typename?: 'Location', postalCode: string | null, postalTown: string | null } | null, captain: { __typename?: 'Member', id: string, memberId: number, firstName: string, lastName: string, imageUrl: string | null } } | null };

export type UpdateTeamInfoMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID']['input'];
  name: Types.Scalars['String']['input'];
  description: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpdateTeamInfoMutation = { __typename?: 'Mutation', updateTeamInfo: { __typename?: 'Team', ranking: number, membershipPolicy: Types.MembershipPolicy, description: string | null, createdDate: any, invitationSetting: Types.InvitationSetting, visible: boolean, locationVisible: boolean, id: string, name: string, type: Types.TeamType, gameTypes: Array<Types.GameType>, teamImageUrl: string | null, multipleReleasesAllowed: boolean, systemsLimit: number | null, location: { __typename?: 'Location', postalCode: string | null, postalTown: string | null } | null, captain: { __typename?: 'Member', id: string, memberId: number, firstName: string, lastName: string, imageUrl: string | null } } | null };

export type UpdateSearchSettingsMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID']['input'];
  membershipPolicy: Types.MembershipPolicy;
  locationVisible: Types.Scalars['Boolean']['input'];
}>;


export type UpdateSearchSettingsMutation = { __typename?: 'Mutation', updateSearchSettings: { __typename?: 'Team', ranking: number, membershipPolicy: Types.MembershipPolicy, description: string | null, createdDate: any, invitationSetting: Types.InvitationSetting, visible: boolean, locationVisible: boolean, id: string, name: string, type: Types.TeamType, gameTypes: Array<Types.GameType>, teamImageUrl: string | null, multipleReleasesAllowed: boolean, systemsLimit: number | null, location: { __typename?: 'Location', postalCode: string | null, postalTown: string | null } | null, captain: { __typename?: 'Member', id: string, memberId: number, firstName: string, lastName: string, imageUrl: string | null } } | null };

export type UpdateGameTypesMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID']['input'];
  gameTypes: Array<Types.InputMaybe<Types.GameType>> | Types.InputMaybe<Types.GameType>;
}>;


export type UpdateGameTypesMutation = { __typename?: 'Mutation', updateGameTypes: { __typename?: 'Team', ranking: number, membershipPolicy: Types.MembershipPolicy, description: string | null, createdDate: any, invitationSetting: Types.InvitationSetting, visible: boolean, locationVisible: boolean, id: string, name: string, type: Types.TeamType, gameTypes: Array<Types.GameType>, teamImageUrl: string | null, multipleReleasesAllowed: boolean, systemsLimit: number | null, location: { __typename?: 'Location', postalCode: string | null, postalTown: string | null } | null, captain: { __typename?: 'Member', id: string, memberId: number, firstName: string, lastName: string, imageUrl: string | null } } | null };

export type UpdateSystemsLimitMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID']['input'];
  systemsLimit: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type UpdateSystemsLimitMutation = { __typename?: 'Mutation', updateSystemsLimit: { __typename?: 'Team', ranking: number, membershipPolicy: Types.MembershipPolicy, description: string | null, createdDate: any, invitationSetting: Types.InvitationSetting, visible: boolean, locationVisible: boolean, id: string, name: string, type: Types.TeamType, gameTypes: Array<Types.GameType>, teamImageUrl: string | null, multipleReleasesAllowed: boolean, systemsLimit: number | null, location: { __typename?: 'Location', postalCode: string | null, postalTown: string | null } | null, captain: { __typename?: 'Member', id: string, memberId: number, firstName: string, lastName: string, imageUrl: string | null } } | null };

export type LeaveTeamMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID']['input'];
}>;


export type LeaveTeamMutation = { __typename?: 'Mutation', leaveTeam: string };

export type CreateTeamMutationVariables = Types.Exact<{
  teamName: Types.Scalars['String']['input'];
  membershipPolicy: Types.MembershipPolicy;
  image: Types.InputMaybe<Types.Scalars['Upload']['input']>;
}>;


export type CreateTeamMutation = { __typename?: 'Mutation', createTeam: { __typename?: 'Team', ranking: number, membershipPolicy: Types.MembershipPolicy, description: string | null, createdDate: any, invitationSetting: Types.InvitationSetting, visible: boolean, locationVisible: boolean, id: string, name: string, type: Types.TeamType, gameTypes: Array<Types.GameType>, teamImageUrl: string | null, multipleReleasesAllowed: boolean, systemsLimit: number | null, location: { __typename?: 'Location', postalCode: string | null, postalTown: string | null } | null, captain: { __typename?: 'Member', id: string, memberId: number, firstName: string, lastName: string, imageUrl: string | null } } };

export type RemoveTeamMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID']['input'];
}>;


export type RemoveTeamMutation = { __typename?: 'Mutation', removeTeam: string | null };

export type PreBookingAllRoundsMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID']['input'];
  gameType: Types.GameType;
  maxAmount: Types.Scalars['Int']['input'];
}>;


export type PreBookingAllRoundsMutation = { __typename?: 'Mutation', setSubscriptionAllRounds: { __typename?: 'BetSubscription', id: string, type: Types.SubscriptionType, maxAmount: number, numberOfRounds: number | null, numberOfRoundsLeft: number | null, buyShareResult: string | null, selectedRounds: Array<{ __typename?: 'GameId', gameType: Types.GameType | null, gameDate: any | null, gameAreaCode: number | null, gameRaceNumber: number | null } | null> | null } | null };

export type PreBookingNumberOfRoundsMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID']['input'];
  gameType: Types.GameType;
  maxAmount: Types.Scalars['Int']['input'];
  numberOfRounds: Types.Scalars['Int']['input'];
}>;


export type PreBookingNumberOfRoundsMutation = { __typename?: 'Mutation', setSubscriptionNumberOfRounds: { __typename?: 'BetSubscription', id: string, type: Types.SubscriptionType, maxAmount: number, numberOfRounds: number | null, numberOfRoundsLeft: number | null, buyShareResult: string | null, selectedRounds: Array<{ __typename?: 'GameId', gameType: Types.GameType | null, gameDate: any | null, gameAreaCode: number | null, gameRaceNumber: number | null } | null> | null } | null };

export type RemoveSubscriptionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  teamId: Types.Scalars['ID']['input'];
  gameType: Types.GameType;
}>;


export type RemoveSubscriptionMutation = { __typename?: 'Mutation', removeSubscription: string };


export const SetGameInitiatorsDocument = gql`
    mutation SetGameInitiators($teamId: ID!, $gameInitiators: [GameIdGameInitiator]!) {
  setGameInitiators(input: {teamId: $teamId, gameInitiators: $gameInitiators}) {
    rounds {
      gameId
      gameInitiator {
        ...CoreMemberFields
      }
      game {
        scheduledStartTime
        tracks {
          id
          name
        }
      }
    }
    roundsNotUpdated {
      gameId
      reason
    }
  }
}
    ${CoreMemberFieldsFragmentDoc}`;
export type SetGameInitiatorsMutationFn = Apollo.MutationFunction<SetGameInitiatorsMutation, SetGameInitiatorsMutationVariables>;

/**
 * __useSetGameInitiatorsMutation__
 *
 * To run a mutation, you first call `useSetGameInitiatorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGameInitiatorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGameInitiatorsMutation, { data, loading, error }] = useSetGameInitiatorsMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      gameInitiators: // value for 'gameInitiators'
 *   },
 * });
 */
export function useSetGameInitiatorsMutation(baseOptions?: Apollo.MutationHookOptions<SetGameInitiatorsMutation, SetGameInitiatorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetGameInitiatorsMutation, SetGameInitiatorsMutationVariables>(SetGameInitiatorsDocument, options);
      }
export type SetGameInitiatorsMutationHookResult = ReturnType<typeof useSetGameInitiatorsMutation>;
export type SetGameInitiatorsMutationResult = Apollo.MutationResult<SetGameInitiatorsMutation>;
export type SetGameInitiatorsMutationOptions = Apollo.BaseMutationOptions<SetGameInitiatorsMutation, SetGameInitiatorsMutationVariables>;
export const SetRotatingGameInitiatorsDocument = gql`
    mutation SetRotatingGameInitiators($teamId: ID!, $gameType: GameType!) {
  setRotatingGameInitiators(input: {teamId: $teamId, gameType: $gameType}) {
    id
    teamId
    gameType
    scheduleType
  }
}
    `;
export type SetRotatingGameInitiatorsMutationFn = Apollo.MutationFunction<SetRotatingGameInitiatorsMutation, SetRotatingGameInitiatorsMutationVariables>;

/**
 * __useSetRotatingGameInitiatorsMutation__
 *
 * To run a mutation, you first call `useSetRotatingGameInitiatorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRotatingGameInitiatorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRotatingGameInitiatorsMutation, { data, loading, error }] = useSetRotatingGameInitiatorsMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      gameType: // value for 'gameType'
 *   },
 * });
 */
export function useSetRotatingGameInitiatorsMutation(baseOptions?: Apollo.MutationHookOptions<SetRotatingGameInitiatorsMutation, SetRotatingGameInitiatorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetRotatingGameInitiatorsMutation, SetRotatingGameInitiatorsMutationVariables>(SetRotatingGameInitiatorsDocument, options);
      }
export type SetRotatingGameInitiatorsMutationHookResult = ReturnType<typeof useSetRotatingGameInitiatorsMutation>;
export type SetRotatingGameInitiatorsMutationResult = Apollo.MutationResult<SetRotatingGameInitiatorsMutation>;
export type SetRotatingGameInitiatorsMutationOptions = Apollo.BaseMutationOptions<SetRotatingGameInitiatorsMutation, SetRotatingGameInitiatorsMutationVariables>;
export const SetCustomRotatingGameInitiatorsDocument = gql`
    mutation SetCustomRotatingGameInitiators($teamId: ID!, $gameType: GameType!, $selectedMemberIds: [Int!]!) {
  setCustomRotatingGameInitiators(
    input: {teamId: $teamId, gameType: $gameType, selectedMemberIds: $selectedMemberIds}
  ) {
    id
    teamId
    gameType
    scheduleType
    selectedMembers {
      ...CoreMemberFields
    }
  }
}
    ${CoreMemberFieldsFragmentDoc}`;
export type SetCustomRotatingGameInitiatorsMutationFn = Apollo.MutationFunction<SetCustomRotatingGameInitiatorsMutation, SetCustomRotatingGameInitiatorsMutationVariables>;

/**
 * __useSetCustomRotatingGameInitiatorsMutation__
 *
 * To run a mutation, you first call `useSetCustomRotatingGameInitiatorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCustomRotatingGameInitiatorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCustomRotatingGameInitiatorsMutation, { data, loading, error }] = useSetCustomRotatingGameInitiatorsMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      gameType: // value for 'gameType'
 *      selectedMemberIds: // value for 'selectedMemberIds'
 *   },
 * });
 */
export function useSetCustomRotatingGameInitiatorsMutation(baseOptions?: Apollo.MutationHookOptions<SetCustomRotatingGameInitiatorsMutation, SetCustomRotatingGameInitiatorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCustomRotatingGameInitiatorsMutation, SetCustomRotatingGameInitiatorsMutationVariables>(SetCustomRotatingGameInitiatorsDocument, options);
      }
export type SetCustomRotatingGameInitiatorsMutationHookResult = ReturnType<typeof useSetCustomRotatingGameInitiatorsMutation>;
export type SetCustomRotatingGameInitiatorsMutationResult = Apollo.MutationResult<SetCustomRotatingGameInitiatorsMutation>;
export type SetCustomRotatingGameInitiatorsMutationOptions = Apollo.BaseMutationOptions<SetCustomRotatingGameInitiatorsMutation, SetCustomRotatingGameInitiatorsMutationVariables>;
export const UpdateCaptainDocument = gql`
    mutation UpdateCaptain($teamId: ID!, $memberId: ID!) {
  updateCaptain(input: {teamId: $teamId, memberId: $memberId}) {
    ...TeamCoreFields
    ranking
    membershipPolicy
    description
    createdDate
    invitationSetting
    visible
    location {
      postalCode
      postalTown
    }
    locationVisible
  }
}
    ${TeamCoreFieldsFragmentDoc}`;
export type UpdateCaptainMutationFn = Apollo.MutationFunction<UpdateCaptainMutation, UpdateCaptainMutationVariables>;

/**
 * __useUpdateCaptainMutation__
 *
 * To run a mutation, you first call `useUpdateCaptainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCaptainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaptainMutation, { data, loading, error }] = useUpdateCaptainMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useUpdateCaptainMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCaptainMutation, UpdateCaptainMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCaptainMutation, UpdateCaptainMutationVariables>(UpdateCaptainDocument, options);
      }
export type UpdateCaptainMutationHookResult = ReturnType<typeof useUpdateCaptainMutation>;
export type UpdateCaptainMutationResult = Apollo.MutationResult<UpdateCaptainMutation>;
export type UpdateCaptainMutationOptions = Apollo.BaseMutationOptions<UpdateCaptainMutation, UpdateCaptainMutationVariables>;
export const UpdateInvitationSettingsDocument = gql`
    mutation UpdateInvitationSettings($teamId: ID!, $invitationSetting: InvitationSetting!) {
  updateInvitationSettings(
    input: {teamId: $teamId, invitationSetting: $invitationSetting}
  ) {
    ...TeamCoreFields
    ranking
    membershipPolicy
    description
    createdDate
    invitationSetting
    visible
    location {
      postalCode
      postalTown
    }
    locationVisible
  }
}
    ${TeamCoreFieldsFragmentDoc}`;
export type UpdateInvitationSettingsMutationFn = Apollo.MutationFunction<UpdateInvitationSettingsMutation, UpdateInvitationSettingsMutationVariables>;

/**
 * __useUpdateInvitationSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateInvitationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvitationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvitationSettingsMutation, { data, loading, error }] = useUpdateInvitationSettingsMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      invitationSetting: // value for 'invitationSetting'
 *   },
 * });
 */
export function useUpdateInvitationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvitationSettingsMutation, UpdateInvitationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvitationSettingsMutation, UpdateInvitationSettingsMutationVariables>(UpdateInvitationSettingsDocument, options);
      }
export type UpdateInvitationSettingsMutationHookResult = ReturnType<typeof useUpdateInvitationSettingsMutation>;
export type UpdateInvitationSettingsMutationResult = Apollo.MutationResult<UpdateInvitationSettingsMutation>;
export type UpdateInvitationSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateInvitationSettingsMutation, UpdateInvitationSettingsMutationVariables>;
export const UpdateTeamInfoDocument = gql`
    mutation UpdateTeamInfo($teamId: ID!, $name: String!, $description: String) {
  updateTeamInfo(input: {teamId: $teamId, name: $name, description: $description}) {
    ...TeamCoreFields
    ranking
    membershipPolicy
    description
    createdDate
    invitationSetting
    visible
    location {
      postalCode
      postalTown
    }
    locationVisible
  }
}
    ${TeamCoreFieldsFragmentDoc}`;
export type UpdateTeamInfoMutationFn = Apollo.MutationFunction<UpdateTeamInfoMutation, UpdateTeamInfoMutationVariables>;

/**
 * __useUpdateTeamInfoMutation__
 *
 * To run a mutation, you first call `useUpdateTeamInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamInfoMutation, { data, loading, error }] = useUpdateTeamInfoMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateTeamInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamInfoMutation, UpdateTeamInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamInfoMutation, UpdateTeamInfoMutationVariables>(UpdateTeamInfoDocument, options);
      }
export type UpdateTeamInfoMutationHookResult = ReturnType<typeof useUpdateTeamInfoMutation>;
export type UpdateTeamInfoMutationResult = Apollo.MutationResult<UpdateTeamInfoMutation>;
export type UpdateTeamInfoMutationOptions = Apollo.BaseMutationOptions<UpdateTeamInfoMutation, UpdateTeamInfoMutationVariables>;
export const UpdateSearchSettingsDocument = gql`
    mutation UpdateSearchSettings($teamId: ID!, $membershipPolicy: MembershipPolicy!, $locationVisible: Boolean!) {
  updateSearchSettings(
    input: {teamId: $teamId, membershipPolicy: $membershipPolicy, locationVisible: $locationVisible}
  ) {
    ...TeamCoreFields
    ranking
    membershipPolicy
    description
    createdDate
    invitationSetting
    visible
    location {
      postalCode
      postalTown
    }
    locationVisible
  }
}
    ${TeamCoreFieldsFragmentDoc}`;
export type UpdateSearchSettingsMutationFn = Apollo.MutationFunction<UpdateSearchSettingsMutation, UpdateSearchSettingsMutationVariables>;

/**
 * __useUpdateSearchSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSearchSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSearchSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSearchSettingsMutation, { data, loading, error }] = useUpdateSearchSettingsMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      membershipPolicy: // value for 'membershipPolicy'
 *      locationVisible: // value for 'locationVisible'
 *   },
 * });
 */
export function useUpdateSearchSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSearchSettingsMutation, UpdateSearchSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSearchSettingsMutation, UpdateSearchSettingsMutationVariables>(UpdateSearchSettingsDocument, options);
      }
export type UpdateSearchSettingsMutationHookResult = ReturnType<typeof useUpdateSearchSettingsMutation>;
export type UpdateSearchSettingsMutationResult = Apollo.MutationResult<UpdateSearchSettingsMutation>;
export type UpdateSearchSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateSearchSettingsMutation, UpdateSearchSettingsMutationVariables>;
export const UpdateGameTypesDocument = gql`
    mutation UpdateGameTypes($teamId: ID!, $gameTypes: [GameType]!) {
  updateGameTypes(input: {teamId: $teamId, gameTypes: $gameTypes}) {
    ...TeamCoreFields
    ranking
    membershipPolicy
    description
    createdDate
    invitationSetting
    visible
    location {
      postalCode
      postalTown
    }
    locationVisible
  }
}
    ${TeamCoreFieldsFragmentDoc}`;
export type UpdateGameTypesMutationFn = Apollo.MutationFunction<UpdateGameTypesMutation, UpdateGameTypesMutationVariables>;

/**
 * __useUpdateGameTypesMutation__
 *
 * To run a mutation, you first call `useUpdateGameTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGameTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGameTypesMutation, { data, loading, error }] = useUpdateGameTypesMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      gameTypes: // value for 'gameTypes'
 *   },
 * });
 */
export function useUpdateGameTypesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGameTypesMutation, UpdateGameTypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGameTypesMutation, UpdateGameTypesMutationVariables>(UpdateGameTypesDocument, options);
      }
export type UpdateGameTypesMutationHookResult = ReturnType<typeof useUpdateGameTypesMutation>;
export type UpdateGameTypesMutationResult = Apollo.MutationResult<UpdateGameTypesMutation>;
export type UpdateGameTypesMutationOptions = Apollo.BaseMutationOptions<UpdateGameTypesMutation, UpdateGameTypesMutationVariables>;
export const UpdateSystemsLimitDocument = gql`
    mutation UpdateSystemsLimit($teamId: ID!, $systemsLimit: Int) {
  updateSystemsLimit(input: {teamId: $teamId, systemsLimit: $systemsLimit}) {
    ...TeamCoreFields
    ranking
    membershipPolicy
    description
    createdDate
    invitationSetting
    visible
    location {
      postalCode
      postalTown
    }
    locationVisible
  }
}
    ${TeamCoreFieldsFragmentDoc}`;
export type UpdateSystemsLimitMutationFn = Apollo.MutationFunction<UpdateSystemsLimitMutation, UpdateSystemsLimitMutationVariables>;

/**
 * __useUpdateSystemsLimitMutation__
 *
 * To run a mutation, you first call `useUpdateSystemsLimitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemsLimitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSystemsLimitMutation, { data, loading, error }] = useUpdateSystemsLimitMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      systemsLimit: // value for 'systemsLimit'
 *   },
 * });
 */
export function useUpdateSystemsLimitMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSystemsLimitMutation, UpdateSystemsLimitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSystemsLimitMutation, UpdateSystemsLimitMutationVariables>(UpdateSystemsLimitDocument, options);
      }
export type UpdateSystemsLimitMutationHookResult = ReturnType<typeof useUpdateSystemsLimitMutation>;
export type UpdateSystemsLimitMutationResult = Apollo.MutationResult<UpdateSystemsLimitMutation>;
export type UpdateSystemsLimitMutationOptions = Apollo.BaseMutationOptions<UpdateSystemsLimitMutation, UpdateSystemsLimitMutationVariables>;
export const LeaveTeamDocument = gql`
    mutation LeaveTeam($teamId: ID!) {
  leaveTeam(input: {teamId: $teamId})
}
    `;
export type LeaveTeamMutationFn = Apollo.MutationFunction<LeaveTeamMutation, LeaveTeamMutationVariables>;

/**
 * __useLeaveTeamMutation__
 *
 * To run a mutation, you first call `useLeaveTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveTeamMutation, { data, loading, error }] = useLeaveTeamMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useLeaveTeamMutation(baseOptions?: Apollo.MutationHookOptions<LeaveTeamMutation, LeaveTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LeaveTeamMutation, LeaveTeamMutationVariables>(LeaveTeamDocument, options);
      }
export type LeaveTeamMutationHookResult = ReturnType<typeof useLeaveTeamMutation>;
export type LeaveTeamMutationResult = Apollo.MutationResult<LeaveTeamMutation>;
export type LeaveTeamMutationOptions = Apollo.BaseMutationOptions<LeaveTeamMutation, LeaveTeamMutationVariables>;
export const CreateTeamDocument = gql`
    mutation CreateTeam($teamName: String!, $membershipPolicy: MembershipPolicy!, $image: Upload) {
  createTeam(
    input: {teamName: $teamName, membershipPolicy: $membershipPolicy}
    image: $image
  ) {
    ...TeamCoreFields
    ranking
    membershipPolicy
    description
    createdDate
    invitationSetting
    visible
    location {
      postalCode
      postalTown
    }
    locationVisible
  }
}
    ${TeamCoreFieldsFragmentDoc}`;
export type CreateTeamMutationFn = Apollo.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      teamName: // value for 'teamName'
 *      membershipPolicy: // value for 'membershipPolicy'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useCreateTeamMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument, options);
      }
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = Apollo.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = Apollo.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const RemoveTeamDocument = gql`
    mutation RemoveTeam($teamId: ID!) {
  removeTeam(input: {teamId: $teamId})
}
    `;
export type RemoveTeamMutationFn = Apollo.MutationFunction<RemoveTeamMutation, RemoveTeamMutationVariables>;

/**
 * __useRemoveTeamMutation__
 *
 * To run a mutation, you first call `useRemoveTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTeamMutation, { data, loading, error }] = useRemoveTeamMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useRemoveTeamMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTeamMutation, RemoveTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTeamMutation, RemoveTeamMutationVariables>(RemoveTeamDocument, options);
      }
export type RemoveTeamMutationHookResult = ReturnType<typeof useRemoveTeamMutation>;
export type RemoveTeamMutationResult = Apollo.MutationResult<RemoveTeamMutation>;
export type RemoveTeamMutationOptions = Apollo.BaseMutationOptions<RemoveTeamMutation, RemoveTeamMutationVariables>;
export const PreBookingAllRoundsDocument = gql`
    mutation PreBookingAllRounds($teamId: ID!, $gameType: GameType!, $maxAmount: Int!) {
  setSubscriptionAllRounds(
    input: {teamId: $teamId, gameType: $gameType, maxAmount: $maxAmount}
  ) {
    id
    type
    maxAmount
    numberOfRounds
    numberOfRoundsLeft
    selectedRounds {
      gameType
      gameDate
      gameAreaCode
      gameRaceNumber
    }
    buyShareResult
  }
}
    `;
export type PreBookingAllRoundsMutationFn = Apollo.MutationFunction<PreBookingAllRoundsMutation, PreBookingAllRoundsMutationVariables>;

/**
 * __usePreBookingAllRoundsMutation__
 *
 * To run a mutation, you first call `usePreBookingAllRoundsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreBookingAllRoundsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [preBookingAllRoundsMutation, { data, loading, error }] = usePreBookingAllRoundsMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      gameType: // value for 'gameType'
 *      maxAmount: // value for 'maxAmount'
 *   },
 * });
 */
export function usePreBookingAllRoundsMutation(baseOptions?: Apollo.MutationHookOptions<PreBookingAllRoundsMutation, PreBookingAllRoundsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PreBookingAllRoundsMutation, PreBookingAllRoundsMutationVariables>(PreBookingAllRoundsDocument, options);
      }
export type PreBookingAllRoundsMutationHookResult = ReturnType<typeof usePreBookingAllRoundsMutation>;
export type PreBookingAllRoundsMutationResult = Apollo.MutationResult<PreBookingAllRoundsMutation>;
export type PreBookingAllRoundsMutationOptions = Apollo.BaseMutationOptions<PreBookingAllRoundsMutation, PreBookingAllRoundsMutationVariables>;
export const PreBookingNumberOfRoundsDocument = gql`
    mutation PreBookingNumberOfRounds($teamId: ID!, $gameType: GameType!, $maxAmount: Int!, $numberOfRounds: Int!) {
  setSubscriptionNumberOfRounds(
    input: {teamId: $teamId, gameType: $gameType, maxAmount: $maxAmount, numberOfRounds: $numberOfRounds}
  ) {
    id
    type
    maxAmount
    numberOfRounds
    numberOfRoundsLeft
    selectedRounds {
      gameType
      gameDate
      gameAreaCode
      gameRaceNumber
    }
    buyShareResult
  }
}
    `;
export type PreBookingNumberOfRoundsMutationFn = Apollo.MutationFunction<PreBookingNumberOfRoundsMutation, PreBookingNumberOfRoundsMutationVariables>;

/**
 * __usePreBookingNumberOfRoundsMutation__
 *
 * To run a mutation, you first call `usePreBookingNumberOfRoundsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreBookingNumberOfRoundsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [preBookingNumberOfRoundsMutation, { data, loading, error }] = usePreBookingNumberOfRoundsMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      gameType: // value for 'gameType'
 *      maxAmount: // value for 'maxAmount'
 *      numberOfRounds: // value for 'numberOfRounds'
 *   },
 * });
 */
export function usePreBookingNumberOfRoundsMutation(baseOptions?: Apollo.MutationHookOptions<PreBookingNumberOfRoundsMutation, PreBookingNumberOfRoundsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PreBookingNumberOfRoundsMutation, PreBookingNumberOfRoundsMutationVariables>(PreBookingNumberOfRoundsDocument, options);
      }
export type PreBookingNumberOfRoundsMutationHookResult = ReturnType<typeof usePreBookingNumberOfRoundsMutation>;
export type PreBookingNumberOfRoundsMutationResult = Apollo.MutationResult<PreBookingNumberOfRoundsMutation>;
export type PreBookingNumberOfRoundsMutationOptions = Apollo.BaseMutationOptions<PreBookingNumberOfRoundsMutation, PreBookingNumberOfRoundsMutationVariables>;
export const RemoveSubscriptionDocument = gql`
    mutation RemoveSubscription($id: ID!, $teamId: ID!, $gameType: GameType!) {
  removeSubscription(input: {id: $id, teamId: $teamId, gameType: $gameType})
}
    `;
export type RemoveSubscriptionMutationFn = Apollo.MutationFunction<RemoveSubscriptionMutation, RemoveSubscriptionMutationVariables>;

/**
 * __useRemoveSubscriptionMutation__
 *
 * To run a mutation, you first call `useRemoveSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSubscriptionMutation, { data, loading, error }] = useRemoveSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      teamId: // value for 'teamId'
 *      gameType: // value for 'gameType'
 *   },
 * });
 */
export function useRemoveSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSubscriptionMutation, RemoveSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSubscriptionMutation, RemoveSubscriptionMutationVariables>(RemoveSubscriptionDocument, options);
      }
export type RemoveSubscriptionMutationHookResult = ReturnType<typeof useRemoveSubscriptionMutation>;
export type RemoveSubscriptionMutationResult = Apollo.MutationResult<RemoveSubscriptionMutation>;
export type RemoveSubscriptionMutationOptions = Apollo.BaseMutationOptions<RemoveSubscriptionMutation, RemoveSubscriptionMutationVariables>;
export type AmountsKeySpecifier = ('netDividend' | 'payoutFee' | 'sellingFee' | 'sellingFeeRefund' | 'stakeRefund' | 'totalCost' | 'totalPayout' | 'totalRefund' | AmountsKeySpecifier)[];
export type AmountsFieldPolicy = {
	netDividend?: FieldPolicy<any> | FieldReadFunction<any>,
	payoutFee?: FieldPolicy<any> | FieldReadFunction<any>,
	sellingFee?: FieldPolicy<any> | FieldReadFunction<any>,
	sellingFeeRefund?: FieldPolicy<any> | FieldReadFunction<any>,
	stakeRefund?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCost?: FieldPolicy<any> | FieldReadFunction<any>,
	totalPayout?: FieldPolicy<any> | FieldReadFunction<any>,
	totalRefund?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ApplicationKeySpecifier = ('createdDate' | 'id' | 'member' | 'team' | 'text' | ApplicationKeySpecifier)[];
export type ApplicationFieldPolicy = {
	createdDate?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	member?: FieldPolicy<any> | FieldReadFunction<any>,
	team?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BatchBetSummaryKeySpecifier = ('numberOfCombinations' | 'numberOfCoupons' | 'numberOfSystems' | 'totalCost' | BatchBetSummaryKeySpecifier)[];
export type BatchBetSummaryFieldPolicy = {
	numberOfCombinations?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfCoupons?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfSystems?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCost?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BatchBetTeamKeySpecifier = ('name' | 'teamId' | BatchBetTeamKeySpecifier)[];
export type BatchBetTeamFieldPolicy = {
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	teamId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BatchBetUploadDataKeySpecifier = ('batchBetUploadId' | 'games' | 'message' | 'status' | 'summary' | BatchBetUploadDataKeySpecifier)[];
export type BatchBetUploadDataFieldPolicy = {
	batchBetUploadId?: FieldPolicy<any> | FieldReadFunction<any>,
	games?: FieldPolicy<any> | FieldReadFunction<any>,
	message?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	summary?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BetMetaKeySpecifier = ('couponId' | 'description' | 'id' | 'imageRef' | BetMetaKeySpecifier)[];
export type BetMetaFieldPolicy = {
	couponId?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	imageRef?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BetSubscriptionKeySpecifier = ('buyShareResult' | 'id' | 'maxAmount' | 'numberOfRounds' | 'numberOfRoundsLeft' | 'selectedRounds' | 'type' | BetSubscriptionKeySpecifier)[];
export type BetSubscriptionFieldPolicy = {
	buyShareResult?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	maxAmount?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfRounds?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfRoundsLeft?: FieldPolicy<any> | FieldReadFunction<any>,
	selectedRounds?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BetWithDetailsKeySpecifier = ('amounts' | 'bet' | 'betMeta' | 'betType' | 'contract' | 'id' | 'offering' | BetWithDetailsKeySpecifier)[];
export type BetWithDetailsFieldPolicy = {
	amounts?: FieldPolicy<any> | FieldReadFunction<any>,
	bet?: FieldPolicy<any> | FieldReadFunction<any>,
	betMeta?: FieldPolicy<any> | FieldReadFunction<any>,
	betType?: FieldPolicy<any> | FieldReadFunction<any>,
	contract?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	offering?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CbsBetKeySpecifier = ('coupons' | 'currency' | 'grading' | 'numberOfSystems' | 'offeringId' | 'placedAt' | 'stake' | 'status' | 'tsn' | 'type' | 'vertical' | CbsBetKeySpecifier)[];
export type CbsBetFieldPolicy = {
	coupons?: FieldPolicy<any> | FieldReadFunction<any>,
	currency?: FieldPolicy<any> | FieldReadFunction<any>,
	grading?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfSystems?: FieldPolicy<any> | FieldReadFunction<any>,
	offeringId?: FieldPolicy<any> | FieldReadFunction<any>,
	placedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	stake?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	tsn?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	vertical?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CbsCouponKeySpecifier = ('id' | 'numberOfCombinations' | 'numberOfSystems' | 'selections' | 'stake' | 'stakePerCombination' | 'unitsPerCombination' | CbsCouponKeySpecifier)[];
export type CbsCouponFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfCombinations?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfSystems?: FieldPolicy<any> | FieldReadFunction<any>,
	selections?: FieldPolicy<any> | FieldReadFunction<any>,
	stake?: FieldPolicy<any> | FieldReadFunction<any>,
	stakePerCombination?: FieldPolicy<any> | FieldReadFunction<any>,
	unitsPerCombination?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CbsOfferingKeySpecifier = ('status' | 'summary' | 'unitStake' | CbsOfferingKeySpecifier)[];
export type CbsOfferingFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	summary?: FieldPolicy<any> | FieldReadFunction<any>,
	unitStake?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContractKeySpecifier = ('maxCost' | 'offeringId' | 'options' | 'owner' | 'stake' | 'type' | ContractKeySpecifier)[];
export type ContractFieldPolicy = {
	maxCost?: FieldPolicy<any> | FieldReadFunction<any>,
	offeringId?: FieldPolicy<any> | FieldReadFunction<any>,
	options?: FieldPolicy<any> | FieldReadFunction<any>,
	owner?: FieldPolicy<any> | FieldReadFunction<any>,
	stake?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CouponKeySpecifier = ('created' | 'id' | 'modified' | CouponKeySpecifier)[];
export type CouponFieldPolicy = {
	created?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	modified?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CouponSelectionsKeySpecifier = ('legs' | CouponSelectionsKeySpecifier)[];
export type CouponSelectionsFieldPolicy = {
	legs?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CreateApplicationResponseKeySpecifier = ('status' | CreateApplicationResponseKeySpecifier)[];
export type CreateApplicationResponseFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GameKeySpecifier = ('endTime' | 'estimatedJackpot' | 'gameId' | 'gameStatus' | 'id' | 'jackpotAmount' | 'scheduledStartTime' | 'tracks' | GameKeySpecifier)[];
export type GameFieldPolicy = {
	endTime?: FieldPolicy<any> | FieldReadFunction<any>,
	estimatedJackpot?: FieldPolicy<any> | FieldReadFunction<any>,
	gameId?: FieldPolicy<any> | FieldReadFunction<any>,
	gameStatus?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	jackpotAmount?: FieldPolicy<any> | FieldReadFunction<any>,
	scheduledStartTime?: FieldPolicy<any> | FieldReadFunction<any>,
	tracks?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GameIdKeySpecifier = ('gameAreaCode' | 'gameDate' | 'gameRaceNumber' | 'gameType' | GameIdKeySpecifier)[];
export type GameIdFieldPolicy = {
	gameAreaCode?: FieldPolicy<any> | FieldReadFunction<any>,
	gameDate?: FieldPolicy<any> | FieldReadFunction<any>,
	gameRaceNumber?: FieldPolicy<any> | FieldReadFunction<any>,
	gameType?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GameInitiatorNotUpdatedKeySpecifier = ('gameId' | 'reason' | GameInitiatorNotUpdatedKeySpecifier)[];
export type GameInitiatorNotUpdatedFieldPolicy = {
	gameId?: FieldPolicy<any> | FieldReadFunction<any>,
	reason?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GameInitiatorScheduleKeySpecifier = ('currentPosition' | 'gameType' | 'id' | 'scheduleType' | 'selectedMembers' | 'teamId' | GameInitiatorScheduleKeySpecifier)[];
export type GameInitiatorScheduleFieldPolicy = {
	currentPosition?: FieldPolicy<any> | FieldReadFunction<any>,
	gameType?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	scheduleType?: FieldPolicy<any> | FieldReadFunction<any>,
	selectedMembers?: FieldPolicy<any> | FieldReadFunction<any>,
	teamId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GradingKeySpecifier = ('coupons' | 'dividend' | 'qualifyingUnits' | GradingKeySpecifier)[];
export type GradingFieldPolicy = {
	coupons?: FieldPolicy<any> | FieldReadFunction<any>,
	dividend?: FieldPolicy<any> | FieldReadFunction<any>,
	qualifyingUnits?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GradingCouponKeySpecifier = ('dividend' | 'effectiveMarks' | 'id' | 'qualifyingCombinations' | 'qualifyingUnits' | GradingCouponKeySpecifier)[];
export type GradingCouponFieldPolicy = {
	dividend?: FieldPolicy<any> | FieldReadFunction<any>,
	effectiveMarks?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	qualifyingCombinations?: FieldPolicy<any> | FieldReadFunction<any>,
	qualifyingUnits?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HorseCouponKeySpecifier = ('betMeta' | 'contractType' | 'created' | 'harryFlavor' | 'harryMaxStake' | 'id' | 'modified' | 'numberOfSystems' | 'selections' | 'stake' | HorseCouponKeySpecifier)[];
export type HorseCouponFieldPolicy = {
	betMeta?: FieldPolicy<any> | FieldReadFunction<any>,
	contractType?: FieldPolicy<any> | FieldReadFunction<any>,
	created?: FieldPolicy<any> | FieldReadFunction<any>,
	harryFlavor?: FieldPolicy<any> | FieldReadFunction<any>,
	harryMaxStake?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	modified?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfSystems?: FieldPolicy<any> | FieldReadFunction<any>,
	selections?: FieldPolicy<any> | FieldReadFunction<any>,
	stake?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LegKeySpecifier = ('harryOpen' | 'raceId' | 'value' | LegKeySpecifier)[];
export type LegFieldPolicy = {
	harryOpen?: FieldPolicy<any> | FieldReadFunction<any>,
	raceId?: FieldPolicy<any> | FieldReadFunction<any>,
	value?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LocationKeySpecifier = ('postalCode' | 'postalTown' | LocationKeySpecifier)[];
export type LocationFieldPolicy = {
	postalCode?: FieldPolicy<any> | FieldReadFunction<any>,
	postalTown?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MarksKeySpecifier = ('marks' | 'reserves' | MarksKeySpecifier)[];
export type MarksFieldPolicy = {
	marks?: FieldPolicy<any> | FieldReadFunction<any>,
	reserves?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MemberKeySpecifier = ('firstName' | 'id' | 'imageUrl' | 'lastName' | 'memberId' | 'online' | MemberKeySpecifier)[];
export type MemberFieldPolicy = {
	firstName?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	imageUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	lastName?: FieldPolicy<any> | FieldReadFunction<any>,
	memberId?: FieldPolicy<any> | FieldReadFunction<any>,
	online?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MemberTeamKeySpecifier = ('activatedRoundsAmount' | 'captain' | 'gameTypes' | 'id' | 'isTeamCaptain' | 'memberAmount' | 'memberSince' | 'membershipPolicy' | 'name' | 'pendingRequests' | 'ranking' | 'subscriptions' | 'teamImageUrl' | 'type' | 'unreadComments' | MemberTeamKeySpecifier)[];
export type MemberTeamFieldPolicy = {
	activatedRoundsAmount?: FieldPolicy<any> | FieldReadFunction<any>,
	captain?: FieldPolicy<any> | FieldReadFunction<any>,
	gameTypes?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isTeamCaptain?: FieldPolicy<any> | FieldReadFunction<any>,
	memberAmount?: FieldPolicy<any> | FieldReadFunction<any>,
	memberSince?: FieldPolicy<any> | FieldReadFunction<any>,
	membershipPolicy?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	pendingRequests?: FieldPolicy<any> | FieldReadFunction<any>,
	ranking?: FieldPolicy<any> | FieldReadFunction<any>,
	subscriptions?: FieldPolicy<any> | FieldReadFunction<any>,
	teamImageUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	unreadComments?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MutationKeySpecifier = ('addGameInitiatorCandidate' | 'batchBetUpload' | 'createApplication' | 'createParticipation' | 'createRound' | 'createSportCoupon' | 'createTeam' | 'generateNewGameInitiatorCandidateLink' | 'getMailNotificationSettings' | 'leaveTeam' | 'optOutMail' | 'placeHorseBet' | 'placeSportBet' | 'playBatchBetV2' | 'processApplication' | 'removeApplication' | 'removeCoupon' | 'removeGameInitiatorCandidate' | 'removeMember' | 'removeSubscription' | 'removeTeam' | 'resetImageAndDescription' | 'saveBetDescription' | 'saveCoupon' | 'saveSportCoupon' | 'sendEmail' | 'setCustomRotatingGameInitiators' | 'setGameInitiator' | 'setGameInitiators' | 'setRotatingGameInitiators' | 'setSubscriptionAllRounds' | 'setSubscriptionNumberOfRounds' | 'setSubscriptionSelectedRounds' | 'updateCaptain' | 'updateGameTypes' | 'updateInvitationSettings' | 'updateRound' | 'updateSearchSettings' | 'updateSystemsLimit' | 'updateTeamInfo' | 'uploadBetImage' | MutationKeySpecifier)[];
export type MutationFieldPolicy = {
	addGameInitiatorCandidate?: FieldPolicy<any> | FieldReadFunction<any>,
	batchBetUpload?: FieldPolicy<any> | FieldReadFunction<any>,
	createApplication?: FieldPolicy<any> | FieldReadFunction<any>,
	createParticipation?: FieldPolicy<any> | FieldReadFunction<any>,
	createRound?: FieldPolicy<any> | FieldReadFunction<any>,
	createSportCoupon?: FieldPolicy<any> | FieldReadFunction<any>,
	createTeam?: FieldPolicy<any> | FieldReadFunction<any>,
	generateNewGameInitiatorCandidateLink?: FieldPolicy<any> | FieldReadFunction<any>,
	getMailNotificationSettings?: FieldPolicy<any> | FieldReadFunction<any>,
	leaveTeam?: FieldPolicy<any> | FieldReadFunction<any>,
	optOutMail?: FieldPolicy<any> | FieldReadFunction<any>,
	placeHorseBet?: FieldPolicy<any> | FieldReadFunction<any>,
	placeSportBet?: FieldPolicy<any> | FieldReadFunction<any>,
	playBatchBetV2?: FieldPolicy<any> | FieldReadFunction<any>,
	processApplication?: FieldPolicy<any> | FieldReadFunction<any>,
	removeApplication?: FieldPolicy<any> | FieldReadFunction<any>,
	removeCoupon?: FieldPolicy<any> | FieldReadFunction<any>,
	removeGameInitiatorCandidate?: FieldPolicy<any> | FieldReadFunction<any>,
	removeMember?: FieldPolicy<any> | FieldReadFunction<any>,
	removeSubscription?: FieldPolicy<any> | FieldReadFunction<any>,
	removeTeam?: FieldPolicy<any> | FieldReadFunction<any>,
	resetImageAndDescription?: FieldPolicy<any> | FieldReadFunction<any>,
	saveBetDescription?: FieldPolicy<any> | FieldReadFunction<any>,
	saveCoupon?: FieldPolicy<any> | FieldReadFunction<any>,
	saveSportCoupon?: FieldPolicy<any> | FieldReadFunction<any>,
	sendEmail?: FieldPolicy<any> | FieldReadFunction<any>,
	setCustomRotatingGameInitiators?: FieldPolicy<any> | FieldReadFunction<any>,
	setGameInitiator?: FieldPolicy<any> | FieldReadFunction<any>,
	setGameInitiators?: FieldPolicy<any> | FieldReadFunction<any>,
	setRotatingGameInitiators?: FieldPolicy<any> | FieldReadFunction<any>,
	setSubscriptionAllRounds?: FieldPolicy<any> | FieldReadFunction<any>,
	setSubscriptionNumberOfRounds?: FieldPolicy<any> | FieldReadFunction<any>,
	setSubscriptionSelectedRounds?: FieldPolicy<any> | FieldReadFunction<any>,
	updateCaptain?: FieldPolicy<any> | FieldReadFunction<any>,
	updateGameTypes?: FieldPolicy<any> | FieldReadFunction<any>,
	updateInvitationSettings?: FieldPolicy<any> | FieldReadFunction<any>,
	updateRound?: FieldPolicy<any> | FieldReadFunction<any>,
	updateSearchSettings?: FieldPolicy<any> | FieldReadFunction<any>,
	updateSystemsLimit?: FieldPolicy<any> | FieldReadFunction<any>,
	updateTeamInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	uploadBetImage?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NotificationSettingKeySpecifier = ('activated' | 'id' | 'label' | NotificationSettingKeySpecifier)[];
export type NotificationSettingFieldPolicy = {
	activated?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OfferingSummaryKeySpecifier = ('legs' | 'pools' | OfferingSummaryKeySpecifier)[];
export type OfferingSummaryFieldPolicy = {
	legs?: FieldPolicy<any> | FieldReadFunction<any>,
	pools?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ParticipantKeySpecifier = ('gameId' | 'id' | 'member' | 'numberOfShares' | 'teamId' | 'totalCost' | ParticipantKeySpecifier)[];
export type ParticipantFieldPolicy = {
	gameId?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	member?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfShares?: FieldPolicy<any> | FieldReadFunction<any>,
	teamId?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCost?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QueryKeySpecifier = ('activeGames' | 'applications' | 'batchBetTeams' | 'batchBetUploadData' | 'currentGames' | 'futureGames' | 'futureRounds' | 'game' | 'gameInitiatorCandidateLink' | 'gameInitiatorCandidates' | 'gameInitiatorMemberships' | 'gameInitiatorSchedule' | 'gameInitiatorSchedules' | 'getCoupons' | 'getMyCoupons' | 'historicalGames' | 'memberParticipations' | 'memberTeams' | 'myTeams' | 'participations' | 'roundBalance' | 'roundParticipants' | 'rounds' | 'sharedReceiptV2' | 'subscription' | 'team' | 'teamApplications' | 'teamCount' | 'teamMembers' | 'teamMemberships' | 'teamRound' | 'teamRounds' | 'teamSearch' | QueryKeySpecifier)[];
export type QueryFieldPolicy = {
	activeGames?: FieldPolicy<any> | FieldReadFunction<any>,
	applications?: FieldPolicy<any> | FieldReadFunction<any>,
	batchBetTeams?: FieldPolicy<any> | FieldReadFunction<any>,
	batchBetUploadData?: FieldPolicy<any> | FieldReadFunction<any>,
	currentGames?: FieldPolicy<any> | FieldReadFunction<any>,
	futureGames?: FieldPolicy<any> | FieldReadFunction<any>,
	futureRounds?: FieldPolicy<any> | FieldReadFunction<any>,
	game?: FieldPolicy<any> | FieldReadFunction<any>,
	gameInitiatorCandidateLink?: FieldPolicy<any> | FieldReadFunction<any>,
	gameInitiatorCandidates?: FieldPolicy<any> | FieldReadFunction<any>,
	gameInitiatorMemberships?: FieldPolicy<any> | FieldReadFunction<any>,
	gameInitiatorSchedule?: FieldPolicy<any> | FieldReadFunction<any>,
	gameInitiatorSchedules?: FieldPolicy<any> | FieldReadFunction<any>,
	getCoupons?: FieldPolicy<any> | FieldReadFunction<any>,
	getMyCoupons?: FieldPolicy<any> | FieldReadFunction<any>,
	historicalGames?: FieldPolicy<any> | FieldReadFunction<any>,
	memberParticipations?: FieldPolicy<any> | FieldReadFunction<any>,
	memberTeams?: FieldPolicy<any> | FieldReadFunction<any>,
	myTeams?: FieldPolicy<any> | FieldReadFunction<any>,
	participations?: FieldPolicy<any> | FieldReadFunction<any>,
	roundBalance?: FieldPolicy<any> | FieldReadFunction<any>,
	roundParticipants?: FieldPolicy<any> | FieldReadFunction<any>,
	rounds?: FieldPolicy<any> | FieldReadFunction<any>,
	sharedReceiptV2?: FieldPolicy<any> | FieldReadFunction<any>,
	subscription?: FieldPolicy<any> | FieldReadFunction<any>,
	team?: FieldPolicy<any> | FieldReadFunction<any>,
	teamApplications?: FieldPolicy<any> | FieldReadFunction<any>,
	teamCount?: FieldPolicy<any> | FieldReadFunction<any>,
	teamMembers?: FieldPolicy<any> | FieldReadFunction<any>,
	teamMemberships?: FieldPolicy<any> | FieldReadFunction<any>,
	teamRound?: FieldPolicy<any> | FieldReadFunction<any>,
	teamRounds?: FieldPolicy<any> | FieldReadFunction<any>,
	teamSearch?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RoundKeySpecifier = ('betMethod' | 'description' | 'game' | 'gameId' | 'gameInitiator' | 'id' | 'isGameInitiator' | 'sales' | 'salesDefaults' | 'state' | 'teamId' | RoundKeySpecifier)[];
export type RoundFieldPolicy = {
	betMethod?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	game?: FieldPolicy<any> | FieldReadFunction<any>,
	gameId?: FieldPolicy<any> | FieldReadFunction<any>,
	gameInitiator?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isGameInitiator?: FieldPolicy<any> | FieldReadFunction<any>,
	sales?: FieldPolicy<any> | FieldReadFunction<any>,
	salesDefaults?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	teamId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SalesKeySpecifier = ('buyLimit' | 'buyLimitPerPerson' | 'deadline' | 'id' | 'price' | 'refundAmount' | 'scheduledReleases' | 'totalSalesAmount' | SalesKeySpecifier)[];
export type SalesFieldPolicy = {
	buyLimit?: FieldPolicy<any> | FieldReadFunction<any>,
	buyLimitPerPerson?: FieldPolicy<any> | FieldReadFunction<any>,
	deadline?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	price?: FieldPolicy<any> | FieldReadFunction<any>,
	refundAmount?: FieldPolicy<any> | FieldReadFunction<any>,
	scheduledReleases?: FieldPolicy<any> | FieldReadFunction<any>,
	totalSalesAmount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SalesDefaultsKeySpecifier = ('buyLimitPerPerson' | 'price' | SalesDefaultsKeySpecifier)[];
export type SalesDefaultsFieldPolicy = {
	buyLimitPerPerson?: FieldPolicy<any> | FieldReadFunction<any>,
	price?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ScheduledReleaseKeySpecifier = ('id' | 'numberOfShares' | 'time' | ScheduledReleaseKeySpecifier)[];
export type ScheduledReleaseFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfShares?: FieldPolicy<any> | FieldReadFunction<any>,
	time?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SetGameInitiatorsResponseKeySpecifier = ('rounds' | 'roundsNotUpdated' | SetGameInitiatorsResponseKeySpecifier)[];
export type SetGameInitiatorsResponseFieldPolicy = {
	rounds?: FieldPolicy<any> | FieldReadFunction<any>,
	roundsNotUpdated?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ShareInfoGradingKeySpecifier = ('dividend' | 'qualifyingUnits' | ShareInfoGradingKeySpecifier)[];
export type ShareInfoGradingFieldPolicy = {
	dividend?: FieldPolicy<any> | FieldReadFunction<any>,
	qualifyingUnits?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ShareInfoV2KeySpecifier = ('grading' | 'winnings' | ShareInfoV2KeySpecifier)[];
export type ShareInfoV2FieldPolicy = {
	grading?: FieldPolicy<any> | FieldReadFunction<any>,
	winnings?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ShareInfoWinningsKeySpecifier = ('my' | 'perShare' | 'total' | ShareInfoWinningsKeySpecifier)[];
export type ShareInfoWinningsFieldPolicy = {
	my?: FieldPolicy<any> | FieldReadFunction<any>,
	perShare?: FieldPolicy<any> | FieldReadFunction<any>,
	total?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SharedReceiptV2KeySpecifier = ('bets' | 'shareInfo' | SharedReceiptV2KeySpecifier)[];
export type SharedReceiptV2FieldPolicy = {
	bets?: FieldPolicy<any> | FieldReadFunction<any>,
	shareInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SportCouponKeySpecifier = ('couponCreator' | 'created' | 'id' | 'modified' | 'payload' | SportCouponKeySpecifier)[];
export type SportCouponFieldPolicy = {
	couponCreator?: FieldPolicy<any> | FieldReadFunction<any>,
	created?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	modified?: FieldPolicy<any> | FieldReadFunction<any>,
	payload?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TeamKeySpecifier = ('batchBetAllowed' | 'captain' | 'createdDate' | 'description' | 'gameTypes' | 'id' | 'invitationSetting' | 'isTeamCaptain' | 'location' | 'locationVisible' | 'membershipPolicy' | 'multipleReleasesAllowed' | 'name' | 'ranking' | 'systemsLimit' | 'teamImageUrl' | 'type' | 'vacantMemberships' | 'visible' | TeamKeySpecifier)[];
export type TeamFieldPolicy = {
	batchBetAllowed?: FieldPolicy<any> | FieldReadFunction<any>,
	captain?: FieldPolicy<any> | FieldReadFunction<any>,
	createdDate?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	gameTypes?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	invitationSetting?: FieldPolicy<any> | FieldReadFunction<any>,
	isTeamCaptain?: FieldPolicy<any> | FieldReadFunction<any>,
	location?: FieldPolicy<any> | FieldReadFunction<any>,
	locationVisible?: FieldPolicy<any> | FieldReadFunction<any>,
	membershipPolicy?: FieldPolicy<any> | FieldReadFunction<any>,
	multipleReleasesAllowed?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	ranking?: FieldPolicy<any> | FieldReadFunction<any>,
	systemsLimit?: FieldPolicy<any> | FieldReadFunction<any>,
	teamImageUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	vacantMemberships?: FieldPolicy<any> | FieldReadFunction<any>,
	visible?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TeamMembershipKeySpecifier = ('id' | 'member' | 'memberSince' | 'participatingHistory' | 'roles' | 'teamId' | TeamMembershipKeySpecifier)[];
export type TeamMembershipFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	member?: FieldPolicy<any> | FieldReadFunction<any>,
	memberSince?: FieldPolicy<any> | FieldReadFunction<any>,
	participatingHistory?: FieldPolicy<any> | FieldReadFunction<any>,
	roles?: FieldPolicy<any> | FieldReadFunction<any>,
	teamId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TeamSearchResponseKeySpecifier = ('nbrOfResults' | 'results' | TeamSearchResponseKeySpecifier)[];
export type TeamSearchResponseFieldPolicy = {
	nbrOfResults?: FieldPolicy<any> | FieldReadFunction<any>,
	results?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TeamSearchResultKeySpecifier = ('captainName' | 'gameTypes' | 'maxShareCost' | 'memberLimit' | 'membershipPolicy' | 'minShareCost' | 'numberOfMembers' | 'postalCode' | 'postalTown' | 'ranking' | 'sticky' | 'teamDescription' | 'teamId' | 'teamImageRef' | 'teamName' | 'teamType' | 'updatedDateTimestamp' | TeamSearchResultKeySpecifier)[];
export type TeamSearchResultFieldPolicy = {
	captainName?: FieldPolicy<any> | FieldReadFunction<any>,
	gameTypes?: FieldPolicy<any> | FieldReadFunction<any>,
	maxShareCost?: FieldPolicy<any> | FieldReadFunction<any>,
	memberLimit?: FieldPolicy<any> | FieldReadFunction<any>,
	membershipPolicy?: FieldPolicy<any> | FieldReadFunction<any>,
	minShareCost?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfMembers?: FieldPolicy<any> | FieldReadFunction<any>,
	postalCode?: FieldPolicy<any> | FieldReadFunction<any>,
	postalTown?: FieldPolicy<any> | FieldReadFunction<any>,
	ranking?: FieldPolicy<any> | FieldReadFunction<any>,
	sticky?: FieldPolicy<any> | FieldReadFunction<any>,
	teamDescription?: FieldPolicy<any> | FieldReadFunction<any>,
	teamId?: FieldPolicy<any> | FieldReadFunction<any>,
	teamImageRef?: FieldPolicy<any> | FieldReadFunction<any>,
	teamName?: FieldPolicy<any> | FieldReadFunction<any>,
	teamType?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedDateTimestamp?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TrackKeySpecifier = ('id' | 'name' | TrackKeySpecifier)[];
export type TrackFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StrictTypedTypePolicies = {
	Amounts?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AmountsKeySpecifier | (() => undefined | AmountsKeySpecifier),
		fields?: AmountsFieldPolicy,
	},
	Application?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ApplicationKeySpecifier | (() => undefined | ApplicationKeySpecifier),
		fields?: ApplicationFieldPolicy,
	},
	BatchBetSummary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BatchBetSummaryKeySpecifier | (() => undefined | BatchBetSummaryKeySpecifier),
		fields?: BatchBetSummaryFieldPolicy,
	},
	BatchBetTeam?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BatchBetTeamKeySpecifier | (() => undefined | BatchBetTeamKeySpecifier),
		fields?: BatchBetTeamFieldPolicy,
	},
	BatchBetUploadData?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BatchBetUploadDataKeySpecifier | (() => undefined | BatchBetUploadDataKeySpecifier),
		fields?: BatchBetUploadDataFieldPolicy,
	},
	BetMeta?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BetMetaKeySpecifier | (() => undefined | BetMetaKeySpecifier),
		fields?: BetMetaFieldPolicy,
	},
	BetSubscription?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BetSubscriptionKeySpecifier | (() => undefined | BetSubscriptionKeySpecifier),
		fields?: BetSubscriptionFieldPolicy,
	},
	BetWithDetails?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BetWithDetailsKeySpecifier | (() => undefined | BetWithDetailsKeySpecifier),
		fields?: BetWithDetailsFieldPolicy,
	},
	CbsBet?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CbsBetKeySpecifier | (() => undefined | CbsBetKeySpecifier),
		fields?: CbsBetFieldPolicy,
	},
	CbsCoupon?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CbsCouponKeySpecifier | (() => undefined | CbsCouponKeySpecifier),
		fields?: CbsCouponFieldPolicy,
	},
	CbsOffering?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CbsOfferingKeySpecifier | (() => undefined | CbsOfferingKeySpecifier),
		fields?: CbsOfferingFieldPolicy,
	},
	Contract?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContractKeySpecifier | (() => undefined | ContractKeySpecifier),
		fields?: ContractFieldPolicy,
	},
	Coupon?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CouponKeySpecifier | (() => undefined | CouponKeySpecifier),
		fields?: CouponFieldPolicy,
	},
	CouponSelections?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CouponSelectionsKeySpecifier | (() => undefined | CouponSelectionsKeySpecifier),
		fields?: CouponSelectionsFieldPolicy,
	},
	CreateApplicationResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CreateApplicationResponseKeySpecifier | (() => undefined | CreateApplicationResponseKeySpecifier),
		fields?: CreateApplicationResponseFieldPolicy,
	},
	Game?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GameKeySpecifier | (() => undefined | GameKeySpecifier),
		fields?: GameFieldPolicy,
	},
	GameId?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GameIdKeySpecifier | (() => undefined | GameIdKeySpecifier),
		fields?: GameIdFieldPolicy,
	},
	GameInitiatorNotUpdated?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GameInitiatorNotUpdatedKeySpecifier | (() => undefined | GameInitiatorNotUpdatedKeySpecifier),
		fields?: GameInitiatorNotUpdatedFieldPolicy,
	},
	GameInitiatorSchedule?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GameInitiatorScheduleKeySpecifier | (() => undefined | GameInitiatorScheduleKeySpecifier),
		fields?: GameInitiatorScheduleFieldPolicy,
	},
	Grading?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GradingKeySpecifier | (() => undefined | GradingKeySpecifier),
		fields?: GradingFieldPolicy,
	},
	GradingCoupon?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GradingCouponKeySpecifier | (() => undefined | GradingCouponKeySpecifier),
		fields?: GradingCouponFieldPolicy,
	},
	HorseCoupon?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HorseCouponKeySpecifier | (() => undefined | HorseCouponKeySpecifier),
		fields?: HorseCouponFieldPolicy,
	},
	Leg?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LegKeySpecifier | (() => undefined | LegKeySpecifier),
		fields?: LegFieldPolicy,
	},
	Location?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LocationKeySpecifier | (() => undefined | LocationKeySpecifier),
		fields?: LocationFieldPolicy,
	},
	Marks?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MarksKeySpecifier | (() => undefined | MarksKeySpecifier),
		fields?: MarksFieldPolicy,
	},
	Member?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MemberKeySpecifier | (() => undefined | MemberKeySpecifier),
		fields?: MemberFieldPolicy,
	},
	MemberTeam?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MemberTeamKeySpecifier | (() => undefined | MemberTeamKeySpecifier),
		fields?: MemberTeamFieldPolicy,
	},
	Mutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier),
		fields?: MutationFieldPolicy,
	},
	NotificationSetting?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NotificationSettingKeySpecifier | (() => undefined | NotificationSettingKeySpecifier),
		fields?: NotificationSettingFieldPolicy,
	},
	OfferingSummary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OfferingSummaryKeySpecifier | (() => undefined | OfferingSummaryKeySpecifier),
		fields?: OfferingSummaryFieldPolicy,
	},
	Participant?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ParticipantKeySpecifier | (() => undefined | ParticipantKeySpecifier),
		fields?: ParticipantFieldPolicy,
	},
	Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier),
		fields?: QueryFieldPolicy,
	},
	Round?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RoundKeySpecifier | (() => undefined | RoundKeySpecifier),
		fields?: RoundFieldPolicy,
	},
	Sales?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SalesKeySpecifier | (() => undefined | SalesKeySpecifier),
		fields?: SalesFieldPolicy,
	},
	SalesDefaults?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SalesDefaultsKeySpecifier | (() => undefined | SalesDefaultsKeySpecifier),
		fields?: SalesDefaultsFieldPolicy,
	},
	ScheduledRelease?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ScheduledReleaseKeySpecifier | (() => undefined | ScheduledReleaseKeySpecifier),
		fields?: ScheduledReleaseFieldPolicy,
	},
	SetGameInitiatorsResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SetGameInitiatorsResponseKeySpecifier | (() => undefined | SetGameInitiatorsResponseKeySpecifier),
		fields?: SetGameInitiatorsResponseFieldPolicy,
	},
	ShareInfoGrading?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ShareInfoGradingKeySpecifier | (() => undefined | ShareInfoGradingKeySpecifier),
		fields?: ShareInfoGradingFieldPolicy,
	},
	ShareInfoV2?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ShareInfoV2KeySpecifier | (() => undefined | ShareInfoV2KeySpecifier),
		fields?: ShareInfoV2FieldPolicy,
	},
	ShareInfoWinnings?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ShareInfoWinningsKeySpecifier | (() => undefined | ShareInfoWinningsKeySpecifier),
		fields?: ShareInfoWinningsFieldPolicy,
	},
	SharedReceiptV2?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SharedReceiptV2KeySpecifier | (() => undefined | SharedReceiptV2KeySpecifier),
		fields?: SharedReceiptV2FieldPolicy,
	},
	SportCoupon?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SportCouponKeySpecifier | (() => undefined | SportCouponKeySpecifier),
		fields?: SportCouponFieldPolicy,
	},
	Team?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TeamKeySpecifier | (() => undefined | TeamKeySpecifier),
		fields?: TeamFieldPolicy,
	},
	TeamMembership?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TeamMembershipKeySpecifier | (() => undefined | TeamMembershipKeySpecifier),
		fields?: TeamMembershipFieldPolicy,
	},
	TeamSearchResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TeamSearchResponseKeySpecifier | (() => undefined | TeamSearchResponseKeySpecifier),
		fields?: TeamSearchResponseFieldPolicy,
	},
	TeamSearchResult?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TeamSearchResultKeySpecifier | (() => undefined | TeamSearchResultKeySpecifier),
		fields?: TeamSearchResultFieldPolicy,
	},
	Track?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TrackKeySpecifier | (() => undefined | TrackKeySpecifier),
		fields?: TrackFieldPolicy,
	}
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;