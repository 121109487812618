export enum BatchBetStatus {
    UNCONFIRMED = "UNCONFIRMED",
    INITIATED = "INITIATED",
    QUEUED = "QUEUED",
    FINISHED = "FINISHED",
    IN_PROCESS = "IN_PROCESS",
    REJECTED = "REJECTED",
    PAID_OUT = "PAID_OUT",
}

export enum BatchBetPlayErrorCode {
    ACCESS_DENIED = "ACCESS_DENIED",
    NOT_MEMBER_OF_TEAM = "NOT_MEMBER_OF_TEAM",
    LACKS_ROLE = "LACKS_ROLE",
    NOT_FOUND = "NOT_FOUND",
    ROUND_NOT_ACTIVATED = "ROUND_NOT_ACTIVATED",
    MUST_PARTICIPATE = "MUST_PARTICIPATE",
    MAX_NR_OF_BATCH_BETS = "MAX_NR_OF_BATCH_BETS",
    INVALID_NR_OF_SYSTEMS = "INVALID_NR_OF_SYSTEMS",
    GAME_DATE_DO_NOT_MATCH = "GAME_DATE_DO_NOT_MATCH",
    NR_OF_RACES_DO_NOT_MATCH = "NR_OF_RACES_DO_NOT_MATCH",
    INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
    INVALID_NR_OF_MARKS = "INVALID_NR_OF_MARKS",
    INVALID_TOTAL_COST = "INVALID_TOTAL_COST",
    INVALID_SYSTEM_LIMIT = "INVALID_SYSTEM_LIMIT",
    INVALID_SYSTEM_LIMIT_CUSTOM = "INVALID_SYSTEM_LIMIT_CUSTOM",
    BATCH_BETS_EXISTS_IN_QUEUE = "BATCH_BETS_EXISTS_IN_QUEUE",
    DOWNSTREAM_TIMEOUT = "DOWNSTREAM_TIMEOUT",
    V75_CLOSED = "V75_CLOSED",
    V86_CLOSED = "V86_CLOSED",
    OBSOLETE_MARKS = "OBSOLETE_MARKS",
    INVALID_MARKS = "INVALID_MARKS",
    TOO_HIGH_AMOUNT = "TOO_HIGH_AMOUNT",
    TOO_LOW_AMOUNT = "TOO_LOW_AMOUNT",
    BETTING_CLOSED = "BETTING_CLOSED",
    AMOUNT_EXCEEDS_LIMIT = "AMOUNT_EXCEEDS_LIMIT",
    BETTING_SYSTEM_CLOSED = "BETTING_SYSTEM_CLOSED",
    EMPTY_OR_NULL = "EMPTY_OR_NULL",
    XML_VALIDATION_ERROR = "XML_VALIDATION_ERROR",
    INVALID_GAME_TYPE_IN_COUPON = "INVALID_GAME_TYPE_IN_COUPON",
    EMPTY_COUPON_LIST = "EMPTY_COUPON_LIST",
    COUPONS_HAVE_DIFFERENT_GAME_TYPE_AND_DATE = "COUPONS_HAVE_DIFFERENT_GAME_TYPE_AND_DATE",
    GAME_TYPE_AND_DATE_DO_NOT_EXIST = "GAME_TYPE_AND_DATE_DO_NOT_EXIST",
    GENERAL_ERROR = "GENERAL_ERROR",
}
