// @ts-expect-error untyped dependency
import reduxLogger from "redux-logger";
import type {Middleware} from "redux";
import Features, {reduxLogger as reduxLoggerFeature} from "@atg-shared/client-features";

const loggerMiddleware: Middleware = Features.isEnabled(reduxLoggerFeature)
    ? reduxLogger
    : () => (next) => (action) => next(action);

export default loggerMiddleware;
