import color from "color";
import system from "@atg-shared/system";

/**
 * @deprecated 320 is the smallest size we support, so just write CSS for this first, without a
 * media query (mobile-first CSS)
 */
const deprecated_xxsScreen = 320;

export const xxsScreen = 375;
export const xsScreen = 480;
export const smScreen = 768;
export const mdScreen = 992;
export const lgScreen = 1200;
export const xlScreen = system.isTillsammans() ? 1336 : 1500;
export const hgScreen = 1600;

export const xxs = `(min-width: ${xxsScreen}px)`;
export const xs = `(min-width: ${xsScreen}px)`;
export const sm = `(min-width: ${smScreen}px)`;
export const md = `(min-width: ${mdScreen}px)`;
export const lg = `(min-width: ${lgScreen}px)`;
export const xl = `(min-width: ${xlScreen}px)`;
export const hg = `(min-width: ${xlScreen}px)`;

/**
 * @deprecated
 * Use a mobile-first approach instead: write CSS for mobile first, then add `@media (...)`
 * overrides for larger devices than this.
 */
export const deprecated_xxsMax = `(max-width: ${deprecated_xxsScreen}px)`;

/**
 * @deprecated
 * Use a mobile-first approach instead: write CSS for mobile first, then add `@media (...)`
 * overrides for larger devices than this.
 */
export const deprecated_upToiPhonePortrait = `(max-width: ${xxsScreen - 1}px)`;

/**
 * @deprecated
 * Use a mobile-first approach instead: write CSS for mobile first, then add `@media (...)`
 * overrides for larger devices than this.
 */
export const deprecated_xsLandscape = `(max-width: ${mdScreen}px) and (orientation: landscape)`;

/**
 * @deprecated
 * Use a mobile-first approach instead: write CSS for mobile first, then add `@media (...)`
 * overrides for larger devices than this.
 */
export const deprecated_xsPortrait = `(max-width: ${mdScreen}px) and (orientation: portrait)`;

/**
 * @deprecated
 * Use a mobile-first approach instead: write CSS for mobile first, then add `@media (...)`
 * overrides for larger devices.
 *
 * (Don't use `-Max` media queries.)
 *
 * (NOTE: This breakpoint is broken – it should be `smScreen - 1`)
 */
export const deprecated_smMax = `(max-width: ${smScreen}px)`;

/**
 * @deprecated
 * Use a mobile-first approach instead: write CSS for mobile first, then add `@media (...)`
 * overrides for larger devices.
 *
 * (Don't use `-Only` media queries.)
 */
export const deprecated_smOnly = `(min-width: ${smScreen}px) and (max-width: ${
    mdScreen - 1
}px)`;

/**
 * @deprecated
 * Use a mobile-first approach instead: write CSS for mobile first, then add `@media (...)`
 * overrides for larger devices.
 *
 * (Don't use `-Max` media queries.)
 */
export const deprecated_mdMax = `(max-width: ${mdScreen - 1}px)`;

/**
 * @deprecated
 * Use a mobile-first approach instead: write CSS for mobile first, then add `@media (...)`
 * overrides for larger devices.
 *
 * (Don't use `-Only` media queries.)
 */
export const deprecated_mdOnly = `(min-width: ${mdScreen}px) and (max-width: ${lgScreen}px)`;

/**
 * @deprecated
 * Use a mobile-first approach instead: write CSS for mobile first, then add `@media (...)`
 * overrides for larger devices.
 *
 * (Don't use `-Max` media queries.)
 *
 * (NOTE: This breakpoint is broken – it should be `lgScreen - 1`)
 */
export const deprecated_lgMax = `(max-width: ${lgScreen}px)`;

/**
 * @deprecated
 * Use a mobile-first approach instead: write CSS for mobile first, then add `@media (...)`
 * overrides for larger devices.
 *
 * (Don't use `-Only` media queries.)
 */
export const deprecated_xsOnly = `(max-width: ${smScreen - 1}px)`;

/**
 * @deprecated
 * Use a mobile-first approach instead: write CSS for mobile first, then add `@media (...)`
 * overrides for larger devices.
 *
 * (Don't use `-Max` media queries.)
 */
export const deprecated_xsMax = `(max-width: ${xsScreen - 1}px)`;

/**
 * ATG gray color #1
 *
 * [Zeplin design](https://app.zeplin.io/project/5d5a6adf2dba3f9bc1d7334e/screen/5d5a73ce859f2851f9052fc2)
 */
export const atgGreyNo1 = "#131a21";

/**
 * ATG gray color #2
 *
 * [Zeplin design](https://app.zeplin.io/project/5d5a6adf2dba3f9bc1d7334e/screen/5d5a73ce859f2851f9052fc2)
 */
export const atgGreyNo2 = "#1f262c";

/**
 * ATG gray color #3
 *
 * [Zeplin design](https://app.zeplin.io/project/5d5a6adf2dba3f9bc1d7334e/screen/5d5a73ce859f2851f9052fc2)
 */
export const atgGreyNo3 = "#2a3e50";

/**
 * ATG gray color #4
 *
 * [Zeplin design](https://app.zeplin.io/project/5d5a6adf2dba3f9bc1d7334e/screen/5d5a73ce859f2851f9052fc2)
 */
export const atgGreyNo4 = "#566473";

/**
 * ATG gray color #5
 *
 * [Zeplin design](https://app.zeplin.io/project/5d5a6adf2dba3f9bc1d7334e/screen/5d5a73ce859f2851f9052fc2)
 */
export const atgGreyNo5 = "#7f8b96";

/**
 * ATG gray color #6
 *
 * [Zeplin design](https://app.zeplin.io/project/5d5a6adf2dba3f9bc1d7334e/screen/5d5a73ce859f2851f9052fc2)
 */
export const atgGreyNo6 = "#aab2b9";

/**
 * ATG gray color #7
 *
 * [Zeplin design](https://app.zeplin.io/project/5d5a6adf2dba3f9bc1d7334e/screen/5d5a73ce859f2851f9052fc2)
 */
export const atgGreyNo7 = "#e0e2e5";

/**
 * ATG gray color #8
 *
 * [Zeplin design](https://app.zeplin.io/project/5d5a6adf2dba3f9bc1d7334e/screen/5d5a73ce859f2851f9052fc2)
 */
export const atgGreyNo8 = "#ececed";

/**
 * ATG gray color #9
 *
 * [Zeplin design](https://app.zeplin.io/project/5c4f0caaa6396e444e8cd372/screen/5fae7380f7babe6951edfae3)
 */
export const atgGreyNo9 = "#7f8b95";

/**
 * ATG gray color #10
 *
 * [Zeplin design](https://app.zeplin.io/project/5c4f0caaa6396e444e8cd372/screen/5fae7380f7babe6951edfae3)
 */
export const atgGreyNo10 = "#c5c7cc";

/**
 * ATG gray color #11
 *
 * [Zeplin design](https://app.zeplin.io/project/5c4f0caaa6396e444e8cd372/screen/5ed4ef7072f1eb5308b4fa2a)
 */
export const atgGreyNo11 = "#20313f";

export const atgGreyNo12 = "#1b2d3d";

export const atgGreyNo13 = "#eaecef";
/**
 * Show receipts background
 *
 * [Zeplin design](https://app.zeplin.io/project/5c4f0caaa6396e444e8cd372/screen/6047451a3209363529534570)
 */
export const atgGreyBlack = "#23313e";

/**
 * ATG green button color
 *
 * [Zeplin design](https://app.zeplin.io/project/5d5a6adf2dba3f9bc1d7334e/screen/5d5a73ce859f2851f9052fc2)
 */
export const buttonGreen = "#00762e";

/**
 * ATG blue botton color
 *
 * [Zeplin design](https://app.zeplin.io/project/5d5a6adf2dba3f9bc1d7334e/screen/5d5a73ce859f2851f9052fc2)
 */
export const buttonBlue = "#ddedf7";

/**
 * ATG link color
 *
 * [Zeplin design](https://app.zeplin.io/project/5d5a6adf2dba3f9bc1d7334e/screen/5d5a73ce859f2851f9052fc2)
 */
export const linkBlue = "#207cb8";

/**
 * ATG error color
 *
 * [Zeplin design](https://app.zeplin.io/project/5d5a6adf2dba3f9bc1d7334e/screen/5d5a73ce859f2851f9052fc2)
 */
export const error = "#c5202f";

/**
 * ATG notice color
 */
export const notice = "#a35513";

/**
 * ATG blueblack
 * [Zeplin design](https://app.zeplin.io/project/5d5a6adf2dba3f9bc1d7334e/screen/5d5a73ce859f2851f9052fc2)
 */
export const blueBlack = "#1f262c";

export const atgBrandYellow = "#ffdd00";
/**
 * ATG dropdown black
 * https://app.zeplin.io/project/5c4f0caaa6396e444e8cd372
 */
export const dropdownBlack = "#0d1014";

// Side navigation
export const mainNavigationBackground = atgGreyNo2;
export const sideNavigationWidth = 296;
export const responsibleGamingHeaderHeight = "34";

export const atgGreenTextColor = "#4da52a";
export const atgGreyBg = "#f5f5f2";
export const atgGreyBodyBg = atgGreyNo8;
export const atgGreyBodyBgNew = "#F0F5FA";
export const atgGreyBodyDisableText = "#7d7d7d";
export const atgGreyBorder = "#cacfd3";
export const atgGreyF5f5f5 = "#f5f5f5";
export const atgGreyEee = "#eee";
export const atgGreyDdd = "#ddd";
export const atgGreyCcc = "#ccc";
export const atgGreyBbb = "#bbb";
export const atgGrey999 = "#999";
export const atgGrey666 = "#666";
export const atgGrey333 = "#333";
export const atgGrey282829 = "#282829";
export const atgGreyA9A9A9 = "#a9a9a9";
export const atgGrey9B9B9B = "#9b9b9b";
export const atgBlueBodyBg = "#eaeff5";
export const atgBlue30 = "#b2cae2";
export const atgBlue40 = "#99b9d9";
export const atgBlue = "#004f9f";

/**
 * https://app.zeplin.io/project/5c4f0caaa6396e444e8cd372/screen/5ed4ef7072f1eb5308b4fa2a
 */
export const atgBlue2 = "#63b3e8";

/**
 * Modal background colors
 */
export const atgBlue3 = "#172e65";
export const atgBlue4 = "#093d76";

export const atgV75Blue = "#0063b7";
export const subMenuV75 = "#014F9F";
export const atgV75DarkBlue = "#1e3480";
export const atgV86Purple = "#a863a5";
export const atgV86DarkPurple = "#711970";
export const atgV64Orange = "#f39432";
export const atgV64DarkOrange = "#e95a0c";
export const atgV65Red = "#e6333f";
export const atgV65DarkRed = "#a60d1f";
export const atgV4Green = "#44ad52";
export const atgV4DarkGreen = "#00663a";
export const atgOtherBlue = "#bcdef7";
export const atgOtherDarkBlue = "#298dcd";
export const atgAccentYellow = "#fd0";
export const atgBlack = "#000";
export const atgWhite = "#fff";
export const atgRed = "#c5202f";
export const atgBorderColor = "#bbcbde";
export const atgToggleColor = "#ecf0f8";
export const atgGsGrey = atgGrey666;
export const atgGsDarkGrey = atgGrey333;
export const atgGsPrimaryGreen = "#2C6045";
export const defaultBodyBackgroundColor = "#142433";
export const defaultTextColor = "#595953";
export const successBackgroundColor = "#dbf2c4";
export const successBorderColor = "#c2d9ab";
export const warningBackgroundColor = "#f1e7b2";
export const warningBorderColor = "#d8ce99";
export const errorBackgroundColor = "#ffd9d9";
export const errorBorderColor = "#e6c0c0";
export const errorTextColor = "#d0011b";
export const successTextColor = "#456921";
export const backgroundBorderBlue = "#64a9d8";
export const pinkBackground = "#fcdee1";
export const gradingCorrectGreen = "#00762e";
export const gradingCorrectClassic = "#22aa07";
export const spelTipsBlue = "#edf7ff";

/**
 * Text colors
 *
 * [Figma](https://www.figma.com/file/rxqNOQKXxTWqfrHnimqTdY/ATG-se-Design-System)
 */
export const textPrimary = "#151F28";
export const textSecondary = "#566473";
export const textNotice = "#7F8B95";
export const textWhite = "#ffffff";
export const textLinkPrimary = "#006DB6";
export const textLinkSecondary = "#469BD4";
export const textLinkHoverState = "#014F9F";

/**
 * V75 primary color
 *
 * [Figma](https://www.figma.com/file/rxqNOQKXxTWqfrHnimqTdY/ATG-se-Design-System)
 */
export const atgV75Primary = "#014f9f";

/**
 * V75 light color
 *
 * Used for accents, gradients, and similar.
 *
 * [Figma](https://www.figma.com/file/rxqNOQKXxTWqfrHnimqTdY/ATG-se-Design-System)
 */
export const atgV75Light = "#006db6";

/**
 * V75 dark color
 *
 * Used for accents, gradients, and similar.
 *
 * [Figma](https://www.figma.com/file/rxqNOQKXxTWqfrHnimqTdY/ATG-se-Design-System)
 */
export const atgV75Dark = "#1c3885";

/**
 * V86 primary color
 *
 * [Figma](https://www.figma.com/file/rxqNOQKXxTWqfrHnimqTdY/ATG-se-Design-System)
 */
export const atgV86Primary = "#802c7e";

/**
 * V86 light color
 *
 * Used for accents, gradients, and similar.
 *
 * [Figma](https://www.figma.com/file/rxqNOQKXxTWqfrHnimqTdY/ATG-se-Design-System)
 */
export const atgV86Light = "#9e4a9c";

/**
 * V86 dark color
 *
 * Used for accents, gradients, and similar.
 *
 * [Figma](https://www.figma.com/file/rxqNOQKXxTWqfrHnimqTdY/ATG-se-Design-System)
 */
export const atgV86Dark = "#711a6f";

/**
 * V64 primary color
 *
 * [Figma](https://www.figma.com/file/rxqNOQKXxTWqfrHnimqTdY/ATG-se-Design-System)
 */
export const atgV64Primary = "#ed6c15";

/**
 * V64 light color
 *
 * Used for accents, gradients, and similar.
 *
 * [Figma](https://www.figma.com/file/rxqNOQKXxTWqfrHnimqTdY/ATG-se-Design-System)
 */
export const atgV64Light = "#f68c28";

/**
 * V64 dark color
 *
 * Used for accents, gradients, and similar.
 *
 * [Figma](https://www.figma.com/file/rxqNOQKXxTWqfrHnimqTdY/ATG-se-Design-System)
 */
export const atgV64Dark = "#ea5b0c";

/**
 * V65 primary color
 *
 * [Figma](https://www.figma.com/file/rxqNOQKXxTWqfrHnimqTdY/ATG-se-Design-System)
 */
export const atgV65Primary = "#c00a26";

/**
 * V65 light color
 *
 * Used for accents, gradients, and similar.
 *
 * [Figma](https://www.figma.com/file/rxqNOQKXxTWqfrHnimqTdY/ATG-se-Design-System)
 */
export const atgV65Light = "#d43140";

/**
 * V65 dark color
 *
 * Used for accents, gradients, and similar.
 *
 * [Figma](https://www.figma.com/file/rxqNOQKXxTWqfrHnimqTdY/ATG-se-Design-System)
 */
export const atgV65Dark = "#a90821";

/**
 * Grand Slam primary color
 *
 * [Figma](https://www.figma.com/file/rxqNOQKXxTWqfrHnimqTdY/ATG-se-Design-System)
 */
export const atgGSPrimary = "#15614e";

/**
 * Grand Slam light color
 *
 * Used for accents, gradients, and similar.
 *
 * [Figma](https://www.figma.com/file/rxqNOQKXxTWqfrHnimqTdY/ATG-se-Design-System)
 */
export const atgGSLight = "#00815c";

/**
 * Grand Slam dark color
 *
 * Used for accents, gradients, and similar.
 *
 * [Figma](https://www.figma.com/file/rxqNOQKXxTWqfrHnimqTdY/ATG-se-Design-System)
 */
export const atgGSDark = "#1d4b43";

/**
 * Other (V4, DD, raket, etc.) primary color
 *
 * [Figma](https://www.figma.com/file/rxqNOQKXxTWqfrHnimqTdY/ATG-se-Design-System)
 */
export const atgOtherPrimary = "#62ade0";

/**
 * Other (V4, DD, raket, etc.) light color
 *
 * Used for accents, gradients, and similar.
 *
 * [Figma](https://www.figma.com/file/rxqNOQKXxTWqfrHnimqTdY/ATG-se-Design-System)
 */
export const atgOtherLight = "#78bee9";

/**
 * Other (V4, DD, raket, etc.) dark color
 *
 * Used for accents, gradients, and similar.
 *
 * [Figma](https://www.figma.com/file/rxqNOQKXxTWqfrHnimqTdY/ATG-se-Design-System)
 */
export const atgOtherDark = "#2a78ad";

/**
 * Race background color
 *
 * [Zeplin design](https://app.zeplin.io/project/5c4f0caaa6396e444e8cd372/screen/5fae7380f7babe6951edfae3)
 */
export const atgFadedBlack = "rgba(42, 62, 80, 0.28)";

export const colorFieldSuccess = "#dff0d8";
export const colorFieldInfo = "#d9edf7";
export const colorFieldWarning = "#fcf8e3";
export const colorFieldError = "#f2dede";
export const colorFieldDanger = "#facece";
export const defaultSlantDeg = "22.7deg";
export const defaultBorderColor = "#dce0e7";
export const defaultSlantSkew = "skew(-22.7deg, 0deg)";
export const reverseSlantSkew = "skew(22.7deg, 0deg)";
export const defaultBoxShadow = "inset 0 1px 2px 0 rgba(0, 0, 0, 0.22)";
export const btnFontSize = "14px";
export const btnTextTransform = "uppercase";
export const btnFontWeight = "900";
export const btnIconSize = "9px";
export const btnPadding = "9px";
export const btnHeight = "42px";
export const btnDefaultColor = "#014991";
export const btnDefaultBorderColor = "#bbcbde";
export const btnDefaultBorderStyle = "solid";
export const btnDefaultBorder = `${btnDefaultBorderStyle} 1px ${btnDefaultBorderColor}`;
export const btnDefaultBorderBottom = btnDefaultBorder;
export const btnDefaultBorderRadius = "2px";
export const btnDefaultGradientStart = "#fff";
export const btnDefaultGradientEnd = "#dbe4ef";
export const btnDefaultGradient = `linear-gradient(${btnDefaultGradientStart}, ${btnDefaultGradientEnd})`;
export const btnDefaultGradientHoverStart = "#f7f9fb";
export const btnDefaultGradientHoverEnd = "#d2ddeb";
export const btnDefaultGradientHover = `linear-gradient(${btnDefaultGradientHoverStart}, ${btnDefaultGradientHoverEnd})`;
export const btnDefaultActiveShadow = "inset 0 3px 5px rgba(0, 0, 0, .125)";
export const btnDefaultPadding = `0 ${btnPadding}`;
export const btnDefaultTextColor = "#254894";
export const btnSuccessBorderColor = "#167a01";
export const btnSuccessGradientStart = "#5dc633";
export const btnSuccessGradientEnd = "#4da52a";
export const btnBuyGradientStart = "#22ac07";
export const btnBuyGradientEnd = "#218f0b";
export const btnBuyHoverGradientStart = "#1d9206";
export const btnBuyHoverGradientEnd = "#1c7a09";
export const inputBorderColor = "#bacbdf";
export const inputInnerShadow = "inset 5px 5px 5px 0 #f4f4f4";
export const inputCheckRadioBackground = "#e2e2df";
export const inputTextFontSize = "inherit";
export const passwordToggleAlignTop = "28%";
export const textShadow = "1px 1px rgba(0, 0, 0, 0.25)";
export const trackColor = "#feda1b";
export const defaultGradientStart = "#0266b0";
export const defaultGradientEnd = "#09428f";
export const navbarDefaultColor = "#014593";
export const navbarDefaultLinkColor = "#084a98";
export const navTabsActiveLinkHoverBg = "#e7e7e7";
export const navbarItemBorderColor = "#063871";
export const navbarDefaultBg = "#fff";
export const navbarGradientActiveStart = "#fff";
export const navbarGradientActiveEnd = "#f6f9fc";
export const navbarGradientStart = defaultGradientStart;
export const navbarGradientEnd = defaultGradientEnd;
export const navbarHeightSmall = "40px";
export const navbarHeightMedium = "50px";
export const linkColor = atgBlue;
export const linkHoverColor = color(linkColor).lighten(-0.15).hex();
export const selectedBackgroundColor = "#e0f2d9";
export const selectedBackgroundColorBlue = "#e0edf6";
export const selectedTextColor = "#1e6800";
export const selectedTextHoverColor = color(selectedTextColor).lighten(-0.15).hex();
export const selectedBorderColor = "#c4e4b7";
export const headerBorderColor = "#c3d2df";
export const loadingIndicatorWidth = "50px";
export const loadingIndicatorHeight = "50px";
export const menuItemFontSize = "12px";
export const menuItemFontWeight = "700";
export const menuItemTextTransform = "none";
export const menuItemPadding = "10px";
export const menuBorderColor = "#ebebeb";
export const menuPadding = "10px";
export const sideMenuWidth = "85%";
export const sideMenuBg = "#fff";
export const contentMenuHeight = "47px";
export const dropDownMenuTextTransform = "uppercase";
export const dropDownMenuFontWeight = "700";
export const dropDownMenuPadding = "10px";
export const borderLightGrey = "#e9e9e9";
export const tableBorderColor = "#dce0e7";
export const lineHeightBase = "1.428571429";
export const cmsLinkColor = "#035ba6";
export const cmsLinkColorHover = "#0e1a42";
export const cmsArticleDefaultMargin = "16px";
export const cmsArticleHeaderTextTransform = "none";
export const cmsAlertSuccessColor = "#cceac0";
export const cmsAlertInfoColor = atgBlue30;
export const cmsAlertWarningColor = "#fbdec0";
export const cmsAlertDangerColor = "#f8c0c5";
export const cmsBoxHeaderBackgroundColor = "#405c6c";
export const cmsBoxBodyBackgroundColor = "#f7fafc";
export const cmsBoxBodyBorderColor = "#d7dcdf";
export const chatMsgFontSize = "14px";
export const chatMsgFontSizeSmall = "14px";
export const chatDialogTitleFontSize = "12px";
export const chatTagFontSize = "12px";
export const chatTitleMarginBottom = "8px";
export const confirmBackgroundColor = "#f5f5f5";
export const selectFieldPadding = "6px 23px 6px 12px";
export const selectFieldTextTransform = "uppercase";
export const selectFieldHeight = "32px";
export const selectFieldFontSize = "12px";
export const fullViewHightWithoutNav = "calc(100vh - 48px)";
export const fullViewHightWithoutNavSm = "calc(100vh - 86px)";
export const darkInputBackgroundColor = "#314756";
export const darkInputBorder = "solid 1px #566572";
export const darkInputErrorColor = "#ed7078";
export const darkInputErrorBorderColor = "#e6333f";
export const darkInputErrorBackgroundColor = "#fef5f5";
export const couponTextColor = "#365160";
export const couponBackgroundColor = "#f2f6f9";
export const couponBorderColor = "#cfdee9";
export const collapseDefaultHeader = `linear-gradient(to bottom, ${atgOtherLight}, ${atgOtherDark});`;
export const startListBackgroundColor = "#fbfdff";
export const darkStartListBackgroundColor = "#2a4251";
export const darkStartListSecondaryTextColor = "#95a4ad";
export const startOrderReserveTextColor = "#4a98cb";
export const startOrderReserveGradientStart = "#d6e1e8";
export const startOrderReserveGradientEnd = "#bcc6cd";
export const scratchedTextColor = "#9badcd";
export const changeColor = "#eb2108";
export const placeholderBorderColor = "#f9fafb";
export const placeholderBackgroundColor = "#f6fbf4";

export const highLightsDescriptionTextColor = atgGreyNo5;
export const highLightsDescriptionTextColorUpdated = atgGreyNo4;
export const highLightsInfoTextColor = atgGreyNo2;
export const highLightsPlayNowColor = linkBlue;

export const tsPageTitleColor = "#324d69";
export const tsCardHeaderColor = atgGreyNo3;
export const tsCardTextColor = "#556473";
export const tsBlueHighlightColor = linkBlue;
export const tsImageBorderColor = "#c5ced9";
export const blueText = "#097ac8";
export const boxShadowReduction = "rgba(0, 0, 0, 0.08) 0px 2px 2px 0px;";
export const boxShadow = "0 2px 2px 0 rgba(0, 0, 0, 0.15);"; // same as WhiteContainer component
export const tsChatMessageHighlightColor = "rgba(252, 233, 171, 0.2)";
export const tsTransparentBlueHighlightColor = "rgba(122, 179, 218, 0.2)";
export const tsMemberButtonColor = "rgba(0, 109, 182, 0.8)";
export const tooltipPopBackgroundColor = "#14191E";

export const iconSuccessColor = "#009a3d";
export const cardSuccessHighlighthColor = "#eef5ea";

// Toasts
export const toastSuccessBgColor = "#d6e9de";

// Font constants
export const fontFamilyText = "Roboto, sans-serif";

export * from "./brands.styles";

export const atgGrayscale150 = "#151F28";
export const atgGrayscale130 = "#1D2B38";
export const atgGrayscale100 = "#2A3E50";
export const atgGrayscale090 = "#3F5161";
export const atgGrayscale080 = "#566473";
export const atgGrayscale070 = "#6A7885";
export const atgGrayscale060 = "#7F8B95";
export const atgGrayscale050 = "#949EA7 ";
export const atgGrayscale040 = "#AAB2B9";
export const atgGrayscale030 = "#BFC5CB";
export const atgGrayscale020 = "#D4D8DC";
export const atgGrayscale010 = "#EAECEF";
export const atgGrayscale005 = "#F4F5F6";

export const atgBackgroundError = "#F9ECEC";
export const tsTextError = "#D20000";

export const tsBluescale009 = "#012E5B";

export const tsPaperBoxShadow =
    "0px 1px 1px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.05)";
export const tsChatSystemMessage01 = "#edf5ff";
export const tsChatSystemMessage02 = "#FDF7E2";
export const tsChatSystemMessage03 = "#8eaeeb";
export const tsChatSystemMessage04 = "#F6D86F";
