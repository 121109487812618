import {combineReducers} from "redux";
import {INITIAL_PURCHASE_STATE, type RoundPurchaseState} from "./round.types";
import {
    PURCHASE_CONFIRM,
    PURCHASE_ERROR,
    PURCHASE_FINISHED,
    PURCHASE_RESET,
    PURCHASE_STARTED,
    PURCHASE_SUCCESS,
    SET_PURCHASE_MODAL,
    type RoundPurchaseAction,
} from "./round.actions";

const purchase = (
    state = INITIAL_PURCHASE_STATE,
    action: RoundPurchaseAction,
): RoundPurchaseState => {
    switch (action.type) {
        case PURCHASE_STARTED:
            return {
                ...INITIAL_PURCHASE_STATE,
                loading: true,
            };
        case PURCHASE_CONFIRM:
            return {
                ...state,
                input: {numberOfShares: action.payload.input.numberOfShares},
            };
        case PURCHASE_SUCCESS:
            return {
                ...state,
                data: action.payload.participant,
                loading: false,
            };
        case PURCHASE_ERROR:
            return {
                ...state,
                error: action.payload.errorCode,
                loading: false,
            };
        case PURCHASE_FINISHED:
            return {
                ...state,
                loading: false,
            };
        case PURCHASE_RESET:
            return INITIAL_PURCHASE_STATE;
        case SET_PURCHASE_MODAL:
            return {
                ...state,
                modal: action.payload,
            };
        default:
            return state;
    }
};

const roundReducer = combineReducers({
    purchase,
});

export default roundReducer;
