import type {Store} from "redux";
import {call, take, takeLatest} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import {subscribe} from "@atg-frame-shared/push";
import * as GameUtils from "../../../common/gameUtils";
import * as GameActions from "../../../common/redux/gameActions";
import * as SportGameActions from "../sportGameActions";
import {SportGamePushEvent} from "./sportGamePushTypes";
import type {SportGamePushData} from "./sportGamePushTypes";

const PUSH_TOPIC_ROOT = "atg-service-sports-infosys/>";

export const messageCallbackFn =
    ({dispatch}: Store) =>
    async (message?: SportGamePushData) => {
        if (!message) return;

        // Update game status
        if (
            message.offering &&
            [
                SportGamePushEvent.OFFERING_SELL_CLOSED,
                SportGamePushEvent.OFFERING_SELL_OPENED,
                SportGamePushEvent.OFFERING_REVIEW_STARTED,
                SportGamePushEvent.OFFERING_BETAGGREGATION_UPDATED,
                SportGamePushEvent.OFFERING_SUMMARY_UPDATED,
                SportGamePushEvent.OFFERING_FINALIZED,
                SportGamePushEvent.OFFERING_DECIDED,
                SportGamePushEvent.OFFERING_CANCELED,
            ].includes(message.type)
        )
            dispatch(SportGameActions.updateGame(message.offering.id, message.offering));

        // Stop all match clocks
        if (
            message.offering &&
            [
                SportGamePushEvent.OFFERING_DECIDED,
                SportGamePushEvent.OFFERING_CANCELED,
            ].includes(message.type)
        )
            message.offering.matches.forEach((match) =>
                dispatch(SportGameActions.stopMatchClock({matchId: match.id})),
            );

        // Update match data
        if (
            message.competition &&
            [
                SportGamePushEvent.COMPETITION_STARTED,
                SportGamePushEvent.COMPETITION_RESULT_UPDATED,
                SportGamePushEvent.COMPETITION_START_CANCELED,
                SportGamePushEvent.COMPETITION_CANCELED,
                SportGamePushEvent.OVERTIME,
            ].includes(message.type)
        ) {
            dispatch(SportGameActions.updateMatch(message.competition));
        }
        // Start match clocks
        if (
            message.competition &&
            message.type === SportGamePushEvent.COMPETITION_STARTED
        )
            dispatch(
                SportGameActions.startMatchClock({
                    matchId: message.competition.id,
                }),
            );

        // Stop match clocks
        if (
            [
                SportGamePushEvent.COMPETITION_START_CANCELED,
                SportGamePushEvent.COMPETITION_DECIDED,
                SportGamePushEvent.COMPETITION_CANCELED,
                SportGamePushEvent.PERIOD_ENDED,
            ].includes(message.type)
        ) {
            switch (message.type) {
                case SportGamePushEvent.COMPETITION_DECIDED: {
                    setTimeout(() => {
                        if (message.competition) {
                            dispatch(
                                SportGameActions.stopMatchClock({
                                    matchId: message.competition.id,
                                }),
                            );
                        }
                    }, 500);
                    break;
                }
                case SportGamePushEvent.COMPETITION_CANCELED:
                case SportGamePushEvent.COMPETITION_START_CANCELED: {
                    if (message.competition) {
                        dispatch(
                            SportGameActions.stopMatchClock({
                                matchId: message.competition.id,
                            }),
                        );
                    }
                    break;
                }
                case SportGamePushEvent.PERIOD_ENDED: {
                    if (message.matchId) {
                        dispatch(
                            SportGameActions.stopMatchClock({
                                matchId: message.matchId,
                            }),
                        );
                    }
                    break;
                }
                default:
                    break;
            }
        }

        // Stop and start match clock when period starts
        if (
            message.matchId &&
            message.clockMinutes !== undefined &&
            message.type === SportGamePushEvent.PERIOD_STARTED
        ) {
            dispatch(
                SportGameActions.stopMatchClock({
                    matchId: message.matchId,
                }),
            );
            dispatch(
                SportGameActions.startMatchClock({
                    matchId: message.matchId,
                    minute: message.clockMinutes,
                    timestamp: message.timestamp,
                }),
            );
        }
    };

export function* pushListener(
    store: Store,
    action: GameActions.GameStartPushListenerAction,
): SagaIterator {
    const {gameId} = action.payload;

    // Make sure it's a sport game
    if (!GameUtils.isSportGame(gameId)) return;

    const messageCallback = yield call(messageCallbackFn, store);

    const unsubscribe = yield call(
        subscribe,
        PUSH_TOPIC_ROOT,
        messageCallback,
        true,
        false,
    );

    yield take(GameActions.GAME_STOP_PUSH_LISTENER);
    yield call(unsubscribe);
}

export default function* sportPushListener(store: Store): SagaIterator {
    yield takeLatest(GameActions.GAME_START_PUSH_LISTENER, pushListener, store);
}
