import type {FetchAction} from "@atg-shared/fetch-types";
import {call, FETCH} from "@atg-shared/fetch-redux";
import type {TeamID, InviteCode, Invite, InviteError} from "@atg-tillsammans/types";
import * as TeamInviteApi from "./inviteApi";

export const ACCEPT_INVITE_ACTION = "teams-invite/ACCEPT_INVITE_ACTION";
export const FETCH_INVITE_INFO_REQUEST = "teams-invite/FETCH_INVITE_INFO_REQUEST";
export const FETCH_INVITE_INFO_RESPONSE = "teams-invite/FETCH_INVITE_INFO_RESPONSE";

export const REQUEST_INVITE_LINK = "teams-invite/REQUEST_INVITE_LINK";
export const RECEIVE_INVITE_LINK = "teams-invite/RECEIVE_INVITE_LINK";
export const RESET_INVITE_LINK = "teams-invite/RESET_INVITE_LINK";

type AcceptInviteProps = {
    teamId: TeamID;
    inviteCode: string;
    gameId?: string;
};

export type AcceptInviteAction = {
    type: typeof ACCEPT_INVITE_ACTION;
    payload: AcceptInviteProps;
};

export type FetchInviteInfoAction = {
    type: typeof FETCH_INVITE_INFO_REQUEST;
    payload: {
        inviteCode: string;
        userName?: string;
    };
};

type FetchInviteInfoResponseAction = {
    type: typeof FETCH_INVITE_INFO_RESPONSE;
    error: false;
    payload: {
        invite: Invite;
    };
};

export type FetchInviteInfoResponseErrorAction = {
    type: typeof FETCH_INVITE_INFO_RESPONSE;
    error: true;
    payload: InviteError;
};

export type GetInviteAction = FetchAction<
    typeof REQUEST_INVITE_LINK,
    typeof RECEIVE_INVITE_LINK,
    InviteCode
>;

export type InviteAction =
    | AcceptInviteAction
    | FetchInviteInfoAction
    | FetchInviteInfoResponseAction
    | FetchInviteInfoResponseErrorAction
    | GetInviteAction;

export const acceptInvite = (params: AcceptInviteProps): AcceptInviteAction => ({
    type: ACCEPT_INVITE_ACTION,
    payload: params,
});

export const fetchInviteInfo = (
    inviteCode: string,
    userName?: string,
): FetchInviteInfoAction => ({
    type: FETCH_INVITE_INFO_REQUEST,
    payload: {inviteCode, userName},
});

export const inviteInfoResponse = (invite: Invite): FetchInviteInfoResponseAction => ({
    type: FETCH_INVITE_INFO_RESPONSE,
    error: false,
    payload: {invite},
});

export const inviteErrorResponse = (
    error: InviteError,
): FetchInviteInfoResponseErrorAction => ({
    type: FETCH_INVITE_INFO_RESPONSE,
    error: true,
    payload: error,
});

export const getInviteCode = (teamId: TeamID): GetInviteAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_INVITE_LINK,
        receiveAction: RECEIVE_INVITE_LINK,
        callApi: call(TeamInviteApi.getInviteCode, teamId),
    },
});

export const refreshInviteCode = (teamId: TeamID, inviteId: number): GetInviteAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_INVITE_LINK,
        receiveAction: RECEIVE_INVITE_LINK,
        callApi: call(TeamInviteApi.refreshInviteCode, {teamId, inviteId}),
    },
});
