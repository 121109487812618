import {
    getLoadingState,
    isLoading,
    isLoaded,
    hasError,
    getLoadingStatus,
    createSelectors,
} from "./domain/fetchSelectors";
import {request, receive, receiveError} from "./domain/fetchActions";

export {default as LoadRoute} from "./routes/LoadRoute";
export {createInitialState, createFetchReducer} from "./domain/fetchReducer";
export {default as fetchSaga} from "./domain/fetchSaga";
export {default as fetchMiddleware} from "./domain/fetchMiddleware";
export const fetchSelectors = {
    getLoadingState,
    isLoading,
    isLoaded,
    hasError,
    getLoadingStatus,
    createSelectors,
};
export {FETCH, FETCH_PROMISE, call} from "./domain/fetchActions";
export const fetchActions = {
    request,
    receive,
    receiveError,
};
