import type {Member} from "../generated";

export class MemberModel implements Member {
    private readonly member: Member;

    constructor(member: Partial<Member>) {
        this.member = {
            id: `${member.id ?? ""}`,
            firstName: member.firstName ?? "",
            lastName: member.lastName ?? "",
            memberId: member.memberId ?? 0,
            imageUrl: member.imageUrl ?? null,
        };
    }

    get id() {
        return this.member.id;
    }

    get memberId() {
        return this.member.memberId;
    }

    get firstName() {
        return this.member.firstName;
    }

    get lastName() {
        return this.member.lastName;
    }

    get imageUrl() {
        return this.member.imageUrl;
    }

    get online() {
        return this.member.online ?? null;
    }

    toGraph = (): Member => ({
        __typename: "Member",
        ...this.member,
        online: this.online,
    });

    static of(member: Partial<Member>): MemberModel {
        return new MemberModel(member);
    }

    static toMemberGraph(member: Partial<Member>): Member {
        return this.of(member).toGraph();
    }
}
