import type {Store, Reducer, Action} from "redux";
import {set, get} from "lodash/fp";
import type {Persistor} from "redux-persist/es/types";

// Based on https://gist.github.com/cvbuelow/ea7bd4deb7824fdb65d81cd2b8c60f5e

export type ReducerMap<RS, A extends Action> = {
    [name: string]: Reducer<RS, A> | undefined;
};

export type CreateReducerFunc<S, A extends Action> = (
    reducers?: ReducerMap<S, A>,
) => Reducer<S, A>;

type LazyReducerAddOn<S, A extends Action> = {
    asyncReducers: ReducerMap<unknown, A>;
    createReducer: (reducers?: ReducerMap<unknown, A>) => Reducer<S, A>;
    persistor?: Persistor;
    name?: string;
};

export type LazyReducerStore<S, A extends Action, St = Store<S, A>> = St &
    LazyReducerAddOn<S, A>;

export const initLazyReducerStore = <S, A extends Action, T = Store<S, A>>(
    store: T,
    createReducer: CreateReducerFunc<S, A>,
    // @ts-expect-error
): LazyReducerStore<S, A, T> => ({
    ...store,
    asyncReducers: {
        root: {},
    },
    createReducer,
});

/**
 * injects lazy reducer
 * @param store - a store to inject to
 * @param stateKey - what part of the Redux state the injected reducer should handle
 * @param asyncReducer - reducer to inject
 * @param shouldPersist - flag specifying if there are any persistant fields, will not be persisted without it
 * @param override - flag that overrides the reducer if it's already defined
 */
export const injectLazyReducer = <S, A extends Action>(
    store: LazyReducerStore<S, A, Store<S, A>>,
    stateKey: string,
    asyncReducer: Reducer<S, A>,
    shouldPersist?: boolean,
    override?: boolean,
) => {
    // If lazyReducer are at root level a `root` namespace is applied
    // to distinguish between nested and top level reducers
    //
    // TODO: Deprecate and remove this logic. It was added to distinguish state between microFEs,
    // but we've since taken another approach with a multi-store setup instead, so this isn't
    // needed. But we need to refactor a few reducers that rely on the nested `.horse` key first.
    const asyncReducerKey = stateKey.includes(".") ? stateKey : `root.${stateKey}`;
    const reducerAlreadyInjected = get(asyncReducerKey, store.asyncReducers);
    if (reducerAlreadyInjected && !override) return;

    store.asyncReducers = set(asyncReducerKey, asyncReducer, store.asyncReducers);
    store.replaceReducer(store.createReducer(store.asyncReducers));

    if (shouldPersist && store.persistor) {
        store.persistor.persist();
    }
};
