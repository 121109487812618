import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import * as ServerInfo from "./serverInfo";

dayjs.extend(utc);

/**
 * Returns a dayjs instance representing the current time on the web server
 */
export function serverTime(useDayjs?: true): dayjs.Dayjs;

/**
 * Returns a date instance representing the current time on the web server
 */
export function serverTime(useDayjs: false): Date;

export function serverTime(useDayjs = true) {
    const offset = ServerInfo.getServerTimeOffset();
    const time = dayjs(Date.now() - offset);
    if (!useDayjs) {
        return time.toDate();
    }
    return time;
}

/**
 * Returns the current time on the server represented as YYYY-MM-DD
 */
export function serverDate() {
    return serverTime(true).format("YYYY-MM-DD");
}
