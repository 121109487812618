import {broadcastAction, frameAction} from "atg-store-addons";
import log from "@atg-shared/log";
import type {Roles} from "./authRoles";
import type {AuthResponse} from "./authApi";

export const LOGIN_FINISHED = "LOGIN_FINISHED";
export const DID_NOT_LOGIN = "DID_NOT_LOGIN"; // special case for app - in some cases we do not login
export const LOGOUT_FINISHED = "LOGOUT_FINISHED";
export const AUTH_CHECK_REQUEST = "AUTH_CHECK_REQUEST";
export const AUTH_CHECK_RESPONSE = "AUTH_CHECK_RESPONSE";

export const AUTH_KEEP_ALIVE = "AUTH_KEEP_ALIVE";
export const AUTH_SET_ROLES = "AUTH_SET_ROLES";
export const AUTH_CLEAR = "AUTH_CLEAR";

export const START_AUTHENTICATION_FLOW = "START_AUTHENTICATION_FLOW";
export const AUTH_ADD_MEMBER_FLOW_OPTIONS = "AUTH_ADD_MEMBER_FLOW_OPTIONS";
export const AUTH_REMOVE_MEMBER_FLOW_OPTIONS = "AUTH_REMOVE_MEMBER_FLOW_OPTIONS";

// member actions, duplicated from atg-member to prevent circular dependency
export const CANCELLED_LOGIN_FLOW = "CANCELLED_LOGIN_FLOW";
export const MEMBER_REGISTER_FINISHED = "MEMBER_REGISTER_FINISHED";
export const MEMBER_REGISTER_CANCEL = "MEMBER_REGISTER_CANCEL";
export const FINISH_MEMBER_FLOW = "FINISH_MEMBER_FLOW";

export const BFF_AUTHENTICATION = "BFF_AUTHENTICATION";

// Opens a modal
const MODAL_FLOW_TYPE = "MODAL_FLOW_TYPE";
// Opens a page
const PAGE_FLOW_TYPE = "PAGE_FLOW_TYPE";
// To be used for authentication inline in other flows, for example purchase flows
const INLINE_FLOW_TYPE = "INLINE_FLOW_TYPE";

type MemberModalFlowType = {
    type: typeof MODAL_FLOW_TYPE;
};

type MemberInlineFlowType = {
    type: typeof INLINE_FLOW_TYPE;
};

type MemberPageFlowType = {
    type: typeof PAGE_FLOW_TYPE;
    loginPath: string;
    registerPath: string;
    successPath: string;
    successPathRedirectMethod?: "history" | "location"; // WICKET: Remove this when tillsammans has been migrated
};

type MemberFlowType = MemberModalFlowType | MemberPageFlowType | MemberInlineFlowType;

export type FlowTriggerAction = {
    type: any;
};

export type MemberFlowOptions = {
    authResponse?: AuthResponse | null;
    triggeredBy?: FlowTriggerAction;
    flowType?: MemberFlowType;
    finishActionType?: any;
    loginMessage?: string;
};

export type CheckAuthAction = {
    type: string;
    payload: {shouldTriggerLogin: boolean};
};

export type BffAuthenticationAction = Readonly<{
    type: typeof BFF_AUTHENTICATION;
    resolve: ({token, hadToLogin}: {token: string; hadToLogin: boolean}) => void;
    reject: (error?: Error) => void;
}>;

export type AuthAddMemberFlowOptionsAction = {
    type: typeof AUTH_ADD_MEMBER_FLOW_OPTIONS;
    payload: MemberFlowOptions | null | undefined;
};

export type AuthRemoveMemberFlowOptionsAction = {
    type: typeof AUTH_REMOVE_MEMBER_FLOW_OPTIONS;
};

export type CheckAuthSuccessAction = {
    type: typeof AUTH_CHECK_RESPONSE;
    payload: AuthResponse;
};

export type CheckAuthErrorAction = {
    type: typeof AUTH_CHECK_RESPONSE;
    error: boolean;
    payload: string;
};

export type AuthSetRolesAction = {
    type: typeof AUTH_SET_ROLES;
    roles: Roles;
};

export type StartAuthenticationFlowAction = {
    type: typeof START_AUTHENTICATION_FLOW;
    payload: {
        options: MemberFlowOptions;
        isForced: boolean;
    };
};

export const bffAuthentication = (
    resolve: ({token, hadToLogin}: {token: string; hadToLogin: boolean}) => void,
    reject: (error?: string) => void,
) =>
    frameAction({
        type: BFF_AUTHENTICATION,
        resolve,
        reject,
    });

export const addMemberFlowOptions = (
    options: MemberFlowOptions,
): AuthAddMemberFlowOptionsAction => ({
    type: AUTH_ADD_MEMBER_FLOW_OPTIONS,
    payload: options,
});

export const removeMemberFlowOptions = (): AuthRemoveMemberFlowOptionsAction => ({
    type: AUTH_REMOVE_MEMBER_FLOW_OPTIONS,
});

export const checkAuth = (shouldTriggerLogin = true): CheckAuthAction =>
    frameAction({
        type: AUTH_CHECK_REQUEST,
        payload: {shouldTriggerLogin},
    });

export const checkAuthSuccess = (payload: AuthResponse): CheckAuthSuccessAction =>
    broadcastAction({
        type: AUTH_CHECK_RESPONSE,
        payload,
    });

export const checkAuthError = (payload: string): CheckAuthErrorAction =>
    broadcastAction({
        type: AUTH_CHECK_RESPONSE,
        error: true,
        payload,
    });

export const setAuthRoles = (roles: Roles): AuthSetRolesAction =>
    broadcastAction({
        type: AUTH_SET_ROLES,
        roles,
    });

export const authKeepAlive = () =>
    frameAction({
        type: AUTH_KEEP_ALIVE,
    });

export const clearAuth = () =>
    frameAction({
        type: AUTH_CLEAR,
    });

// special action used in the app to indicate that we did not open login modal
export const didNotLogin = () => ({
    type: DID_NOT_LOGIN,
});

export const startAuthenticationFlow = (
    options: MemberFlowOptions = {},
    isForced = false,
): StartAuthenticationFlowAction => {
    if (options.loginMessage) {
        log.warn(
            `startAuthenticationFlow: called with loginMessage "${options.loginMessage}". limbo/ghost state?`,
        );
    }

    return frameAction({
        type: START_AUTHENTICATION_FLOW,
        payload: {
            options,
            isForced,
        },
    });
};
