export enum PlaceBetFlow {
    PLACE_SPORT_BET = "PLACE_SPORT_BET",
}

export enum PlaceBetErrorCode {
    INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
    FAILED_TO_GENERATE_BET = "FAILED_TO_GENERATE_BET",
    ROUND_NOT_ACTIVATED = "ROUND_NOT_ACTIVATED",
    MAX_NR_OF_BETS = "MAX_NR_OF_BETS",
    MUST_PARTICIPATE = "MUST_PARTICIPATE",
    SELL_NOT_OPEN = "SELL_NOT_OPEN",
    COMPETITION_UNDER_REVIEW = "COMPETITION_UNDER_REVIEW",
    ASYNC_RETRYABLE_TIMEOUT = "ASYNC_RETRYABLE_TIMEOUT",
    TECHNICAL_ERROR = "TECHNICAL_ERROR",
}

export type PlaceBetError = {
    error: string;
    errorCode: PlaceBetErrorCode;
    message: string;
    status: number;
    timestamp: string;
};
