import * as React from "react";
import {t} from "@lingui/macro";
import type {Member} from "@atg-tillsammans/types/generated";
import ChatAvatar from "@atg-tillsammans/chat/components/TeamChatAvatar";
import {Typography} from "atg-ui-components";
import {capitalized} from "../domain/tillsammansPushUtil";
import * as styles from "./LoginNotification.styles";

export type Props = {
    member: Member;
};

function LoginNotification({member}: Props) {
    return (
        <div data-test-id="notification-login-root" css={styles.container}>
            <div css={styles.aside}>
                {member && (
                    <ChatAvatar
                        imageUrl={member.imageUrl ?? null}
                        alt={`${member.firstName} ${member.lastName}`}
                        online
                        showTooltip
                    />
                )}
            </div>
            <div css={styles.main}>
                {member && (
                    <Typography
                        data-test-id="notification-login-title"
                        variant="headline3"
                        component="span"
                        color="inherit"
                        style={styles.title}
                    >
                        {t({
                            id: "push.notification.login.title",
                            message: `${capitalized(
                                `${member.firstName} ${member.lastName}`,
                            )} är online`,
                        })}
                    </Typography>
                )}
            </div>
        </div>
    );
}

export default LoginNotification;
