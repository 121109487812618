/* API constants */
export const CUSTOMIZATION_ENDPONT = "/services/tokenized-proxy/cc/api/v1/customization";
export const CUSTOMIZATION_CONTEXT = "TILLSAMMANS_MEMBER";

/* Redux action constants */
export const GET_MEMBER_CUSTOMIZATION_REQUEST =
    "customization/GET_MEMBER_CUSTOMIZATION_REQUEST";
export const GET_MEMBER_CUSTOMIZATION_RESPONSE =
    "customization/GET_MEMBER_CUSTOMIZATION_RESPONSE";

export const ADD_MEMBER_CUSTOMIZATION_REQUEST =
    "customization/ADD_MEMBER_CUSTOMIZATION_REQUEST";
export const ADD_MEMBER_CUSTOMIZATION_RESPONSE =
    "customization/ADD_MEMBER_CUSTOMIZATION_RESPONSE";

export const CLEAR_MEMBER_CUSTOMIZATION = "customization/CLEAR_MEMBER_CUSTOMIZATION";

export const SET_CUSTOMIZATION_ID = "customization/SET_CUSTOMIZATION_ID";

export const SET_STARTPAGE_HERO_FLAG = "customization/SET_STARTPAGE_HERO_FLAG";
