import type {SagaIterator} from "redux-saga";
import {call, select, takeLatest} from "redux-saga/effects";
import {AuthSelectors, authenticate} from "@atg-shared/auth";
import {CallAuthActionType} from "./callAuth";
import type {CallAuthAction} from "./callAuthActions";

export function* callAuth({
    payload: {onSuccess, onCancel},
}: CallAuthAction): SagaIterator {
    const isLoggedIn = yield select(AuthSelectors.isNormalLogin);

    if (isLoggedIn) {
        onSuccess();
        return;
    }

    const {status} = yield call(authenticate, undefined, true);
    if (status === "authSuccess") {
        onSuccess();
    } else if (status === "authCancelled" && onCancel) {
        onCancel();
    }
}

export function* callAuthSaga(): SagaIterator {
    yield takeLatest(CallAuthActionType.CALL_AUTH, callAuth);
}
