import type {Logger} from "loglevel";
import log from "@atg-tillsammans/log";

export type ApolloConfigOptions = {
    logger: Logger;
    devToolsEnabled: boolean;
};

export const createDefaultOptions = ({
    logger = log,
    devToolsEnabled = false,
}: Partial<ApolloConfigOptions> = {}): ApolloConfigOptions => ({
    logger,
    devToolsEnabled,
});
