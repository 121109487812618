import {getApolloClient} from "@atg-tillsammans/apollo-client";
import {TeamRoundDocument} from "@atg-tillsammans/graphql/query/__generated__/roundQueries.graphql.generated";
import {
    CreateParticipationDocument,
    type CreateParticipationMutationVariables,
    type CreateParticipationMutation,
} from "@atg-tillsammans/graphql/mutation/__generated__/participationMutations.graphql.generated";
import {type RoundErrorCode} from "@atg-tillsammans/types";

export class RoundService {
    static async createParticipation(variables: CreateParticipationMutationVariables) {
        const client = getApolloClient();
        if (client) {
            const {data, errors} = await client.mutate<CreateParticipationMutation>({
                mutation: CreateParticipationDocument,
                variables,
                refetchQueries: [TeamRoundDocument],
            });
            return {
                participation: data?.createParticipation,
                error:
                    errors && errors[0]?.extensions?.errorCode
                        ? (errors[0].extensions.errorCode as RoundErrorCode)
                        : undefined,
            };
        }
        return null;
    }
}
