import type {AlertSeverity} from "atg-ui-components";

export type SagaFlowStatus = {
    hasError: boolean;
    isLoading: boolean;
    hasLoaded: boolean;
};

export const FLOW_STATUS_INITIAL: SagaFlowStatus = {
    isLoading: false,
    hasError: false,
    hasLoaded: false,
} as const;

export const FLOW_STATUS_LOADING: SagaFlowStatus = {
    isLoading: true,
    hasError: false,
    hasLoaded: false,
} as const;

export const FLOW_STATUS_WARNING: SagaFlowStatus = {
    isLoading: true,
    hasError: true,
    hasLoaded: false,
} as const;

export const FLOW_STATUS_ERROR: SagaFlowStatus = {
    isLoading: false,
    hasError: true,
    hasLoaded: true,
} as const;

export const FLOW_STATUS_SUCCESS: SagaFlowStatus = {
    isLoading: false,
    hasError: false,
    hasLoaded: true,
} as const;

export type SagaFlowMessage = {
    flowId: string;
    itemId: string;
    status: AlertSeverity;
    message?: string;
    error?: Error;
};
