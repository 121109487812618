import type {AtgResponse} from "@atg-shared/fetch-types";
import type {
    deprecated_MemberTeam,
    TeamMembers,
    TeamMember,
} from "@atg-tillsammans/types";
import {MemberModel} from "@atg-tillsammans/types/src/member.model";
import ImageUtils from "atg-ui-image/utils/imageUtils";

const transformUserTeamsResponse = (response: AtgResponse<deprecated_MemberTeam[]>) => ({
    ...response,
    data: response.data?.map(
        (team: deprecated_MemberTeam): deprecated_MemberTeam => ({
            ...team,
            id: `${team.id}`,
            leader: MemberModel.of({
                ...team.leader,
                imageUrl: team?.leader?.imageUrl
                    ? ImageUtils.getImageRef(team.leader.imageUrl)
                    : team?.leader?.imageUrl,
            }),
            teamImageUrl: team?.teamImageUrl
                ? ImageUtils.getImageRef(team.teamImageUrl)
                : team.teamImageUrl,
        }),
    ),
});

const deprecated_transformTeamMembersResponse = (response: AtgResponse<TeamMembers>) => ({
    ...response,
    data: response.data?.map(
        (member: TeamMember): TeamMember => ({
            ...member,
            imageUrl: member.imageUrl
                ? ImageUtils.getImageRef(member.imageUrl)
                : member.imageUrl,
        }),
    ),
});

const TeamApiUtils = {
    transformUserTeamsResponse,
    deprecated_transformTeamMembersResponse,
};

export default TeamApiUtils;
