import {omit} from "lodash/fp";
import type {MemberCustomization} from "../types/app";
import {
    DEFAULT_MEMBER_FLAGS,
    type FetchMemberCustomizationResponse,
} from "./memberCustomizationTypes";

export const mapResponse = ({
    id,
    data,
}: FetchMemberCustomizationResponse): MemberCustomization => {
    const {flags} = data
        ? (JSON.parse(data) as MemberCustomization)
        : {flags: DEFAULT_MEMBER_FLAGS};

    return {
        id,
        flags,
    };
};

export const trimPersistData = ({flags, ...rest}: MemberCustomization) => ({
    ...rest,
    flags: omit("_persist", flags),
});
