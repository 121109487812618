import {call, FETCH} from "@atg-shared/fetch-redux";
import type {AtgResponse, FetchAction} from "@atg-shared/fetch-types";
import type {ChannelId, Message, MessageAuthorType, MyChannelStats} from "./teamChat";
import * as ChatApi from "./teamChatApi";
import type {State} from "./index";

export const REQUEST_CHANNEL_MESSAGES = "chat/REQUEST_CHANNEL_MESSAGES";
export const RECEIVE_CHANNEL_MESSAGES = "chat/RECEIVE_CHANNEL_MESSAGES";
export const REQUEST_MY_CHANNEL_STATS = "chat/REQUEST_MY_CHANNEL_STATS";
export const RECEIVE_MY_CHANNEL_STATS = "chat/RECEIVE_MY_CHANNEL_STATS";
export const RESET_CHANNEL_STATUS = "chat/RESET_CHANNEL_STATUS";
export const POST_MESSAGE = "chat/POST_MESSAGE";

export const RECEIVE_POST_MESSAGE_RESPONSE = "chat/RECIEVE_POST_MESSAGE_RESPONSE";
export const RESET_POST_MESSAGE = "chat/RESET_POST_MESSAGE";
export const SAVE_MESSAGE = "chat/SAVE_MESSAGE";
export const RECEIVE_SAVE_MESSAGE_RESPONSE = "chat/RECEIVE_SAVE_MESSAGE_RESPONSE";
export const RESET_SAVE_MESSAGE = "chat/RESET_SAVE_MESSAGE";
export const REMOVE_MESSAGE = "chat/REMOVE_MESSAGE";
export const RECEIVE_REMOVE_MESSAGE_RESPONSE = "chat/RECEIVE_REMOVE_MESSAGE_RESPONSE";
export const RESET_REMOVE_MESSAGE = "chat/RESET_REMOVE_MESSAGE";
export const REPORT_MESSAGE = "chat/REPORT_MESSAGE";
export const RECEIVE_REPORT_MESSAGE_RESPONSE = "chat/RECEIVE_REPORT_MESSAGE_RESPONSE";
export const BLOCK_MEMBER = "chat/BLOCK_MEMBER";
export const RECEIVE_BLOCK_MEMBER_RESPONSE = "chat/RECEIVE_BLOCK_MEMBER_RESPONSE";
export const RESET_BLOCK_MEMBER = "chat/RESET_BLOCK_MEMBER";
export const RESET_REPORT_MESSAGE = "chat/RESET_REPORT_MESSAGE";

export const CHANGE_MESSAGE_TEXT = "chat/CHANGE_MESSAGE_TEXT";
export const ADD_MESSAGE = "chat/ADD_MESSAGE";
export const DELETE_MESSAGE = "chat/DELETE_MESSAGE";
export const PIN_OR_UNPIN_MESSAGE = "chat/PIN_OR_UNPIN_MESSAGE";

export const OPEN_DIALOG = "chat/OPEN_DIALOG";
export const CLOSE_DIALOG = "chat/CLOSE_DIALOG";

export const LOCATION_CHANGE = "@@router/ON_LOCATION_CHANGE";

type ChannelHistoryResponse = Message[];
export type PaginationContext = {
    channelId: ChannelId;
    page: number;
};

export type ChatChannelMessagesFetchAction = FetchAction<
    typeof REQUEST_CHANNEL_MESSAGES,
    typeof RECEIVE_CHANNEL_MESSAGES,
    ChannelHistoryResponse,
    State,
    PaginationContext
>;

export type ChannelContext = {
    channelId: ChannelId;
};

export type FetchMyChannelStatsAction = FetchAction<
    typeof REQUEST_MY_CHANNEL_STATS,
    typeof RECEIVE_MY_CHANNEL_STATS,
    MyChannelStats,
    State,
    ChannelContext
>;

export type RemoveChatMessageAction = FetchAction<
    typeof REMOVE_MESSAGE,
    typeof RECEIVE_REMOVE_MESSAGE_RESPONSE,
    Record<string, never>,
    State
>;

export type ReportChatMessageAction = FetchAction<
    typeof REPORT_MESSAGE,
    typeof RECEIVE_REPORT_MESSAGE_RESPONSE,
    Record<string, never>,
    State
>;

export type BlockChatMemberAction = FetchAction<
    typeof BLOCK_MEMBER,
    typeof RECEIVE_BLOCK_MEMBER_RESPONSE,
    Record<string, never>,
    State
>;

type MessageContext = {
    messageText: string;
};

export type ChatPostMessageAction = FetchAction<
    typeof POST_MESSAGE,
    typeof RECEIVE_POST_MESSAGE_RESPONSE,
    Message,
    State,
    MessageContext
>;

export type SavePostMessageAction = FetchAction<
    typeof SAVE_MESSAGE,
    typeof RECEIVE_SAVE_MESSAGE_RESPONSE,
    Record<string, never>,
    State
>;

export type PinOrUnpinMessageAction = {
    type: typeof PIN_OR_UNPIN_MESSAGE;
    message: Message;
};

export type ReceivePostMessageAction = {
    type: typeof RECEIVE_POST_MESSAGE_RESPONSE;
    error?: unknown;
    payload: Message;
};

type ResetPostMessageAction = {
    type: typeof RESET_POST_MESSAGE;
};

type LocationChangeAction = {
    type: typeof LOCATION_CHANGE;
};

type ResetSaveMessageAction = {
    type: typeof RESET_SAVE_MESSAGE;
};

type ResetRemoveMessageAction = {
    type: typeof RESET_REMOVE_MESSAGE;
};

type ResetReportMessageAction = {
    type: typeof RESET_REPORT_MESSAGE;
};

type ResetBlockMemberAction = {
    type: typeof RESET_BLOCK_MEMBER;
};

export type ChangeMessageTextAction = {
    type: typeof CHANGE_MESSAGE_TEXT;
    message: Message;
};

export type AddMessageAction = {
    type: typeof ADD_MESSAGE;
    message: Message;
};

export type DeleteMessageAction = {
    type: typeof DELETE_MESSAGE;
    messageId: number;
};

type OpenDialogAction = {
    type: typeof OPEN_DIALOG;
};

type CloseDialogAction = {
    type: typeof CLOSE_DIALOG;
};

export type Action =
    | ChatChannelMessagesFetchAction
    | FetchMyChannelStatsAction
    | ChatPostMessageAction
    | ResetPostMessageAction
    | ResetSaveMessageAction
    | ResetRemoveMessageAction
    | ResetReportMessageAction
    | ResetBlockMemberAction
    | ReceivePostMessageAction
    | PinOrUnpinMessageAction
    | SavePostMessageAction
    | RemoveChatMessageAction
    | ReportChatMessageAction
    | BlockChatMemberAction
    | ChangeMessageTextAction
    | AddMessageAction
    | DeleteMessageAction
    | OpenDialogAction
    | CloseDialogAction
    | LocationChangeAction;

export const changeMessageText = (message: Message) => ({
    type: CHANGE_MESSAGE_TEXT,
    message,
});

export const addMessage = (message: Message): AddMessageAction => ({
    type: ADD_MESSAGE,
    message,
});

export const deleteMessage = (messageId: number): DeleteMessageAction => ({
    type: DELETE_MESSAGE,
    messageId,
});

export const pinOrUnpinMessage = (message: Message) => ({
    type: PIN_OR_UNPIN_MESSAGE,
    message,
});

export const openDialog = (): OpenDialogAction => ({
    type: OPEN_DIALOG,
});

export const closeDialog = (): CloseDialogAction => ({
    type: CLOSE_DIALOG,
});

const transformChatHistoryResponse = (response: AtgResponse<ChannelHistoryResponse>) => {
    const messages = Array.isArray(response?.data) ? response.data : [];
    return {
        ...response,
        data: messages.map((message) => ({
            ...message,
            user: {...message.user},
        })),
    };
};

export const fetchChannelHistory = (
    channelId: ChannelId,
    page = 0,
): ChatChannelMessagesFetchAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_CHANNEL_MESSAGES,
        receiveAction: RECEIVE_CHANNEL_MESSAGES,
        callApi: call(ChatApi.fetchChannelHistory, channelId, page),
        context: {
            page,
            channelId,
        },
        transformResponse: transformChatHistoryResponse,
    },
});

export const fetchMyChannelStats = (channelId: ChannelId): FetchMyChannelStatsAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_MY_CHANNEL_STATS,
        receiveAction: RECEIVE_MY_CHANNEL_STATS,
        callApi: call(ChatApi.fetchMyChannelStats, channelId),
        context: {
            channelId,
        },
    },
});

export const postMessage = (
    channelId: ChannelId,
    messageText: string,
    authorType: MessageAuthorType,
): ChatPostMessageAction => {
    const text = messageText.trim();
    return {
        type: FETCH,
        payload: {
            requestAction: POST_MESSAGE,
            receiveAction: RECEIVE_POST_MESSAGE_RESPONSE,
            callApi: call(ChatApi.postMessage, channelId, messageText, authorType),
            context: {
                messageText: text,
            },
        },
    };
};

export const resetPostMessage = (): ResetPostMessageAction => ({
    type: RESET_POST_MESSAGE,
});

export const resetRemoveMessage = (): ResetRemoveMessageAction => ({
    type: RESET_REMOVE_MESSAGE,
});

export const resetSaveMessage = (): ResetSaveMessageAction => ({
    type: RESET_SAVE_MESSAGE,
});

export const resetReportMessage = (): ResetReportMessageAction => ({
    type: RESET_REPORT_MESSAGE,
});

export const resetBlockMember = (): ResetBlockMemberAction => ({
    type: RESET_BLOCK_MEMBER,
});

export const saveMessage = (
    channelId: ChannelId,
    commentId: number,
    messageText: string,
    authorType: MessageAuthorType,
) => ({
    type: FETCH,
    payload: {
        requestAction: SAVE_MESSAGE,
        receiveAction: RECEIVE_SAVE_MESSAGE_RESPONSE,
        callApi: call(ChatApi.saveMessage, channelId, commentId, messageText, authorType),
    },
});

export const removeMessage = (
    channelId: ChannelId,
    commentId: number,
): RemoveChatMessageAction => ({
    type: FETCH,
    payload: {
        requestAction: REMOVE_MESSAGE,
        receiveAction: RECEIVE_REMOVE_MESSAGE_RESPONSE,
        callApi: call(ChatApi.removeMessage, channelId, commentId),
    },
});

export const reportMessage = (
    channelId: ChannelId,
    commentId: number,
    text: string,
): ReportChatMessageAction => ({
    type: FETCH,
    payload: {
        requestAction: REPORT_MESSAGE,
        receiveAction: RECEIVE_REPORT_MESSAGE_RESPONSE,
        callApi: call(ChatApi.reportMessage, channelId, commentId, text),
    },
});

export const blockMember = (
    channelId: ChannelId,
    commentId: number,
): BlockChatMemberAction => ({
    type: FETCH,
    payload: {
        requestAction: BLOCK_MEMBER,
        receiveAction: RECEIVE_BLOCK_MEMBER_RESPONSE,
        callApi: call(ChatApi.blockMember, channelId, commentId),
    },
});
