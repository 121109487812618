import type {AnyAction} from "redux";
import {FETCH, FETCH_PROMISE} from "./fetchActions";

const consumeAllFetchActions =
    <A extends AnyAction>() =>
    (next: (action: A) => void) =>
    (action: A) => {
        if (action.type === FETCH) return;
        if (action.type === FETCH_PROMISE) return;
        next(action);
    };

export default consumeAllFetchActions;
