export type ImageAspectRatio = {
    widthUnit: number;
    heightUnit: number;
    heightMultiplicand: number;
};

export const aspectRatio = (widthUnit: number, heightUnit: number): ImageAspectRatio => ({
    widthUnit,
    heightUnit,
    heightMultiplicand: heightUnit / widthUnit,
});

export const ASPECT_RATIO_1_1: ImageAspectRatio = aspectRatio(1, 1);
export const ASPECT_RATIO_4_3: ImageAspectRatio = aspectRatio(4, 3);
export const ASPECT_RATIO_16_9: ImageAspectRatio = aspectRatio(16, 9);
export const ASPECT_RATIO_191_1: ImageAspectRatio = aspectRatio(1.91, 1);
export const ASPECT_RATIO_21_1: ImageAspectRatio = aspectRatio(2.1, 1);
export const ASPECT_RATIO_3_2: ImageAspectRatio = aspectRatio(3, 2);
