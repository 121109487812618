import {isEmpty} from "lodash";
import {t} from "@lingui/macro";
import * as Storage from "@atg-shared/storage";
import * as Time from "@atg-shared/server-time";
import {GameType} from "@atg-tillsammans-shared/types";
import type {
    CbsOffering,
    Grading,
    ShareInfoGrading,
} from "@atg-tillsammans/types/generated";
import {formatCurrency} from "@atg-shared/currency";
import type {Payout, ReceiptGrading, ReceiptSummaryPool} from "../domain/receiptTypes";

export const getOfferingUnitDividendFormatted = (
    offering: CbsOffering,
    payoutPool: Payout,
): string => {
    const pools = offering.summary.pools as Record<number, ReceiptSummaryPool>;

    if (pools == null || pools[payoutPool] == null) {
        return "";
    }

    const {unitDividend} = pools[payoutPool];

    return unitDividend > 0
        ? formatCurrency(unitDividend)
        : t({id: "common.jackpot", message: "Jackpot"});
};

export const getReceiptGrading = (
    grading: ShareInfoGrading | Grading | null,
): ReceiptGrading | null => {
    if (!grading) return null;

    const {dividend, qualifyingUnits} = grading as {
        dividend: Record<string, number> | null;
        qualifyingUnits: Record<string, number> | null;
    };

    if (!dividend || !qualifyingUnits) return null;

    return {
        splits: Object.keys(qualifyingUnits).reduce(
            (splits, corrects) => ({
                ...splits,
                [corrects]: {
                    corrects,
                    units: qualifyingUnits[corrects],
                    dividend: dividend[corrects],
                    dividendFormatted: formatCurrency(dividend[corrects]),
                },
            }),
            {},
        ),
        total: dividend.total,
        totalFormatted: formatCurrency(dividend.total),
    };
};

export const getPayoutCorrects = (gameType: GameType): Payout[] => {
    switch (gameType) {
        case GameType.V75:
            return ["7", "6", "5"];
        case GameType.V86:
            return ["8", "7", "6"];
        case GameType.BIG9:
            return ["9", "8", "7"];
        default:
            return [];
    }
};

/**
 * Returns the timestamp from a sneak key (in local storage).
 * If not a valid sneak key then return null
 *
 * @param sneakKey E.g "receipt-sneak-0B04100141000101-1695376800"
 * @returns timestamp as a number if valid otherwise  null
 */
export const getReceiptSneakTimestamp = (sneakKey: string) => {
    const parts = sneakKey.split("-");

    if (parts.length !== 4) return null;

    const timestamp = Number(parts[3]);

    return isNaN(timestamp) ? null : timestamp;
};

/**
 * Clears old sneaked receipt state in local storage.
 * Sneak state older than 30 days will be cleared from local storage.
 */
export const clearReceiptSneakState = () => {
    const existingKeys = Storage.filterByKey("receipt-sneak");

    if (isEmpty(existingKeys)) return;

    const now = Time.serverTime();

    Object.keys(existingKeys).forEach((sneakKey) => {
        const sneakTimestamp = getReceiptSneakTimestamp(sneakKey);

        /**
         * 1. If not a valid sneak key (= null) then the remove item
         * 2. If sneak state is older than 30 days then remove item
         */
        if (!sneakTimestamp || now.subtract(30, "days").unix() >= sneakTimestamp) {
            Storage.removeItem(sneakKey);
        }
    });
};
