import {fromMinor} from "@atg-shared/currency";
import {
    MembershipPolicy,
    TeamType,
    type TeamSearchFilter,
} from "@atg-tillsammans/types/generated";

/**
 * Gives a better tracking structure if something like a label is used as input and contains multiple words.
 * Lowercase and replace spaces with a hyphen.
 * Also collapses all adjacent hyphens into one and removes leading and trailing hyphens
 * @example
 * toAnalyticsFormat("Live casino"); // Outputs: "live-casino"
 * or
 * toAnalyticsFormat("Hem - Casino"); // Outputs: "hem-casino"
 */
export function toAnalyticsFormat(input: string): string {
    return input
        .toLowerCase() // Convert to lowercase
        .replace(/\s+-\s+|\s+|-+/g, "-") // Replace spaces around hyphens and multiple hyphens with a single hyphen
        .replace(/^\-+|\-+$/g, ""); // Remove leading and trailing hyphens, if any
}

interface DropDownEventType {
    eventAction: string;
    eventType?: string | string[] | undefined;
    value?: string | undefined;
}

export function getTeamSearchDropDownEventType(
    type: string,
    partialFilter: Partial<TeamSearchFilter>,
): DropDownEventType | null {
    switch (type) {
        case "GAME_TYPE":
            return {
                eventAction: "changed_game_type_filter",
                eventType: partialFilter.gameTypes || undefined,
            };
        case "SHARE_COST": {
            const {costMin = 0, costMax = "1000+"} = partialFilter;

            return {
                eventAction: "changed_share_cost_filter",
                eventType: `${fromMinor(costMin)}-${
                    costMax !== "1000+" ? fromMinor(costMax) : costMax
                }`,
            };
        }

        case "NUMBER_OF_MEMBERS": {
            const {nbrMembersMin = "1", nbrMembersMax = "50"} = partialFilter;

            return {
                eventAction: "changed_number_of_members_filter",
                eventType: `${nbrMembersMin}-${nbrMembersMax}`,
            };
        }
        default:
            return null;
    }
}

type TeamSearchEventType = {
    teamPolicy?: MembershipPolicy | null;
    teamType?: TeamType | null;
};

export function teamTypeSearchEventType(conditions: TeamSearchEventType): string {
    const {teamPolicy, teamType} = conditions;

    let eventType: string;
    if (teamType === TeamType.CAMPAIGN) {
        eventType = "campaign";
    } else if (teamType === TeamType.STANDARD) {
        eventType = teamPolicy === MembershipPolicy.OPEN ? "open" : "closed";
    } else {
        eventType = "all";
    }

    return eventType;
}
