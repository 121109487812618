import {clone, merge, pickBy} from "lodash";
import root from "window-or-global";
import * as LocalStorage from "@atg-shared/storage";
import type * as featureNames from "./featureNames";

export * from "./featureNames";

export type Feature = keyof typeof featureNames;

export const STORAGE_KEY = "clientFeatures";
export type ClientFeaturesType = Record<Feature, boolean>;

let _storage: LocalStorage.Storage = LocalStorage;

export const setStorage = (storage: LocalStorage.Storage): void => {
    _storage = storage;
};

class Features {
    cachedFeatures: ClientFeaturesType | null;

    overriddenFeatures: ClientFeaturesType | null;

    constructor() {
        this.cachedFeatures = null;
        this.overriddenFeatures = null;
    }

    isEnabled(featureName: Feature): boolean {
        if (!this.cachedFeatures) {
            this.cachedFeatures = this._getAll();
        }

        const feature = this.cachedFeatures?.[featureName];
        return feature === undefined ? false : feature;
    }

    isSet(featureName: Feature): boolean {
        if (!this.overriddenFeatures) {
            this.overriddenFeatures = this._getOverridden();
        }
        const feature = this.overriddenFeatures?.[featureName];
        return feature !== undefined;
    }

    /** Removes feature overrides from localStorage where the stored override value matches the default value of the feature toggle */
    removeNonOverrides() {
        const overriddenFeatures = this._getOverridden();
        const defaultFeatures = this._getDefault();

        // get only the features that have been overriden with a value different from the default value
        const actualOverriden = pickBy(
            overriddenFeatures,
            (overriddenValue, feature) =>
                defaultFeatures[feature as Feature] !== overriddenValue,
        );

        _storage.setItem(STORAGE_KEY, JSON.stringify(actualOverriden));
    }

    _clearCachedFeatures() {
        this.cachedFeatures = null;
    }

    // eslint-disable-next-line class-methods-use-this
    _getDefault() {
        const features = clone(root.clientFeatures);
        return features === undefined ? ({} as typeof features) : features;
    }

    // eslint-disable-next-line class-methods-use-this
    _getOverridden(): ClientFeaturesType {
        const overriddenFeatures = _storage.getItem(STORAGE_KEY);
        if (!overriddenFeatures) return {} as ClientFeaturesType;

        return JSON.parse(overriddenFeatures);
    }

    _getAll() {
        return merge(this._getDefault(), this._getOverridden());
    }

    getAllEnabled() {
        return pickBy(this._getAll(), (value) => Boolean(value));
    }

    _updateOverriden(name: Feature) {
        const features = this._getAll();
        if (features[name] === undefined) return;
        const overridenFeatures = this._getOverridden();

        overridenFeatures[name] = !features[name];
        _storage.setItem(STORAGE_KEY, JSON.stringify(overridenFeatures));
    }

    _removeOverriden(name: Feature) {
        const features = this._getOverridden();
        if (features[name] === undefined) return;

        delete features[name];
        _storage.setItem(STORAGE_KEY, JSON.stringify(features));
    }

    // eslint-disable-next-line class-methods-use-this
    _removeAll() {
        _storage.removeItem(STORAGE_KEY);
    }
}

const features = new Features();
export default features;
