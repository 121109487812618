// eslint-disable-next-line camelcase, no-var
declare var __webpack_public_path__: string;

// The webpack `publicPath` config can be set at build or runtime. It's used by webpack to know
// where to load code from when doing code-splitting, e.g. using `import()` or when webpack splits
// `node_modules` assets into separate chunks.
//
// Usually we just load from `/assets/<microFE>/`, e.g. `/assets/frame/frame-solclientjs.fed672dcf4bc2e938ed1.js`.
// Since the URL starts with `/` it's relative to the current domain, but in some situations we want
// to be able to load from other domains (development builds with fallback assets).
//
// In order to make it possible to load assets cross-domain, dynamically set the public path to be
// the same as the _entrypoint_ for the current microFE.
//
// Since this has to be configured before any code-splitting occurs, this logic should run very very
// early.
//
// ref: https://webpack.js.org/guides/public-path/#on-the-fly
// eslint-disable-next-line camelcase
__webpack_public_path__ = `${(document.currentScript as HTMLScriptElement).src.replace(
    /\/[^/]*$/,
    "",
)}/`;
