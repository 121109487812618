export const LOGIN_ON_TILLSAMMANS = "tillsammans/LOGIN_ON_TILLSAMMANS";

export type LoginOnTillsammansAction = {
    type: typeof LOGIN_ON_TILLSAMMANS;
};

export type Action = LoginOnTillsammansAction;

const loginOnTillsammans = (): LoginOnTillsammansAction => ({
    type: LOGIN_ON_TILLSAMMANS,
});

export const TrackingActions = {
    loginOnTillsammans,
};
