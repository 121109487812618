/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import type {EmotionStyle} from "../../utils/emotion";
import * as styles from "./Typography.styles";

export type Variant =
    | "body1"
    | "body2"
    | "body3"
    | "headline1"
    | "headline2"
    | "headline3"
    | "headline4"
    | "brandHeadline1"
    | "brandHeadline2"
    | "brandHeadline3"
    | "brandHeadline4"
    | "sectionHeadline";

type CustomProps = {
    /**
     * The content of `Typography`. Should be pain text.
     */
    children: React.ReactNode;
    /**
     * The color of the text.
     */
    color?: "primary" | "secondary" | "error" | "success" | "notice" | "link" | "inherit";
    /**
     * The component or element used for the root node.
     *
     * Will otherwise default to an appropriate element based on the variant used
     */
    component?: React.ElementType;
    /**
     * If `true`, the text will not wrap, but instead will truncate with a text overflow ellipsis.
     *
     * Note that text overflow can only happen with block or inline-block level elements
     *
     * (the element needs to have a width in order to overflow).
     */
    ellipsis?: boolean;
    /**
     * If `true`, the text will have a bottom margin.
     */
    gutterBottom?: boolean;
    /**
     * Switch the color of the text to fit dark or light mode.
     *
     * A lighter text will be shown during "dark mode". A darker text in "light mode"
     */
    mode?: "dark" | "light";
    /**
     * [Emotion 10 style object used in place of the traditional className.](https://developer.atg.se/frontend/styling.html#emotion)
     */
    style?: EmotionStyle;
    /**
     * The variant that should be displayed, based on the zeplin design.
     *
     * The component maps the variant prop to an appropriate HTML element type.
     *
     * Example: headline1 to `<h1>`.
     *
     * If you wish to overwrite that mapping, you can use the `component` prop.
     */
    variant?: Variant;
};

export type Props = Omit<React.HTMLAttributes<HTMLElement>, keyof CustomProps> &
    CustomProps;

/**
 * maps a variant to a fitting element. The element type can be
 * easily overwritten with the "component" prop (e.g. component="span")
 */
export const variantMapping = {
    body1: "p",
    body2: "p",
    body3: "p",
    brandHeadline1: "h1",
    brandHeadline2: "h2",
    brandHeadline3: "h3",
    brandHeadline4: "h4",
    headline1: "h1",
    headline2: "h2",
    headline3: "h3",
    headline4: "h4",
    sectionHeadline: "h2",
} as const;

/**
 * A typographic component with variants and colors [based on the "Typography
 * Text Block" in zeplin](https://app.zeplin.io/project/5d5a6adf2dba3f9bc1d7334e/screen/5d5a73b4a192a38cadd452be)
 */
const Typography = React.forwardRef<HTMLElement, Props>(
    (
        {
            children,
            color = "primary",
            component,
            ellipsis = false,
            gutterBottom = false,
            mode = "light",
            style,
            variant = "body1",
            ...other
        }: Props,
        ref,
    ) => {
        const Component = component || variantMapping[variant] || "p";
        return (
            <Component
                {...other}
                ref={ref}
                css={[
                    styles.rootStyle({
                        gutterBottom,
                        color,
                        ellipsis,
                        mode,
                    }),
                    styles[variant],
                    style,
                ]}
            >
                {children}
            </Component>
        );
    },
);

Typography.displayName = "Typography";
export default Typography;
