import {AlertSeverity} from "atg-ui-components";
import type {SagaFlowStatus, SagaFlowMessage} from "./sagaFlow";
import {
    FLOW_STATUS_INITIAL,
    FLOW_STATUS_SUCCESS,
    FLOW_STATUS_LOADING,
    FLOW_STATUS_ERROR,
    FLOW_STATUS_WARNING,
} from "./sagaFlow";

export const SET_SAGA_FLOW_STATUS = "ts-redux/SET_SAGA_FLOW_STATUS";
export const ADD_SAGA_FLOW_MESSAGE = "ts-redux/ADD_SAGA_FLOW_MESSAGE";
export const REMOVE_SAGA_FLOW_MESSAGE = "ts-redux/REMOVE_SAGA_FLOW_MESSAGE";

export type SagaFlowStatusAction = {
    type: typeof SET_SAGA_FLOW_STATUS;
    payload: {
        flowId: string;
        flowStatus: SagaFlowStatus;
    };
};

export const init = (flowId: string): SagaFlowStatusAction => ({
    type: SET_SAGA_FLOW_STATUS,
    payload: {
        flowId,
        flowStatus: FLOW_STATUS_LOADING,
    },
});

export const success = (flowId: string): SagaFlowStatusAction => ({
    type: SET_SAGA_FLOW_STATUS,
    payload: {
        flowId,
        flowStatus: FLOW_STATUS_SUCCESS,
    },
});

export const error = (flowId: string): SagaFlowStatusAction => ({
    type: SET_SAGA_FLOW_STATUS,
    payload: {
        flowId,
        flowStatus: FLOW_STATUS_ERROR,
    },
});

export const warning = (flowId: string): SagaFlowStatusAction => ({
    type: SET_SAGA_FLOW_STATUS,
    payload: {
        flowId,
        flowStatus: FLOW_STATUS_WARNING,
    },
});

export const reset = (flowId: string): SagaFlowStatusAction => ({
    type: SET_SAGA_FLOW_STATUS,
    payload: {
        flowId,
        flowStatus: FLOW_STATUS_INITIAL,
    },
});

type AddSagaFlowMessageAction = {
    type: typeof ADD_SAGA_FLOW_MESSAGE;
    error: boolean;
    payload: SagaFlowMessage;
};

type RemoveFlowMessageAction = {
    type: typeof REMOVE_SAGA_FLOW_MESSAGE;
    payload: {
        flowId?: string;
        itemId?: string;
    };
};

export type SagaFlowMessageAction = AddSagaFlowMessageAction | RemoveFlowMessageAction;

export const infoMessage = ({
    flowId,
    itemId,
    message,
}: {
    flowId: string;
    itemId: string;
    message?: string;
}): AddSagaFlowMessageAction => ({
    type: ADD_SAGA_FLOW_MESSAGE,
    error: false,
    payload: {
        flowId,
        itemId,
        status: AlertSeverity.INFO,
        message,
    },
});

export const successMessage = ({
    flowId,
    itemId,
    message,
}: {
    flowId: string;
    itemId: string;
    message?: string;
}): AddSagaFlowMessageAction => ({
    type: ADD_SAGA_FLOW_MESSAGE,
    error: false,
    payload: {
        flowId,
        itemId,
        status: AlertSeverity.SUCCESS,
        message,
    },
});

export const warningMessage = ({
    flowId,
    itemId,
    message,
    error: rawError,
}: {
    flowId: string;
    itemId: string;
    message?: string;
    error?: Error;
}): AddSagaFlowMessageAction => ({
    type: ADD_SAGA_FLOW_MESSAGE,
    error: false,
    payload: {
        flowId,
        itemId,
        status: AlertSeverity.WARNING,
        message,
        error: rawError,
    },
});

export const errorMessage = ({
    flowId,
    itemId,
    message,
    error: rawError,
}: {
    flowId: string;
    itemId: string;
    message?: string;
    error?: Error;
}): AddSagaFlowMessageAction => ({
    type: ADD_SAGA_FLOW_MESSAGE,
    error: true,
    payload: {
        flowId,
        itemId,
        status: AlertSeverity.ERROR,
        message,
        error: rawError,
    },
});

export const removeMessage = ({
    flowId,
    itemId,
}: {
    flowId?: string;
    itemId?: string;
}): RemoveFlowMessageAction => ({
    type: REMOVE_SAGA_FLOW_MESSAGE,
    payload: {
        flowId,
        itemId,
    },
});
