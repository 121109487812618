import {fork, takeEvery, takeLatest, put} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import type {Dispatch} from "redux";
import type {ReceiveAction} from "@atg-shared/fetch-types";
import {TeamActions} from "@atg-tillsammans/team-data-access/redux/team";
import {TeamService} from "@atg-tillsammans/team-data-access/services";
import * as ModalActions from "atg-modals/modalActions";
import * as ChatActions from "./teamChatActions";
import chatListenerSaga from "./teamChatListenerSaga";
import type {Action} from "./index";

export function* addMessage(action: ChatActions.ReceivePostMessageAction): SagaIterator {
    if (action.error) return;

    yield put(ChatActions.addMessage(action.payload));
}

export function* saveMessage(action: Action): SagaIterator {
    if (action.type === ChatActions.RECEIVE_SAVE_MESSAGE_RESPONSE) {
        if (action.error) return;
        yield put(ChatActions.resetSaveMessage());
    }
}

export function* clearModals(action: Action): SagaIterator {
    if (
        action.type === ChatActions.RECEIVE_REPORT_MESSAGE_RESPONSE ||
        action.type === ChatActions.RECEIVE_BLOCK_MEMBER_RESPONSE
    ) {
        if (action.error) return;

        switch (action.type) {
            case ChatActions.RECEIVE_REPORT_MESSAGE_RESPONSE:
                yield put(ModalActions.closeReportChatMessageModal());
                break;
            case ChatActions.RECEIVE_BLOCK_MEMBER_RESPONSE:
                yield put(ModalActions.closeBlockChatMemberModal());
                break;
            default:
        }
    }
}

export function* updateNrOfUnreadComments(
    action: ReceiveAction<
        | typeof ChatActions.RECEIVE_CHANNEL_MESSAGES
        | typeof ChatActions.RECEIVE_MY_CHANNEL_STATS,
        {nrOfUnreadComments: number},
        {channelId?: string}
    >,
): SagaIterator {
    if (action.error || !action.context || !action.context.channelId) {
        return;
    }

    const {channelId} = action.context;
    const nrOfUnreadComments = action.payload.nrOfUnreadComments
        ? action.payload.nrOfUnreadComments
        : 0;
    yield put(TeamActions.setUnreadComments(channelId, nrOfUnreadComments));
    TeamService.updateUnreadMessages(channelId, nrOfUnreadComments);
}

export default function* chatSaga(dispatch: Dispatch): SagaIterator {
    yield takeEvery(ChatActions.RECEIVE_SAVE_MESSAGE_RESPONSE, saveMessage);
    yield takeEvery(
        [
            ChatActions.RECEIVE_REPORT_MESSAGE_RESPONSE,
            ChatActions.RECEIVE_BLOCK_MEMBER_RESPONSE,
        ],
        clearModals,
    );
    yield takeEvery(ChatActions.RECEIVE_POST_MESSAGE_RESPONSE, addMessage);
    yield takeLatest(
        [ChatActions.RECEIVE_CHANNEL_MESSAGES, ChatActions.RECEIVE_MY_CHANNEL_STATS],
        updateNrOfUnreadComments,
    );
    yield fork(chatListenerSaga, dispatch);
}
