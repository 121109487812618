import {type MemberFlags} from "../types/app";
import {
    APP_CONTEXT_ENTER,
    APP_CONTEXT_LEAVE,
    USER_ENTERED_TILLSAMMANS,
} from "./appConstants";

export type EnterAppContextAction = {
    type: typeof APP_CONTEXT_ENTER;
};

export type LeaveAppContextAction = {
    type: typeof APP_CONTEXT_LEAVE;
};

export type UserEnteredTillsammansAction = {
    type: typeof USER_ENTERED_TILLSAMMANS;
};

export type AppAction =
    | EnterAppContextAction
    | LeaveAppContextAction
    | UserEnteredTillsammansAction;

const enterAppContext = (): EnterAppContextAction => ({
    type: APP_CONTEXT_ENTER,
});

const leaveAppContext = (): LeaveAppContextAction => ({
    type: APP_CONTEXT_LEAVE,
});

const userEnteredTillsammans = (): UserEnteredTillsammansAction => ({
    type: USER_ENTERED_TILLSAMMANS,
});

export const AppActions = {
    enterAppContext,
    leaveAppContext,
    userEnteredTillsammans,
};
