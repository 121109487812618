import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import root from "window-or-global";
import {type MemberFlags} from "../types/app";
import {
    DEFAULT_MEMBER_FLAGS,
    type CustomizationState,
    type MemberCustomizationState,
} from "./memberCustomizationTypes";
import {
    ADD_MEMBER_CUSTOMIZATION_RESPONSE,
    CLEAR_MEMBER_CUSTOMIZATION,
    GET_MEMBER_CUSTOMIZATION_RESPONSE,
    SET_CUSTOMIZATION_ID,
    SET_STARTPAGE_HERO_FLAG,
} from "./memberCustomizationConstants";
import {type MemberCustomizationAction} from "./memberCustomizationActions";
import {mapResponse} from "./memberCustomizationUtils";

export const data = (
    state: CustomizationState = {},
    action: MemberCustomizationAction,
): CustomizationState => {
    switch (action.type) {
        case GET_MEMBER_CUSTOMIZATION_RESPONSE: {
            if (action.error) return state;
            if (action.payload.data && action.payload.data.length > 0)
                return {
                    ...state,
                    customizationId: action.payload.data[0].id,
                };
            return state;
        }
        case ADD_MEMBER_CUSTOMIZATION_RESPONSE: {
            if (action.error) return state;
            if (action.payload.data)
                return {
                    ...state,
                    customizationId: action.payload.data.id,
                };
            return state;
        }
        case SET_CUSTOMIZATION_ID: {
            return {...state, customizationId: action.payload.customizationId};
        }
        case CLEAR_MEMBER_CUSTOMIZATION: {
            return {};
        }
        default:
            return state;
    }
};

export const memberFlags = (
    state = DEFAULT_MEMBER_FLAGS,
    action: MemberCustomizationAction,
): MemberFlags => {
    switch (action.type) {
        case SET_STARTPAGE_HERO_FLAG:
            return {
                ...state,
                showStartPageHero: action.payload.visible,
                showStartPageOnboarding: action.payload.visible,
            };
        case GET_MEMBER_CUSTOMIZATION_RESPONSE: {
            if (action.error) return state;
            if (action.payload.data && action.payload.data.length > 0) {
                const {flags} = mapResponse(action.payload.data[0]);
                return {
                    ...state,
                    ...flags,
                };
            }
            return state;
        }
        case ADD_MEMBER_CUSTOMIZATION_RESPONSE: {
            if (action.error) return state;
            if (action.payload.data) {
                const {flags} = mapResponse(action.payload.data);
                return {
                    ...state,
                    ...flags,
                };
            }
            return state;
        }
        default:
            return state;
    }
};

const getSuffix = () => {
    const env = root?.clientConfig?.env ? root.clientConfig.env.toLowerCase() : "";
    if (env === "prod" || env === "") return "";
    return `_${env}`;
};

const tillsammansFlagsPersistConfig = {
    storage,
    key: `tillsammans-flags${getSuffix()}`,
    version: 0,
};

export const memberCustomizationReducer = combineReducers<MemberCustomizationState>({
    data,
    flags: persistReducer(tillsammansFlagsPersistConfig, memberFlags),
});
