import type {GameID} from "@atg-tillsammans-shared/types";
import type {Member} from "../generated";
import type {TeamID} from "./team";

export enum RoundState {
    OPEN = "OPEN",
    SOLD_OUT = "SOLD_OUT",
    CLOSED = "CLOSED",
    PLACED = "PLACED",
    NOT_PLACED = "NOT_PLACED",
    INACTIVE = "INACTIVE",
    PAUSED = "PAUSED",
}

export enum RoundBetMethod {
    VXY = "VXY",
    HARRY = "HARRY",
}

export type RoundSales = {
    deadline: string;
    price: number;
    buyLimit: number;
    buyLimitPerPerson: number;
    totalSalesAmount: number;
    refundAmount: number;
};

export type RoundGameInitiator = Member;

export type Round = {
    id: string;
    teamId: TeamID;
    gameId: GameID;
    state: RoundState;
    description?: string;
    sales?: RoundSales;
    betMethod?: RoundBetMethod;
    isGameInitiator: boolean;
    gameInitiator: RoundGameInitiator;
};

export enum RoundErrorCode {
    RGS_SELF_EXCLUSION = "RGS_SELF_EXCLUSION",
    RGS_COVID_SELF_EXCLUSION = "RGS_COVID_SELF_EXCLUSION",
    RGS_TEMPORARY_SELF_EXCLUSION = "RGS_TEMPORARY_SELF_EXCLUSION",
    RGS_NAR_EXCLUSION = "RGS_NAR_EXCLUSION",
    RGS_NAR_UNDETERMINED = "RGS_NAR_UNDETERMINED",
    RGS_DEPOSIT_LIMIT_NOT_SET = "RGS_DEPOSIT_LIMIT_NOT_SET",
    RGS_HIGH_DEPOSIT_LIMIT = "RGS_HIGH_DEPOSIT_LIMIT",
    RGS_NETLOSS_LIMIT_EXCEEDED = "RGS_NETLOSS_LIMIT_EXCEEDED",
    RGS_NETLOSS_SERVER_ERROR = "RGS_NETLOSS_SERVER_ERROR",
    RGS_KYC_QUESTIONNAIRE_NOT_SUBMITTED = "RGS_KYC_QUESTIONNAIRE_NOT_SUBMITTED",
    RGS_CASINO_LOSS_LIMIT_NOT_SET = "RGS_CASINO_LOSS_LIMIT_NOT_SET",
    RGS_CASINO_HIGH_LOSS_LIMIT = "RGS_CASINO_HIGH_LOSS_LIMIT",
    RGS_CASINO_LOSS_LIMIT_EXCEEDED = "RGS_CASINO_LOSS_LIMIT_EXCEEDED",
    RGS_CASINO_PLAY_TIME_LIMIT_EXCEEDED = "RGS_CASINO_PLAY_TIME_LIMIT_EXCEEDED",
    RGS_CASINO_PLAY_TIME_LIMIT_NOT_SET = "RGS_CASINO_PLAY_TIME_LIMIT_NOT_SET",
    RGS_CASINO_FULL_PLAY_LIMIT_NOT_SET = "RGS_CASINO_FULL_PLAY_LIMIT_NOT_SET",
    RGS_CALL_FAILED = "RGS_CALL_FAILED",
    ALREADY_ACTIVATED = "ALREADY_ACTIVATED",
    INCORRECT_AMOUNT_OF_SHARES = "INCORRECT_AMOUNT_OF_SHARES",
    WRONG_GAME_TYPE = "WRONG_GAME_TYPE",
    INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
    GENERAL_ERROR = "GENERAL_ERROR",
    NOT_FOUND = "NOT_FOUND",
    LACKS_ROLE = "LACKS_ROLE",
    ACCESS_DENIED = "ACCESS_DENIED",
    INPUT_CONSTRAINT_VIOLATION = "INPUT_CONSTRAINT_VIOLATION",
    PURCHASE_CLOSED = "PURCHASE_CLOSED",
    NOT_MEMBER_OF_TEAM = "NOT_MEMBER_OF_TEAM",
    ALREADY_PURCHASED = "ALREADY_PURCHASED",
    TOTAL_MAX_SOLD_SHARES_EXCEEDED = "TOTAL_MAX_SOLD_SHARES_EXCEEDED",
    MAX_NUMBER_SHARES_PER_MEMBER_EXCEEDED = "MAX_NUMBER_SHARES_PER_MEMBER_EXCEEDED",
    NOT_ENOUGH_SHARES_FOR_SALE = "NOT_ENOUGH_SHARES_FOR_SALE",
    BET_ALREADY_PLACED = "BET_ALREADY_PLACED",
    CLOSED = "CLOSED",
}
