import {css} from "@emotion/react";
import {atgWhite} from "@atg-tillsammans-shared/css/overridableVariables";
import {colors} from "@atg-tillsammans-shared/css";

const container = (size: number) => css`
    position: relative;
    height: ${size}px;
`;

const onlineMarkerCircleWrapper = css`
    position: absolute;
    right: 0;
    top: 1px;
    z-index: 1;
`;

const onlineMarker = css`
    background-color: ${colors.status.online.background};
    border-radius: 50%;
    height: 8px;
    width: 8px;
    box-shadow: 0 0 0 2px ${atgWhite};
`;

const styles = {
    container,
    onlineMarkerCircleWrapper,
    onlineMarker,
};

export default styles;
