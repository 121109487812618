import * as React from "react";
import Linkify from "linkify-react";
import {Typography} from "atg-ui-components";
import browserHistory from "atg-history";
import type {Member} from "../../domain/teamChat";
import ChatAvatar from "../TeamChatAvatar";
import * as styles from "./ChatNotification.styles";

export type Props = {
    text: string;
    title: string;
    user?: Member;
    link?: string;
};

function ChatNotification({text, title, user, link}: Props) {
    return (
        <div
            data-test-id="notification-chat-root"
            css={styles.container}
            onClick={link ? () => browserHistory.push(link) : undefined}
        >
            <div css={styles.aside}>
                {user && (
                    <ChatAvatar
                        imageUrl={user.imageUrl ?? null}
                        alt={`${user.firstName} ${user.lastName}`}
                        online
                        showTooltip
                    />
                )}
            </div>
            <div css={styles.main}>
                {user && (
                    <Typography
                        data-test-id="notification-chat-title"
                        variant="headline3"
                        component="span"
                        color="inherit"
                        style={styles.title}
                    >
                        {title}
                    </Typography>
                )}
                <Typography
                    data-test-id="notification-chat-text"
                    variant="body2"
                    component="p"
                    gutterBottom
                    color="inherit"
                    ellipsis
                    style={styles.message}
                >
                    <Linkify
                        options={{
                            validate: (value: string) => !value.startsWith("//"),
                            target: "_blank",
                        }}
                    >
                        {text}
                    </Linkify>
                </Typography>
            </div>
        </div>
    );
}

export default ChatNotification;
