import root from "window-or-global";
import type {ClientConfig} from "@atg-shared/client-config";

export const getClientConfig = (): ClientConfig => root.clientConfig as ClientConfig;

export const getQuickSearchConfig = (): {enabled: boolean; surveyId: string} => {
    const defaultValue = {enabled: false, surveyId: ""};
    return (root.clientConfig as ClientConfig)?.tillsammans?.quicksearch ?? defaultValue;
};

/**
 * Return the configured atgse url.
 *
 * @returns atgse url without a trailing '/'
 */
export const getAtgseUrl = (): string => {
    const baseUrl = root?.clientConfig?.atgseURL ?? "/";
    if (!baseUrl) {
        return "";
    }
    return baseUrl.slice(-1) === "/" ? baseUrl.slice(0, -1) : baseUrl;
};
/**
 * Return the configured tillsammans home path in atgse.
 *
 * @returns tillsammans home path
 */
export const getTillsammansHomePath = (): string => `${getAtgseUrl()}/tillsammans`;

/**
 * Return the configured tillsammans url.
 *
 * @returns tillsammans url without a trailing '/'
 * @deprecated use getTillsammansHomePath instead
 */
export const getTillsammansUrl = (): string => {
    const baseUrl = "/";
    return baseUrl.slice(-1) === "/" ? baseUrl.slice(0, -1) : baseUrl;
};
