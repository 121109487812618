import root from "window-or-global";
import Features, {newImageHandler} from "@atg-shared/client-features";

export type ImagesConfig = {
    aws: {
        imagesCloudfrontUrl: string;
        imagesCloudfrontUrlV2?: string;
        staticImagesCloudfrontUrl: string;
    };
};

const getImagesCloudfrontUrl = (
    useNewImageHandler: boolean = Features.isEnabled(newImageHandler),
): string => {
    const {imagesCloudfrontUrl, imagesCloudfrontUrlV2} = (
        root.clientConfig as unknown as ImagesConfig
    ).aws;
    return useNewImageHandler && imagesCloudfrontUrlV2
        ? imagesCloudfrontUrlV2
        : imagesCloudfrontUrl;
};

const getStaticImagesCloudfrontUrl = (): string =>
    (root.clientConfig as unknown as ImagesConfig).aws.staticImagesCloudfrontUrl;

export {getImagesCloudfrontUrl, getStaticImagesCloudfrontUrl};
