import {broadcastAction, frameAction} from "atg-store-addons";
import type {
    FetchAction,
    ReceiveAction,
    ReceiveErrorAction,
} from "@atg-shared/fetch-types";
import {FETCH, call} from "@atg-shared/fetch-redux";
import type {
    ACR_USERNAME_PASSWORD,
    ACR_BANKID,
    ACR_FREJA_EID,
    AZP_ATGSE,
    ACR_BANKID_QR,
    ACR_FREJA_EID_QR,
} from "./accessTokenConstants";
import {
    REQUEST_NEW_ACCESS_TOKEN,
    RECEIVE_NEW_ACCESS_TOKEN,
    FINISHED_AUTHENTICATION,
    ACCESS_TOKEN_SUCCESS,
    ACCESS_TOKEN_ERROR,
    RESET_ACCESS_TOKEN,
    AUTHENTICATION_SUCCESS,
    AUTHENTICATION_ERROR,
    RECEIVE_REDUCED_ACCESS_TOKEN,
    SET_ACCESS_TOKEN_TIMESTAMP,
} from "./accessTokenConstants";
import {getAccessToken} from "./authApi";

export type ACR =
    | typeof ACR_USERNAME_PASSWORD
    | typeof ACR_BANKID
    | typeof ACR_FREJA_EID
    | typeof ACR_BANKID_QR
    | typeof ACR_FREJA_EID_QR;
export type AZP = typeof AZP_ATGSE | string;

// https://github.com/atgse/atg-authentication-curity-plugins/blob/v0.51.0/atg-authentication-action-create-account/src/main/java/se/atg/identityserver/plugin/createaccount/authenticationaction/CreateAccountAuthenticationAction.java
type MemberFlow =
    | "ALREADY_A_MEMBER_IN_CREATE_ACCOUNT_FLOW"
    | "ALREADY_A_MEMBER_IN_LOGIN_FLOW"
    | "NEW_MEMBER_FROM_CREATE_ACCOUNT_FLOW"
    | "NEW_MEMBER_FROM_LOGIN_FLOW";

export type IDToken = {
    acr: ACR;
    // eslint-disable-next-line camelcase
    at_hash: string;
    aud: string;
    // eslint-disable-next-line camelcase
    auth_time: number;
    azp: AZP; // "atg", "tillsammans"
    exp: number;
    iat: number;
    iss: string; // "https://iam.test1.hh.atg.se/~"
    jti: string; // "59ffb1df-a529-4d75-b18a-9ba381f32a72"
    nbf: number; // 1541603954
    purpose: string; // "id"
    sub: string; // "MaRdaH830"
    memberFlow?: MemberFlow;
    hideBalance: boolean;
};

export const REQUEST_ACCESS_TOKEN = "access-token/REQUEST_ACCESS_TOKEN";

type RequestAccessTokenAction = {
    type: typeof REQUEST_ACCESS_TOKEN;
};

export type AccessTokenPayload = {
    accessToken: string;
    authState?: string;
};

export type SetTimestampPayload = {
    timestamp: number;
};
export type AuthenticationResponse = {
    idToken: IDToken;
} & AccessTokenPayload;

export type AccessTokenResponse = {
    type: typeof RECEIVE_NEW_ACCESS_TOKEN;
    payload: AccessTokenPayload;
};

export type AccessTokenFailMeta = {
    code: number;
    statusText: string;
};

export type FetchNewAccessTokenAction = FetchAction<
    typeof REQUEST_NEW_ACCESS_TOKEN,
    typeof RECEIVE_NEW_ACCESS_TOKEN,
    AccessTokenPayload,
    any
>;

export type ReceiveNewAccessTokenAction =
    | ReceiveAction<typeof RECEIVE_NEW_ACCESS_TOKEN, AccessTokenPayload, any>
    | ReceiveErrorAction<typeof RECEIVE_NEW_ACCESS_TOKEN, any>;

type ResetAccessTokenAction = {
    type: typeof RESET_ACCESS_TOKEN;
};

export type AccessTokenSuccessAction = {
    type: typeof ACCESS_TOKEN_SUCCESS;
};

export type AccessTokenErrorAction = {
    type: typeof ACCESS_TOKEN_ERROR;
};

export type SetTimestamp = {
    type: typeof SET_ACCESS_TOKEN_TIMESTAMP;
    payload: {
        timestamp: number;
    };
};

export type AuthenticationSuccessAction = {
    type: typeof AUTHENTICATION_SUCCESS;
    payload: AuthenticationResponse;
};

export type ReceiveReducedAccessTokenAction = {
    type: typeof RECEIVE_REDUCED_ACCESS_TOKEN;
    payload: AccessTokenPayload;
};

export type AuthenticationErrorAction = {
    type: typeof AUTHENTICATION_ERROR;
};

export type Action =
    | FetchNewAccessTokenAction
    | ResetAccessTokenAction
    | AuthenticationSuccessAction
    | ReceiveReducedAccessTokenAction
    | SetTimestamp;

export const authenticationSuccess = (
    accessToken: string,
    idToken: IDToken,
): AuthenticationSuccessAction =>
    frameAction({
        type: AUTHENTICATION_SUCCESS,
        payload: {
            accessToken,
            idToken,
        },
    });

export const authenticationError = (): AuthenticationErrorAction => ({
    type: AUTHENTICATION_ERROR,
});

export const resetAccessToken = (): ResetAccessTokenAction => ({
    type: RESET_ACCESS_TOKEN,
});

export const fetchNewAccessToken = (): FetchNewAccessTokenAction => ({
    type: FETCH,
    payload: {
        requestAction: REQUEST_NEW_ACCESS_TOKEN,
        receiveAction: RECEIVE_NEW_ACCESS_TOKEN,
        callApi: call(getAccessToken),
    },
});

export const requestAccessToken = (
    shouldTriggerLogin = false,
): RequestAccessTokenAction =>
    frameAction({
        type: REQUEST_ACCESS_TOKEN,
        payload: {shouldTriggerLogin},
    });

export const finishedAuthentication = () => ({
    type: FINISHED_AUTHENTICATION,
});

export const accessTokenSuccess = (): AccessTokenSuccessAction =>
    broadcastAction({
        type: ACCESS_TOKEN_SUCCESS,
    });

export const accessTokenError = (): AccessTokenErrorAction =>
    broadcastAction({
        type: ACCESS_TOKEN_ERROR,
    });

export const reduceAccessToken = (
    payload: AccessTokenPayload,
): ReceiveReducedAccessTokenAction => ({
    type: RECEIVE_REDUCED_ACCESS_TOKEN,
    payload,
});

export const setTimestamp = (payload: SetTimestampPayload): SetTimestamp => ({
    type: SET_ACCESS_TOKEN_TIMESTAMP,
    payload,
});
