import type {Store, Action} from "redux";
import type {Saga, SagaMiddleware} from "redux-saga";

// Based on https://gist.github.com/cvbuelow/ea7bd4deb7824fdb65d81cd2b8c60f5e

type LazySagaAddOn = {
    lazySagas: Set<() => void>;
    sagaMiddleware: SagaMiddleware;
};

export type LazySagaStore<S, A extends Action, St = Store<S, A>> = St & LazySagaAddOn;

export const initLazySagaStore = <S, A extends Action, T = unknown>(
    store: T,
    sagaMiddleware: SagaMiddleware,
): LazySagaStore<S, A, T> => ({
    ...store,
    lazySagas: new Set(),
    sagaMiddleware,
});

/**
 * Run a saga on the passed in store
 *
 * This is similar to `yield spawn()`, with two important differences:
 * 1. the function can be called from outside of a running saga (useful for lazy-loading sagas)
 * 2. the same saga will never be run more than once (singleton)
 */
export const runLazySaga = <S, A extends Action, Sa extends Saga>(
    store: LazySagaStore<S, A, Store<S, A>>,
    asyncSaga: Sa,
    ...args: Parameters<Sa>
) => {
    // singleton logic – never run the same saga more than once
    // (the same saga could be reused in different places on the site, and if the user visits the
    // different places we don't want too end up having the same saga running several times)
    if (store.lazySagas.has(asyncSaga)) return;
    store.lazySagas.add(asyncSaga);

    store.sagaMiddleware.run(asyncSaga, ...args);
};
