import dayjs from "dayjs";
import root from "window-or-global";
import atgRequest from "@atg-shared/fetch";
import {INFO_SERVICE_URL} from "@atg-shared/service-url";
import ServerTimeZone from "./serverTimeZone";

declare global {
    // eslint-disable-next-line vars-on-top, no-var, no-underscore-dangle
    var __serverTimeOffset: number | undefined;
}

const getPreliminaryOffset = () => {
    const localUtcOffset = -dayjs().utcOffset() * 60 * 1000;
    const serverUtcOffset = ServerTimeZone.utcOffset(dayjs()) * 60 * 1000;

    return serverUtcOffset - localUtcOffset;
};

const parseServerInfo = (time: string) => {
    const localTime = dayjs();
    const serverTime = dayjs(time);

    return Number(localTime) - Number(serverTime);
};

export const update = async (baseUrl = "", fetchFn: typeof atgRequest = atgRequest) => {
    const response = await fetchFn<{time: string}>(
        `${baseUrl}${INFO_SERVICE_URL}/server`,
    );
    root.__serverTimeOffset = parseServerInfo(response.data.time);
};

export const getServerTimeOffset = () => {
    // to avoid side effects in import stage, we get preliminary offset on demand if serverTimeOffset is undefined
    if (!root.__serverTimeOffset) {
        root.__serverTimeOffset = getPreliminaryOffset();
    }
    return root.__serverTimeOffset;
};
