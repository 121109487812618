import {isObject} from "lodash";
import root from "window-or-global";
import {getSystemName} from "@atg-shared/system";

export const LOCAL = "local";
export const SESSION = "session";
type StorageType = typeof LOCAL | typeof SESSION;

export interface Storage {
    setItem(key: string, value: string): void;
    getItem(key: string): string | null;
    removeItem(key: string): void;
}

const fallbackStorage = {
    local: {},
    session: {},
};

const isLocalStorageNameSupported = () => {
    const testKey = "test";

    try {
        const storage = root.localStorage;
        storage.setItem(testKey, "1");
        storage.removeItem(testKey);
        return true;
    } catch (error: unknown) {
        return false;
    }
};

export const reset = () => {
    const sessionStorage = isLocalStorageNameSupported()
        ? root.sessionStorage
        : root.fallbackStorage.session;
    const localStorage = isLocalStorageNameSupported()
        ? root.localStorage
        : root.fallbackStorage.local;

    const sessions = Object.keys(sessionStorage);
    const locals = Object.keys(localStorage);

    sessions.forEach((session) => removeItem(session, SESSION));
    locals.forEach((local) => removeItem(local, LOCAL));
};

export const getItem = <T = string>(
    key: string,
    storageType: StorageType = LOCAL,
): T | null => {
    if (isLocalStorageNameSupported()) {
        return root[`${storageType}Storage`].getItem(key) as unknown as T;
    }

    const fallbackStorageValue = root.fallbackStorage[storageType][key] as T;
    return fallbackStorageValue === undefined ? null : fallbackStorageValue;
};

export const setItem = (
    key: string,
    item: string,
    storageType: StorageType = LOCAL,
): void => {
    if (process.env.NODE_ENV === "development") {
        if (!key || key === "undefined") {
            throw new Error(
                "Something is trying to write 'undefined' to storage. Find it and fix it, pretty please",
            );
        }
    }

    if (isLocalStorageNameSupported()) {
        root[`${storageType}Storage`].setItem(key, item);

        if (getSystemName() !== "ATGApp") {
            // Allows StorageEvent to be dispatched within the
            // same document it was dispatched from, although only
            // with information about the entry that was changed
            root.dispatchEvent(new StorageEvent("storage", {key, newValue: item}));
        }
        return;
    }

    root.fallbackStorage[storageType][key] = item;
};

export const removeItem = (key: string, storageType: StorageType = LOCAL): void => {
    if (isLocalStorageNameSupported()) {
        root[`${storageType}Storage`].removeItem(key);
        return;
    }

    delete root.fallbackStorage[storageType][key];
};

export const putObject = (
    key: string,
    item: unknown,
    storageType: StorageType = LOCAL,
): void => {
    if (!isObject(item)) {
        throw new Error("Trying to store an non-object in local storage");
    }

    setItem(key, JSON.stringify(item), storageType);
};

export const getObject = <T = Record<string, unknown>>(
    key: string,
    storageType: StorageType = LOCAL,
): T | null => {
    const item = getItem(key, storageType);

    if (!item) return null;

    try {
        return JSON.parse(item);
    } catch (ex: unknown) {
        throw new Error("Stored item is not valid JSON");
    }
};

export const setObject = (
    key: string,
    item: unknown,
    storageType: StorageType = LOCAL,
): void => {
    if (!isObject(item)) {
        throw new Error("Trying to store an non-object in local storage");
    }

    const oldItem = getObject(key, storageType);

    setItem(
        key,
        JSON.stringify({
            ...oldItem,
            ...item,
        }),
        storageType,
    );
};

export const filterByKey = (
    searchKey: string | RegExp,
    storageType: StorageType = LOCAL,
) => {
    const storageItems = !isLocalStorageNameSupported()
        ? root.fallbackStorage[storageType]
        : root[`${storageType}Storage`];

    return Object.keys(storageItems)
        .filter((key) => key.match(searchKey))
        .reduce((acc, key) => {
            acc[key] = storageItems[key];
            return acc;
        }, {} as Record<string, unknown>);
};

if (typeof root !== "undefined") root.fallbackStorage = fallbackStorage;

export const subscribe = (callback: (e: StorageEvent) => void, key?: string) => {
    const maybeCallback = (e: StorageEvent) => {
        if (!key) {
            // trigger callback unconditionally if no key was specified
            callback(e);
        } else if (key === e.key) {
            // trigger callback only when the key on the event matches the specified key
            callback(e);
        }
    };

    root.addEventListener("storage", maybeCallback);

    const unsubscribe = () => {
        root.removeEventListener("storage", maybeCallback);
    };

    return unsubscribe;
};
