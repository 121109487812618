export const GAME_FETCH = "game/FETCH";

export const GAME_START_PUSH_LISTENER = "game/START_PUSH_LISTENER";
export const GAME_STOP_PUSH_LISTENER = "game/STOP_PUSH_LISTENER";

export type GameFetchAction = {
    type: typeof GAME_FETCH;
    payload: {
        gameId: string;
        forceFetch: boolean;
    };
};

export type GameStartPushListenerAction = {
    type: typeof GAME_START_PUSH_LISTENER;
    payload: {
        gameId: string;
    };
};

export type GameStopPushListenerAction = {
    type: typeof GAME_STOP_PUSH_LISTENER;
};

export type Action =
    | GameFetchAction
    | GameStartPushListenerAction
    | GameStopPushListenerAction;

export const fetchGame = (gameId: string, forceFetch = false): GameFetchAction => ({
    type: GAME_FETCH,
    payload: {gameId, forceFetch},
});

export const startPushListener = (gameId: string): GameStartPushListenerAction => ({
    type: GAME_START_PUSH_LISTENER,
    payload: {gameId},
});

export const stopPushListener = (): GameStopPushListenerAction => ({
    type: GAME_STOP_PUSH_LISTENER,
});
