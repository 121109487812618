import * as React from "react";
import {isEmpty, isEqual, find} from "lodash";
import {usePrevious} from "@react-hookz/web";
import {makeVar, useReactiveVar} from "@apollo/client";
import {parseDateTimestamp} from "@atg-shared/datetime";
import type {HorseCoupon as HorseCouponType} from "@atg-tillsammans/types/generated";
import type {TeamID} from "@atg-tillsammans/types";
import type HorseCoupon from "../domain/HorseCoupon.class";

type HookParams = {
    coupons: HorseCoupon[];
    handleAddCoupon: (
        teamId: string,
        gameId: string,
        couponData: HorseCouponType,
    ) => void;
};

export enum HorseCouponPushEvent {
    UPDATE = "UPDATE",
    REMOVE = "REMOVE",
}
// Varaible that holds the pushed coupon data
export const couponPushDataVar = makeVar<{
    [couponId: string]: {
        event: HorseCouponPushEvent;
        teamId: TeamID;
        gameId: string;
        coupon?: HorseCouponType;
    };
}>({});

export const setHorseCouponPushData = (
    couponId: string,
    event: HorseCouponPushEvent,
    teamId: TeamID,
    gameId: string,
    coupon?: HorseCouponType,
) =>
    couponPushDataVar({
        ...couponPushDataVar(),
        [couponId]: {
            event,
            teamId,
            gameId,
            coupon,
        },
    });

export const getCouponPushData = (couponId: string) =>
    couponPushDataVar()[couponId] || null;
const findCouponById = (coupons: HorseCoupon[], couponId: string) =>
    find(coupons, (coupon) => coupon.id === couponId);
export default function useHorseCouponPush({coupons, handleAddCoupon}: HookParams) {
    const couponPushData = useReactiveVar(couponPushDataVar);

    const prevCouponPushData = usePrevious(couponPushData);

    React.useEffect(() => {
        if (
            !prevCouponPushData ||
            isEmpty(couponPushData) ||
            isEqual(prevCouponPushData, couponPushData)
        ) {
            return;
        }
        Object.keys(couponPushData).forEach((pushedCouponId) => {
            const pushData = couponPushData[pushedCouponId];
            if (!pushData) return;
            const coupon = findCouponById(coupons, pushedCouponId);
            // Handle remove event
            if (coupon && pushData.event === HorseCouponPushEvent.REMOVE) {
                coupon.removeLocally();
                return;
            }
            // Handle update
            if (
                coupon &&
                pushData.event === HorseCouponPushEvent.UPDATE &&
                pushData.coupon?.modified &&
                parseDateTimestamp(pushData.coupon.modified).isAfter(coupon.modified)
            ) {
                coupon.setData(pushData.coupon);
            }
            // Handle add
            if (
                !coupon &&
                pushData.event === HorseCouponPushEvent.UPDATE &&
                pushData.coupon
            ) {
                handleAddCoupon(
                    pushData.teamId.toString(),
                    pushData.gameId,
                    pushData.coupon,
                );
            }
        });
    }, [coupons, prevCouponPushData, couponPushData, handleAddCoupon]);
}
