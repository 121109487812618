import type {Reducer} from "redux";
import {omit} from "lodash";
import type {BetState} from "../betTypes";
import {getBetError} from "../domain/betErrorMessages";
import * as BetActions from "./betActions";

const betReducer: Reducer<BetState, BetActions.Action> = (
    state: BetState = {},
    action: BetActions.Action,
) => {
    switch (action.type) {
        case BetActions.BET_SUCCESS:
            return {
                ...state,
                [action.payload.couponId]: {
                    success: true,
                    ...action.payload,
                },
            };
        case BetActions.BET_ERROR:
            return {
                ...state,
                [action.payload.couponId]: {
                    success: false,
                    couponId: action.payload.couponId,
                    error: getBetError(action.payload.code),
                },
            };
        case BetActions.BET_RESET: {
            const {couponIds} = action.payload;
            return omit(
                state,
                couponIds.map((couponId) => couponId),
            );
        }
        default:
            return state;
    }
};

export default betReducer;
