import {reduce} from "lodash/fp";
import type {FetchState} from "@atg-shared/fetch-types";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import type {Action} from "./jackpotActions";
import {
    REQUEST_SPORT_JACKPOT,
    RECEIVE_SPORT_JACKPOT,
    RESET_JACKPOT,
} from "./jackpotActions";

/**
 * Contains the jackpot amount per game.
 *
 * NOTE: As mentioned in README.md, "jackpot" in the scope of this package refers to **estimated
 * turnover *PLUS* carry-over money** from previous games.
 *
 * @todo clarify if amount is in kronor or öre
 */
interface JackpotState {
    [gameId: string]: number;
}

export type State = {
    jackpot: FetchState<JackpotState>;
};

const jackpotReducer = createFetchReducer(
    REQUEST_SPORT_JACKPOT,
    RECEIVE_SPORT_JACKPOT,
    RESET_JACKPOT,
    (state: JackpotState, action: Action) => {
        switch (action.type) {
            case RECEIVE_SPORT_JACKPOT: {
                if (!action.error && action.payload) {
                    return reduce(
                        (newState, offering) => {
                            if (offering.pool.expectedJackpot > 0) {
                                return {
                                    ...newState,
                                    [offering.id]: offering.pool.expectedJackpot,
                                };
                            }
                            return {...newState};
                        },
                        state,
                        action.payload,
                    );
                }
                return state;
            }
            default:
                return state;
        }
    },
    {},
);

export default jackpotReducer;
