import * as React from "react";
import {t} from "@lingui/macro";
import {Paths} from "@atg-tillsammans-shared/navigation";
import {getWeekDay, parseGameType} from "@atg-tillsammans-shared/utils";
import ChatAvatar from "@atg-tillsammans/chat/components/TeamChatAvatar";
import type {Round} from "@atg-tillsammans/types/generated";
import {Typography} from "atg-ui-components";
import browserHistory from "atg-history";
import {capitalized} from "../domain/tillsammansPushUtil";
import * as styles from "./RoundActivatedNotification.styles";

export type Props = {
    round: Round;
    teamName: string;
    numberOfShares: number;
};

function RoundActivatedNotification({round, teamName, numberOfShares}: Props) {
    const {gameInitiator} = round;
    return (
        <div
            data-test-id="notification-activatedround-root"
            css={styles.container}
            onClick={() =>
                browserHistory.push(Paths.getTeamGamePagePath(round.teamId, round.gameId))
            }
        >
            <div css={styles.aside}>
                {gameInitiator && (
                    <ChatAvatar
                        imageUrl={gameInitiator.imageUrl ?? null}
                        alt={`${gameInitiator.firstName} ${gameInitiator.lastName}`}
                        online
                        showTooltip
                    />
                )}
            </div>
            <div css={styles.main}>
                {gameInitiator && (
                    <Typography
                        data-test-id="notification-activatedround-title"
                        variant="headline3"
                        component="span"
                        color="inherit"
                        style={styles.title}
                    >
                        {t({
                            id: "push.notification.activatedround.title",
                            message: `${capitalized(
                                `${gameInitiator.firstName} ${gameInitiator.lastName}`,
                            )} har öppnat ${getWeekDay(round.gameId)}ens ${parseGameType(
                                round.gameId,
                            )} i ${teamName}! ${
                                numberOfShares > 0
                                    ? `Du deltar med ${numberOfShares} andel${
                                          numberOfShares > 1 ? "ar" : ""
                                      }.`
                                    : ""
                            }`,
                        })}
                    </Typography>
                )}
            </div>
        </div>
    );
}

export default RoundActivatedNotification;
