import {css} from "@emotion/react";

const container = ({
    size,
    background,
    rounded,
    shadow,
}: {
    size: number;
    background: string;
    rounded?: boolean;
    shadow?: boolean;
}) => css`
    width: ${size}px;
    height: ${size}px;

    ${rounded &&
    css`
        border-radius: 50%;
    `};

    ${background &&
    background !== "none" &&
    css`
        img {
            background-color: ${background};
        }
    `};

    ${shadow &&
    css`
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    `};
`;

const styles = {
    container,
};

export default styles;
