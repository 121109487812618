import {colors, fonts, receipt, search, siteBackground, view, mediaQueries} from "./src";

/**
 * Commonly used legacy constans for styling.
 *
 * Original source for these are : packages/ui/atg-ui/css/overridableVariables.styles.js
 */

export const atgWhite = colors.common.white;
export const atgBlack = colors.common.black;
/** Game colors */
export const atgV75Primary = colors.gameType.V75.default;
export const atgV75Light = colors.gameType.V75.subtle;
export const atgV75Dark = colors.gameType.V75.strong;
export const atgV86Primary = colors.gameType.V86.default;
export const atgV86Light = colors.gameType.V86.subtle;
export const atgV64Primary = colors.gameType.V64.default;
export const atgV64Light = colors.gameType.V64.subtle;
export const atgV64Dark = colors.gameType.V64.strong;
export const atgV65Primary = colors.gameType.V65.default;
export const atgV65Light = colors.gameType.V65.subtle;
export const atgOtherPrimary = colors.gameType.assorted.default;
/** Grayscale colors */
export const atgGrayscale150 = colors.common.grayscale150;
export const atgGrayscale130 = colors.common.grayscale130;
// export const atgGrayscale100 = colors.common.grayscale100; // also atgGreyNo3
export const atgGrayscale080 = colors.common.grayscale080; // also atgGreyNo4
export const atgGrayscale070 = colors.common.grayscale070;
export const atgGrayscale060 = colors.common.grayscale060; // also atgGreyNo9
export const atgGrayscale050 = colors.common.grayscale050;
export const atgGrayscale040 = colors.common.grayscale040; // also atgGreyNo6
export const atgGrayscale030 = colors.common.grayscale030;
export const atgGrayscale020 = colors.common.grayscale020;
export const atgGrayscale010 = colors.common.grayscale010; // also atgGreyNo13
export const atgGrayscale005 = colors.common.grayscale005;
/** Text colors */
// export const textPrimary = colors.text.primary;
// export const textSecondary = colors.text.secondary;
export const textLinkPrimary = colors.link.primary;
export const textLinkSecondary = colors.link.secondary;
export const textLinkHoverState = colors.link.hover;
// export const textError = colors.text.error;
// export const textSuccess = colors.text.success;
/** Font constants */
export const fontFamilyText = fonts.default.fontFamily;
export const fontFamilyBrand = fonts.brand.fontFamily;
/** Brand colors */
// export const atgBlue = colors.brand.default;
export const atgGreyNo2 = colors.legacy.gray02;
export const atgGreyNo5 = colors.legacy.gray05;
export const atgGreyNo7 = colors.legacy.gray07;
export const atgGreyNo8 = colors.legacy.gray08;
export const atgGrey666 = colors.legacy.gray666;
export const atgGrey999 = colors.legacy.gray999;
export const atgGreyEee = colors.legacy.grayEEE;
export const atgGreyF5f5f5 = colors.legacy.greyF5F5F5;
export const atgGreen = colors.legacy.green;
export const atgRed = colors.legacy.red;
export const atgBrandYellow = colors.brand.accent;
/** Receipt constants */
export const receiptBorderColor = receipt.borderColor;
export const receiptButtonTextColor = receipt.button.textColor;
/** Search input constants */
export const inputBorderColor = search.input.borderColor;
export const {
    gradientStart: btnDefaultGradientStart,
    gradientEnd: btnDefaultGradientEnd,
    gradientHoverStart: btnDefaultGradientHoverStart,
    gradientHoverEnd: btnDefaultGradientHoverEnd,
} = search.button.default;
/** Background colors */
export const atgGreyBg = colors.background.gray;
export const atgBlueBodyBg = siteBackground;
/** Grading colors */
export const gradingCorrectGreen = colors.grading.correct.green;
/** CMS alert colors */
export const {
    success: cmsAlertSuccessColor,
    info: cmsAlertInfoColor,
    warning: cmsAlertWarningColor,
    danger: cmsAlertDangerColor,
} = colors.cms.alert;
/** Media queries */
export const {legacy_xxs: xxs, legacy_xs: xs, sm, md, lg, xl} = mediaQueries.minWidth;
export const {xs: deprecated_xsOnly} = mediaQueries.maxWidth;
/** View constants */
export const {default: fullViewHightWithoutNav, sm: fullViewHightWithoutNavSm} =
    view.fullHightWithoutNav;
