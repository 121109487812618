import type {GlobalGameState, Game, GameSummary} from "../gameTypes";
import * as GameUtils from "../gameUtils";

export const getGameById = (state: GlobalGameState, gameId: string): Game | null =>
    state.game.horse.games[gameId] || state.game.sport.games[gameId] || null;

export const getGameSummaryById = (
    state: GlobalGameState,
    gameId: string,
): GameSummary | null => {
    const game = getGameById(state, gameId);
    return game ? GameUtils.mapGameToGameSummary(game) : null;
};
