import * as React from "react";
import type {ImageUrl} from "atg-ui-image/domain/image";
import MemberAvatar from "atg-ui-image/components/MemberAvatar";
import styles from "./TeamChatAvatar.styles";

export type Props = {
    imageUrl: ImageUrl | null;
    size?: number;
    online?: boolean;
    alt?: string;
    showTooltip?: boolean;
};

function OnlineIndicator() {
    return (
        <div css={styles.onlineMarkerCircleWrapper}>
            <div css={styles.onlineMarker} />
        </div>
    );
}

function TeamChatAvatar({
    imageUrl,
    size = 40,
    online = false,
    alt,
    showTooltip = false,
}: Props) {
    return (
        <div css={styles.container(size)}>
            {online && <OnlineIndicator />}
            <MemberAvatar
                imageUrl={imageUrl}
                alt={alt}
                size={size}
                rounded
                showTooltip={showTooltip}
            />
        </div>
    );
}

export default TeamChatAvatar;
