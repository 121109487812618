import type {MatchResult, MatchNumber, Team, Match, MatchRef} from "./matchTypes";

export enum OfferingStatus {
    UPCOMING = "UPCOMING",
    SELL_OPEN = "SELL_OPEN",
    SELL_CLOSED = "SELL_CLOSED",
    DECIDED = "DECIDED",
    UNDER_REVIEW = "UNDER_REVIEW",
    GRADING = "GRADING",
    REVOKING_GRADING = "REVOKING_GRADING",
    CANCELED = "CANCELED",
    GRADED = "GRADED",
    FINALIZED = "FINALIZED",
}

export type MostPlayed = {
    winners: {
        percentage: number;
        selection: MatchResult["winner"];
        homeTeam: Team;
        awayTeam: Team;
        matchNumber: MatchNumber;
    }[];
    goals: {
        percentage: number;
        selection: MatchResult["goals"];
        homeTeam: Team;
        awayTeam: Team;
        matchNumber: MatchNumber;
    }[];
    wildcards: {
        percentage: number;
        homeTeam: Team;
        awayTeam: Team;
        matchNumber: MatchNumber;
    }[];
};

export type Winning = {
    systemCount: number;
    payout: number;
    jackpot?: boolean;
};

export type Winnings = {
    [P in Payout]: Winning;
};

export type Payout = "7" | "8" | "9";

export type PoolPayouts =
    | {
          [P in Payout]: {
              pot: number;
              qualifyingUnits: number;
              unitDividend: number;
              jackpot?: boolean;
          };
      };

export type Pool = {
    turnover: number | null;
    payouts?: PoolPayouts | null;
    potDistribution?: {[P in Payout]: number} | null;
    expectedJackpot: number;
    unitStake: number;
    minUnitDividend: number;
    timestamp: string;
    numberOfBets: number;
    numberOfSystems: number;
};

export type Offering = {
    gameType: string;
    id: string;
    sport?: string;
    name: string | null;
    description: string | null;
    startTime: string;
    lastUpdate?: string;
    status: OfferingStatus;
    pool: Pool;
    matches: ReadonlyArray<Match>;
    overUnderLimit: number;
    serialNumber: number;
};

export type OfferingMatch = Match & {
    matchId: string;
    overUnderLimit: number;
    homeTeamName: string;
    awayTeamName: string;
    homeTeamAbbreviation: string;
    awayTeamAbbreviation: string;
};

export type OfferingWithMatches = Omit<Offering, "matches"> & {
    matches: ReadonlyArray<OfferingMatch>;
};

export type OfferingWithMatchRefs = Omit<Offering, "matches"> & {
    matches: ReadonlyArray<MatchRef>;
};

export type OfferingsById = {[key: string]: OfferingWithMatchRefs};
