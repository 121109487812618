import type {Match} from "@atg-sport-shared/big9-types/matchTypes";
import type {SportGame} from "../../sportGameTypes";

export enum SportGamePushEvent {
    OFFERING_SELL_CLOSED = "OFFERING_SELL_CLOSED",
    OFFERING_SELL_OPENED = "OFFERING_SELL_OPENED",
    OFFERING_BETAGGREGATION_UPDATED = "OFFERING_BETAGGREGATION_UPDATED",
    OFFERING_SUMMARY_UPDATED = "OFFERING_SUMMARY_UPDATED",
    OFFERING_REVIEW_STARTED = "OFFERING_REVIEW_STARTED",
    OFFERING_DECIDED = "OFFERING_DECIDED",
    OFFERING_FINALIZED = "OFFERING_FINALIZED",
    OFFERING_CANCELED = "OFFERING_CANCELED",
    COMPETITION_STARTED = "COMPETITION_STARTED",
    COMPETITION_START_CANCELED = "COMPETITION_START_CANCELED",
    COMPETITION_RESULT_UPDATED = "COMPETITION_RESULT_UPDATED",
    COMPETITION_DECIDED = "COMPETITION_DECIDED",
    COMPETITION_CANCELED = "COMPETITION_CANCELED",
    PERIOD_STARTED = "PERIOD_STARTED",
    PERIOD_ENDED = "PERIOD_ENDED",
    OVERTIME = "OVERTIME",
}

export type SportGamePushData = {
    requestId: string;
    type: SportGamePushEvent;
    offering?: SportGame;
    competition?: Match;
    matchId?: Match["id"];
    clockMinutes?: number;
    timestamp?: string;
};
