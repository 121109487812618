import {uniqueId} from "lodash";
import {frameAction} from "atg-store-addons";
import type {Toast, ToastWithId} from "./toast";

export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";
export const HIDE_TOASTS = "HIDE_TOASTS";
export const CLEAR_TOASTS = "CLEAR_TOASTS";

export type ShowToastAction = {
    type: typeof SHOW_TOAST;
    payload: {
        toast: ToastWithId;
    };
};

export type HideToastAction = {
    type: typeof HIDE_TOAST;
    payload: {
        toast: ToastWithId;
    };
};

export type HideToastsAction = {
    type: typeof HIDE_TOASTS;
    payload: {
        toasts: ToastWithId[];
    };
};

export type ClearToastsAction = {
    type: typeof CLEAR_TOASTS;
    payload: {
        excludeReasons?: string[];
    };
};

export type ToastAction =
    | ShowToastAction
    | HideToastAction
    | HideToastsAction
    | ClearToastsAction;

export const showToast = (toast: Toast | ToastWithId): ShowToastAction => {
    const id: string = toast.id || uniqueId();
    const toastWithId: ToastWithId = {...toast, id};
    return frameAction({
        type: SHOW_TOAST,
        payload: {toast: toastWithId},
    });
};

export const hideToast = (toast: ToastWithId): HideToastAction =>
    frameAction({
        type: HIDE_TOAST,
        payload: {toast},
    });

export const hideToasts = (toasts: ToastWithId[]): HideToastsAction =>
    frameAction({
        type: HIDE_TOASTS,
        payload: {toasts},
    });

export const clearToasts = (excludeReasons?: string[]): ClearToastsAction =>
    frameAction({
        type: CLEAR_TOASTS,
        payload: {
            excludeReasons,
        },
    });
