import root from "window-or-global";
import type {Logger, LogLevelNumbers} from "loglevel";
import {throttle, take, truncate} from "lodash";
import {now} from "@atg-shared/datetime";
import stringify from "json-stringify-safe";
import fetch from "@atg-shared/fetch";
import {AtgRequestError} from "@atg-shared/fetch-types";
import {TEAM_CLIENT_LOG_SERVICE_URL} from "@atg-shared/service-url";

const LOG_INTERVAL = 10 * 1000;

type LogData = Record<string, unknown>;

type LogEntry = {
    logLevel: string;
    message: string;
    clientTimestamp: string;
    data: LogData;
};

let logEntries: LogEntry[] = [];

function getReadableLogLevel(logLevel: LogLevelNumbers): string {
    switch (logLevel) {
        case 0:
            return "trace";
        case 1:
            return "debug";
        case 2:
            return "info";
        case 3:
            return "warn";
        case 4:
            return "error";
        case 5:
        default:
            return "silent";
    }
}

function formatLogPayload(entries: LogEntry[] = []) {
    return stringify(
        entries.map(({logLevel, clientTimestamp, message, data}) => ({
            category: "tillsammans.client",
            level: logLevel,
            content: stringify({
                url: root.location.href,
                // @ts-expect-error
                clientVersions: root.clientVersion,
                env: root.clientConfig.env,
                clientTimestamp,
                ...(typeof message === "string" ? {message} : message),
                ...(typeof data === "string" ? {data} : data),
            }),
        })),
    );
}

function truncateLogEntries(entries: LogEntry[] = []) {
    return take(
        entries.map(({logLevel, message}) => ({
            level: logLevel,
            message: truncate(message, {length: 30}),
        })),
        4,
    );
}

async function logToServer() {
    if (logEntries.length === 0) return;

    const entriesToPost = logEntries;

    // Empty to log entry array
    logEntries = [];

    try {
        await fetch(TEAM_CLIENT_LOG_SERVICE_URL, {
            method: "POST",
            body: formatLogPayload(entriesToPost),
        });
    } catch (err: unknown) {
        if (!(err instanceof AtgRequestError)) return;

        try {
            await fetch(TEAM_CLIENT_LOG_SERVICE_URL, {
                method: "POST",
                body: formatLogPayload([
                    {
                        logLevel: "error",
                        message: "Error posting logs",
                        clientTimestamp: now().toISOString(),
                        data: {
                            truncatedMessages: truncateLogEntries(entriesToPost),
                            lostLogEntries: entriesToPost.length,
                            response: err.response,
                        },
                    },
                ]),
            });
        } catch (innerErr: unknown) {
            console.error("Failed posting logs", {error: innerErr, entriesToPost});
        }
    }
}

const logToServerThrottled = throttle(logToServer, LOG_INTERVAL, {leading: false});

export function addLogEntry(
    logLevel: LogLevelNumbers,
    message: string,
    data: LogData = {},
) {
    logEntries = [
        ...logEntries,
        {
            logLevel: getReadableLogLevel(logLevel),
            message,
            clientTimestamp: now().toISOString(),
            data,
        },
    ];

    logToServerThrottled();
}

export default function serverLog(log: Logger) {
    const originalFactory = log.methodFactory;

    // eslint-disable-next-line no-param-reassign
    log.methodFactory = (methodName, logLevel, loggerName) => {
        const rawMethod = originalFactory(methodName, logLevel, loggerName);

        return (message, data, ...rest) => {
            rawMethod(message, data, ...rest);

            if (["warn", "error"].includes(methodName)) {
                addLogEntry(logLevel, message, data);
            }
        };
    };
}
