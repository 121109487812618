import Features, {avoidPermanentTillsammansRedirect} from "@atg-shared/client-features";

export const TILLSAMMANS_NAMESPACE = "tillsammans";
export const MY_TEAMS_NAMESPACE = "mina-lag";
export const START_NAMESPACE = "start";

export const TILLSAMMANS_ROOT_PATH = `/${TILLSAMMANS_NAMESPACE}`;
export const TILLSAMMANS_START_PATH = `${TILLSAMMANS_ROOT_PATH}/${START_NAMESPACE}`;
export const TILLSAMMANS_MY_TEAMS_PATH = `${TILLSAMMANS_ROOT_PATH}/${MY_TEAMS_NAMESPACE}`;
export const TILLSAMMANS_SEARCH_TEAMS_PATH = `${TILLSAMMANS_ROOT_PATH}/hittalag`;
export const TILLSAMMANS_CREATE_TEAM_PATH = `${TILLSAMMANS_ROOT_PATH}/skapalag`;

/**
 * Legacy path used in stand alone Tillsammans
 */
export const TILLSAMMANS_OVERVIEW_PATH = "/oversikt";

/**
 * Utility funnctions to grab correct navigation paths could be replaced by above
 * constants when migratoin is finished.
 */

export const getTillsammansBasePath = () => TILLSAMMANS_ROOT_PATH;

export const getTillsammansHomePath = () => {
    if (Features.isEnabled(avoidPermanentTillsammansRedirect)) {
        return TILLSAMMANS_START_PATH;
    }
    return TILLSAMMANS_ROOT_PATH;
};

export const getMyTeamsPath = () => TILLSAMMANS_MY_TEAMS_PATH;

export const getCreateTeamPath = () => TILLSAMMANS_CREATE_TEAM_PATH;

export const getFindTeamPath = () => TILLSAMMANS_SEARCH_TEAMS_PATH;

export const getTeamPagePath = (teamId: string) =>
    `/${TILLSAMMANS_NAMESPACE}/lagsida/${teamId}`;

export const getTeamGamePagePath = (teamId: string, gameId: string) =>
    `${getTeamPagePath(teamId)}/omgang/${gameId}`;

export const getTeamSubpagePath = (teamId: string, pagePath: string) =>
    `${getTeamPagePath(teamId)}/${pagePath}`;

/**
 * Utility functions for figuring out the current page based on a given path
 */
export const isMyTeamsPage = (pathname: string) => pathname === getMyTeamsPath();
export const isFindTeamsPage = (pathname: string) => pathname === getFindTeamPath();
export const isCreateTeamPage = (pathname: string) => pathname === getCreateTeamPath();

export const isTeamBasePage = (pathname: string) =>
    /^(\/utloggad|\/tillsammans|\/tillsammans-new)?\/lagsida\/\d+$/g.test(pathname);

export const isTeamRoundPage = (pathname: string) =>
    /^(\/utloggad|\/tillsammans|\/tillsammans-new)?\/lagsida\/\d+(\/omgang\/)([a-zA-Z0-9_-]+)$/g.test(
        pathname,
    );

export const isTeamSubpagePath = (pathname: string) =>
    /^(\/utloggad|\/tillsammans|\/tillsammans-new)?\/lagsida\/\d+\/(laginstallningar|laginformation|bjud-in|spellaggare|foranmalningar|medlemmar|epost)(\/)?$/g.test(
        pathname,
    );
