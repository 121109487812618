import {fetchAuthorized} from "@atg-shared/auth";
import {
    TEAM_CHAT_SERVICE_URL,
    TEAM_CHAT_PUBLIC_SERVICE_URL,
} from "@atg-shared/service-url";
import * as ChatUtils from "../utils/teamChatUtils";
import type {ChannelId, MessageAuthorType} from "./teamChat";

export const fetchChannelHistory = (channelId: ChannelId, page: number) =>
    fetchAuthorized(
        `${TEAM_CHAT_SERVICE_URL}/channels/${ChatUtils.channelIdToString(
            channelId,
        )}/history?page=${page}`,
        undefined,
        {
            memberFlowEnabled: false,
            fallbackUrl: `${TEAM_CHAT_PUBLIC_SERVICE_URL}/channels/${ChatUtils.channelIdToString(
                channelId,
            )}/history?page=${page}`,
        },
    );

export const fetchMyChannelStats = (channelId: ChannelId) =>
    fetchAuthorized(
        `${TEAM_CHAT_SERVICE_URL}/channels/${ChatUtils.channelIdToString(
            channelId,
        )}/stats`,
        undefined,
        {
            memberFlowEnabled: false,
        },
    );

export const postMessage = (
    channelId: ChannelId,
    text: string,
    authorType: MessageAuthorType,
) =>
    fetchAuthorized(
        `${TEAM_CHAT_SERVICE_URL}/channels/${ChatUtils.channelIdToString(
            channelId,
        )}/comments`,
        {
            method: "POST",
            body: JSON.stringify({
                text,
                authorType,
            }),
        },
    );

export const saveMessage = (
    channelId: ChannelId,
    commentId: number,
    messageText: string,
    authorType: MessageAuthorType,
) =>
    fetchAuthorized(
        `${TEAM_CHAT_SERVICE_URL}/channels/${ChatUtils.channelIdToString(
            channelId,
        )}/comments/${commentId}`,
        {
            method: "PUT",
            body: JSON.stringify({
                text: messageText,
                authorType,
            }),
        },
    );

export const removeMessage = (channelId: ChannelId, commentId: number) =>
    fetchAuthorized(
        `${TEAM_CHAT_SERVICE_URL}/channels/${ChatUtils.channelIdToString(
            channelId,
        )}/comments/${commentId}`,
        {
            method: "DELETE",
        },
    );

export const reportMessage = (channelId: ChannelId, commentId: number, text: string) =>
    fetchAuthorized(
        `${TEAM_CHAT_SERVICE_URL}/channels/${ChatUtils.channelIdToString(
            channelId,
        )}/comments/${commentId}/report`,
        {
            method: "POST",
            body: JSON.stringify({
                text,
            }),
        },
    );

export const blockMember = (channelId: ChannelId, commentId: number) =>
    fetchAuthorized(
        `${TEAM_CHAT_SERVICE_URL}/channels/${ChatUtils.channelIdToString(
            channelId,
        )}/block/${commentId}`,
        {
            method: "POST",
        },
    );
