import type {AtgRequestError} from "./fetch.types";

export const LOADING_STATUS = {
    INITIAL: "initial",
    LOADING: "loading",
    OK: "ok",
    ERROR: "error",
} as const;

export type LoadingStatusInitial = {
    status: typeof LOADING_STATUS.INITIAL;
};

export type LoadingStatusLoading<LoadContext extends Record<string, unknown>> =
    LoadContext & {
        status: typeof LOADING_STATUS.LOADING;
    };

export type LoadingStatusOk = {
    status: typeof LOADING_STATUS.OK;
};

export type LoadingStatusError = {
    status: typeof LOADING_STATUS.ERROR;
    exception: AtgRequestError;
};

export type GenericLoadingStatus<LoadContext extends Record<string, unknown>> =
    | LoadingStatusInitial
    | LoadingStatusLoading<LoadContext>
    | LoadingStatusOk
    | LoadingStatusError;

export type LoadingStatus = GenericLoadingStatus<Record<string, unknown>>;
