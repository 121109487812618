/* eslint-disable atg/use-format-currency */
import root from "window-or-global";

/**
 * @param amount The amount represents minor units of the currency (e.g. 1000 -> 10 in SEK)
 */
export const fromMinor = (amount: number | undefined | null): number | undefined => {
    if (amount === undefined || amount === null) return undefined;
    return amount / 100;
};

/**
 * @param amount The amount represents major units of the currency (e.g. 10 -> 1000 in SEK)
 */
export const fromMajor = (amount: number | undefined | null): number | undefined => {
    if (amount === undefined || amount === null) return undefined;
    return amount * 100;
};

export const isWholeMajorAmount = (numerator: number): boolean => numerator % 100 === 0;

type FormatCurrencyOptions = {
    hideDecimals?: boolean;
    hideCurrency?: boolean;
    forceDecimals?: boolean;
    fromMajor?: boolean;
    currencyDisplay?: Intl.NumberFormatOptions["currencyDisplay"];
};

/**
 * Format currency using the [Intl.NumberFormat API](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat).
 * It takes minor units as default and uses the specific market's currency e.g EUR or SEK.
 * @param amount The amount in minor value (e.g. `55000` = `550 kr` or `10000.253` =
 * `100,25 kr`)
 * @param options hideDecimals, hideCurrency & extends [Intl.NumberFormatOptions](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat)
 * @return { string } `{value} kr`
 * @example formatCurrency(55000) -> "550 kr"
 * @example formatCurrency(10000.253) -> "100,25 kr"
 */
export const formatCurrency = (
    amount: number | undefined,
    options: FormatCurrencyOptions = {
        hideDecimals: false,
        hideCurrency: false,
        forceDecimals: false,
        fromMajor: false,
    },
): string => {
    if (amount === undefined || amount === null) return "";
    const {hideDecimals, hideCurrency, forceDecimals, ...restOptions} = options;
    let decimals: number;

    const getTargetAmount = () => {
        let targetAmount = amount;

        if (!options.fromMajor) {
            targetAmount = fromMinor(amount) as number;
        }

        if (hideDecimals) {
            targetAmount = Math.trunc(targetAmount);
        }

        return targetAmount;
    };

    const targetAmount = getTargetAmount();

    if (hideDecimals) {
        decimals = 0;
    } else if (forceDecimals) {
        decimals = 2;
    } else {
        /**
         * if our amount doesn't have decimals, we show amount as "200 kr"
         * instead of "200,00 kr"
         */
        decimals = targetAmount % 1 === 0 ? 0 : 2;
    }

    /**
     *  must use both maximum & minimum to fix the
     * `maximumFractionDigits is out of range` error
     *  on Safari <=14
     */
    const fractions = {
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals,
    };

    const locale = root.marketConfig.defaultLocale;

    if (hideCurrency) {
        return new Intl.NumberFormat(locale, {
            ...fractions,
            ...restOptions,
        }).format(targetAmount);
    }

    return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: root.marketConfig.currency,
        ...fractions,
        ...restOptions,
    }).format(targetAmount);
};

type FormatOptions = {
    hideDecimals?: boolean;
    hideCurrency?: boolean;
    useDotForDecimals?: boolean;
};

const DEFAULT_FORMAT_OPTIONS = {
    hideDecimals: false,
    hideCurrency: false,
    useDotForDecimals: false,
};

/**
 * @param amount the amount in Swedish öre (e.g. `550` = `5,50 kr`)
 * @deprecated use `formatCurrency()` instead. It accepts minor amount (ore, cents) instead of major (kr, eur)
 * and uses the specific market's currency e.g EUR or SEK.
 */
export const deprecated_formatCurrency = (
    amount: number,
    options: FormatOptions = DEFAULT_FORMAT_OPTIONS,
): string => {
    const isNegative = amount < 0;
    const absAmount = Math.abs(amount).toString();
    const padAmount = "000".substring(0, 3 - absAmount.length) + absAmount;
    const decimals = options.hideDecimals
        ? ""
        : (options.useDotForDecimals ? "." : ",") + padAmount.slice(-2);
    const currency = options.hideCurrency ? "" : " kr";
    const arr: any[] = [];
    let value = padAmount.slice(0, -2);

    while (value.length) {
        arr.splice(0, 0, value.slice(-3));
        value = value.slice(0, -3);
    }

    const resultingString = arr.join(" ") + decimals + currency;

    if (isNegative) return `-${resultingString}`;

    return resultingString;
};

type CurrencyFormatOptions = {
    hideDecimals?: boolean;
    hideCurrency?: boolean;
    [otherOptions: string]: unknown;
};

/**
 * Format currency using the Intl.NumberFormat API.
 * @param amount the amount in absolute value (e.g. `550` = `550 kr` or `5.52` =
 * `5,52 kr`)
 * @param options hideDecimals, hideCurrency & extends [Intl.NumberFormatOptions](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat)
 * @return { string } `{value} kr`
 * @example deprecated_currencyFormat(550) -> "550 kr"
 * @example deprecated_currencyFormat(100.253) -> "100,25 kr"
 * @deprecated use `formatCurrency()` instead. It accepts minor amount (ore, cents) instead of major (kr, eur)
 * and uses the specific market's currency e.g EUR or SEK.
 */
export const deprecated_currencyFormat = (
    amount: number | undefined,
    options: CurrencyFormatOptions = {
        hideDecimals: false,
        hideCurrency: false,
        forceDecimals: false,
    },
): string => {
    if (amount === undefined || amount === null) return "";
    const {hideDecimals, hideCurrency, forceDecimals, ...restOptions} = options;
    let decimals: number;

    if (hideDecimals) {
        decimals = 0;
    } else if (forceDecimals) {
        decimals = 2;
    } else {
        /**
         * if our amount doesn't have decimals, we show amount as "200 kr"
         * instead of "200,00 kr"
         */
        decimals = amount % 1 === 0 ? 0 : 2;
    }

    const targetAmount = hideDecimals ? Math.trunc(amount) : amount;

    /**
     *  must use both maximum & minimum to fix the
     * `maximumFractionDigits is out of range` error
     *  on Safari <=14
     */
    const fractions = {
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals,
    };

    if (hideCurrency) {
        return new Intl.NumberFormat("sv-SE", {
            ...fractions,
            ...restOptions,
        }).format(targetAmount);
    }

    return new Intl.NumberFormat("sv-SE", {
        style: "currency",
        currency: "SEK",
        ...fractions,
        ...restOptions,
    }).format(targetAmount);
};

/**
 * Using deprecated_currencyFormat from a given amount in öre
 * @param amount the amount in absolute value on öre (e.g. `550` = `5,52 kr`)
 * @param options hideDecimals, hideCurrency & extends [Intl.NumberFormatOptions](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat)
 * @return { string } `{value} kr`
 * @example deprecated_currencyFormatOre(550) -> "5,50 kr"
 * @example deprecated_currencyFormatOre(100.253) -> "1,00 kr"
 * @deprecated use `formatCurrency()` instead. It accepts minor amount (ore, cents) instead of major (kr, eur)
 * and uses the specific market's currency e.g EUR or SEK.
 */
export const deprecated_currencyFormatOre = (
    amount: number | undefined,
    options: CurrencyFormatOptions = {
        hideDecimals: false,
        hideCurrency: false,
        forceDecimals: true,
    },
) => deprecated_currencyFormat(amount ? amount / 100 : amount, {...options});
