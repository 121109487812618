import {makeVar} from "@apollo/client";
import type {Offset} from "@atg-tillsammans/types";

export const memberIdVar = makeVar<number | null>(null);

export const shuffleSeedVar = makeVar<number>(Math.floor(Math.random() * 1337) + 1);

// keep track of user actions in videoplayer frame
export const isPinnedVideoFrame = makeVar(false);
export const isExpandedVideoFrame = makeVar(false);

/**
 * Variable to hold offset for main content container.
 */
const offset = {top: 34, left: 296};
export const mainOffset = makeVar<Offset>(offset);
