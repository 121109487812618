import {find} from "lodash";
import {fetchSelectors} from "@atg-shared/fetch-redux";

import type {
    FetchPersonalizationState,
    GlobalPersonalizationState,
    NotificationSetting,
    GuideType,
} from "./personalization";

export const getPersonalizationState = (
    state: GlobalPersonalizationState,
): FetchPersonalizationState => state.personalization;

export const getNotificationSettings = (
    state: GlobalPersonalizationState,
): NotificationSetting[] => getPersonalizationState(state).notificationSettings;

export const isChatNotificationsActivated = (
    state: GlobalPersonalizationState,
): boolean | null => getPersonalizationState(state).notificationSettings[0]?.activated;

export const isLoginNotificationsActivated = (
    state: GlobalPersonalizationState,
): boolean | null => getPersonalizationState(state).notificationSettings[1]?.activated;

export const isRoundActivatedNotificationsActivated = (
    state: GlobalPersonalizationState,
): boolean | null => getPersonalizationState(state).notificationSettings[2]?.activated;

export const isSetPushNotificationSettingsLoading = (state: GlobalPersonalizationState) =>
    fetchSelectors.isLoading(getPersonalizationState(state));

export const isGuideVisible = (
    state: GlobalPersonalizationState,
    guideType: GuideType,
): boolean =>
    find(getPersonalizationState(state).guideFlags, ["guideType", guideType])?.show ??
    false;
