import type {Logger} from "loglevel";

/**
 * These message levels are forwarded to Splunk
 */
// Note: this should be kept in sync with `atg-eslint-rules/rules/log-syntax.js`
const FORWARDED_ERROR_LEVELS = ["warn", "error"];
/**
 * These are messages that should be ignored and not forwarded to Splunk to reduce log volume
 *
 * Note: before ignoring a message, make sure that it is not a "real" error and if possible it's always better to fix the issue and prevent it from triggering at all. Only add it to the ignore list as a last resort
 */
const IGNORE_MESSAGES = [
    "ResizeObserver loop limit exceeded",
    // Do not log this type of error until we know how to adress it properly,
    // https://splunk.hastsportenshus.atg.se/en-GB/app/search/search?sid=1709563729.39634_B240AC68-0A45-488F-ABE1-0C58E81C58D1
    "ResizeObserver loop completed with undelivered notifications",
    "Uncaught ReferenceError: sa_event is not defined",
    "Uncaught ReferenceError: pixie is not defined",
];

/**
 * Log errors and warnings to Splunk
 *
 * Loglevel plugin documentation: https://github.com/pimterry/loglevel#writing-plugins
 */
const serverLogPlugin = (log: Logger, microFE: string) => {
    const originalFactory = log.methodFactory;

    // eslint-disable-next-line no-param-reassign
    log.methodFactory = (methodName, logLevel, loggerName) => {
        const rawMethod = originalFactory(methodName, logLevel, loggerName);

        return async (message, data, ...rest) => {
            rawMethod(message, data, ...rest);

            if (
                FORWARDED_ERROR_LEVELS.includes(methodName) &&
                !IGNORE_MESSAGES.includes(message)
            ) {
                const addLogEntry = (
                    await import(/* webpackChunkName: "addLogEntry" */ "./addLogEntry")
                ).default;
                addLogEntry(methodName, message, data, microFE);
            }
        };
    };
};

export default serverLogPlugin;
