import type {Store} from "redux";
import {put, call, takeLatest, select, spawn} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import * as GameUtils from "../../common/gameUtils";
import * as GameActions from "../../common/redux/gameActions";
import * as GameSelectors from "../../common/redux/gameSelectors";
import sportGamePushListener from "./push/sportGamePushListener";
import * as SportGameActions from "./sportGameActions";
import * as SportGameAPI from "./sportGameApi";
import matcheSaga from "./sportMatchSaga";

export function* gameFetchFlow({
    payload: {gameId, forceFetch},
}: GameActions.GameFetchAction): SagaIterator {
    if (!GameUtils.isSportGame(gameId)) return;

    if (!forceFetch) {
        const existingGame = yield select(GameSelectors.getGameById, gameId);
        if (existingGame) return;
    }

    try {
        const {data} = yield call(SportGameAPI.fetchSportGame, gameId);

        yield put(SportGameActions.fetchGameSuccess(data));
    } catch (e: unknown) {
        yield put(SportGameActions.fetchGameError());
    }
}

export function* statisticsFetchFlow({
    payload: {gameId},
}: SportGameActions.SportMatchStatisticsFetchRequestAction): SagaIterator {
    if (!GameUtils.isSportGame(gameId)) return;

    try {
        const {data} = yield call(SportGameAPI.fetchPreMatchStatistics, gameId);

        yield put(SportGameActions.fetchMatchStatisticsSuccess(data));
    } catch (e: unknown) {
        yield put(SportGameActions.fetchMatchStatisticsError());
    }
}

export function* commentsFetchFlow(): SagaIterator {
    try {
        const {data} = yield call(SportGameAPI.fetchMatchComments);

        yield put(SportGameActions.fetchMatchCommentsSuccess(data));
    } catch (e: unknown) {
        yield put(SportGameActions.fetchMatchCommenetsError());
    }
}

export default function* gameSaga(store: Store): SagaIterator {
    yield spawn(sportGamePushListener, store);
    yield spawn(matcheSaga);

    yield takeLatest(
        [GameActions.GAME_FETCH, SportGameActions.SPORT_GAME_FETCH_REQUEST],
        gameFetchFlow,
    );
    yield takeLatest(
        [SportGameActions.SPORT_MATCH_STATISTICS_FETCH_REQUEST],
        statisticsFetchFlow,
    );
    yield takeLatest(
        [SportGameActions.SPORT_MATCH_COMMENTS_FETCH_REQUEST],
        commentsFetchFlow,
    );
}
