import * as Types from '@atg-tillsammans/types/generated';

import { gql } from '@apollo/client';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type TeamSearchResultFieldsFragment = { __typename?: 'TeamSearchResult', teamId: string, gameTypes: Array<Types.GameType>, teamName: string, teamDescription: string | null, teamType: Types.TeamType, teamImageRef: string | null, membershipPolicy: Types.MembershipPolicy, captainName: string, minShareCost: number, maxShareCost: number, numberOfMembers: number, memberLimit: number, postalCode: number | null, postalTown: string | null, updatedDateTimestamp: string, ranking: number, sticky: boolean };

export const TeamSearchResultFieldsFragmentDoc = gql`
    fragment TeamSearchResultFields on TeamSearchResult {
  teamId
  gameTypes
  teamName
  teamDescription
  teamType
  teamImageRef
  membershipPolicy
  captainName
  minShareCost
  maxShareCost
  gameTypes
  numberOfMembers
  memberLimit
  postalCode
  postalTown
  updatedDateTimestamp
  ranking
  sticky
}
    `;
export type AmountsKeySpecifier = ('netDividend' | 'payoutFee' | 'sellingFee' | 'sellingFeeRefund' | 'stakeRefund' | 'totalCost' | 'totalPayout' | 'totalRefund' | AmountsKeySpecifier)[];
export type AmountsFieldPolicy = {
	netDividend?: FieldPolicy<any> | FieldReadFunction<any>,
	payoutFee?: FieldPolicy<any> | FieldReadFunction<any>,
	sellingFee?: FieldPolicy<any> | FieldReadFunction<any>,
	sellingFeeRefund?: FieldPolicy<any> | FieldReadFunction<any>,
	stakeRefund?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCost?: FieldPolicy<any> | FieldReadFunction<any>,
	totalPayout?: FieldPolicy<any> | FieldReadFunction<any>,
	totalRefund?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ApplicationKeySpecifier = ('createdDate' | 'id' | 'member' | 'team' | 'text' | ApplicationKeySpecifier)[];
export type ApplicationFieldPolicy = {
	createdDate?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	member?: FieldPolicy<any> | FieldReadFunction<any>,
	team?: FieldPolicy<any> | FieldReadFunction<any>,
	text?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BatchBetSummaryKeySpecifier = ('numberOfCombinations' | 'numberOfCoupons' | 'numberOfSystems' | 'totalCost' | BatchBetSummaryKeySpecifier)[];
export type BatchBetSummaryFieldPolicy = {
	numberOfCombinations?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfCoupons?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfSystems?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCost?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BatchBetTeamKeySpecifier = ('name' | 'teamId' | BatchBetTeamKeySpecifier)[];
export type BatchBetTeamFieldPolicy = {
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	teamId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BatchBetUploadDataKeySpecifier = ('batchBetUploadId' | 'games' | 'message' | 'status' | 'summary' | BatchBetUploadDataKeySpecifier)[];
export type BatchBetUploadDataFieldPolicy = {
	batchBetUploadId?: FieldPolicy<any> | FieldReadFunction<any>,
	games?: FieldPolicy<any> | FieldReadFunction<any>,
	message?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	summary?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BetMetaKeySpecifier = ('couponId' | 'description' | 'id' | 'imageRef' | BetMetaKeySpecifier)[];
export type BetMetaFieldPolicy = {
	couponId?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	imageRef?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BetSubscriptionKeySpecifier = ('buyShareResult' | 'id' | 'maxAmount' | 'numberOfRounds' | 'numberOfRoundsLeft' | 'selectedRounds' | 'type' | BetSubscriptionKeySpecifier)[];
export type BetSubscriptionFieldPolicy = {
	buyShareResult?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	maxAmount?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfRounds?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfRoundsLeft?: FieldPolicy<any> | FieldReadFunction<any>,
	selectedRounds?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BetWithDetailsKeySpecifier = ('amounts' | 'bet' | 'betMeta' | 'betType' | 'contract' | 'id' | 'offering' | BetWithDetailsKeySpecifier)[];
export type BetWithDetailsFieldPolicy = {
	amounts?: FieldPolicy<any> | FieldReadFunction<any>,
	bet?: FieldPolicy<any> | FieldReadFunction<any>,
	betMeta?: FieldPolicy<any> | FieldReadFunction<any>,
	betType?: FieldPolicy<any> | FieldReadFunction<any>,
	contract?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	offering?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CbsBetKeySpecifier = ('coupons' | 'currency' | 'grading' | 'numberOfSystems' | 'offeringId' | 'placedAt' | 'stake' | 'status' | 'tsn' | 'type' | 'vertical' | CbsBetKeySpecifier)[];
export type CbsBetFieldPolicy = {
	coupons?: FieldPolicy<any> | FieldReadFunction<any>,
	currency?: FieldPolicy<any> | FieldReadFunction<any>,
	grading?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfSystems?: FieldPolicy<any> | FieldReadFunction<any>,
	offeringId?: FieldPolicy<any> | FieldReadFunction<any>,
	placedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	stake?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	tsn?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	vertical?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CbsCouponKeySpecifier = ('id' | 'numberOfCombinations' | 'numberOfSystems' | 'selections' | 'stake' | 'stakePerCombination' | 'unitsPerCombination' | CbsCouponKeySpecifier)[];
export type CbsCouponFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfCombinations?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfSystems?: FieldPolicy<any> | FieldReadFunction<any>,
	selections?: FieldPolicy<any> | FieldReadFunction<any>,
	stake?: FieldPolicy<any> | FieldReadFunction<any>,
	stakePerCombination?: FieldPolicy<any> | FieldReadFunction<any>,
	unitsPerCombination?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CbsOfferingKeySpecifier = ('status' | 'summary' | 'unitStake' | CbsOfferingKeySpecifier)[];
export type CbsOfferingFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	summary?: FieldPolicy<any> | FieldReadFunction<any>,
	unitStake?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ContractKeySpecifier = ('maxCost' | 'offeringId' | 'options' | 'owner' | 'stake' | 'type' | ContractKeySpecifier)[];
export type ContractFieldPolicy = {
	maxCost?: FieldPolicy<any> | FieldReadFunction<any>,
	offeringId?: FieldPolicy<any> | FieldReadFunction<any>,
	options?: FieldPolicy<any> | FieldReadFunction<any>,
	owner?: FieldPolicy<any> | FieldReadFunction<any>,
	stake?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CouponKeySpecifier = ('created' | 'id' | 'modified' | CouponKeySpecifier)[];
export type CouponFieldPolicy = {
	created?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	modified?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CouponSelectionsKeySpecifier = ('legs' | CouponSelectionsKeySpecifier)[];
export type CouponSelectionsFieldPolicy = {
	legs?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CreateApplicationResponseKeySpecifier = ('status' | CreateApplicationResponseKeySpecifier)[];
export type CreateApplicationResponseFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GameKeySpecifier = ('endTime' | 'estimatedJackpot' | 'gameId' | 'gameStatus' | 'id' | 'jackpotAmount' | 'scheduledStartTime' | 'tracks' | GameKeySpecifier)[];
export type GameFieldPolicy = {
	endTime?: FieldPolicy<any> | FieldReadFunction<any>,
	estimatedJackpot?: FieldPolicy<any> | FieldReadFunction<any>,
	gameId?: FieldPolicy<any> | FieldReadFunction<any>,
	gameStatus?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	jackpotAmount?: FieldPolicy<any> | FieldReadFunction<any>,
	scheduledStartTime?: FieldPolicy<any> | FieldReadFunction<any>,
	tracks?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GameIdKeySpecifier = ('gameAreaCode' | 'gameDate' | 'gameRaceNumber' | 'gameType' | GameIdKeySpecifier)[];
export type GameIdFieldPolicy = {
	gameAreaCode?: FieldPolicy<any> | FieldReadFunction<any>,
	gameDate?: FieldPolicy<any> | FieldReadFunction<any>,
	gameRaceNumber?: FieldPolicy<any> | FieldReadFunction<any>,
	gameType?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GameInitiatorNotUpdatedKeySpecifier = ('gameId' | 'reason' | GameInitiatorNotUpdatedKeySpecifier)[];
export type GameInitiatorNotUpdatedFieldPolicy = {
	gameId?: FieldPolicy<any> | FieldReadFunction<any>,
	reason?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GameInitiatorScheduleKeySpecifier = ('currentPosition' | 'gameType' | 'id' | 'scheduleType' | 'selectedMembers' | 'teamId' | GameInitiatorScheduleKeySpecifier)[];
export type GameInitiatorScheduleFieldPolicy = {
	currentPosition?: FieldPolicy<any> | FieldReadFunction<any>,
	gameType?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	scheduleType?: FieldPolicy<any> | FieldReadFunction<any>,
	selectedMembers?: FieldPolicy<any> | FieldReadFunction<any>,
	teamId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GradingKeySpecifier = ('coupons' | 'dividend' | 'qualifyingUnits' | GradingKeySpecifier)[];
export type GradingFieldPolicy = {
	coupons?: FieldPolicy<any> | FieldReadFunction<any>,
	dividend?: FieldPolicy<any> | FieldReadFunction<any>,
	qualifyingUnits?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GradingCouponKeySpecifier = ('dividend' | 'effectiveMarks' | 'id' | 'qualifyingCombinations' | 'qualifyingUnits' | GradingCouponKeySpecifier)[];
export type GradingCouponFieldPolicy = {
	dividend?: FieldPolicy<any> | FieldReadFunction<any>,
	effectiveMarks?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	qualifyingCombinations?: FieldPolicy<any> | FieldReadFunction<any>,
	qualifyingUnits?: FieldPolicy<any> | FieldReadFunction<any>
};
export type HorseCouponKeySpecifier = ('betMeta' | 'contractType' | 'created' | 'harryFlavor' | 'harryMaxStake' | 'id' | 'modified' | 'numberOfSystems' | 'selections' | 'stake' | HorseCouponKeySpecifier)[];
export type HorseCouponFieldPolicy = {
	betMeta?: FieldPolicy<any> | FieldReadFunction<any>,
	contractType?: FieldPolicy<any> | FieldReadFunction<any>,
	created?: FieldPolicy<any> | FieldReadFunction<any>,
	harryFlavor?: FieldPolicy<any> | FieldReadFunction<any>,
	harryMaxStake?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	modified?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfSystems?: FieldPolicy<any> | FieldReadFunction<any>,
	selections?: FieldPolicy<any> | FieldReadFunction<any>,
	stake?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LegKeySpecifier = ('harryOpen' | 'raceId' | 'value' | LegKeySpecifier)[];
export type LegFieldPolicy = {
	harryOpen?: FieldPolicy<any> | FieldReadFunction<any>,
	raceId?: FieldPolicy<any> | FieldReadFunction<any>,
	value?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LocationKeySpecifier = ('postalCode' | 'postalTown' | LocationKeySpecifier)[];
export type LocationFieldPolicy = {
	postalCode?: FieldPolicy<any> | FieldReadFunction<any>,
	postalTown?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MarksKeySpecifier = ('marks' | 'reserves' | MarksKeySpecifier)[];
export type MarksFieldPolicy = {
	marks?: FieldPolicy<any> | FieldReadFunction<any>,
	reserves?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MemberKeySpecifier = ('firstName' | 'id' | 'imageUrl' | 'lastName' | 'memberId' | 'online' | MemberKeySpecifier)[];
export type MemberFieldPolicy = {
	firstName?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	imageUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	lastName?: FieldPolicy<any> | FieldReadFunction<any>,
	memberId?: FieldPolicy<any> | FieldReadFunction<any>,
	online?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MemberTeamKeySpecifier = ('activatedRoundsAmount' | 'captain' | 'gameTypes' | 'id' | 'isTeamCaptain' | 'memberAmount' | 'memberSince' | 'membershipPolicy' | 'name' | 'pendingRequests' | 'ranking' | 'subscriptions' | 'teamImageUrl' | 'type' | 'unreadComments' | MemberTeamKeySpecifier)[];
export type MemberTeamFieldPolicy = {
	activatedRoundsAmount?: FieldPolicy<any> | FieldReadFunction<any>,
	captain?: FieldPolicy<any> | FieldReadFunction<any>,
	gameTypes?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isTeamCaptain?: FieldPolicy<any> | FieldReadFunction<any>,
	memberAmount?: FieldPolicy<any> | FieldReadFunction<any>,
	memberSince?: FieldPolicy<any> | FieldReadFunction<any>,
	membershipPolicy?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	pendingRequests?: FieldPolicy<any> | FieldReadFunction<any>,
	ranking?: FieldPolicy<any> | FieldReadFunction<any>,
	subscriptions?: FieldPolicy<any> | FieldReadFunction<any>,
	teamImageUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	unreadComments?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MutationKeySpecifier = ('addGameInitiatorCandidate' | 'batchBetUpload' | 'createApplication' | 'createParticipation' | 'createRound' | 'createSportCoupon' | 'createTeam' | 'generateNewGameInitiatorCandidateLink' | 'getMailNotificationSettings' | 'leaveTeam' | 'optOutMail' | 'placeHorseBet' | 'placeSportBet' | 'playBatchBetV2' | 'processApplication' | 'removeApplication' | 'removeCoupon' | 'removeGameInitiatorCandidate' | 'removeMember' | 'removeSubscription' | 'removeTeam' | 'resetImageAndDescription' | 'saveBetDescription' | 'saveCoupon' | 'saveSportCoupon' | 'sendEmail' | 'setCustomRotatingGameInitiators' | 'setGameInitiator' | 'setGameInitiators' | 'setRotatingGameInitiators' | 'setSubscriptionAllRounds' | 'setSubscriptionNumberOfRounds' | 'setSubscriptionSelectedRounds' | 'updateCaptain' | 'updateGameTypes' | 'updateInvitationSettings' | 'updateRound' | 'updateSearchSettings' | 'updateSystemsLimit' | 'updateTeamInfo' | 'uploadBetImage' | MutationKeySpecifier)[];
export type MutationFieldPolicy = {
	addGameInitiatorCandidate?: FieldPolicy<any> | FieldReadFunction<any>,
	batchBetUpload?: FieldPolicy<any> | FieldReadFunction<any>,
	createApplication?: FieldPolicy<any> | FieldReadFunction<any>,
	createParticipation?: FieldPolicy<any> | FieldReadFunction<any>,
	createRound?: FieldPolicy<any> | FieldReadFunction<any>,
	createSportCoupon?: FieldPolicy<any> | FieldReadFunction<any>,
	createTeam?: FieldPolicy<any> | FieldReadFunction<any>,
	generateNewGameInitiatorCandidateLink?: FieldPolicy<any> | FieldReadFunction<any>,
	getMailNotificationSettings?: FieldPolicy<any> | FieldReadFunction<any>,
	leaveTeam?: FieldPolicy<any> | FieldReadFunction<any>,
	optOutMail?: FieldPolicy<any> | FieldReadFunction<any>,
	placeHorseBet?: FieldPolicy<any> | FieldReadFunction<any>,
	placeSportBet?: FieldPolicy<any> | FieldReadFunction<any>,
	playBatchBetV2?: FieldPolicy<any> | FieldReadFunction<any>,
	processApplication?: FieldPolicy<any> | FieldReadFunction<any>,
	removeApplication?: FieldPolicy<any> | FieldReadFunction<any>,
	removeCoupon?: FieldPolicy<any> | FieldReadFunction<any>,
	removeGameInitiatorCandidate?: FieldPolicy<any> | FieldReadFunction<any>,
	removeMember?: FieldPolicy<any> | FieldReadFunction<any>,
	removeSubscription?: FieldPolicy<any> | FieldReadFunction<any>,
	removeTeam?: FieldPolicy<any> | FieldReadFunction<any>,
	resetImageAndDescription?: FieldPolicy<any> | FieldReadFunction<any>,
	saveBetDescription?: FieldPolicy<any> | FieldReadFunction<any>,
	saveCoupon?: FieldPolicy<any> | FieldReadFunction<any>,
	saveSportCoupon?: FieldPolicy<any> | FieldReadFunction<any>,
	sendEmail?: FieldPolicy<any> | FieldReadFunction<any>,
	setCustomRotatingGameInitiators?: FieldPolicy<any> | FieldReadFunction<any>,
	setGameInitiator?: FieldPolicy<any> | FieldReadFunction<any>,
	setGameInitiators?: FieldPolicy<any> | FieldReadFunction<any>,
	setRotatingGameInitiators?: FieldPolicy<any> | FieldReadFunction<any>,
	setSubscriptionAllRounds?: FieldPolicy<any> | FieldReadFunction<any>,
	setSubscriptionNumberOfRounds?: FieldPolicy<any> | FieldReadFunction<any>,
	setSubscriptionSelectedRounds?: FieldPolicy<any> | FieldReadFunction<any>,
	updateCaptain?: FieldPolicy<any> | FieldReadFunction<any>,
	updateGameTypes?: FieldPolicy<any> | FieldReadFunction<any>,
	updateInvitationSettings?: FieldPolicy<any> | FieldReadFunction<any>,
	updateRound?: FieldPolicy<any> | FieldReadFunction<any>,
	updateSearchSettings?: FieldPolicy<any> | FieldReadFunction<any>,
	updateSystemsLimit?: FieldPolicy<any> | FieldReadFunction<any>,
	updateTeamInfo?: FieldPolicy<any> | FieldReadFunction<any>,
	uploadBetImage?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NotificationSettingKeySpecifier = ('activated' | 'id' | 'label' | NotificationSettingKeySpecifier)[];
export type NotificationSettingFieldPolicy = {
	activated?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OfferingSummaryKeySpecifier = ('legs' | 'pools' | OfferingSummaryKeySpecifier)[];
export type OfferingSummaryFieldPolicy = {
	legs?: FieldPolicy<any> | FieldReadFunction<any>,
	pools?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ParticipantKeySpecifier = ('gameId' | 'id' | 'member' | 'numberOfShares' | 'teamId' | 'totalCost' | ParticipantKeySpecifier)[];
export type ParticipantFieldPolicy = {
	gameId?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	member?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfShares?: FieldPolicy<any> | FieldReadFunction<any>,
	teamId?: FieldPolicy<any> | FieldReadFunction<any>,
	totalCost?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QueryKeySpecifier = ('activeGames' | 'applications' | 'batchBetTeams' | 'batchBetUploadData' | 'currentGames' | 'futureGames' | 'futureRounds' | 'game' | 'gameInitiatorCandidateLink' | 'gameInitiatorCandidates' | 'gameInitiatorMemberships' | 'gameInitiatorSchedule' | 'gameInitiatorSchedules' | 'getCoupons' | 'getMyCoupons' | 'historicalGames' | 'memberParticipations' | 'memberTeams' | 'myTeams' | 'participations' | 'roundBalance' | 'roundParticipants' | 'rounds' | 'sharedReceiptV2' | 'subscription' | 'team' | 'teamApplications' | 'teamCount' | 'teamMembers' | 'teamMemberships' | 'teamRound' | 'teamRounds' | 'teamSearch' | QueryKeySpecifier)[];
export type QueryFieldPolicy = {
	activeGames?: FieldPolicy<any> | FieldReadFunction<any>,
	applications?: FieldPolicy<any> | FieldReadFunction<any>,
	batchBetTeams?: FieldPolicy<any> | FieldReadFunction<any>,
	batchBetUploadData?: FieldPolicy<any> | FieldReadFunction<any>,
	currentGames?: FieldPolicy<any> | FieldReadFunction<any>,
	futureGames?: FieldPolicy<any> | FieldReadFunction<any>,
	futureRounds?: FieldPolicy<any> | FieldReadFunction<any>,
	game?: FieldPolicy<any> | FieldReadFunction<any>,
	gameInitiatorCandidateLink?: FieldPolicy<any> | FieldReadFunction<any>,
	gameInitiatorCandidates?: FieldPolicy<any> | FieldReadFunction<any>,
	gameInitiatorMemberships?: FieldPolicy<any> | FieldReadFunction<any>,
	gameInitiatorSchedule?: FieldPolicy<any> | FieldReadFunction<any>,
	gameInitiatorSchedules?: FieldPolicy<any> | FieldReadFunction<any>,
	getCoupons?: FieldPolicy<any> | FieldReadFunction<any>,
	getMyCoupons?: FieldPolicy<any> | FieldReadFunction<any>,
	historicalGames?: FieldPolicy<any> | FieldReadFunction<any>,
	memberParticipations?: FieldPolicy<any> | FieldReadFunction<any>,
	memberTeams?: FieldPolicy<any> | FieldReadFunction<any>,
	myTeams?: FieldPolicy<any> | FieldReadFunction<any>,
	participations?: FieldPolicy<any> | FieldReadFunction<any>,
	roundBalance?: FieldPolicy<any> | FieldReadFunction<any>,
	roundParticipants?: FieldPolicy<any> | FieldReadFunction<any>,
	rounds?: FieldPolicy<any> | FieldReadFunction<any>,
	sharedReceiptV2?: FieldPolicy<any> | FieldReadFunction<any>,
	subscription?: FieldPolicy<any> | FieldReadFunction<any>,
	team?: FieldPolicy<any> | FieldReadFunction<any>,
	teamApplications?: FieldPolicy<any> | FieldReadFunction<any>,
	teamCount?: FieldPolicy<any> | FieldReadFunction<any>,
	teamMembers?: FieldPolicy<any> | FieldReadFunction<any>,
	teamMemberships?: FieldPolicy<any> | FieldReadFunction<any>,
	teamRound?: FieldPolicy<any> | FieldReadFunction<any>,
	teamRounds?: FieldPolicy<any> | FieldReadFunction<any>,
	teamSearch?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RoundKeySpecifier = ('betMethod' | 'description' | 'game' | 'gameId' | 'gameInitiator' | 'id' | 'isGameInitiator' | 'sales' | 'salesDefaults' | 'state' | 'teamId' | RoundKeySpecifier)[];
export type RoundFieldPolicy = {
	betMethod?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	game?: FieldPolicy<any> | FieldReadFunction<any>,
	gameId?: FieldPolicy<any> | FieldReadFunction<any>,
	gameInitiator?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isGameInitiator?: FieldPolicy<any> | FieldReadFunction<any>,
	sales?: FieldPolicy<any> | FieldReadFunction<any>,
	salesDefaults?: FieldPolicy<any> | FieldReadFunction<any>,
	state?: FieldPolicy<any> | FieldReadFunction<any>,
	teamId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SalesKeySpecifier = ('buyLimit' | 'buyLimitPerPerson' | 'deadline' | 'id' | 'price' | 'refundAmount' | 'scheduledReleases' | 'totalSalesAmount' | SalesKeySpecifier)[];
export type SalesFieldPolicy = {
	buyLimit?: FieldPolicy<any> | FieldReadFunction<any>,
	buyLimitPerPerson?: FieldPolicy<any> | FieldReadFunction<any>,
	deadline?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	price?: FieldPolicy<any> | FieldReadFunction<any>,
	refundAmount?: FieldPolicy<any> | FieldReadFunction<any>,
	scheduledReleases?: FieldPolicy<any> | FieldReadFunction<any>,
	totalSalesAmount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SalesDefaultsKeySpecifier = ('buyLimitPerPerson' | 'price' | SalesDefaultsKeySpecifier)[];
export type SalesDefaultsFieldPolicy = {
	buyLimitPerPerson?: FieldPolicy<any> | FieldReadFunction<any>,
	price?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ScheduledReleaseKeySpecifier = ('id' | 'numberOfShares' | 'time' | ScheduledReleaseKeySpecifier)[];
export type ScheduledReleaseFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfShares?: FieldPolicy<any> | FieldReadFunction<any>,
	time?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SetGameInitiatorsResponseKeySpecifier = ('rounds' | 'roundsNotUpdated' | SetGameInitiatorsResponseKeySpecifier)[];
export type SetGameInitiatorsResponseFieldPolicy = {
	rounds?: FieldPolicy<any> | FieldReadFunction<any>,
	roundsNotUpdated?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ShareInfoGradingKeySpecifier = ('dividend' | 'qualifyingUnits' | ShareInfoGradingKeySpecifier)[];
export type ShareInfoGradingFieldPolicy = {
	dividend?: FieldPolicy<any> | FieldReadFunction<any>,
	qualifyingUnits?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ShareInfoV2KeySpecifier = ('grading' | 'winnings' | ShareInfoV2KeySpecifier)[];
export type ShareInfoV2FieldPolicy = {
	grading?: FieldPolicy<any> | FieldReadFunction<any>,
	winnings?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ShareInfoWinningsKeySpecifier = ('my' | 'perShare' | 'total' | ShareInfoWinningsKeySpecifier)[];
export type ShareInfoWinningsFieldPolicy = {
	my?: FieldPolicy<any> | FieldReadFunction<any>,
	perShare?: FieldPolicy<any> | FieldReadFunction<any>,
	total?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SharedReceiptV2KeySpecifier = ('bets' | 'shareInfo' | SharedReceiptV2KeySpecifier)[];
export type SharedReceiptV2FieldPolicy = {
	bets?: FieldPolicy<any> | FieldReadFunction<any>,
	shareInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SportCouponKeySpecifier = ('couponCreator' | 'created' | 'id' | 'modified' | 'payload' | SportCouponKeySpecifier)[];
export type SportCouponFieldPolicy = {
	couponCreator?: FieldPolicy<any> | FieldReadFunction<any>,
	created?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	modified?: FieldPolicy<any> | FieldReadFunction<any>,
	payload?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TeamKeySpecifier = ('batchBetAllowed' | 'captain' | 'createdDate' | 'description' | 'gameTypes' | 'id' | 'invitationSetting' | 'isTeamCaptain' | 'location' | 'locationVisible' | 'membershipPolicy' | 'multipleReleasesAllowed' | 'name' | 'ranking' | 'systemsLimit' | 'teamImageUrl' | 'type' | 'vacantMemberships' | 'visible' | TeamKeySpecifier)[];
export type TeamFieldPolicy = {
	batchBetAllowed?: FieldPolicy<any> | FieldReadFunction<any>,
	captain?: FieldPolicy<any> | FieldReadFunction<any>,
	createdDate?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	gameTypes?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	invitationSetting?: FieldPolicy<any> | FieldReadFunction<any>,
	isTeamCaptain?: FieldPolicy<any> | FieldReadFunction<any>,
	location?: FieldPolicy<any> | FieldReadFunction<any>,
	locationVisible?: FieldPolicy<any> | FieldReadFunction<any>,
	membershipPolicy?: FieldPolicy<any> | FieldReadFunction<any>,
	multipleReleasesAllowed?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	ranking?: FieldPolicy<any> | FieldReadFunction<any>,
	systemsLimit?: FieldPolicy<any> | FieldReadFunction<any>,
	teamImageUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	vacantMemberships?: FieldPolicy<any> | FieldReadFunction<any>,
	visible?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TeamMembershipKeySpecifier = ('id' | 'member' | 'memberSince' | 'participatingHistory' | 'roles' | 'teamId' | TeamMembershipKeySpecifier)[];
export type TeamMembershipFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	member?: FieldPolicy<any> | FieldReadFunction<any>,
	memberSince?: FieldPolicy<any> | FieldReadFunction<any>,
	participatingHistory?: FieldPolicy<any> | FieldReadFunction<any>,
	roles?: FieldPolicy<any> | FieldReadFunction<any>,
	teamId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TeamSearchResponseKeySpecifier = ('nbrOfResults' | 'results' | TeamSearchResponseKeySpecifier)[];
export type TeamSearchResponseFieldPolicy = {
	nbrOfResults?: FieldPolicy<any> | FieldReadFunction<any>,
	results?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TeamSearchResultKeySpecifier = ('captainName' | 'gameTypes' | 'maxShareCost' | 'memberLimit' | 'membershipPolicy' | 'minShareCost' | 'numberOfMembers' | 'postalCode' | 'postalTown' | 'ranking' | 'sticky' | 'teamDescription' | 'teamId' | 'teamImageRef' | 'teamName' | 'teamType' | 'updatedDateTimestamp' | TeamSearchResultKeySpecifier)[];
export type TeamSearchResultFieldPolicy = {
	captainName?: FieldPolicy<any> | FieldReadFunction<any>,
	gameTypes?: FieldPolicy<any> | FieldReadFunction<any>,
	maxShareCost?: FieldPolicy<any> | FieldReadFunction<any>,
	memberLimit?: FieldPolicy<any> | FieldReadFunction<any>,
	membershipPolicy?: FieldPolicy<any> | FieldReadFunction<any>,
	minShareCost?: FieldPolicy<any> | FieldReadFunction<any>,
	numberOfMembers?: FieldPolicy<any> | FieldReadFunction<any>,
	postalCode?: FieldPolicy<any> | FieldReadFunction<any>,
	postalTown?: FieldPolicy<any> | FieldReadFunction<any>,
	ranking?: FieldPolicy<any> | FieldReadFunction<any>,
	sticky?: FieldPolicy<any> | FieldReadFunction<any>,
	teamDescription?: FieldPolicy<any> | FieldReadFunction<any>,
	teamId?: FieldPolicy<any> | FieldReadFunction<any>,
	teamImageRef?: FieldPolicy<any> | FieldReadFunction<any>,
	teamName?: FieldPolicy<any> | FieldReadFunction<any>,
	teamType?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedDateTimestamp?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TrackKeySpecifier = ('id' | 'name' | TrackKeySpecifier)[];
export type TrackFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StrictTypedTypePolicies = {
	Amounts?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AmountsKeySpecifier | (() => undefined | AmountsKeySpecifier),
		fields?: AmountsFieldPolicy,
	},
	Application?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ApplicationKeySpecifier | (() => undefined | ApplicationKeySpecifier),
		fields?: ApplicationFieldPolicy,
	},
	BatchBetSummary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BatchBetSummaryKeySpecifier | (() => undefined | BatchBetSummaryKeySpecifier),
		fields?: BatchBetSummaryFieldPolicy,
	},
	BatchBetTeam?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BatchBetTeamKeySpecifier | (() => undefined | BatchBetTeamKeySpecifier),
		fields?: BatchBetTeamFieldPolicy,
	},
	BatchBetUploadData?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BatchBetUploadDataKeySpecifier | (() => undefined | BatchBetUploadDataKeySpecifier),
		fields?: BatchBetUploadDataFieldPolicy,
	},
	BetMeta?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BetMetaKeySpecifier | (() => undefined | BetMetaKeySpecifier),
		fields?: BetMetaFieldPolicy,
	},
	BetSubscription?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BetSubscriptionKeySpecifier | (() => undefined | BetSubscriptionKeySpecifier),
		fields?: BetSubscriptionFieldPolicy,
	},
	BetWithDetails?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BetWithDetailsKeySpecifier | (() => undefined | BetWithDetailsKeySpecifier),
		fields?: BetWithDetailsFieldPolicy,
	},
	CbsBet?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CbsBetKeySpecifier | (() => undefined | CbsBetKeySpecifier),
		fields?: CbsBetFieldPolicy,
	},
	CbsCoupon?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CbsCouponKeySpecifier | (() => undefined | CbsCouponKeySpecifier),
		fields?: CbsCouponFieldPolicy,
	},
	CbsOffering?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CbsOfferingKeySpecifier | (() => undefined | CbsOfferingKeySpecifier),
		fields?: CbsOfferingFieldPolicy,
	},
	Contract?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ContractKeySpecifier | (() => undefined | ContractKeySpecifier),
		fields?: ContractFieldPolicy,
	},
	Coupon?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CouponKeySpecifier | (() => undefined | CouponKeySpecifier),
		fields?: CouponFieldPolicy,
	},
	CouponSelections?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CouponSelectionsKeySpecifier | (() => undefined | CouponSelectionsKeySpecifier),
		fields?: CouponSelectionsFieldPolicy,
	},
	CreateApplicationResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CreateApplicationResponseKeySpecifier | (() => undefined | CreateApplicationResponseKeySpecifier),
		fields?: CreateApplicationResponseFieldPolicy,
	},
	Game?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GameKeySpecifier | (() => undefined | GameKeySpecifier),
		fields?: GameFieldPolicy,
	},
	GameId?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GameIdKeySpecifier | (() => undefined | GameIdKeySpecifier),
		fields?: GameIdFieldPolicy,
	},
	GameInitiatorNotUpdated?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GameInitiatorNotUpdatedKeySpecifier | (() => undefined | GameInitiatorNotUpdatedKeySpecifier),
		fields?: GameInitiatorNotUpdatedFieldPolicy,
	},
	GameInitiatorSchedule?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GameInitiatorScheduleKeySpecifier | (() => undefined | GameInitiatorScheduleKeySpecifier),
		fields?: GameInitiatorScheduleFieldPolicy,
	},
	Grading?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GradingKeySpecifier | (() => undefined | GradingKeySpecifier),
		fields?: GradingFieldPolicy,
	},
	GradingCoupon?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GradingCouponKeySpecifier | (() => undefined | GradingCouponKeySpecifier),
		fields?: GradingCouponFieldPolicy,
	},
	HorseCoupon?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | HorseCouponKeySpecifier | (() => undefined | HorseCouponKeySpecifier),
		fields?: HorseCouponFieldPolicy,
	},
	Leg?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LegKeySpecifier | (() => undefined | LegKeySpecifier),
		fields?: LegFieldPolicy,
	},
	Location?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LocationKeySpecifier | (() => undefined | LocationKeySpecifier),
		fields?: LocationFieldPolicy,
	},
	Marks?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MarksKeySpecifier | (() => undefined | MarksKeySpecifier),
		fields?: MarksFieldPolicy,
	},
	Member?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MemberKeySpecifier | (() => undefined | MemberKeySpecifier),
		fields?: MemberFieldPolicy,
	},
	MemberTeam?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MemberTeamKeySpecifier | (() => undefined | MemberTeamKeySpecifier),
		fields?: MemberTeamFieldPolicy,
	},
	Mutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier),
		fields?: MutationFieldPolicy,
	},
	NotificationSetting?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NotificationSettingKeySpecifier | (() => undefined | NotificationSettingKeySpecifier),
		fields?: NotificationSettingFieldPolicy,
	},
	OfferingSummary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OfferingSummaryKeySpecifier | (() => undefined | OfferingSummaryKeySpecifier),
		fields?: OfferingSummaryFieldPolicy,
	},
	Participant?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ParticipantKeySpecifier | (() => undefined | ParticipantKeySpecifier),
		fields?: ParticipantFieldPolicy,
	},
	Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier),
		fields?: QueryFieldPolicy,
	},
	Round?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RoundKeySpecifier | (() => undefined | RoundKeySpecifier),
		fields?: RoundFieldPolicy,
	},
	Sales?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SalesKeySpecifier | (() => undefined | SalesKeySpecifier),
		fields?: SalesFieldPolicy,
	},
	SalesDefaults?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SalesDefaultsKeySpecifier | (() => undefined | SalesDefaultsKeySpecifier),
		fields?: SalesDefaultsFieldPolicy,
	},
	ScheduledRelease?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ScheduledReleaseKeySpecifier | (() => undefined | ScheduledReleaseKeySpecifier),
		fields?: ScheduledReleaseFieldPolicy,
	},
	SetGameInitiatorsResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SetGameInitiatorsResponseKeySpecifier | (() => undefined | SetGameInitiatorsResponseKeySpecifier),
		fields?: SetGameInitiatorsResponseFieldPolicy,
	},
	ShareInfoGrading?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ShareInfoGradingKeySpecifier | (() => undefined | ShareInfoGradingKeySpecifier),
		fields?: ShareInfoGradingFieldPolicy,
	},
	ShareInfoV2?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ShareInfoV2KeySpecifier | (() => undefined | ShareInfoV2KeySpecifier),
		fields?: ShareInfoV2FieldPolicy,
	},
	ShareInfoWinnings?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ShareInfoWinningsKeySpecifier | (() => undefined | ShareInfoWinningsKeySpecifier),
		fields?: ShareInfoWinningsFieldPolicy,
	},
	SharedReceiptV2?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SharedReceiptV2KeySpecifier | (() => undefined | SharedReceiptV2KeySpecifier),
		fields?: SharedReceiptV2FieldPolicy,
	},
	SportCoupon?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SportCouponKeySpecifier | (() => undefined | SportCouponKeySpecifier),
		fields?: SportCouponFieldPolicy,
	},
	Team?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TeamKeySpecifier | (() => undefined | TeamKeySpecifier),
		fields?: TeamFieldPolicy,
	},
	TeamMembership?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TeamMembershipKeySpecifier | (() => undefined | TeamMembershipKeySpecifier),
		fields?: TeamMembershipFieldPolicy,
	},
	TeamSearchResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TeamSearchResponseKeySpecifier | (() => undefined | TeamSearchResponseKeySpecifier),
		fields?: TeamSearchResponseFieldPolicy,
	},
	TeamSearchResult?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TeamSearchResultKeySpecifier | (() => undefined | TeamSearchResultKeySpecifier),
		fields?: TeamSearchResultFieldPolicy,
	},
	Track?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TrackKeySpecifier | (() => undefined | TrackKeySpecifier),
		fields?: TrackFieldPolicy,
	}
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;