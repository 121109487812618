import root from "window-or-global";
import atgRequest, {addAuthHeaders, fetchFileUpload} from "@atg-shared/fetch";
import {fetchAuthorized} from "@atg-shared/auth";
import {TEAM_SERVICE_PUBLIC_URL, TEAM_SERVICE_URL} from "@atg-shared/service-url";
import type {MembershipPolicy} from "@atg-tillsammans/types";

export const mapToCreateFormData = ({
    teamName,
    membershipPolicy,
    teamImageFile,
}: {
    teamName: string;
    membershipPolicy: MembershipPolicy;
    teamImageFile?: File | null;
}) => {
    const formData = new FormData();
    formData.append("teamName", teamName);
    formData.append("membershipPolicy", membershipPolicy);
    if (teamImageFile) {
        formData.append("teamImageFile", teamImageFile);
    }
    return formData;
};

export const mapFormData = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    return formData;
};

export const saveTeamImage = (teamId: string, file: File) =>
    fetchAuthorized(
        `${TEAM_SERVICE_URL}/${teamId}/images`,
        {
            headers: addAuthHeaders({}),
            body: mapFormData(file),
        },
        {},
        fetchFileUpload,
    );

export const fetchTeamMembers = (teamId: string, isLoggedOut = false) =>
    root.location.pathname.includes("/utloggad/") || isLoggedOut
        ? atgRequest(`${TEAM_SERVICE_PUBLIC_URL}/${teamId}/members`, {
              method: "GET",
          })
        : fetchAuthorized(`${TEAM_SERVICE_URL}/${teamId}/members`, {
              method: "GET",
          });

export const revokeApplication = (applicationId: number) =>
    fetchAuthorized(`${TEAM_SERVICE_URL}/applications/${applicationId}`, {
        method: "DELETE",
    });

export const joinTeam = (teamId: number) =>
    atgRequest(`${TEAM_SERVICE_URL}/${teamId}/members`, {
        method: "POST",
    })
        .then((response) => response)
        .catch((error) => {
            if (error.response.meta.code === 409) {
                return error.response;
            }
            throw error;
        });

export const applyForTeam = (teamId: number, greeting: unknown) =>
    atgRequest(`${TEAM_SERVICE_URL}/${teamId}/applications`, {
        method: "POST",
        body: JSON.stringify({greeting}),
    })
        .then((response) => response)
        .catch((error) => {
            if (error.response.meta.code === 409) {
                return error.response;
            }
            throw error;
        });
