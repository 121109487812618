import type {
    CreateParticipationInput,
    Participant,
} from "@atg-tillsammans/types/generated";
import type {RoundErrorCode} from "@atg-tillsammans/types";
import type {RoundPurchaseModalType} from "./round.types";

export const PURCHASE_STARTED = "round/PURCHASE_STARTED";
export const PURCHASE_CONFIRM = "round/PURCHASE_CONFIRM";
export const PURCHASE_SUCCESS = "round/PURCHASE_SUCCESS";
export const PURCHASE_ERROR = "round/PURCHASE_ERROR";
export const PURCHASE_FINISHED = "round/PURCHASE_FINISHED";
export const PURCHASE_RESET = "round/PURCHASE_RESET";
export const SET_PURCHASE_MODAL = "round/SET_PURCHASE_MODAL";

interface PurchasePayload {
    roundId: string;
}

interface PurchaseConfirmPayload extends PurchasePayload {
    input: CreateParticipationInput;
}

interface PurchaseSuccessPayload extends PurchasePayload {
    participant: Participant;
}

interface PurchaseErrorPayload extends PurchasePayload {
    errorCode: RoundErrorCode;
}

export type RoundPurchaseConfirmAction = {
    type: typeof PURCHASE_CONFIRM;
    payload: PurchaseConfirmPayload;
};

export type RoundPurchaseAction =
    | {
          type: typeof PURCHASE_STARTED;
          payload: PurchasePayload;
      }
    | RoundPurchaseConfirmAction
    | {
          type: typeof PURCHASE_SUCCESS;
          payload: PurchaseSuccessPayload;
      }
    | {
          type: typeof PURCHASE_ERROR;
          error: boolean;
          payload: PurchaseErrorPayload;
      }
    | {
          type: typeof PURCHASE_FINISHED;
      }
    | {
          type: typeof PURCHASE_RESET;
      }
    | {
          type: typeof SET_PURCHASE_MODAL;
          payload: RoundPurchaseModalType;
      };

export const RoundPurchaseActions = {
    startPurchase: (payload: PurchasePayload): RoundPurchaseAction => ({
        type: PURCHASE_STARTED,
        payload,
    }),
    confirmPurchase: (payload: PurchaseConfirmPayload): RoundPurchaseConfirmAction => ({
        type: PURCHASE_CONFIRM,
        payload,
    }),
    purchaseSuccess: (payload: PurchaseSuccessPayload): RoundPurchaseAction => ({
        type: PURCHASE_SUCCESS,
        payload,
    }),
    purchaseError: (payload: PurchaseErrorPayload): RoundPurchaseAction => ({
        type: PURCHASE_ERROR,
        error: true,
        payload,
    }),
    purchaseFinished: (): RoundPurchaseAction => ({
        type: PURCHASE_FINISHED,
    }),
    resetPurchase: (): RoundPurchaseAction => ({
        type: PURCHASE_RESET,
    }),
    setPurchaseModal: (payload: RoundPurchaseModalType): RoundPurchaseAction => ({
        type: SET_PURCHASE_MODAL,
        payload,
    }),
};
