import root from "window-or-global";
// eslint-disable-next-line @emotion/no-vanilla
import {sheet} from "@emotion/css";
import createCache, {type EmotionCache} from "@emotion/cache";

const {isPrerender} = root;

if (isPrerender) {
    /**
     * `speedy` has to be disabled if we prerender so that the css is rendered into the dom
     *
     *  https://github.com/emotion-js/emotion/blob/03532e3482daab90505ebd272ecf1939c4332c0e/docs/ssr.md#puppeteer
     *  https://github.com/emotion-js/emotion/blob/23f43ab9f24d44219b0b007a00f4ac681fe8712e/packages/css/src/create-instance.js#L69-L75
     *  https://github.com/emotion-js/emotion/blob/23f43ab9f24d44219b0b007a00f4ac681fe8712e/packages/sheet/src/index.js#L64
     */
    sheet.speedy(false);
}

// eslint-disable-next-line import/no-mutable-exports
let cache: EmotionCache;

/**
 * Creates a cache object using the provided key and stores it in the module scope so that it can be imported later by components in the same microFE.
 * This function should be called once in the entrypoint for each microFE before any components have been imported / rendered.
 *
 * @param key a unique cache key, typically the name of the microFE that the cache will be used for
 * @returns the created EmotionCache object
 */
export const configureEmotionCache = (key: string) => {
    if (!cache) {
        // disable speedy on the cache if we prerender so that the css is rendered into the dom
        cache = createCache({key, ...(isPrerender && {speedy: false})});
        // Turn off emotion warnings about "unsafe usage of :first-child" and similar selectors.
        // !!THIS MIGHT BREAK SERVER-SIDE RENDERING (SSR)!!
        // If we ever decide to do SSR this needs to be
        // investigated and updated.
        //
        // Some references below with more details:
        // - https://github.com/emotion-js/emotion/issues/1178 (background w/ technical details)
        // - https://github.com/emotion-js/emotion/issues/1105#issuecomment-557726922 (our current workaround)
        // - https://emotion.sh/docs/ssr#advanced-approach (how we could potentially adapt for SSR in the future, if needed)
        cache.compat = true;
    }
    return cache;
};

export {cache, type EmotionCache};
