import type {Store} from "redux";
import {spawn} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import sportGameSaga from "../../sport/redux/sportGameSaga";
import horseGameSaga from "../../horse/redux/horseGameSaga";

export default function* gameSaga(store: Store): SagaIterator {
    yield spawn(horseGameSaga, store);
    yield spawn(sportGameSaga, store);
}
