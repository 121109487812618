import type {
    FetchAction,
    FetchLifecycleActions,
    FetchReducerType,
    FetchState,
    FetchTriggerAction,
    LoadingState,
    RequestAction,
} from "@atg-shared/fetch-types";
import {FETCH} from "./fetchActions";

const mergeState = <EntityState>(
    entityState: FetchState<EntityState> | EntityState,
    loadingState: LoadingState,
): FetchState<EntityState> => ({
    ...entityState,
    __loadingState: loadingState,
});

export const createInitialState = <EntityState>(
    initialState: EntityState,
): FetchState<EntityState> =>
    mergeState(initialState, {
        loading: false,
        loaded: false,
        error: false,
    });

const isFetchTriggerAction = <
    RequestActionType,
    ReceiveActionType,
    Response,
    State,
    Context,
    ResetActionType,
>(
    action:
        | FetchAction<RequestActionType, ReceiveActionType, Response, State, Context>
        | {type: ResetActionType},
): action is FetchTriggerAction<
    RequestActionType,
    ReceiveActionType,
    Response,
    State,
    Context
> => action.type === FETCH;

const isRequestAction = <RequestActionType, ReceiveActionType, Response, Context>(
    action: FetchLifecycleActions<
        RequestActionType,
        ReceiveActionType,
        Response,
        Context
    >,
    requestActionType: RequestActionType,
): action is RequestAction<RequestActionType, Context> =>
    action.type === requestActionType;

/**
 * This is a higher-order reducer, or a reducer factory, which extends the wrapped reducer with
 * extra logic to handle a request/response flow. It handles loading states and error states.
 *
 * @param requestAction the action type that initiates the request
 * @param receiveAction the action type that contains the response
 * @param resetAction (optional) action type that will cause the loading state to be reset to its
 * initial state
 * @param reducer the inner reducer which handles all logic apart from loading states
 * @param initialState the initial state that should be passed to the inner reducer
 */
export const createFetchReducer =
    <
        EntityState,
        Response,
        RequestActionType,
        ReceiveActionType,
        ResetActionType,
        Context,
    >(
        requestAction: RequestActionType,
        receiveAction: ReceiveActionType,
        resetAction: ResetActionType,
        reducer: (
            // The wrapped reducer will always be passed an "enhanced state" with the hidden
            // `__loadingState` property, i.e. `FetchState<EntityState>`.
            state: FetchState<EntityState>,
            action: any,
            // The wrapped reducer typically just returns a new state of type `EntityState`, but it's
            // also possible that it spreads the previous state into the new state. Since the previous
            // state is enhanced with the hidden `__loadingState` property, the return type in this case
            // becomes FetchState<EntityState>.
        ) => EntityState | FetchState<EntityState>,
        initialState: EntityState,
    ): FetchReducerType<
        EntityState,
        Response,
        RequestActionType,
        ReceiveActionType,
        ResetActionType,
        Context
    > =>
    (state = createInitialState(initialState), action) => {
        if (isFetchTriggerAction(action)) return state;

        // eslint-disable-next-line no-underscore-dangle
        if ("__category" in action && action.__category === "fetch") {
            // request
            if (isRequestAction(action, requestAction)) {
                return mergeState(reducer(state, action), {
                    loading: true,
                    loaded: false,
                    error: false,
                });
            }

            // receive
            if (action.type === receiveAction) {
                if (action.error) {
                    return mergeState(reducer(state, action), {
                        loading: false,
                        loaded: true,
                        error: true,
                        exception: action.payload,
                    });
                }
                if (!action.payload) return state;
                return mergeState(reducer(state, action), {
                    loading: false,
                    loaded: true,
                    error: false,
                });
            }

            // TODO: `newState` usually does not contain the inner `__loadingState` property, so
            // the `newState === state` will typically fail, causing unnecessary rerenderes.
            // Try to fix sometime.
            const newState = reducer(state, action);
            if (newState === state) return state;
            // eslint-disable-next-line no-underscore-dangle
            return mergeState(newState, state.__loadingState);
        }

        // reset
        if (action.type === resetAction) {
            return mergeState(reducer(state, action), {
                loading: false,
                loaded: false,
                error: false,
            });
        }

        const newState = reducer(state, action);
        if (newState === state) return state;
        // eslint-disable-next-line no-underscore-dangle
        return mergeState(newState, state.__loadingState);
    };
