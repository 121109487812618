import {broadcastAction, frameAction} from "atg-store-addons";
import {MODAL_SHOW, MODAL_CLOSE, CLOSE_ALL_MODALS} from "./modalActionTypes";

const show = (modalId) => () => ({
    type: MODAL_SHOW,
    payload: modalId,
});

// Why not adding "payload" to above function? Some components pass these actions
// directly on onClick handlers like `onClick={showModal}`, and that would make
// the whole event object being sent through our reducers, and might end up in the store
// Make modals that need extra data passed explicitly pass a payload to avoid this
const showWithExtraPayload = (modalId) => (payload) => ({
    type: MODAL_SHOW,
    payload: {...payload, modalId},
});

const close = (modalId) => () => ({
    type: MODAL_CLOSE,
    payload: modalId,
});

export const showScratchedHorseWarningModal = show("betOnScratchedHorseModal");
export const closeScratchedHorseWarningModal = close("betOnScratchedHorseModal");
export const showLoginModal = show("loginModal");
export const closeLoginModal = close("loginModal");
export const showUserAgreementModal = show("userAgreementModal");
export const closeUserAgreementModal = close("userAgreementModal");
export const showConfirmBetModal = show("confirmBetModal");
export const closeConfirmBetModal = close("confirmBetModal");
export const closeBetConfirmModal = close("BetConfirmModal"); // Could perhaps be some other action?
export const showTop7ConfirmModal = show("top7ConfirmModal");
export const closeTop7ConfirmModal = close("top7ConfirmModal");
export const showHarryConfirmModal = show("harryConfirmModal");
export const closeHarryConfirmModal = close("harryConfirmModal");
export const showReceiptModal = show("receiptModal");
export const closeReceiptModal = close("receiptModal");
export const showStartlistOptionsModal = show("startlistOptionsModal");
export const closeStartlistOptionsModal = close("startlistOptionsModal");
export const showResultsOptionsModal = show("resultsOptionsModal");
export const closeResultsOptionsModal = close("resultsOptionsModal");
export const showStakeGameBetCostModal = show("stakeGameBetCostModal");
export const closeStakeGameBetCostModal = close("stakeGameBetCostModal");
export const showQuickPlayStakeGameBetCostModal = show("quickPlayStakeGameBetCostModal");
export const closeQuickPlayStakeGameBetCostModal = close(
    "quickPlayStakeGameBetCostModal",
);
export const showHarryBetLimitModal = show("harryBetLimitModal");
export const closeHarryBetLimitModal = close("harryBetLimitModal");
export const showTrioFlexStakeModal = show("trioFlexStakeModal");
export const closeTrioFlexStakeModal = close("trioFlexStakeModal");
export const showHarrySelectionModal = show("harrySelectionModal");
export const closeHarrySelectionModal = close("harrySelectionModal");
export const showBettingLockConfirmationModal = show("bettingLockConfirmationModal");
export const closeBettingLockConfirmationModal = close("bettingLockConfirmationModal");

export const showEditPushNotificationSettingsModal = show(
    "editPushNotificationSettingsModal",
);
export const closeEditPushNotificationSettingsModal = close(
    "editPushNotificationSettingsModal",
);

export const showDepositLimitModal = () => frameAction(show("depositLimitModal")());
export const closeDepositLimitModal = () => frameAction(close("depositLimitModal")());

export const showEditMobileNumberModal = show("editMobileNumberModal");
export const closeEditMobileNumberModal = close("editMobileNumberModal");
export const showEditEmailModal = show("editEmailModal");
export const closeEditEmailModal = close("editEmailModal");
export const showSmsRecoveryModal = show("smsRecoveryModal");
export const closeSmsRecoveryModal = close("smsRecoveryModal");
export const showEditContactInfoModal = show("editContactInfoModal");
export const closeEditContactInfoModal = close("editContactInfoModal");

// Two factor
export const showTwoFactorConfirmationModal = show("twoFactorConfirmationModal");
export const closeTwoFactorConfirmationModal = close("twoFactorConfirmationModal");
export const showTwoFactorLoginConfirmationModal = show(
    "twoFactorLoginConfirmationModal",
);
export const closeTwoFactorLoginConfirmationModal = close(
    "twoFactorLoginConfirmationModal",
);
export const showTwoFactorActivationModal = show("twoFactorActivationModal");
export const closeTwoFactorActivationModal = close("twoFactorActivationModal");
export const showTwoFactorDeactivationModal = show("twoFactorDeactivationModal");
export const closeTwoFactorDeactivationModal = close("twoFactorDeactivationModal");

// Login or Register
export const showLoginOrRegisterModal = show("loginOrRegisterModal");
export const closeLoginOrRegisterModal = close("loginOrRegisterModal");

// Change password
export const showChangeTempPasswordModal = show("changeTempPasswordModal");
export const closeChangeTempPasswordModal = close("changeTempPasswordModal");
export const showChangePasswordModal = show("changePasswordModal");
export const closeChangePasswordModal = close("changePasswordModal");

export const showVMiV75Modal = show("VMiV75Modal");
export const closeVMiV75Modal = close("VMiV75Modal");
export const showAlreadyRegisteredInVMiV75Modal = show("alreadyRegisteredInVMiV75Modal");
export const closeAlreadyRegisteredInVMiV75Modal = close(
    "alreadyRegisteredInVMiV75Modal",
);
export const showVMiV75SimpleModal = show("VMiV75SimpleModal");
export const closeVMiV75SimpleModal = close("VMiV75SimpleModal");
export const showVMiV75ErrorModal = show("VMiV75ErrorModal");
export const closeVMiV75ErrorModal = close("VMiV75ErrorModal");

export const closeAllModals = () => broadcastAction({type: CLOSE_ALL_MODALS});

export const showMarketingCommunicatonConsentModal = show(
    "marketingCommunicationConsent",
);
export const closeMarketingCommunicatonConsentModal = close(
    "marketingCommunicationConsent",
);

// Welcome screen
export const showWelcomeNewUserModal = show("welcomeNewUserModal");
export const closeWelcomeNewUserModal = () =>
    broadcastAction(close("welcomeNewUserModal")());

// User gambling summary screen
export const showUserGamblingSummaryModal = show("userGamblingSummaryModal");
export const closeUserGamblingSummaryModal = () =>
    broadcastAction(close("userGamblingSummaryModal")());

// Contact Information Update modal
export const showUpdateContactInformationModal = show("updateContactInformationModal");
export const closeUpdateContactInformationModal = close("updateContactInformationModal");

// Verify Contact Information modal
export const showVerifyContactInformationModal = show("verifyContactInformationModal");
export const closeVerifyContactInformationModal = close("verifyContactInformationModal");

// Review Contact Information modal
export const showReviewContactInformationModal = show("reviewContactInformationModal");
export const closeReviewContactInformationModal = close("reviewContactInformationModal");

// KYC modal
export const showKycQuestionnaireModal = show("kycQuestionnaireModal");
export const closeKycQuestionnaireModal = close("kycQuestionnaireModal");
export const showKycQuestionnaireFinishedModal = show("kycQuestionnaireFinishedModal");
export const closeKycQuestionnaireFinishedModal = close("kycQuestionnaireFinishedModal");

// RGS
export const showRgsAtgCheckModal = show("rgsAtgCheckModal");
export const closeRgsAtgCheckModal = close("rgsAtgCheckModal");
export const showLoginTimeReachedLogoutModal = show("loginTimeReachedLogoutModal");
export const closeLoginTimeReachedLogoutModal = close("loginTimeReachedLogoutModal");
export const showFifteenMinutesLeftModal = show("fifteenMinutesLeftModal");
export const closeFifteenMinutesLeftModal = close("fifteenMinutesLeftModal");

export const showSearchSportModal = show("searchSport");
export const closeSearchSportModal = close("searchSport");

export const showSearchCasinoModal = show("searchCasino");
export const closeSearchCasinoModal = close("searchCasino");

export const showCasinoPanicModal = show("casinoPanicModal");
export const closeCasinoPanicModal = close("casinoPanicModal");
export const showCasinoErrorModal = show("casinoErrorModal");
export const closeCasinoErrorModal = close("casinoErrorModal");
export const showCasinoLossLimitModal = show("casinoLossLimitModal");
export const closeCasinoLossLimitModal = close("casinoLossLimitModal");

export const showConfirmWithdrawalBank = show("confirmWithdrawalBank");
export const closeConfirmWithdrawalBank = close("confirmWithdrawalBank");

export const showWithdrawRegisterBankModal = show("withdrawRegisterBankModal");
export const closeWithdrawRegisterBankModal = close("withdrawRegisterBankModal");
export const showWithdrawDeleteBankModal = show("withdrawDeleteBankModal");
export const closeWithdrawDeleteBankModal = close("withdrawDeleteBankModal");
export const showWithdrawEditMobileModal = show("withdrawEditMobileModal");
export const closeWithdrawEditMobileModal = close("withdrawEditMobileModal");
export const showWithdrawConfirmModal = show("withdrawConfirmModal");
export const closeWithdrawConfirmModal = close("withdrawConfirmModal");

// Deposit in modal
export const showDepositModal = showWithExtraPayload("depositModal");
export const closeDepositModal = close("depositModal");

export const showBig9ClearBetslipModal = show("big9ClearBetslipModal");
export const closeBig9ClearBetslipModal = close("big9ClearBetslipModal");

export const showOldBig9StatisticsModal = show("oldBig9StatisticsModal");
export const closeOldBig9StatisticsModal = close("oldBig9StatisticsModal");

export const showBig9StatisticsModal = show("big9StatisticsModal");
export const closeBig9StatisticsModal = close("big9StatisticsModal");

export const showGotoAccountSettingsModal = show("gotoAccountSettingsModal");
export const closeGotoAccountSettingsModal = close("gotoAccountSettingsModal");

// Retrieve invite code modal
export const showRetrieveNewInviteCodeModal = show("retrieveNewInviteCodeModal");
export const closeRetrieveNewInviteCodeModal = close("retrieveNewInviteCodeModal");

// Image upload
export const showImageUploadModal = show("imageUploadModal");
export const closeImageUploadModal = close("imageUploadModal");

// App - Deposit Limit Reminder
export const showDepositLimitReminder = show("depositLimitReminder");
export const closeDepositLimitReminder = close("depositLimitReminder");

// Chat - Block message modal
export const showBlockChatMemberModal = show("blockChatMemberModal");
export const closeBlockChatMemberModal = close("blockChatMemberModal");

// Chat - Report message modal
export const showReportChatMessageModal = show("reportChatMessageModal");
export const closeReportChatMessageModal = close("reportChatMessageModal");

export const showReducedCouponStakeModal = show("reducedCouponStakeModal");
export const closeReducedCouponStakeModal = close("reducedCouponStakeModal");

// Kambi modal
export const showKambiModal = showWithExtraPayload("kambiModal");
export const closeKambiModal = close("kambiModal");

// Welcome New Customer Modal/Drawer
export const showWelcomeNewCustomerModal = show("welcomeNewCustomerModal");
export const closeWelcomeNewCustomerModal = () =>
    broadcastAction(close("welcomeNewCustomerModal")());

// Session Reminder Modal
export const showSessionReminderModal = show("sessionReminderModal");
export const closeSessionReminderModal = close("sessionReminderModal");
