import type {Reducer} from "redux";
import {combineReducers} from "redux";
import type {GameReducerState} from "../../common/gameTypes";
import * as HorseGameActions from "./horseGameActions";

export type HorseGameReducerState = GameReducerState["horse"]["games"];
export type HorseRaceReducerState = GameReducerState["horse"]["races"];

const games: Reducer<HorseGameReducerState, HorseGameActions.Action> = (
    state: HorseGameReducerState = {},
    action: HorseGameActions.Action,
) => {
    switch (action.type) {
        case HorseGameActions.HORSE_GAME_FETCH_RESPONSE:
            return action.error || !action.payload.game
                ? state
                : {
                      ...state,
                      [action.payload.game.id]: action.payload.game,
                  };
        case HorseGameActions.HORSE_GAME_UPDATE:
            return {
                ...state,
                [action.payload.game.id]: action.payload.game,
            };
        default:
            return state;
    }
};

const races: Reducer<HorseRaceReducerState, HorseGameActions.Action> = (
    state: HorseRaceReducerState = {},
    action: HorseGameActions.Action,
) => {
    switch (action.type) {
        case HorseGameActions.HORSE_RACE_EXTENDED_FETCH_RESPONSE:
            return action.error || !action.payload.race
                ? state
                : {
                      ...state,
                      [action.payload.race.id]: action.payload.race,
                  };
        default:
            return state;
    }
};

export default combineReducers({games, races});
