import {omit} from "lodash/fp";
import type {MemberCustomization, MemberFlag, MemberFlags} from "../types/app";
import {type GlobalState as State} from "./memberCustomizationTypes";

const memberCustomizationState = (state: State) => state.memberCustomization;

export const getCustomizationId = (state: State) =>
    memberCustomizationState(state).data.customizationId;

export const getMemberFlags = (state: State): MemberFlags => {
    const {flags} = memberCustomizationState(state);
    return omit("_persist", flags);
};

export const getMemberFlag = (state: State, flag: MemberFlag) =>
    getMemberFlags(state)[flag];

export const getMemberCustomization = (state: State): MemberCustomization => ({
    id: getCustomizationId(state),
    flags: getMemberFlags(state),
});
