import {uniqBy, capitalize} from "lodash";
import {TeamType} from "@atg-tillsammans/types";
import type {ChannelId, ChannelInfo, Message} from "../domain/teamChat";
import {AuthorType, ChatAllowedAction} from "../domain/teamChat";
import {t} from "@lingui/macro";

const systemAuthorTypes = [AuthorType.SYSTEM, AuthorType.INFO];

export const isSystemAuthorType = (messageAuthorType: AuthorType): boolean =>
    systemAuthorTypes.includes(messageAuthorType);

export const channelIdToString = (channelId: ChannelId): string => channelId;

export const channelIdFromString = (channelIdString: string): ChannelId =>
    channelIdString;

export const messageById = (
    messages: Message[],
    messageId: number,
): Message | undefined => messages.find((message) => message.id === messageId);

export const mergeMessages = (messages: Message[], moreMessages: Message[]): Message[] =>
    uniqBy(messages.concat(moreMessages), "id");

export const getNameFromMessage = (message?: Message): string => {
    if (!message) return "";

    const {user} = message;

    if (!user) return "";

    if (user.firstName && isSystemAuthorType(message.authorType)) {
        return user.firstName;
    }

    if (user.firstName && user.lastName) {
        return `${user.firstName} ${user.lastName}`;
    }

    if (user.lastName) return user.lastName;

    if (user.firstName) return user.firstName;

    if (user.name) return user.name;

    if (user.displayName) return user.displayName;

    return "";
};

export const capitalizedNameFromMessage = (message?: Message): string =>
    getNameFromMessage(message).split(" ").map(capitalize).join(" ") || "";

export const isNotEmpty = (text?: string | null): boolean =>
    typeof text === "string" && Boolean(text.trim());

export const getMessageMemberId = (message: Message): number | null =>
    message && message.user ? message.user.id : null;

export const isMessageOwner = (message: Message, memberId: number | null): boolean =>
    getMessageMemberId(message) === memberId;

export const canRemoveMessages = (allowedActions: ChatAllowedAction[]): boolean =>
    allowedActions.includes(ChatAllowedAction.REMOVE_COMMENTS);
export const canBlockMessages = (allowedActions: ChatAllowedAction[]): boolean =>
    allowedActions && allowedActions.includes(ChatAllowedAction.BLOCK_COMMENTS);

const linkRegex =
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gim;

const allowedLinks: string[] = [
    ".atg.se/?sharedBet",
    ".atg.se/inbjudan",
    ".atg.se/tillsammans/inbjudan",
];

export const hasIllegalLink = (input: string): boolean => {
    if (!input) return false;
    const links = input.match(linkRegex);
    if (!links) return false;

    return links.some(
        (link) => !allowedLinks.some((allowedLink) => link.indexOf(allowedLink) !== -1),
    );
};

export const getTag = (authorType: AuthorType): string | null => {
    if (authorType === AuthorType.TEAM_CAPTAIN) {
        return t({id: "chat.message.author.tag.captain", message: "Lagkapten"});
    }
    if (authorType === AuthorType.BET_PLACER) {
        return t({id: "chat.message.author.tag.gameinitiator", message: "Spelläggare"});
    }
    if (authorType === AuthorType.MODERATOR) {
        return t({id: "chat.message.author.tag.moderator", message: "Moderator"});
    }
    if (isSystemAuthorType(authorType)) {
        return t({id: "chat.message.author.tag.system", message: "ATG Tillsammans®"});
    }
    return null;
};

export const mapErrorCode = (id: string | null): string => {
    switch (id) {
        case "USER_BLOCKED_FROM_CHANNEL":
            return t({
                id: "chat.error.message.USER_BLOCKED_FROM_CHANNEL",
                message:
                    "Laget har blockerat dig från att skriva inlägg i chatten. Kontakta ATG Kundservice om du vill ha mer information kring detta.",
            });
        case "NOT_MEMBER_OF_TEAM":
        case "LACKS_ROLE":
        case "NOT_CURRENT_USER":
        case "NOT_OWNER_OF_COMMENT":
            return t({
                id: "chat.error.message.LACKS_ROLE",
                message: "Det du försöker göra är inte tillåtet.",
            });
        case "NEVER_BOUGHT_A_SHARE":
            return t({
                id: "chat.error.message.NEVER_BOUGHT_A_SHARE",
                message: "För att skriva inlägg måste du ha köpt en andel",
            });
        case "COMMENT_NOT_IN_CHANNEL":
        case "ACCESS_DENIED":
        default:
            return t({
                id: "chat.error.message.default",
                message: "Tyvärr verkar något ha gått fel hos oss. Försök igen senare.",
            });
    }
};

export const getChannelInfo = (
    channelId: ChannelId,
    teamName: string,
    allowedActions: Array<ChatAllowedAction>,
    memberId: number | null,
): ChannelInfo => ({
    channelId,
    allowedActions,
    memberId,
    header: t({id: "chat.channel.info.header", message: "Chatt för laget"}),
    subHeader: teamName,
    buttonTitle: "",
    getTag,
    mapErrorCode,
});

export const getAuthorType = (
    isCaptain: boolean,
    isActiveBetter: boolean,
): AuthorType => {
    if (isCaptain) {
        return AuthorType.TEAM_CAPTAIN;
    }
    if (isActiveBetter) {
        return AuthorType.BET_PLACER;
    }
    return AuthorType.MEMBER;
};

export const getAllowedActions = (
    authorType: AuthorType,
    teamType: TeamType,
): Array<ChatAllowedAction> => {
    switch (authorType) {
        case AuthorType.TEAM_CAPTAIN:
            return [TeamType.CAMPAIGN, TeamType.BEGINNER].includes(teamType)
                ? [
                      ChatAllowedAction.BLOCK_COMMENTS,
                      ChatAllowedAction.REMOVE_COMMENTS,
                      ChatAllowedAction.UPDATE_CHAT,
                  ]
                : [ChatAllowedAction.REMOVE_COMMENTS, ChatAllowedAction.UPDATE_CHAT];
        case AuthorType.BET_PLACER:
            return TeamType.BEGINNER === teamType
                ? [ChatAllowedAction.REMOVE_COMMENTS, ChatAllowedAction.UPDATE_CHAT]
                : [ChatAllowedAction.UPDATE_CHAT];
        default:
            return [ChatAllowedAction.UPDATE_CHAT];
    }
};
