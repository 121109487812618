import {delay, put, takeEvery} from "redux-saga/effects";
import {SHOW_TOAST, type ShowToastAction} from "./toastActions";
import * as ToastActions from "./toastActions";

export function* hideToastWhenTimeoutExpired({payload: {toast}}: ShowToastAction) {
    const {timeout = 3000} = toast;
    if (timeout === 0) return;
    yield delay(timeout);
    yield put(ToastActions.hideToast(toast));
}

export default function* toastSaga() {
    yield takeEvery(SHOW_TOAST, hideToastWhenTimeoutExpired);
}
