import type {State as AuthState} from "@atg-shared/auth/domain";
import type {FetchState} from "@atg-shared/fetch-types";
import type {TeamMembers} from "@atg-tillsammans/types";
import type {MemberTeam} from "@atg-tillsammans/types/generated";

export type MemberTeamState = {
    items: MemberTeam[] | null;
};

type TeamMembersState = {
    data: TeamMembers;
};

export type TeamMembersFetchState = FetchState<TeamMembersState>;

type ImageUploadState = {
    loading: boolean;
    error: {
        message: string;
    } | null;
};

export type CreateTeamState = {
    teamId: string | null;
    loading: boolean;
    error: string | null;
};

export const DEFAULT_CREATE_TEAM_STATE: CreateTeamState = {
    teamId: null,
    loading: false,
    error: null,
};

type RevokeApplicationState = Record<string, never>;

export type RevokeApplicationFetchState = FetchState<RevokeApplicationState>;

type TeamState = {
    teamId: string | null;
    teamImageUrl: string | null;
};

export type State = {
    list: MemberTeamState;
    team: TeamState;
    teamMembers: TeamMembersFetchState;
    imageUploadFlow: ImageUploadState;
    revokeApplication: RevokeApplicationFetchState;
    createTeamFlow: CreateTeamState;
};

export type GlobalState = {
    teams: State;
} & AuthState;
