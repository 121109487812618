import {call, FETCH} from "@atg-shared/fetch-redux";
import type {FetchAction} from "@atg-shared/fetch-types";
import type {
    NotificationSetting,
    PersonalizationSettings,
    GuideFlag,
} from "./personalization";
import * as PersonalizationApi from "./personalizationApi";

export const RECEIVED_PERSONALIZATION_SETTINGS_PUSH =
    "tillsammans/RECEIVED_PERSONALIZATION_SETTINGS_PUSH";

export const UPDATE_PUSH_NOTIFICATION_SETTINGS_REQUEST =
    "tillsammans/UPDATE_PUSH_NOTIFICATION_SETTINGS_REQUEST";
export const UPDATE_PUSH_NOTIFICATION_SETTINGS_RESPONSE =
    "tillsammans/UPDATE_PUSH_NOTIFICATION_SETTINGS_RESPONSE";

export const UPDATE_GUIDE_FLAG_REQUEST = "tillsammans/UPDATE_GUIDE_FLAG_REQUEST";
export const UPDATE_GUIDE_FLAG_RESPONSE = "tillsammans/UPDATE_GUIDE_FLAG_RESPONSE";

export type ReceivedPersonalizationSettingsPushAction = {
    type: typeof RECEIVED_PERSONALIZATION_SETTINGS_PUSH;
    payload: PersonalizationSettings;
};

export type UpdatePushNotificationSettingsAction = FetchAction<
    typeof UPDATE_PUSH_NOTIFICATION_SETTINGS_REQUEST,
    typeof UPDATE_PUSH_NOTIFICATION_SETTINGS_RESPONSE,
    NotificationSetting[]
>;

export type UpdateGuideFlagAction = FetchAction<
    typeof UPDATE_GUIDE_FLAG_REQUEST,
    typeof UPDATE_GUIDE_FLAG_RESPONSE,
    GuideFlag[]
>;

export const receivedPersonalizationSettingsPush = (
    personalizationSettings: PersonalizationSettings,
): ReceivedPersonalizationSettingsPushAction => ({
    type: RECEIVED_PERSONALIZATION_SETTINGS_PUSH,
    payload: personalizationSettings,
});

export const updatePushNotificationSettings = (
    notificationSettings: NotificationSetting[],
): UpdatePushNotificationSettingsAction => ({
    type: FETCH,
    payload: {
        requestAction: UPDATE_PUSH_NOTIFICATION_SETTINGS_REQUEST,
        receiveAction: UPDATE_PUSH_NOTIFICATION_SETTINGS_RESPONSE,
        callApi: call(PersonalizationApi.updatePushNotificationSettings, {
            notificationSettings,
        }),
    },
});

export const updateGuideFlag = (guideFlag: GuideFlag): UpdateGuideFlagAction => ({
    type: FETCH,
    payload: {
        requestAction: UPDATE_GUIDE_FLAG_REQUEST,
        receiveAction: UPDATE_GUIDE_FLAG_RESPONSE,
        callApi: call(PersonalizationApi.updateGuideFlag, guideFlag),
    },
});

export type PersonalizationAction =
    | ReceivedPersonalizationSettingsPushAction
    | UpdatePushNotificationSettingsAction
    | UpdateGuideFlagAction;
