import {fetchAuthorized} from "@atg-shared/auth";
import type {MemberCustomization} from "../types/app";
import type {FetchMemberCustomizationResponse} from "./memberCustomizationTypes";
import {
    CUSTOMIZATION_CONTEXT,
    CUSTOMIZATION_ENDPONT,
} from "./memberCustomizationConstants";
import {trimPersistData} from "./memberCustomizationUtils";

const toRequetData = (customization: MemberCustomization) => {
    const {id, ...data} = trimPersistData(customization);
    return {id, data: JSON.stringify(data)};
};

export const getMemberCustomization = () =>
    fetchAuthorized<FetchMemberCustomizationResponse[]>(
        `${CUSTOMIZATION_ENDPONT}?context=${CUSTOMIZATION_CONTEXT}`,
        {
            method: "GET",
        },
    );

export const createMemberCustomization = (customization: MemberCustomization) => {
    const {data} = toRequetData(customization);
    return fetchAuthorized<FetchMemberCustomizationResponse>(
        `${CUSTOMIZATION_ENDPONT}?context=${CUSTOMIZATION_CONTEXT}`,
        {
            method: "POST",
            body: JSON.stringify({
                context: CUSTOMIZATION_CONTEXT,
                data,
            }),
        },
    );
};

export const updateMemberCustomization = (
    customization: Required<MemberCustomization>,
) => {
    const {id, data} = toRequetData(customization);
    return fetchAuthorized<FetchMemberCustomizationResponse>(
        `${CUSTOMIZATION_ENDPONT}?context=${CUSTOMIZATION_CONTEXT}`,
        {
            method: "PUT",
            body: JSON.stringify({
                id,
                context: CUSTOMIZATION_CONTEXT,
                data,
            }),
        },
    );
};

export const deleteMemberCustomization = (id: string) =>
    fetchAuthorized<FetchMemberCustomizationResponse>(`${CUSTOMIZATION_ENDPONT}/${id}`, {
        method: "DELETE",
    });
