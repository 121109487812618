import {t} from "@lingui/macro";
import {PlaceBetErrorCode} from "@atg-tillsammans-shared/types/sport";

export const placeBetErrorMessage = (errorCode: PlaceBetErrorCode): string => {
    switch (errorCode) {
        case PlaceBetErrorCode.COMPETITION_UNDER_REVIEW:
            return t({
                id: "placeBet.error.message.COMPETITION_UNDER_REVIEW",
                message:
                    "Tyvärr har något inträffat som gjort att vi temporärt behöver stänga spelet. Vänligen kontakta kundservice för mer information.",
            });
        case PlaceBetErrorCode.SELL_NOT_OPEN:
            return t({
                id: "placeBet.error.message.SELL_NOT_OPEN",
                message:
                    "Tyvärr, spelet är redan stängt. Det beror troligen på att första matchen har börjat.",
            });
        case PlaceBetErrorCode.INSUFFICIENT_FUNDS:
            return t({
                id: "placeBet.error.message.INSUFFICIENT_FUNDS",
                message:
                    "Laget har inte tillräckligt med pengar för att spela den här kupongen.",
            });
        case PlaceBetErrorCode.FAILED_TO_GENERATE_BET:
            return t({
                id: "placeBet.error.message.FAILED_TO_GENERATE_BET",
                message:
                    "Du har inte gett Harry tillräckligt med utrymme att jobba. Pröva att höja insatsen eller ta bort några hästar.",
            });
        case PlaceBetErrorCode.MUST_PARTICIPATE:
            return t({
                id: "placeBet.error.message.MUST_PARTICIPATE",
                message: "Du måste delta i omgången för att kunna spela kupongen.",
            });
        case PlaceBetErrorCode.ASYNC_RETRYABLE_TIMEOUT:
            return t({
                id: "placeBet.error.message.ASYNC_RETRYABLE_TIMEOUT",
                message:
                    "Det tar lite längre tid än vanligt att lämna in spelet, vänta kvar!",
            });
        case PlaceBetErrorCode.ROUND_NOT_ACTIVATED:
        case PlaceBetErrorCode.MAX_NR_OF_BETS:
        default:
            return t({
                id: "placeBet.error.message.default",
                message:
                    "Ditt spel kunde tyvärr inte registreras på grund av ett temporärt tekniskt fel. Vänligen försök igen.",
            });
    }
};
