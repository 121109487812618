import {put, take} from "redux-saga/effects";
import {ACCESS_TOKEN_SUCCESS, ACCESS_TOKEN_ERROR} from "./accessTokenConstants";
import type {
    ReceiveNewAccessTokenAction,
    AccessTokenSuccessAction,
} from "./accessTokenActions";
import {requestAccessToken} from "./accessTokenActions";

type Action = ReceiveNewAccessTokenAction | AccessTokenSuccessAction;

function* refreshAccessToken(shouldTriggerLogin = false) {
    yield put(requestAccessToken(shouldTriggerLogin));
    const action: Action = yield take([ACCESS_TOKEN_SUCCESS, ACCESS_TOKEN_ERROR]);

    if (action.type === ACCESS_TOKEN_SUCCESS) return true;

    return false;
}

export default refreshAccessToken;
