import type {Participant} from "@atg-tillsammans/types/generated";
import type {RoundErrorCode} from "@atg-tillsammans/types";

export enum RoundPurchaseModalType {
    CONFIRM,
    SUCCESS,
    NONE,
}

export type RoundPurchaseState = {
    input: {
        numberOfShares: number;
    } | null;
    data: Participant | null;
    loading: boolean;
    error: RoundErrorCode | null;
    warning: string | null;
    modal: RoundPurchaseModalType;
};

export const INITIAL_PURCHASE_STATE: RoundPurchaseState = {
    input: null,
    data: null,
    loading: false,
    error: null,
    warning: null,
    modal: RoundPurchaseModalType.NONE,
};

export type State = {
    purchase: RoundPurchaseState;
};

export type GlobalState = {
    round: State;
};
