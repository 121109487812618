import type {Bet} from "@atg-sport-shared/big9-types/betTypes";
import type {HarryBetBody} from "@atg-sport-shared/big9-types/apiTypes/betTypes";
import {call, FETCH} from "@atg-shared/fetch-redux";
import type {FetchAction} from "@atg-shared/fetch-types";
import type SportCoupon from "@atg-tillsammans/coupon-common/sport/domain/SportCoupon.class";
import type {PlaceBetCoupon} from "@atg-sport-shared/big9-types/couponTypes";
import * as SportBetApi from "./sportBetApi";

export const SPORT_PLACE_BET_FLOW = "tillsammans/SPORT_PLACE_BET_FLOW" as const;
export const SPORT_PLACE_HARRY_BET_FLOW =
    "tillsammans/SPORT_PLACE_HARRY_BET_FLOW" as const;
export const SPORT_PLACE_BET_FLOW_STATUS =
    "tillsammans/SPORT_COUPON_PLACE_BET_STATUS" as const;
export const SPORT_PLACE_BET_REQUEST = "tillsammans/SPORT_PLACE_BET_REQUEST" as const;
export const SPORT_PLACE_BET_RECEIVE = "tillsammans/SPORT_PLACE_BET_RECEIVE" as const;
export const SPORT_PLACE_BET_RESET = "tillsammans/SPORT_PLACE_BET_RESET" as const;

export const SPORT_PLACE_HARRY_BET_REQUEST =
    "tillsammans/SPORT_PLACE_HARRY_BET_REQUEST" as const;
export const SPORT_PLACE_HARRY_BET_RECEIVE =
    "tillsammans/SPORT_PLACE_HARRY_BET_RECEIVE" as const;
export const SPORT_PLACE_HARRY_BET_RESET =
    "tillsammans/SPORT_PLACE_HARRY_BET_RESET" as const;

export type SportPlaceBetFetchAction = FetchAction<
    typeof SPORT_PLACE_BET_REQUEST,
    typeof SPORT_PLACE_BET_RECEIVE,
    Bet
>;

export type SportPlaceHarryBetFetchAction = FetchAction<
    typeof SPORT_PLACE_HARRY_BET_REQUEST,
    typeof SPORT_PLACE_HARRY_BET_RECEIVE,
    Bet
>;

export type SportPlaceBetFlowAction = {
    type: typeof SPORT_PLACE_BET_FLOW;
    payload: {
        teamId: number;
        gameId: string;
        coupon: SportCoupon;
    };
};

export type SportPlaceBetResetAction = {
    type: typeof SPORT_PLACE_BET_RESET;
};

export type SportBetActions =
    | SportPlaceBetFlowAction
    | SportPlaceBetFetchAction
    | SportPlaceBetResetAction;

export const placeBetFlow = (
    teamId: number,
    gameId: string,
    coupon: SportCoupon,
): SportPlaceBetFlowAction => ({
    type: SPORT_PLACE_BET_FLOW,
    payload: {teamId, gameId, coupon},
});

export const placeBet = (
    teamId: number,
    gameId: string,
    betBody: PlaceBetCoupon,
): SportPlaceBetFetchAction => ({
    type: FETCH,
    payload: {
        requestAction: SPORT_PLACE_BET_REQUEST,
        receiveAction: SPORT_PLACE_BET_RECEIVE,
        callApi: call(SportBetApi.placeBet, {gameId, teamId, betBody}),
    },
});

export const placeHarrySportBet = (
    teamId: number,
    gameId: string,
    betBody: HarryBetBody,
): SportPlaceHarryBetFetchAction => ({
    type: FETCH,
    payload: {
        requestAction: SPORT_PLACE_HARRY_BET_REQUEST,
        receiveAction: SPORT_PLACE_HARRY_BET_RECEIVE,
        callApi: call(SportBetApi.placeHarrySportBet, {teamId, gameId, betBody}),
    },
});

export const placeBetResetFlow = (): SportPlaceBetResetAction => ({
    type: SPORT_PLACE_BET_RESET,
});
