import {SM, MD, LG} from "./matchMediaConstants";

/** @deprecated in favor of `atg-breakpoints` */
export const isSmallScreen = (state) =>
    state.activeBreakpoint ? state.activeBreakpoint < SM : false;

/** @deprecated in favor of `atg-breakpoints` */
export const isMediumScreen = (state) =>
    state.activeBreakpoint ? state.activeBreakpoint < MD : false;

/** @deprecated in favor of `atg-breakpoints` */
export const isLargeScreen = (state) =>
    state.activeBreakpoint ? state.activeBreakpoint >= MD : false;

/** @deprecated in favor of `atg-breakpoints` */
export const isLargerScreen = (state) =>
    state.activeBreakpoint ? state.activeBreakpoint >= LG : false;

/** @deprecated in favor of `atg-breakpoints` */
export const getActiveBreakpoint = (state) => state.activeBreakpoint;
