import type {SagaIterator} from "redux-saga";
import {takeLatest, takeLeading, take, call, put, select} from "redux-saga/effects";
import {AuthSelectors} from "@atg-shared/auth";
import * as UserActions from "@atg-global-shared/user/userActionTypes";
import {TeamActions} from "@atg-tillsammans/team-data-access/redux/team";
import {
    type GetMemberCustomizationResponseAction,
    MemberCustomizationActions,
} from "./memberCustomizationActions";
import {
    GET_MEMBER_CUSTOMIZATION_REQUEST,
    GET_MEMBER_CUSTOMIZATION_RESPONSE,
} from "./memberCustomizationConstants";
import * as CustomizationAPI from "./memberCustomizationApi";
import * as MemberCustomizationSelectors from "./memberCustomizationSelectors";
import * as AppActions from "./appConstants";

export function* fetchMemberCustomization(): SagaIterator {
    try {
        const {ok, data} = yield call(CustomizationAPI.getMemberCustomization);
        if (ok) {
            yield put(MemberCustomizationActions.fetchMemberCustomizationSuccess(data));
        } else {
            yield put(MemberCustomizationActions.fetchMemberCustomizationError());
        }
    } catch (e: unknown) {
        yield put(MemberCustomizationActions.fetchMemberCustomizationError());
    }
}

export function* initMemberCustomization(): SagaIterator {
    yield put(MemberCustomizationActions.fetchMemberCustomization());

    const {error, payload}: GetMemberCustomizationResponseAction = yield take([
        GET_MEMBER_CUSTOMIZATION_RESPONSE,
    ]);

    if (!error && payload.data?.length === 0) {
        try {
            const {flags} = yield select(
                MemberCustomizationSelectors.getMemberCustomization,
            );

            const {ok, data} = yield call(CustomizationAPI.createMemberCustomization, {
                flags,
            });

            if (ok) {
                yield put(MemberCustomizationActions.addMemberCustomizationSuccess(data));
            }
        } catch (e: unknown) {
            yield put(MemberCustomizationActions.addMemberCustomizationError());
        }
    }
}

export function* cleanupMemberCustomization(): SagaIterator {
    const isLoggedIn = yield select(AuthSelectors.isLoggedIn);

    if (!isLoggedIn) return;

    const memberCustomization = yield select(
        MemberCustomizationSelectors.getMemberCustomization,
    );

    if (memberCustomization.id) {
        const {ok} = yield call(
            CustomizationAPI.updateMemberCustomization,
            memberCustomization,
        );

        if (ok) {
            yield put(MemberCustomizationActions.clearMemberCustomization());
        }
    }
}

export function* updateStartPageFlags({
    payload,
}: TeamActions.SetMyTeamsAction): SagaIterator {
    const isLoggedIn = yield select(AuthSelectors.isLoggedIn);

    if (!isLoggedIn) return;

    yield put(MemberCustomizationActions.setStartPageHeroFlag(payload.teams.length <= 0));
}

export function* memberCustomizationSaga(): SagaIterator {
    yield takeLatest(GET_MEMBER_CUSTOMIZATION_REQUEST, fetchMemberCustomization);
    yield takeLeading(
        [
            UserActions.LOGIN_FINISHED,
            UserActions.RECEIVE_USER,
            AppActions.USER_ENTERED_TILLSAMMANS,
        ],
        initMemberCustomization,
    );
    yield takeLeading(
        [UserActions.LOGOUT_INITIATED, AppActions.APP_CONTEXT_LEAVE],
        cleanupMemberCustomization,
    );
    yield takeLatest(TeamActions.RECEIVE_TEAMS, updateStartPageFlags);
}
