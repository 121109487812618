import {css} from "@emotion/react";
import * as palette from "../../palette";

export const defaultFontFamily = '"Roboto", "Helvetica", "Arial", sans-serif';
export const brandFontFamily = '"ATGRubrik", sans-serif';
export const fontWeightRegular = 400;
export const fontWeightMedium = 500;
export const fontWeightBold = 700;
// 16px is the default font-size used by browsers.
const htmlFontSize = 16;
export const pxToRem = (fontSize: number): string => `${fontSize / htmlFontSize}rem`;

// values from Zeplin
// Unitless following https://meyerweb.com/eric/thoughts/2006/02/08/unitless-line-heights/
export const lineHeights = {
    headline1: 1.67,
    headline2: 1.6,
    headline3: 1.5,
    headline4: 1.43,
    brandHeadline1: 1.17,
    brandHeadline2: 1.43,
    brandHeadline3: 1.6,
    brandHeadline4: 1.5,
    sectionHeadline: 1.33,
    body1: 1.5,
    body2: 1.43,
    body3: 1.33,
};

// values from Zeplin
export const fontSizes = {
    headline1: 24,
    headline2: 20,
    headline3: 16,
    headline4: 14,
    brandHeadline1: 48,
    brandHeadline2: 28,
    brandHeadline3: 20,
    brandHeadline4: 16,
    sectionHeadline: 12,
    body1: 16,
    body2: 14,
    body3: 12,
};

export const sectionHeadlineColor = "#7f8b95";

export const fontColor = {
    dark: {
        primary: palette.common.white,
        secondary: "#d2d6e5",
        error: palette.feedback.error,
        success: palette.feedback.success,
        notice: palette.feedback.notice,
        link: palette.link.text,
        inherit: "inherit",
    },
    light: {
        primary: palette.text.primary,
        secondary: palette.grey[400],
        error: palette.feedback.error,
        success: palette.feedback.success,
        notice: palette.feedback.notice,
        link: palette.link.text,
        inherit: "inherit",
    },
};

type RootProps = {
    gutterBottom?: boolean;
    color: "primary" | "secondary" | "error" | "success" | "notice" | "link" | "inherit";
    ellipsis?: boolean;
    mode: "light" | "dark";
};

export const rootStyle = ({gutterBottom, color, ellipsis, mode}: RootProps) => css`
    margin: 0;
    color: ${fontColor[mode][color]};

    ${color === "link" &&
    css`
        &:hover {
            text-decoration: underline;
        }
    `}

    ${gutterBottom &&
    css`
        margin-bottom: 0.75em;
    `}

    ${ellipsis &&
    css`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    `}
`;

/**
 * these variants are based on the typography designs in
 * https://app.zeplin.io/project/5d5a6adf2dba3f9bc1d7334e/screen/5d5a73b4a192a38cadd452be
 */
export const headline1 = css`
    font-family: ${defaultFontFamily};
    font-size: ${pxToRem(fontSizes.headline1)};
    font-weight: ${fontWeightMedium};
    line-height: ${lineHeights.headline1};
`;

export const headline2 = css`
    font-family: ${defaultFontFamily};
    font-size: ${pxToRem(fontSizes.headline2)};
    font-weight: ${fontWeightMedium};
    line-height: ${lineHeights.headline2};
`;

export const headline3 = css`
    font-family: ${defaultFontFamily};
    font-size: ${pxToRem(fontSizes.headline3)};
    font-weight: ${fontWeightMedium};
    line-height: ${lineHeights.headline3};
`;

export const headline4 = css`
    font-family: ${defaultFontFamily};
    font-size: ${pxToRem(fontSizes.headline4)};
    font-weight: ${fontWeightMedium};
    line-height: ${lineHeights.headline4};
`;

export const brandHeadline1 = css`
    font-family: ${brandFontFamily};
    font-size: ${pxToRem(fontSizes.brandHeadline1)};
    font-weight: ${fontWeightRegular};
    line-height: ${lineHeights.brandHeadline1};
`;

export const brandHeadline2 = css`
    font-family: ${brandFontFamily};
    font-size: ${pxToRem(fontSizes.brandHeadline2)};
    font-weight: ${fontWeightRegular};
    line-height: ${lineHeights.brandHeadline2};
`;

export const brandHeadline3 = css`
    font-family: ${brandFontFamily};
    font-size: ${pxToRem(fontSizes.brandHeadline3)};
    font-weight: ${fontWeightRegular};
    line-height: ${lineHeights.brandHeadline3};
`;

export const brandHeadline4 = css`
    font-family: ${brandFontFamily};
    font-size: ${pxToRem(fontSizes.brandHeadline4)};
    font-weight: ${fontWeightRegular};
    line-height: ${lineHeights.brandHeadline4};
`;

export const sectionHeadline = css`
    font-family: ${defaultFontFamily};
    font-size: ${pxToRem(fontSizes.sectionHeadline)};
    font-weight: ${fontWeightMedium};
    line-height: ${lineHeights.sectionHeadline};
    text-transform: uppercase;
    color: ${sectionHeadlineColor};
`;

export const body1 = css`
    font-family: ${defaultFontFamily};
    font-size: ${pxToRem(fontSizes.body1)};
    font-weight: ${fontWeightRegular};
    line-height: ${lineHeights.body1};
`;

export const body2 = css`
    font-family: ${defaultFontFamily};
    font-size: ${pxToRem(fontSizes.body2)};
    font-weight: ${fontWeightRegular};
    line-height: ${lineHeights.body2};
`;

export const body3 = css`
    font-family: ${defaultFontFamily};
    font-size: ${pxToRem(fontSizes.body3)};
    font-weight: ${fontWeightRegular};
    line-height: ${lineHeights.body3};
`;
