import type {Selector} from "reselect";
import {createSelector} from "reselect";
import type {Match, MatchClock} from "@atg-sport-shared/big9-types/matchTypes";
import type {
    LeagueStatistics,
    MatchStatistics,
    TeamStatistics,
} from "@atg-sport-shared/big9-types/statisticsTypes";
import type {GlobalGameState} from "../../common/gameTypes";
import type {SportStatistics} from "../sportGameTypes";

export const getSportStatistics = (state: GlobalGameState): SportStatistics =>
    state.game.sport.statistics;

export const getMatchesStatistics = (
    state: GlobalGameState,
): SportStatistics["matches"] | undefined => getSportStatistics(state).matches;

export const getTeamsStatistics = (
    state: GlobalGameState,
): SportStatistics["teams"] | undefined => getSportStatistics(state).teams;

export const getLeaguesStatistics = (
    state: GlobalGameState,
): SportStatistics["leagues"] | undefined => getSportStatistics(state).leagues;

export const getMatchComments = (
    state: GlobalGameState,
): SportStatistics["matchComments"] => getSportStatistics(state).matchComments;

export const createMatchStatisticsSelector = (
    match: Match,
): Selector<GlobalGameState, MatchStatistics | null> =>
    createSelector(
        getMatchesStatistics,
        (matches) =>
            (match?.sportradar.eventId && matches?.[match.sportradar.eventId]) || null,
    );

export const createTeamSelector = (
    id = "",
): Selector<GlobalGameState, TeamStatistics | null> =>
    createSelector(getTeamsStatistics, (teams) => teams?.[id] || null);

export const createLeagueSelector = (
    leagueId: LeagueStatistics["id"],
): Selector<GlobalGameState, LeagueStatistics | null> =>
    createSelector(getLeaguesStatistics, (leagues) => leagues?.[leagueId] || null);

export const createMatchCommentForMatchSelector = (
    matchId: string,
    gameId: string,
): Selector<GlobalGameState, string | null> =>
    createSelector(getMatchComments, (comments) => comments[gameId]?.[matchId] ?? null);

export const getMatchClocks = (state: GlobalGameState): Record<string, MatchClock> =>
    state.game.sport.matchClocks;
