import type {TrackEvent} from "@atg-shared/analytics";
import {trackEvent} from "@atg-shared/analytics";
import type {GameType} from "@atg-tillsammans/types/generated";
import {
    MENU_CLICK,
    TEAM_CARD_CLICK,
    TEAM_CHAT,
    START_PAGE_CLICK,
    type LOGIN_STARTED,
    type LOGIN_COMPLETE,
    type TillsammansTrackingEvent,
    TEAM_PAGE_CLICK,
    TEAM_SEARCH,
    TERMINATE_TEAM_CLICK,
    PURCHASE_SHARE,
} from "./tillsammansAnalytics.types";
import {toAnalyticsFormat} from "./tillsammansAnalyticsUtils";

const trackTillsammansEvent: TrackEvent<TillsammansTrackingEvent> = trackEvent;

export const trackClickArea = (clickArea?: string | number) => {
    trackTillsammansEvent({
        event: TEAM_CARD_CLICK,
        clickedSection: `Area Clicked: ${clickArea}`,
    });
};

export const trackTeamChatSend = (teamName: string) => {
    trackTillsammansEvent({
        event: TEAM_CHAT,
        clickedAction: "sent message in chat",
        teamName,
    });
};

type LoginEvent = typeof LOGIN_STARTED | typeof LOGIN_COMPLETE;

export const trackLoginEvent = (event: LoginEvent) => {
    trackTillsammansEvent({
        event,
    });
};

export const trackSideMenuClick = (label: string) => {
    trackTillsammansEvent({
        event: MENU_CLICK,
        menuType: "tillsammans",
        menuLabel: toAnalyticsFormat(label),
    });
};

export const trackStartPageClick = ({
    label,
    location,
    target,
    area,
}: {
    label: string;
    location: string;
    target?: string | undefined;
    area?: string | undefined;
}) => {
    trackTillsammansEvent({
        event: START_PAGE_CLICK,
        startPageObject: label.toLowerCase(),
        resultOfClick: location,
        target,
        area,
    });
};

export const trackStartPageTeamCardClick = ({
    label,
    location,
    teamId,
}: {
    label: string;
    location: string;
    teamId: string;
}) => {
    trackStartPageClick({
        label,
        location,
        target: `team-${teamId}`,
    });
};

export const trackTeamPageClick = ({
    label,
    location,
    area,
}: {
    label: string;
    location?: string | undefined;
    area?: string | undefined;
}) => {
    trackTillsammansEvent({
        event: TEAM_PAGE_CLICK,
        teamPageObject: label,
        resultOfClick: location,
        area,
    });
};

export const trackTeamSearchEvent = ({
    eventAction,
    eventType,
    value,
}: {
    eventAction: string;
    eventType?: string | string[] | undefined;
    value?: string | undefined;
}) => {
    trackTillsammansEvent({
        event: TEAM_SEARCH,
        eventAction,
        eventType,
        value,
    });
};

export const trackTerminateTeamEvent = ({teamName}: {teamName: string}) => {
    trackTillsammansEvent({
        event: TERMINATE_TEAM_CLICK,
        teamName,
    });
};

export const trackPurchaseShareEvent = ({
    cost,
    name,
    variant,
    category,
    id,
}: {
    cost: number;
    name: GameType;
    variant: string;
    category: string;
    id: string;
}) => {
    trackTillsammansEvent({
        event: PURCHASE_SHARE,
        area: undefined,
        cost: cost ? cost / 100 : undefined,
        name,
        variant,
        category,
        id,
    });
};
