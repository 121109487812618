import loglevel from "loglevel";
import serverLog from "./src/serverLog";

export {addLogEntry} from "./src/serverLog";

const log = loglevel.getLogger("tillsammans");

// Note: Plugin responsible for reporting back errors to splunk
serverLog(log);

log.setLevel("warn");

export default log;
