import {BatchBetPlayErrorCode} from "@atg-tillsammans/types";
import type {BetWithDetails, ShareInfoV2} from "@atg-tillsammans/types/generated";
import type {AlertSeverity} from "atg-ui-components";

export enum BetErrorCode {
    INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
    FAILED_TO_GENERATE_BET = "FAILED_TO_GENERATE_BET",
    MAX_NR_OF_BETS = "MAX_NR_OF_BETS",
    SELL_NOT_OPEN = "SELL_NOT_OPEN",
    COMPETITION_UNDER_REVIEW = "COMPETITION_UNDER_REVIEW",
    ASYNC_RETRYABLE_TIMEOUT = "ASYNC_RETRYABLE_TIMEOUT",
    TECHNICAL_ERROR = "TECHNICAL_ERROR",
    INVALID_HARRY_BET_V75 = "INVALID_HARRY_BET_V75",
    INVALID_HARRY_BET_V86 = "INVALID_HARRY_BET_V86",
    CONDITIONS_TOO_STRICT = "CONDITIONS_TOO_STRICT",
    INVALID_COUPON_STAKE = "INVALID_COUPON_STAKE",
    INVALID_HARRY_MAX_STAKE = "INVALID_HARRY_MAX_STAKE",
    COUPON_ALREADY_PLACED = "COUPON_ALREADY_PLACED",
    BET_ALREADY_PLACED = "BET_ALREADY_PLACED",
    RACE_ALREADY_STARTED = "RACE_ALREADY_STARTED",
    UNKNOWN_STATE = "UNKNOWN_STATE",
}

export type BetStatusErrorCode = keyof typeof BetStatusErrorCodes;

export const BetStatusErrorCodes = {...BetErrorCode, ...BatchBetPlayErrorCode};

export type BetStatusError = {
    code: BetStatusErrorCode;
    level: AlertSeverity;
    message: string;
};

export type BetStatus = {
    couponId: string;
    success: boolean;
    receipt?: BetWithDetails;
    error?: BetStatusError;
};

export type BetState = {
    [id: string]: BetStatus;
};

export type GlobalBetState = {
    bet: BetState;
};

export type ReceiptSummary = ShareInfoV2 | null;
