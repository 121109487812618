import {put, call, select, takeEvery} from "redux-saga/effects";
import {AtgRequestError} from "@atg-shared/fetch-types";
import type {
    AtgResponse,
    FetchPromiseAction,
    FetchTriggerAction,
} from "@atg-shared/fetch-types";
import * as FetchActions from "./fetchActions";

const createRequestError = (error?: Error): AtgRequestError => {
    const message = error ? error.message : "Unknown error";
    const wrappedMessage = `Wrapped error: ${message}`;
    return new AtgRequestError(message, {
        data: {
            message: wrappedMessage,
        },
        meta: {code: -1, statusText: wrappedMessage},
        ok: false,
    });
};

const isAtgRequestError = (error: any): error is AtgRequestError => error?.response;

export function* fetchData(action: FetchTriggerAction<any, any, any, any, any>) {
    const {
        callApi,
        requestAction,
        receiveAction,
        shouldCallApi,
        context,
        transformResponse,
    } = action.payload;
    if (shouldCallApi) {
        if (typeof shouldCallApi === "function") {
            const shouldCall: boolean = yield select(shouldCallApi);
            if (!shouldCall) return;
        } else {
            const shouldCallSelector: (state: any) => boolean = yield call(
                shouldCallApi.func,
                ...shouldCallApi.params,
            );
            const shouldCall: boolean = yield select(shouldCallSelector);
            if (!shouldCall) return;
        }
    }

    try {
        yield put(FetchActions.request(requestAction, context));

        let response: AtgResponse<unknown>;
        if (typeof callApi === "function") {
            response = yield call(callApi);
        } else {
            response = yield call(callApi.func, ...callApi.params);
        }
        if (transformResponse) {
            response = transformResponse(response);
        }
        yield put(FetchActions.receive(receiveAction, response.data, context));
    } catch (error: unknown) {
        const requestError = isAtgRequestError(error)
            ? error
            : createRequestError(error as Error);

        // eslint-disable-next-line no-console
        console.log("fetchData: could not handle request/response", error);

        try {
            // This call can actually throw, hence the nested try/catch. See [HRS1-344] for example
            // of when this can happen (or feel free to refactor/fix :)
            yield put(FetchActions.receiveError(receiveAction, requestError, context));
        } catch (innerError: unknown) {
            // eslint-disable-next-line no-console
            console.warn("fetchData: error logic threw error...", innerError);
        }
    }
}

export function* fetchDataPromise(action: FetchPromiseAction<any>) {
    const {callApi, resolve, reject} = action.payload;
    try {
        let response: AtgResponse<unknown>;
        if (typeof callApi === "function") {
            response = yield call(callApi);
        } else {
            response = yield call(callApi.func, ...callApi.params);
        }
        resolve(response.data);
    } catch (error: unknown) {
        const requestError = isAtgRequestError(error)
            ? error
            : createRequestError(error as Error);
        reject(requestError);
    }
}

export default function* fetchSaga() {
    yield takeEvery(FetchActions.FETCH, fetchData);
    yield takeEvery(FetchActions.FETCH_PROMISE, fetchDataPromise);
}
