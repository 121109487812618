export type AtgResponseMeta = {
    code: number;
    statusText: string;
    totalCount?: number | null | undefined;
    authRequired?: boolean;
    tokenRefreshRequired?: boolean;
    isOffline?: boolean;
    exception?: Error;
};

export type AtgGenericResponse<T> = {
    data: T;
    meta: AtgResponseMeta;
};

/**
 * A successful response via the `atg-fetch` fetch function
 */
export type AtgResponse<T> = AtgGenericResponse<T> & {
    ok: true;
};

export type ErrorData = {
    message?: string;
    status?: string;
    reason?: string;
};

export enum ErrorReasons {
    ABS_UNAVAILABLE = "ABS_UNAVAILABLE",
    CONDITIONS_TOO_STRICT = "CONDITIONS_TOO_STRICT",
    DEPOSIT_LIMIT_NOT_SET = "DEPOSIT_LIMIT_NOT_SET",
    FAILED_VALIDATION = "FAILED_VALIDATION",
    GENERATOR_NOT_READY = "GENERATOR_NOT_READY",
    INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
    INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
    NAR_EXCLUSION = "NAR_EXCLUSION",
    NAR_UNDETERMINED = "NAR_UNDETERMINED",
    NETLOSS_LIMIT_EXCEEDED = "NETLOSS_LIMIT_EXCEEDED",
    NO_OF_SYSTEMS_PER_OFFERING_EXCEEDED = "NO_OF_SYSTEMS_PER_OFFERING_EXCEEDED",
    OFFERING_NOT_FOUND = "OFFERING_NOT_FOUND",
    POOL_CLOSED = "POOL_CLOSED",
    SELF_EXCLUSION = "SELF_EXCLUSION",
    SELL_NOT_OPEN = "SELL_NOT_OPEN",
    STAKE_TOO_HIGH = "STAKE_TOO_HIGH",
    STAKE_TOO_HIGH_REDUCED_BET = "STAKE_TOO_HIGH_REDUCED_BET",
    STAKE_TOO_LOW = "STAKE_TOO_LOW",
    TEMPORARY_SELF_EXCLUSION = "TEMPORARY_SELF_EXCLUSION",
    KYC_QUESTIONNAIRE_NOT_SUBMITTED = "KYC_QUESTIONNAIRE_NOT_SUBMITTED",
}

/**
 * We actually get the following error format from some services (e.g. horse-betting, but also others)
 * Structure varies (some have "details" key, others "message"), but in an essence it is an Array of error objects
 */
export type MultipleErrors = {
    errors: Array<{
        message?: string;
        code?: number;
        reason?: ErrorReasons;
    }>;
    status: number;
};

/**
 * An error response via the `atg-fetch` fetch function

 */
export type AtgErrorResponse = AtgGenericResponse<ErrorData> & {
    ok: false;
};

/**
 * An error response via the `atg-fetch` fetch function
 */
export type AtgMultipleErrorsResponse = AtgGenericResponse<MultipleErrors> & {
    ok: false;
};

/**
 * An error with multiple error response
 */
export type AtgRequestMultipleErrors = {
    response: AtgMultipleErrorsResponse;
};

export class AtgRequestError extends Error {
    response: AtgErrorResponse;

    constructor(message: string, response: AtgErrorResponse) {
        super(message);
        this.response = response;
    }
}
