import root from "window-or-global";
import type {AtgResponse} from "@atg-shared/fetch-types";
import atgRequest, {pureFetch} from "@atg-shared/fetch";
import type {TeamID, InviteCode, Invite, TeamInviteResult} from "@atg-tillsammans/types";
import {TEAM_SERVICE_URL} from "@atg-shared/service-url";

const getPublicApiUrl = () => `${root.clientConfig.horse.publicApiUrl}/team/api/v1/teams`;

export const fetchInviteInfo = (
    inviteCode: string,
    userName?: string,
): Promise<AtgResponse<Invite>> =>
    userName
        ? pureFetch(`${getPublicApiUrl()}/invitations/${inviteCode}/${userName}`, {
              method: "GET",
          })
        : pureFetch(`${getPublicApiUrl()}/invitations/${inviteCode}`, {
              method: "GET",
          });

export const joinTeamByInvite = (teamId: TeamID, inviteCode: string) =>
    atgRequest<TeamInviteResult>(`${TEAM_SERVICE_URL}/${teamId}/invitations`, {
        method: "POST",
        body: JSON.stringify({inviteCode}),
    })
        .then((response) => (response && response.data && response.data) || {})
        .catch((error) => {
            if (error.response.meta.code === 409) {
                return error.response.data;
            }
            throw error;
        });

/**
 * Originally from teamInviteApi.ts
 *
 * @param teamId
 * @returns
 */
export const getInviteCode = (teamId: TeamID) =>
    atgRequest<InviteCode>(`${TEAM_SERVICE_URL}/${teamId}/invitation`);

/**
 * Originally from teamInviteApi.ts
 *
 * @param param0
 * @returns
 */
export const refreshInviteCode = ({
    teamId,
    inviteId,
}: {
    teamId: TeamID;
    inviteId: number;
}) =>
    atgRequest<InviteCode>(`${TEAM_SERVICE_URL}/${teamId}/invitation/${inviteId}`, {
        method: "PUT",
    });
