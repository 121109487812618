import type {ImageAspectRatio} from "./aspectRatio";
import {ASPECT_RATIO_16_9, ASPECT_RATIO_1_1, ASPECT_RATIO_4_3} from "./aspectRatio";

export type ImageRef = string;

export type ImageUrl = string;

export interface ImageSize {
    width?: number;
    height?: number;
}

export interface ResponsiveImageProps {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
}

export interface BorderProps extends ResponsiveImageProps {
    color?: string;
    borderRadius?: string;
}

export interface ImageProps extends ImageSize {
    imageUrl: ImageUrl;
}

export enum ImageLabelType {
    SHOP = "SHOP",
    MEMBER = "MEMBER",
    TEAM = "TEAM",
}

export enum ImageFileFolder {
    members = "members",
    teams = "teams",
    filebets = "filebets",
    shops = "shops",
}

export type ImageFileOptions = {
    type: ImageFileFolder;
    aspectRatio: ImageAspectRatio;
    maxFileSize: number;
};

export const MEMBER_IMAGE_OPTIONS: ImageFileOptions = {
    type: ImageFileFolder.members,
    aspectRatio: ASPECT_RATIO_1_1,
    maxFileSize: 6,
};

export const TEAM_IMAGE_OPTIONS: ImageFileOptions = {
    type: ImageFileFolder.teams,
    aspectRatio: ASPECT_RATIO_16_9,
    maxFileSize: 6,
};

export const BATCH_BET_IMAGE_OPTIONS: ImageFileOptions = {
    type: ImageFileFolder.filebets,
    aspectRatio: ASPECT_RATIO_16_9,
    maxFileSize: 6,
};

export const SHOP_IMAGE_OPTIONS: ImageFileOptions = {
    type: ImageFileFolder.shops,
    aspectRatio: ASPECT_RATIO_4_3,
    maxFileSize: 6,
};

export type ImageFilePayload = {
    file: File;
};

// eslint-disable-next-line prefer-regex-literals
const regexp = RegExp("(https?://.*?)(/.*)");

const webImageUrl = /^https?:\/\/[a-z\d_\-./\\]+\.(jpg|jpeg|gif|png|svg|jfif)$/;
const webImageUrlWithoutGif = /^https?:\/\/[a-z\d_\-./\\]+\.(jpg|jpeg|png|svg|jfif)$/;

const isValidUrl = (imageUrl: ImageUrl, allowGif = true) =>
    allowGif
        ? webImageUrl.test(imageUrl.toLowerCase())
        : webImageUrlWithoutGif.test(imageUrl.toLowerCase());

const isAllowedExt = (imageUrl: ImageUrl | string) =>
    imageUrl.match(/^(.(?!.*\.gif$))*$/g);

const isAllowedImage = (imageUrl: ImageUrl | string) => {
    const extension = imageUrl?.split(".")?.pop()?.toLowerCase();
    return extension
        ? ["jpg", "jpeg", "gif", "png", "svg", "jfif"].includes(extension)
        : false;
};

const split = (imageUrl: ImageUrl) => {
    const match = imageUrl.match(regexp);
    return match && match.length === 3 ? {host: match[1], path: match[2]} : null;
};

const mapSize = ({width = 0, height = 0}: ImageProps): string =>
    width > 0 || height > 0 ? `/${width}x${height}` : "";

const size = (props: ImageProps): string => {
    const {imageUrl} = props;
    const image = split(imageUrl);
    return image ? `${image.host}${mapSize(props)}${image.path}` : imageUrl;
};

const fitIn = (props: ImageProps): string => {
    const {imageUrl} = props;
    const image = split(imageUrl);
    return image ? `${image.host}/fit-in${mapSize(props)}${image.path}` : imageUrl;
};

type ImageFilterOptions = {
    blur?: number;
    /*
     *  ...
     */
};

const mapFilterOptions = ({blur}: ImageFilterOptions) => {
    let filters = "";
    if (blur) filters += `:blur(${blur})`;
    return filters ? `/filters${filters}` : "";
};

const filter = (props: ImageProps, options: ImageFilterOptions = {}): string => {
    const {imageUrl} = props;
    const image = split(imageUrl);
    return image
        ? `${image.host}${mapSize(props)}${mapFilterOptions(options)}${image.path}`
        : imageUrl;
};

export const tools = {
    isValidUrl,
    isAllowedExt,
    isAllowedImage,
    fitIn,
    size,
    filter,
};
