import type {SagaIterator} from "redux-saga";
import root from "window-or-global";
import {get} from "lodash";
import {pureFetch} from "@atg-shared/fetch";
import type {AtgResponse} from "@atg-shared/fetch-types";
import {BIG9_OFFERING_API_URL} from "@atg-shared/service-url";
import type {Offering} from "@atg-sport-shared/big9-types/offeringTypes";
import type {StatisticsState} from "@atg-sport-shared/big9-types/statisticsTypes";
import type {MatchComments} from "@atg-sport-shared/big9-types/matchTypes";

type SagaIteratorOrPromise<T> = SagaIterator<T> | Promise<T>;

export const fetchSportGame = (gameId: string) =>
    pureFetch<Offering>(`${BIG9_OFFERING_API_URL}/${gameId}`);

export const fetchPreMatchStatistics = (offeringId: string) =>
    pureFetch<StatisticsState>(
        `${get(root, "clientConfig.sport.big9PreMatchStatistics")}/${offeringId}`,
    );

export const fetchMatchComments = (): SagaIteratorOrPromise<AtgResponse<MatchComments>> =>
    pureFetch<MatchComments>(
        `${get(root, "clientConfig.sport.cloudfrontUrl")}/content/big9MatchComments`,
    );
