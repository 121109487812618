import type {BrowserHistory} from "history";

export const ROUTER_ON_LOCATION_CHANGED = "@@router/ON_LOCATION_CHANGED" as const;

export const locationChangedAction = (payload: {
    action: BrowserHistory["action"];
    location: BrowserHistory["location"];
}) => ({
    type: ROUTER_ON_LOCATION_CHANGED,
    payload,
});

export type LocationChangedAction = ReturnType<typeof locationChangedAction>;
