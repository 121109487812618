import type {ApolloError} from "@apollo/client";
import {t} from "@lingui/macro";
import {CreateApplicationStatus} from "@atg-tillsammans/types";

export const getCreateErrorMessage = (apolloError: ApolloError): string => {
    const extensions = apolloError?.graphQLErrors[0]?.extensions;

    switch (extensions?.errorCode) {
        case "NOT_FOUND":
            return t({
                id: "application.error.message.NOT_FOUND",
                message: "Laget existerar inte.",
            });
        default:
            return t({
                id: "application.error.message.default",
                message: "Ett okänt tekniskt fel inträffade. Vänligen försök igen.",
            });
    }
};

export function getCreateSuccessMessage(status: CreateApplicationStatus): string {
    switch (status) {
        case CreateApplicationStatus.JOINED:
            return t({
                id: "application.success.message.JOINED",
                message: "Du är nu medlem i laget.",
            });
        case CreateApplicationStatus.AWAITING_APPROVAL:
            return t({
                id: "application.success.message.AWAITING_APPROVAL",
                message: "Din ansökan har skickats till laget.",
            });
        case CreateApplicationStatus.APPLICATION_EXISTS:
            return t({
                id: "application.success.message.APPLICATION_EXISTS",
                message: "Du har redan skickat in en ansökan till laget",
            });
        case CreateApplicationStatus.QUEUED:
            return t({
                id: "application.success.message.QUEUED",
                message: "Laget är fullt men du är ställd i kö.",
            });
        case CreateApplicationStatus.ALREADY_MEMBER:
            return t({
                id: "application.success.message.ALREADY_MEMBER",
                message: "Du är redan medlem i laget.",
            });
        case CreateApplicationStatus.TO_MANY_TEAMS:
            return t({
                id: "application.success.message.TO_MANY_TEAMS",
                message:
                    "Attans! Du kan inte gå med i fler lag eftersom du nått maxgränsen för antal lag per person. För att gå med i det här laget behöver du först gå ur ett annat.",
            });
        default:
            return "";
    }
}
