import root from "window-or-global";
import type {MarketConfig} from "./types";
import getMarketFromEnv, {isValidMarket} from "./getMarketFromEnv";

export * from "./types";

declare global {
    // eslint-disable-next-line vars-on-top, no-var
    var marketConfig: MarketConfig;
}

/**
 * Get the config for the current market
 * @returns market config object for current market
 */
const getMarketConfig = () => root.marketConfig;

export {getMarketConfig, getMarketFromEnv, isValidMarket};
