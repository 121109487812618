import styled from "@emotion/styled";
import {css} from "@emotion/react";

const wrapper = (height: string) => css`
    padding-top: ${height};
    overflow: hidden;
`;

export const ImageContainer = styled.div<{
    height?: string;
}>`
    width: 100%;
    position: relative;
    ${({height}) => height && wrapper(height)};
`;

export default ImageContainer;
