import {combineReducers} from "redux";
import {get} from "lodash";
import {createFetchReducer} from "@atg-shared/fetch-redux";
import type {FetchReducer, FetchState} from "@atg-shared/fetch-types";
import {type Pagination} from "atg-fetch-pagination/domain/pagination";
import * as ChatUtils from "../utils/teamChatUtils";
import {
    type BlockChatMemberAction,
    type ChatChannelMessagesFetchAction,
    type ChatPostMessageAction,
    type SavePostMessageAction,
    type RemoveChatMessageAction,
    type ReportChatMessageAction,
    ADD_MESSAGE,
    BLOCK_MEMBER,
    CHANGE_MESSAGE_TEXT,
    CLOSE_DIALOG,
    DELETE_MESSAGE,
    LOCATION_CHANGE,
    OPEN_DIALOG,
    POST_MESSAGE,
    RECEIVE_BLOCK_MEMBER_RESPONSE,
    RECEIVE_CHANNEL_MESSAGES,
    RECEIVE_POST_MESSAGE_RESPONSE,
    RECEIVE_REMOVE_MESSAGE_RESPONSE,
    RECEIVE_REPORT_MESSAGE_RESPONSE,
    RECEIVE_SAVE_MESSAGE_RESPONSE,
    PIN_OR_UNPIN_MESSAGE,
    REMOVE_MESSAGE,
    REPORT_MESSAGE,
    REQUEST_CHANNEL_MESSAGES,
    RESET_BLOCK_MEMBER,
    RESET_CHANNEL_STATUS,
    RESET_POST_MESSAGE,
    RESET_REMOVE_MESSAGE,
    RESET_REPORT_MESSAGE,
    RESET_SAVE_MESSAGE,
    SAVE_MESSAGE,
} from "./teamChatActions";
import {type ChannelId, type Message} from "./teamChat";
import {type Action} from "./index";

export type ChannelState = {
    id: ChannelId;
    messages: Message[];
    dialogOpen: boolean;
} & Pagination;

const INITIAL_STATE = {
    id: "",
    messages: [],
    dialogOpen: false,
    page: -1,
    hasMore: false,
};

export const channel: FetchReducer<ChannelState, ChatChannelMessagesFetchAction> =
    createFetchReducer(
        REQUEST_CHANNEL_MESSAGES,
        RECEIVE_CHANNEL_MESSAGES,
        RESET_CHANNEL_STATUS,
        (state: ChannelState, action: Action) => {
            switch (action.type) {
                case CHANGE_MESSAGE_TEXT:
                    return {
                        ...state,
                        messages: state.messages.map((message) => {
                            if (message.id !== action.message.id) {
                                return message;
                            }

                            return {
                                ...message,
                                ...action.message,
                            };
                        }),
                    };
                case ADD_MESSAGE:
                    if (action.message.channelId !== state.id) {
                        return state;
                    }
                    return {
                        ...state,
                        messages: ChatUtils.mergeMessages(state.messages, [
                            action.message,
                        ]),
                    };
                case DELETE_MESSAGE:
                    return {
                        ...state,
                        messages: state.messages.filter(
                            (message) => message.id !== action.messageId,
                        ),
                    };
                case PIN_OR_UNPIN_MESSAGE:
                    return {
                        ...state,
                        messages: state.messages.map((message) =>
                            message.id === action.message.id
                                ? {...message, pinned: action.message.pinned}
                                : {...message, pinned: false},
                        ),
                    };
                case OPEN_DIALOG:
                    return {
                        ...state,
                        dialogOpen: true,
                    };
                case LOCATION_CHANGE:
                case CLOSE_DIALOG:
                    return {
                        ...state,
                        dialogOpen: false,
                    };
                case REQUEST_CHANNEL_MESSAGES:
                    if (action.context && action.context.page > 0) {
                        return state;
                    }
                    return {...INITIAL_STATE, dialogOpen: state.dialogOpen};
                case RECEIVE_CHANNEL_MESSAGES: {
                    if (action.error) return state;
                    const pagination = action.context
                        ? {
                              id: action.context.channelId,
                              page: action.context.page,
                              hasMore: action.payload.length === 20,
                          }
                        : {};
                    return {
                        ...state,
                        messages: ChatUtils.mergeMessages(state.messages, action.payload),
                        ...pagination,
                    };
                }
                default:
                    return state;
            }
        },
        INITIAL_STATE,
    );

type PostMessageState = {
    messageText: string;
};

type RemoveMessageState = {
    error: string | null;
};

const getErrorCode = (action: unknown) =>
    get(action, "payload.response.data.errorCode", null);

export const postMessage: FetchReducer<PostMessageState, ChatPostMessageAction> =
    createFetchReducer(
        POST_MESSAGE,
        RECEIVE_POST_MESSAGE_RESPONSE,
        RESET_POST_MESSAGE,
        (state: PostMessageState, action: Action) => {
            switch (action.type) {
                case POST_MESSAGE:
                    return {
                        messageText: action.context ? action.context.messageText : "",
                    };
                case RECEIVE_POST_MESSAGE_RESPONSE:
                    if (action.error) return state;
                    return {messageText: ""};
                case RESET_POST_MESSAGE:
                    return {messageText: ""};
                default:
                    return state;
            }
        },
        {messageText: ""},
    );

export const saveMessage: FetchReducer<RemoveMessageState, SavePostMessageAction> =
    createFetchReducer(
        SAVE_MESSAGE,
        RECEIVE_SAVE_MESSAGE_RESPONSE,
        RESET_SAVE_MESSAGE,
        (state: RemoveMessageState, action: Action) => {
            switch (action.type) {
                case RECEIVE_SAVE_MESSAGE_RESPONSE:
                    if (!action.error) return state;

                    return {error: getErrorCode(action)};
                case RESET_SAVE_MESSAGE:
                    return {error: null};
                default:
                    return state;
            }
        },
        {error: null},
    );

export const removeMessage: FetchReducer<RemoveMessageState, RemoveChatMessageAction> =
    createFetchReducer(
        REMOVE_MESSAGE,
        RECEIVE_REMOVE_MESSAGE_RESPONSE,
        RESET_REMOVE_MESSAGE,
        (state: RemoveMessageState, action: Action) => {
            switch (action.type) {
                case RECEIVE_REMOVE_MESSAGE_RESPONSE:
                    if (!action.error) return state;

                    return {error: getErrorCode(action)};
                case RESET_REMOVE_MESSAGE:
                    return {error: null};
                default:
                    return state;
            }
        },
        {error: null},
    );

export const blockMember: FetchReducer<RemoveMessageState, BlockChatMemberAction> =
    createFetchReducer(
        BLOCK_MEMBER,
        RECEIVE_BLOCK_MEMBER_RESPONSE,
        RESET_BLOCK_MEMBER,
        (state: RemoveMessageState, action: Action) => {
            switch (action.type) {
                case RECEIVE_BLOCK_MEMBER_RESPONSE:
                    if (!action.error) return state;

                    return {error: getErrorCode(action)};
                case RESET_BLOCK_MEMBER:
                    return {error: null};
                default:
                    return state;
            }
        },
        {error: null},
    );

export const reportMessage: FetchReducer<RemoveMessageState, ReportChatMessageAction> =
    createFetchReducer(
        REPORT_MESSAGE,
        RECEIVE_REPORT_MESSAGE_RESPONSE,
        RESET_REPORT_MESSAGE,
        (state: RemoveMessageState, action: Action) => {
            switch (action.type) {
                case RECEIVE_REPORT_MESSAGE_RESPONSE:
                    if (!action.error) return state;

                    return {error: getErrorCode(action)};
                case RESET_REPORT_MESSAGE:
                    return {error: null};
                default:
                    return state;
            }
        },
        {error: null},
    );

export type State = {
    channel: FetchState<ChannelState>;
    postMessage: FetchState<PostMessageState>;
    saveMessage: FetchState<RemoveMessageState>;
    removeMessage: FetchState<RemoveMessageState>;
    reportMessage: FetchState<RemoveMessageState>;
    blockMember: FetchState<RemoveMessageState>;
};

const chatReducer = combineReducers({
    channel,
    saveMessage,
    postMessage,
    removeMessage,
    reportMessage,
    blockMember,
});

export default chatReducer;
