import {css} from "@emotion/react";
import {atgWhite, fontFamilyText} from "@atg-tillsammans-shared/css/overridableVariables";

export const aside = css`
    margin-right: 16px;
`;

export const wrapper = {
    margin: "16px",
    borderRadius: "16px",
};

export const main = css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    word-wrap: break-word;
    word-break: break-word;
`;

export const container = css`
    display: flex;
    flex-direction: row;
    color: ${atgWhite};
`;

export const content = css`
    align-self: stretch;
    margin-bottom: 8px;
`;

export const title = css`
    font-size: 16px;
`;

export const message = css`
    white-space: pre-wrap;
    font-size: 14px;
    font-family: ${fontFamilyText};
    line-height: 20px;
    position: relative;
    height: 36.4px;
    margin-right: 2px;
    &:after {
        content: "";
        text-align: right;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 70%;
        height: 16.8px;
        background: linear-gradient(to right, rgba(18, 18, 18, 0), rgba(18, 18, 18, 1));
    }
`;
