import * as React from "react";
import type {SagaIterator} from "redux-saga";
import {call, take, takeEvery} from "redux-saga/effects";
import * as UserActions from "@atg-global-shared/user/userActionTypes";
import {TillsammansAnalytics} from "@atg-tillsammans/analytics";
import {
    LOGIN_COMPLETE,
    LOGIN_STARTED,
} from "@atg-tillsammans/analytics/src/tillsammansAnalytics.types";
import {LOGIN_ON_TILLSAMMANS} from "./trackingActions";

export function* loginTillsammansStartpage(): SagaIterator {
    yield call(TillsammansAnalytics.trackLoginEvent, LOGIN_STARTED);
    const loginFinished = yield take(UserActions.LOGIN_FINISHED);

    if (loginFinished.payload.success) {
        TillsammansAnalytics.trackLoginEvent(LOGIN_COMPLETE);
    }
}

export default function* trackingSaga(): SagaIterator {
    yield takeEvery(LOGIN_ON_TILLSAMMANS, loginTillsammansStartpage);
}
