import {gql} from "@apollo/client";

export const CORE_BET_META_FIELDS = gql`
    fragment CoreBetMetaFields on BetMeta {
        id
        couponId
        description
        imageRef
    }
`;
