import type {BetWithDetails} from "@atg-tillsammans/types/generated";
import type {BetStatusErrorCode} from "../betTypes";

export const BET_SUCCESS = "@bet/SUCCESS";
export const BET_ERROR = "@bet/ERROR";
export const BET_RESET = "@bet/RESET";

export type BetSuccessAction = {
    type: typeof BET_SUCCESS;
    payload: {couponId: string; receipt: BetWithDetails};
};

export type BetErrorAction = {
    type: typeof BET_ERROR;
    payload: {couponId: string; code: BetStatusErrorCode};
};

export type BetResetAction = {
    type: typeof BET_RESET;
    payload: {couponIds: string[]};
};

export type Action = BetSuccessAction | BetErrorAction | BetResetAction;

export const betSuccess = (
    couponId: string,
    receipt: BetWithDetails,
): BetSuccessAction => ({
    type: BET_SUCCESS,
    payload: {couponId, receipt},
});

export const betError = (couponId: string, code: unknown): BetErrorAction => ({
    type: BET_ERROR,
    payload: {couponId, code: code as BetStatusErrorCode},
});

export const betReset = (couponIds: string[]): BetResetAction => ({
    type: BET_RESET,
    payload: {couponIds},
});
