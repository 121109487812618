import root from "window-or-global";
import {forEach} from "lodash/fp";
import type {TrackingEvent, TrackingVar} from "./analytics.types";

const isCamelCase = (str: string) => /^[a-z][a-zA-Z0-9]+$/.test(str);

export type TrackEvent<T extends TrackingEvent = TrackingEvent> = (eventData: T) => void;

/**
 * Send an event to GTM, by pushing it to `window.dataLayer`. Must contain the required `event`
 * property, which has special meaning in GTM.
 *
 * If you want to set a global tracking variable without tying it to a specific event, use the
 * `trackVariable` function instead.
 */
export const trackEvent: TrackEvent = (eventData) => {
    // To a large extent our GTM integration is based on name conventions. By being strict about our
    // naming it becomes easier to find the relevant events and variables in the GTM web admin.
    if (process.env.NODE_ENV !== "production") {
        // TypeScript will catch this mistake since `event` is a required field on the `TrackingEvent` type,
        // but this is helpful in case this function is used in an unsafe/untyped manner
        if (!eventData.event) {
            throw new Error(
                `Required property "event" missing in ${JSON.stringify(
                    eventData,
                    null,
                    2,
                )}`,
            );
        }

        if (!isCamelCase(eventData.event)) {
            throw new Error(
                `Expected event name (VALUE) to be in camelCase, got "${eventData.event}".`,
            );
        }

        // @ts-expect-error suppressed error for Flow to TS migration
        forEach.convert({cap: false})((val, key) => {
            if (!/^[a-z][a-zA-Z0-9]+$/.test(key)) {
                throw new Error(
                    `Expected event property (KEY) to be in camelCase, got "${key}".`,
                );
            }
        }, eventData);
    }

    if (root.dataLayer) {
        root.dataLayer.push(eventData);
    }
};

/**
 * Set a global GTM variable, by pushing it to `window.dataLayer`. Must **not** contain an `event`
 * property, which has special meaning in GTM.
 *
 * If you want to trigger an event, use the `trackEvent` function instead.
 */
export const trackVariable = (variableData: TrackingVar) => {
    // To a large extent our GTM integration is based on name conventions. By being strict about our
    // naming it becomes easier to find the relevant events and variables in the GTM web admin.
    if (process.env.NODE_ENV !== "production") {
        if (variableData.event) {
            throw new Error(
                `Prohibited property "event" exists in in ${JSON.stringify(
                    variableData,
                    null,
                    2,
                )}`,
            );
        }

        // @ts-expect-error: suppressed error during Flow to TS migration
        forEach.convert({cap: false})((val, key) => {
            if (!isCamelCase(key)) {
                throw new Error(
                    `Expected variable property (KEY) to be in camelCase, got "${key}".`,
                );
            }
        }, variableData);
    }

    if (root.dataLayer) {
        root.dataLayer.push(variableData);
    }
};
