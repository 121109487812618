import {t} from "@lingui/macro";
import {formatCurrency} from "@atg-shared/currency";
import {AlertSeverity} from "atg-ui-components";
import type {BetStatusError} from "../betTypes";
import {BetStatusErrorCodes} from "../betTypes";

export const getBetError = (code: unknown): BetStatusError => {
    switch (code) {
        case BetStatusErrorCodes.INVALID_COUPON_STAKE:
            return {
                code,
                message: t({
                    id: "bet.error.message.INVALID_COUPON_STAKE",
                    message: `Maxinsats för en kupong är ${formatCurrency(99999, {
                        fromMajor: true,
                    })}.`,
                }),
                level: AlertSeverity.WARNING,
            };
        case BetStatusErrorCodes.INVALID_HARRY_MAX_STAKE:
            return {
                code,
                message: t({
                    id: "bet.error.message.INVALID_HARRY_MAX_STAKE",
                    message: `Insatsen för Harry Boy måste vara mellan ${formatCurrency(
                        10,
                        {fromMajor: true},
                    )} och ${formatCurrency(10000, {fromMajor: true})}.`,
                }),
                level: AlertSeverity.WARNING,
            };
        case BetStatusErrorCodes.COUPON_ALREADY_PLACED:
        case BetStatusErrorCodes.BET_ALREADY_PLACED:
            return {
                code,
                message: t({
                    id: "bet.error.message.BET_ALREADY_PLACED",
                    message: "Spelet är redan inlämnat av en annan lagmedlem.",
                }),
                level: AlertSeverity.WARNING,
            };
        case BetStatusErrorCodes.INSUFFICIENT_FUNDS:
            return {
                code,
                message: t({
                    id: "bet.error.message.INSUFFICIENT_FUNDS",
                    message:
                        "Det finns inte tillräckligt med pengar i lagkassan för att lägga spelet. Ta bort några markeringar och prova igen.",
                }),
                level: AlertSeverity.WARNING,
            };
        case BetStatusErrorCodes.INVALID_HARRY_BET_V75:
        case BetStatusErrorCodes.INVALID_HARRY_BET_V86:
        case BetStatusErrorCodes.CONDITIONS_TOO_STRICT:
        case BetStatusErrorCodes.FAILED_TO_GENERATE_BET:
            return {
                code,
                message: t({
                    id: "bet.error.message.FAILED_TO_GENERATE_BET",
                    message:
                        "Du har inte gett Harry tillräckligt med utrymme att jobba. Pröva att höja insatsen eller ta bort några markeringar.",
                }),
                level: AlertSeverity.WARNING,
            };
        case BetStatusErrorCodes.ASYNC_RETRYABLE_TIMEOUT:
            return {
                code,
                message: t({
                    id: "bet.error.message.ASYNC_RETRYABLE_TIMEOUT",
                    message:
                        "Det tar lite längre tid än vanligt att lämna in spelet, vänta kvar!",
                }),
                level: AlertSeverity.WARNING,
            };
        case BetStatusErrorCodes.COMPETITION_UNDER_REVIEW:
            return {
                code,
                message: t({
                    id: "bet.error.message.COMPETITION_UNDER_REVIEW",
                    message: "Omgången är tyvärr tillfälligt stängd för spel.",
                }),
                level: AlertSeverity.ERROR,
            };
        case BetStatusErrorCodes.V75_CLOSED:
        case BetStatusErrorCodes.V86_CLOSED:
        case BetStatusErrorCodes.BETTING_CLOSED:
        case BetStatusErrorCodes.BETTING_SYSTEM_CLOSED:
        case BetStatusErrorCodes.SELL_NOT_OPEN:
            return {
                code,
                message: t({
                    id: "bet.error.message.SELL_NOT_OPEN",
                    message: "Omgången är stängd för spel.",
                }),
                level: AlertSeverity.ERROR,
            };
        case BetStatusErrorCodes.RACE_ALREADY_STARTED:
            return {
                code,
                message: t({
                    id: "bet.error.message.RACE_ALREADY_STARTED",
                    message:
                        "Spelet kan inte lämnas in eftersom tävlingarna har startat.",
                }),
                level: AlertSeverity.ERROR,
            };
        case BetStatusErrorCodes.ROUND_NOT_ACTIVATED:
        case BetStatusErrorCodes.DOWNSTREAM_TIMEOUT:
            return {
                code,
                message: t({
                    id: "bet.error.message.ROUND_NOT_ACTIVATED",
                    message:
                        "Ditt spel kunde tyvärr inte registreras på grund av ett temporärt tekniskt fel. Vänligen försök igen.",
                }),
                level: AlertSeverity.ERROR,
            };
        case BetStatusErrorCodes.MAX_NR_OF_BETS:
            return {
                code,
                message: t({
                    id: "bet.error.message.MAX_NR_OF_BETS",
                    message: "Ni kan tyvärr inte lämna in fler spel för omgången.",
                }),
                level: AlertSeverity.WARNING,
            };
        case BetStatusErrorCodes.LACKS_ROLE:
            return {
                code,
                message: t({
                    id: "bet.error.message.LACKS_ROLE",
                    message: "Du är inte spelläggare för den här omgången.",
                }),
                level: AlertSeverity.WARNING,
            };
        case BetStatusErrorCodes.MUST_PARTICIPATE:
            return {
                code,
                message: t({
                    id: "bet.error.message.MUST_PARTICIPATE",
                    message: "Du måste ha köpt en andel i spelet för att kunna lämna in.",
                }),
                level: AlertSeverity.WARNING,
            };
        case BetStatusErrorCodes.INVALID_NR_OF_SYSTEMS:
            return {
                code,
                message: t({
                    id: "bet.error.message.INVALID_NR_OF_SYSTEMS",
                    message: "Spelet innehåller kuponger med ogiltigt antal system.",
                }),
                level: AlertSeverity.WARNING,
            };
        case BetStatusErrorCodes.COUPONS_HAVE_DIFFERENT_GAME_TYPE_AND_DATE:
            return {
                code,
                message: t({
                    id: "bet.error.message.COUPONS_HAVE_DIFFERENT_GAME_TYPE_AND_DATE",
                    message:
                        "Spelet innehåller kuponger med blandade datum eller spelformer.",
                }),
                level: AlertSeverity.WARNING,
            };
        case BetStatusErrorCodes.MAX_NR_OF_BATCH_BETS:
            return {
                code,
                message: t({
                    id: "bet.error.message.MAX_NR_OF_BATCH_BETS",
                    message:
                        "Tyvärr, laget har redan spelat max antal filer för den här omgången.",
                }),
                level: AlertSeverity.WARNING,
            };
        case BetStatusErrorCodes.INVALID_SYSTEM_LIMIT_CUSTOM:
        case BetStatusErrorCodes.INVALID_SYSTEM_LIMIT:
            return {
                code,
                message: t({
                    id: "bet.error.message.INVALID_SYSTEM_LIMIT",
                    message:
                        "Spelet överstiger den begränsning på antal system som satts för laget denna omgång.",
                }),
                level: AlertSeverity.WARNING,
            };
        case BetStatusErrorCodes.BATCH_BETS_EXISTS_IN_QUEUE:
            return {
                code,
                message: t({
                    id: "bet.error.message.BATCH_BETS_EXISTS_IN_QUEUE",
                    message: "Det finns redan spel som köar. Prova igen lite senare.",
                }),
                level: AlertSeverity.WARNING,
            };
        case BetStatusErrorCodes.UNKNOWN_STATE:
            return {
                code,
                message: t({
                    id: "bet.error.message.UNKNOWN_STATE",
                    message:
                        "Ladda om lagsidan för att se om ni fått ett kvitto på spelet. Annars behöver du kontakta kundservice på 08-620 07 00.",
                }),
                level: AlertSeverity.WARNING,
            };
        case BetStatusErrorCodes.EMPTY_COUPON_LIST:
        case BetStatusErrorCodes.EMPTY_OR_NULL:
        case BetStatusErrorCodes.GAME_DATE_DO_NOT_MATCH:
        case BetStatusErrorCodes.INVALID_GAME_TYPE_IN_COUPON:
        case BetStatusErrorCodes.INVALID_NR_OF_MARKS:
        case BetStatusErrorCodes.INVALID_TOTAL_COST:
        case BetStatusErrorCodes.NR_OF_RACES_DO_NOT_MATCH:
        case BetStatusErrorCodes.XML_VALIDATION_ERROR:
        case BetStatusErrorCodes.GAME_TYPE_AND_DATE_DO_NOT_EXIST:
            return {
                code,
                message: t({
                    id: "bet.error.message.XML_VALIDATION_ERROR",
                    message:
                        "Tyvärr verkar något i din fil vara felaktigt angivet. Kontrollera filen och försök igen.",
                }),
                level: AlertSeverity.WARNING,
            };
        case BetStatusErrorCodes.INVALID_MARKS:
        case BetStatusErrorCodes.OBSOLETE_MARKS:
            return {
                code,
                message: t({
                    id: "bet.error.message.INVALID_MARKS",
                    message:
                        "Tyvärr verkar innehåller ditt spel ogiltiga markeringar. Kontrollera spelet och försök igen.",
                }),
                level: AlertSeverity.WARNING,
            };
        default:
            return {
                code: BetStatusErrorCodes.TECHNICAL_ERROR,
                message: t({
                    id: "bet.error.message.TECHNICAL_ERROR",
                    message:
                        "Ditt spel kunde tyvärr inte registreras på grund av ett temporärt tekniskt fel. Vänligen försök igen.",
                }),
                level: AlertSeverity.ERROR,
            };
    }
};
