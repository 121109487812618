import type {Solace} from "@atg-frame-shared/push";
import {broadcastAction} from "atg-store-addons";

export const INIT = "solace/INIT";
export const CONNECTED = "solace/CONNECTED";
export const RECONNECTED = "solace/RECONNECTED";
export const TEST_SOLACE_EVENT = "solace/TEST_SOLACE_EVENT";

export type ConnectedAction = {
    type: typeof CONNECTED;
    client: Solace;
};

export type ReconnectedAction = {
    type: typeof RECONNECTED;
    client: Solace;
};

export type InitAction = {
    type: typeof INIT;
};

export type TestSolaceEventAction = {
    type: typeof TEST_SOLACE_EVENT;
    eventCode: number;
};

export const connected = (client: Solace): ConnectedAction =>
    broadcastAction({
        type: CONNECTED,
        client,
    });

export const reconnected = (client: Solace): ReconnectedAction =>
    broadcastAction({
        type: RECONNECTED,
        client,
    });

export const init = (): InitAction => ({
    type: INIT,
});

export const testSolaceEvent = (eventCode: number): TestSolaceEventAction => ({
    type: TEST_SOLACE_EVENT,
    eventCode,
});
